import { Lock } from "@mui/icons-material";
import Button from "../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Desbloquear" },
  "en-US": { label: "Unlock" },
};

const Submit = ({ onSubmit: onClick }) => {
  return (
    <Button
      {...{
        UIText,
        color: "error",
        onClick,
        startIcon: <Lock />,
        variant: "outlined",
      }}
    />
  );
};

export default Submit;
