import { Grid } from "@mui/material";
import { Fragment } from "react";
import Divider from "../../UI2/Divider";
import GroupPicker from "../../UI2/Specialized/GroupPicker";

const Top = () => {

  return (
    <Fragment>
      <Grid
        container
        item
        {...{ justifyContent: "space-between", padding: 2, xs: 12 }}
      >
        <GroupPicker {...{ xs: 6, travelerType:'modal' }} />
      </Grid>
      <Divider />
    </Fragment>
  );
};

export default Top;
