const onlyNumbers = (str) => {
  const regex = /[^0-9]/g;
  const numbersOnly = str.replace(regex, "");
//   if (numbersOnly === "") {
//     return "String does not contain any numbers";
//   }
console.log(numbersOnly)
  return numbersOnly;
};

export default onlyNumbers;
