import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import Cancel from "./Components/Cancel";
import Start from "./Components/Start";
import Devices from "./Components/Display/Devices";

const FastRecovery = (props) => {
  const { dispatch, settings } = useUIBoilerplate();

  const devs = ["a", "b", "c"];

  const UIText = {
    "es-CL": {
      header: `Recuperar ${devs.length} dispositivo${true ? "(s)" : ""}`,
    },
    "en-US": { header: `Recover ${devs.length} devices${true ? "(s)" : ""}` },
  };
  const { header } = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  //   useEffect(() => {
  //     if (!isNew) {
  //       dispatch(UIactions.setField({ id: groupNameId, value: name }));
  //     }
  //   }, [dispatch, isNew, name]);

  //   useEffect(() => {
  //     return () => {
  //       dispatch(workers.clear(postGroupId));
  //       dispatch(UIactions.clear(groupNameId));
  //     };
  //   }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Devices />
      </DialogContent>
      <DialogActions sx={{padding:3,paddingTop:0,paddingBottom:2}}>
        <Cancel />
        <Start />
      </DialogActions>
    </Dialog>
  );
};

export default FastRecovery;
