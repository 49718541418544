import { CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import { UISliceActions } from "../../../store/slices/UI2";
import filterObjKeys from "../../../utility/filterObjectKeys";
import SingleTwinSelect from "../Utilities/SingleTwinSelect";
import AutoGrid from "../Wrappers/AutoGrid";
import { IconButton as MUIIconButton } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const CheckButton = (props) => {
  const { id, arrayId = "selected", size = "small", xs, fontSize } = props;
  const filteredProps = filterObjKeys(props, 'arrayId', "xs");
  const { dispatch, UI } = useUIBoilerplate();
  const selected  = UI[arrayId]??[]

  // if (!id) {
  //   console.log("CheckButton has no id!");
  //   return;
  // }

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(UISliceActions.toggleSingle({ id: arrayId, key: id }));
  };
  const isSelected = typeof selected.find((x) => x === id) !== "undefined";

  const icon = SingleTwinSelect(
    <CheckBoxOutlineBlank sx={{ fontSize: `${fontSize}rem` }} />,
    <CheckBox color="primary" sx={{ fontSize: `${fontSize}rem` }} />,
    isSelected
  );

  return (
    <AutoGrid {...{ xs }}>
      <MUIIconButton {...filteredProps} {...{ onClick: handleClick, size }}>
        {icon}
      </MUIIconButton>
    </AutoGrid>
  );
};

export default CheckButton;
