import { FactCheckOutlined } from "@mui/icons-material";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import IconButton from "../../../../../../UI2/IconButton";

const UIText = {
  'es-CL': { fixedTooltip: 'Ver detalles' },
  'en-US': { fixedTooltip: 'View details' },
}

const Actions = (props) => {
  const { id } = props;
  const { navigate } = useUIBoilerplate();

  const handleClick = () => {
          navigate(`/dashboard/recovery/review?id=${id}`);
  };

  // let data = {
  //   id,
  //   buttonsData: [
  //     {
  //       icon: <Edit sx={{ color: "grey" }} />,
  //       UIText: {
  //         "es-CL": { label: "Editar" },
  //         "en-US": { label: "Edit" },
  //       },
  //       onClick: () => {
  //         dispatch(
  //           UISliceActions.openModal({
  //             type: "edit-recovery-device",
  //             payload: { ...props },
  //           })
  //         );
  //       },
  //     },
  //     {
  //       icon: <Tune sx={{ color: "grey" }} />,
  //       UIText: {
  //         "es-CL": { label: "Avanzado" },
  //         "en-US": { label: "Advanced" },
  //       },
  //       onClick: () => {
  //         navigate(`/dashboard/recovery/advanced?id=${id}`);
  //       },
  //     },
  //     {
  //       icon: <Delete sx={{ color: "grey" }} />,
  //       UIText: {
  //         "es-CL": { label: "Remover" },
  //         "en-US": { label: "Remove" },
  //       },
  //       onClick: () => {
  //         dispatch(recoveriesSliceActions.deleteRecoveries([id]));
  //         dispatch(recoveriesSliceActions.removeUnusedRepositories());
  //         dispatch(UISliceActions.remove({ id: defaultSelected, key: id }));
  //       },
  //     },
  //   ],
  // };

  // return <MoreActions {...data} />;
  return <IconButton {...{ UIText, onClick:handleClick, icon: <FactCheckOutlined /> }} />;
};

export default Actions;
