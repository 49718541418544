import { useLocation } from "react-router-dom";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import DrawerButton from "./DrawerButton";
import { Person } from "@mui/icons-material";
import useURLQuery from "../../../../../../hooks2/useURLQuery";

const UIText = {
  "es-CL": { label: "Usuarios" },
  "en-US": { label: "Users" },
};

const Users = () => {
  const { auth } = useUIBoilerplate();
  const location = useLocation().pathname.split("/")[2];
  const { group } = useURLQuery();
  const selected = location === "users";
  const navTo = `/dashboard/users?group=${group ? group : auth.group}`;
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <Person {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Users;
