import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { timeDiff } from "../../../utility/humanizers";

const UIText = {
  "es-CL": { label: "Intervalo de respaldo" },
  "en-US": { label: "Backup intervalo" },
};

const Interval = (props) => {
  const { scan_interval, locale } = props;
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid container item xs={6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid container item xs={6}>
        <Typography>
          {scan_interval % 15 ? "~" : ""}
          {timeDiff(scan_interval * 60 * 1000)}
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default Interval;
