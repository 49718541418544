import { Grid } from "@mui/material";
import ShowDeleted from "./Deleted";
import Export from "./Export";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const Actions = ({allFiles}) => {
  const { auth } = useUIBoilerplate();
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <ShowDeleted />
      {auth.group === 1 ? <Export {...{allFiles}}/> : null}
    </Grid>
  );
};

export default Actions;
