import { Switch as MUISwitch } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import AutoGrid from "./Wrappers/AutoGrid";

const Switch = (props) => {
  const { id, xs, onChange: fn = () => {}, disableDefault } = props;
  const filteredProps = filterObjKeys(props, "xs", "onChange", "disableDefault");
  const { dispatch, UI } = useUIBoilerplate();
  const active = UI[id];

  const onChange = (e, value) => {
    if (!disableDefault) {
      dispatch(UIactions.setValue({ id, value }));
    }
    fn(value);
  };

  return (
    <AutoGrid {...{ xs }}>
      <MUISwitch {...filteredProps} {...{ onChange, checked: !!active }} />
    </AutoGrid>
  );
};

export default Switch;
