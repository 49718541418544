import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { createMachineData } from "./utils";
import { workersSliceActions as workers } from "../../slices/workers";

export const getDevicesId = "getDevices";
export const devicesDataId = "devices";
export const devicesListId = "devicesList";

const getDevices = (data) => {
  // console.log("getting devices(2)!");
  return async (dispatch) => {
    const { controller, cloner_key, server, user } = data;
    const id = getDevicesId
    dispatch(workers.start(getDevicesId));
    try {
      const response = await axios.get(server + "api/machinesXXX", {
        signal: controller.signal,
        headers: { cloner_key },
        params: { user },
      });
      // console.log('response',response)
      // let newDevices = [];
      for (const machine of response.data.machines_info) {
        const newDevice = createMachineData(user, 0, machine)
        dispatch(warehouse.add({id:'devices',key:newDevice.mid,data:newDevice}))
      }
      // newDevices.sort((a, b) => sortObjByKey(a, b, "name"));
      // dispatch(warehouse.load({ id: devicesDataId, data: newDevices }));
      // const newDevices2 = newDevices.filter( x => !!x.mid).map(x => x.mid)
      // dispatch(warehouse.load({id:devicesListId, data:newDevices2}))

      dispatch(workers.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default getDevices;
