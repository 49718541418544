/**
 * Login component that renders a login form with user input fields and buttons.
 * @returns {JSX.Element} Login component UI.
 */
// Importing necessary dependencies
import { Grid } from "@mui/material";
import { useEffect } from "react";
import loginToCloner from "../../store/actions2/Login/loginToCloner";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { workersSliceActions } from "../../store/slices/workers";
import {
  notEmptyPassword,
  notEmptyUser,
} from "../../utility/stringValidations-deprecated";
import LoginBtn from "./Components/Buttons/LoginBtn";
import ResetBtn from "./Components/Buttons/ResetBtn";
import Header from "./Components/Header";
import Password from "./Components/Password";
import TFAView from "./Components/TFAView/TFAView";
import User from "./Components/User/User";
import { id as loginId } from "../../store/actions2/Login/loginToCloner";
import { id as gauthId } from "../../store/actions2/Login/loginWithGAuth";
import Logo from "./Components/Logo2";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import Locale from "./Components/Buttons/Locale";
import LegacyBtn from "./Components/Buttons/LegacyBtn";
import LegacyDialog from "./Components/Legacy/LegacyDialog";

// Defining the Login component
const Login = () => {
  // Importing necessary dependencies
  const { auth, dispatch, navigate, UI, settings } = useUIBoilerplate();
  const locale = settings.locale ?? "es-CL";
  const { token } = auth;
  const { user = {}, password = {}, twofactor } = UI;
  const disabled = twofactor;
  const height = window.innerHeight;

  // Clearing workers and UI state on unmount
  useEffect(() => {
    return () => {
      dispatch(workersSliceActions.clear(loginId));
      dispatch(workersSliceActions.clear(gauthId));
      // dispatch(UIactions.fullClear());
    };
  }, [dispatch]);

  // Redirecting to dashboard if token exists
  useEffect(() => {
    if (token) {
      navigate("/dashboard/reports", { replace: true });
    }
  }, [dispatch, navigate, token]);

  // Hard validation function
  const hardValidation = () => {
    const value = { id: "user", fn: notEmptyUser, force: true };
    dispatch(UIactions.validateField(value));
    const value2 = { id: "password", fn: notEmptyPassword, force: true };
    dispatch(UIactions.validateField(value2));
  };

  // Login function
  const login = () => {
    if (!user.error && !password.error && user.value && password.value) {
      const payload = { user: user.value, pass: password.value, locale };
      dispatch(loginToCloner(payload));
    }
  };

  // Rendering the Login component
  return (
    <Grid
      container
      id="login-container"
      sx={{ width: "100vw", height }}
      alignContent="space-between"
    >
      <Locale />
      <Grid container item xs={12} rowGap={3}>
        <Logo />
        <Header {...{ locale }} />
        <User {...{ disabled, hardValidation, login }} />
        <Password {...{ disabled, hardValidation, login }} />
        {!twofactor ? (
          <Grid container item xs={12} justifyContent="center" rowGap={2}>
            <LoginBtn {...{ hardValidation, login }} />
            <ResetBtn />
          </Grid>
        ) : (
          <TFAView />
        )}
      </Grid>
      <Grid item xs={12} />
      <Grid container item xs={12} sx={{padding:1 }} justifyContent="end" >
        <LegacyBtn />
      </Grid>
      <LegacyDialog />
    </Grid>
  );
};

export default Login;
