import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";

export const deleteSharedLinksId = "deleteSharedLinks";

const UIText = {
  "es-CL": {
    success: "Los enlaces fueron eliminados exitosamente",
    error: "Error al eliminar el enlace de descarga:",
  },
  "en-US": {
    success: "The download links were deleted successfully",
    error: "Error while trying to delete the download link:",
  },
};

// deleting shared links
const deleteSharedLinks = (data) => {
  const { server, cloner_key, ids, locale } = data;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workers.start(deleteSharedLinksId));
    try {
      for (const id of ids) {
        console.log("id", id);
        const url = server + "api/share";
        await axios.delete(url, {
          params: { id },
          headers: { cloner_key },
        });
      }

      dispatch(workers.finish({ id: deleteSharedLinksId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id: deleteSharedLinksId, errors: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
  };
};

export default deleteSharedLinks;
