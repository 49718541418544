import { Grid } from "@mui/material";
import { useEffect } from "react";
import TextField from "../../../../UI2/TextField";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../store/slices/UI2";
import SlimAlert from "../../../../UI2/SlimAlert";
import notEmptyPassword from "../../../../../utility/validations/notEmptyPassword";
import { configDataId } from "../../../../../store/actions2/Devices/getConfig";
import { useWorker } from "../../../../../hooks2/useWorker";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import HelpTooltip from "../../../../UI2/HelpTooltip";
import Switch from "../../../../UI2/Switch2";

const UIText2 = {
  "es-CL": {
    label: "Bloqueo de selección",
    tooltip:
      "Impide el cambio de selección de archivos del dispositivo a menos que se proporcione la contraseña establecida",
    warning: "El bloqueo de cambio de selección requiere una contraseña",
  },
  "en-US": {
    label: "Selection block",
    tooltip:
      "Prevents the change of file selection from the device unless the established password is provided",
    warning: "The selection block requieres a password",
  },
};
const UIText = {
  "es-CL": { label: "Contraseña", placeholder: "********" },
  "en-US": { label: "Password", placeholder: "********" },
};

export const cpasswordActiveUI = "cpasswordActive";
export const cpasswordUI = "cpassword";

const Multiupload = (props) => {
  const { locale } = props;
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const { working } = useWorker(postDeviceId);
  const config = warehouse[configDataId] ?? {};
  const cpassword = config.cpassword;
  const active = UI[cpasswordActiveUI];
  const field = UI[cpasswordUI] ?? {};
  const { value, error } = field;
  const { warning } = UIText2[locale];

  useEffect(() => {
    if (!!cpassword && !value) {
      dispatch(UISliceActions.clear(cpasswordUI));
    }
  }, [dispatch, cpassword, value]);

  const reset = () => {
    dispatch(UISliceActions.clear(cpasswordUI));
  };

  const Warning = !cpassword && !value && active && !error ? warning : null;

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={7} alignItems="center" columnGap={1}>
        <Switch
          labelRight
          {...{
            id: cpasswordActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            xs: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid>
      <TextField
        {...{
          id: cpasswordUI,
          type: "password",
          UIText,
          disabled: working,
          hide: !active,
          validation: notEmptyPassword,
          xs: 5,
        }}
      />
      <SlimAlert
        {...{
          messages: [Warning ?? error],
          severity: Warning ? "warning" : undefined,
          paddingTop:1
        }}
      />
    </Grid>
  );
};

export default Multiupload;
