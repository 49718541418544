import { Grid } from "@mui/material";
import {default as LocaleMenu} from "../../../Dashboard/Components/TopBar/Actions/Locale";

const Locale = () => {
  return (
    <Grid container item xs={12} padding={2} justifyContent="end">
      <LocaleMenu />
    </Grid>
  );
};

export default Locale;
