import { Grid } from "@mui/material";
import RecursiveSwitch from "./Recursive";
import Configure from "./Configure";
import DeleteBtn from "./Delete";
import Recover from "./Recover";
import Exclusions from "./Exclusions";

const Actions = () => {
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <RecursiveSwitch />
      <Configure />
      <Exclusions />
      <Recover />
      <DeleteBtn />
    </Grid>
  );
};

export default Actions;