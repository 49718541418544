/**
 * Creates a recovery request for the given devices and dispatches actions to update the store.
 *
 * @param {Object} data - The data required to create the recovery request.
 * @param {string} data.server - The server URL to send the request to.
 * @param {string} data.cloner_key - The API key to authenticate the request.
 * @param {string} data.locale - The locale to use for displaying UI text.
 * @param {Array<Object>} data.devices - The devices to create the recovery request for.
 * @returns {Function} - The async function that dispatches actions to update the store.
 */
import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { recoveriesSliceActions as recoveries } from "../../slices/recoveries";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": {
    info: "Creando solicitud de recuperación",
    success: "Solicitud de recuperación creada",
    error: "Error al obtener los repositorios",
  },
  "en-US": {
    info: "Creating recovery request",
    success: "Recovery request created",
    error: "Error fetching repositories",
  },
};

export const addRecoveriesUI = "addRecoveries";

const addRecoveries = (data) => {
  const { server, cloner_key, locale, devices } = data;
  const id = addRecoveriesUI;
  const { success, error, info } = UIText[locale];

  return async (dispatch) => {
    dispatch(workers.start(id));
    enqueueSnackbar(info, { variant: "info" });

    for (const device of devices) {
      const { user, mid, space_active, lastBkp } = device;
      if (!mid) {
        continue;
      }
      let repositories = [];
      try {
        const url = server + "api/repositories";
        const response = await axios.get(url, {
          headers: { cloner_key },
          params: { id: mid, user },
        });
        for (const repo of response.data.repositories) {
          repositories.push({
            id: repo.id,
            metafile: repo.root,
            name: repo.name,
            last_backup: repo.last_backup,
            size: repo.active_space,
            recover: true,
          });
        }
      } catch (e) {
        enqueueSnackbar(`${error}: ${e.message}`, { variant: "error" });
        dispatch(workers.finish({ id, error: e.message }));
      }
      dispatch(
        recoveries.newRecovery({
          user,
          mid,
          device: device.name,
          repositories,
          fullSize: space_active,
          lastBkp,
          mode: "simple",
        })
      );
    }

    enqueueSnackbar(success, { variant: "success" });
    dispatch(recoveries.initialize());
    dispatch(workers.finish({ id }));
  };
};

export default addRecoveries;
