import { Box, Grid, Typography } from "@mui/material";
import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";
import { AttachFile } from "@mui/icons-material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { tt: "tiene archivos adjuntos" },
  "en-US": { tt: "has attached files" },
};

const Subject = ({ fontSize, subject, bodyPreview, hasAttachments }) => {
  const { settings } = useUIBoilerplate();
  const fixedTooltip = (subject ? `${subject} - ` : "") + bodyPreview;
  const { tt } = UIText[settings.locale];
  // const fixedTooltip = subject;
  // console.log("fixedTooltip", subject);

  return (
    <Grid container item columnGap={1} xs={12} alignItems="center">
      <AutoHelp {...{ fixedTooltip }}>
        <Grid item xs={hasAttachments?11:12}>
          <Typography noWrap component="div" fontSize={13}>
            {subject ? (
              <Box display="inline" sx={{ fontWeight: "bold" }}>
                {subject}
                {" - "}
              </Box>
            ) : null}
            {bodyPreview}
          </Typography>
        </Grid>
      </AutoHelp>
      {hasAttachments ? (
        <Grid container item xs={0.5} alignItems="center" justifyContent="end">
          <AutoHelp {...{ fixedTooltip: tt }}>
            <AttachFile sx={{ fontSize: 20 }} color="primary" />
          </AutoHelp>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default Subject;
