/**
 * Rescan component.
 * Renders an action button that triggers a force rescan action for selected devices.
 *
 * @returns {JSX.Element} The Rescan component.
 */
import { YoutubeSearchedFor } from "@mui/icons-material";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../store/actions2/Devices/getAllDevices";
import { useWorker } from "../../../../hooks2/useWorker";
import forceRescan, {
  forceRescanId,
} from "../../../../store/actions2/Reports/forceRescan";

const UIText = {
  "es-CL": { label: "Forzar escaneo" },
  "en-US": { label: "Force scan" },
};

const Rescan = () => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { working } = useWorker(forceRescanId);
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesDataId];

  const onClick = () => {
    let payload = [];
    for (const mid of selected) {
      const device = devices[mid];
      const payloadItem = {
        user: device.user,
        machine: device.mid,
        token: auth.token,
      };
      payload.push(payloadItem);
    }
    dispatch(
      forceRescan({
        ...auth,
        payload,
        locale: settings.locale,
      })
    );
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <YoutubeSearchedFor />,
        variant: "outlined",
      }}
    />
  );
};

export default Rescan;
