import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const { dispatch } = useUIBoilerplate();
  // const open = UI["group-traveler2-open"] ?? false;

  const onClick = () => {
    dispatch(UISliceActions.closeModal());
  };

  return <Button {...{ UIText, onMouseUp: onClick, variant:'text', size:'medium' }} />;
};

export default Cancel;
