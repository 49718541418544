import { useEffect } from "react";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

// Dashboard reroute
const Reroute = () => {
  const { navigate, auth } = useUIBoilerplate();
  const { group } = auth;

  useEffect(() => {
    navigate("/dashboard/reports?group=" + group, { replace: true });
  }, [navigate, group]);
};

export default Reroute;
