export const options = [
  { universal: { label: "100 kbps" }, value: 12800 },
  { universal: { label: "500 kbps" }, value: 64000 },
  { universal: { label: "1 Mbps" }, value: 131072 },
  { universal: { label: "5 Mbps" }, value: 655360 },
  { universal: { label: "10 Mbps" }, value: 1310720 },
  { universal: { label: "20 Mbps" }, value: 2621440 },
  { universal: { label: "40 Mbps" }, value: 5242880 },
  { universal: { label: "80 Mbps" }, value: 10485760 },
  { universal: { label: "160 Mbps" }, value: 20971520 },
];

export const options2 = [
  { universal: { label: "2" }, value: 2 },
  { universal: { label: "3" }, value: 3 },
  { universal: { label: "4" }, value: 4 },
  { universal: { label: "5" }, value: 5 },
];

export const options3 = [
  {
    "es-CL": { label: "15 minutos" },
    "en-US": { label: "15 minutes" },
    value: 15,
  },
  {
    "es-CL": { label: "30 minutos" },
    "en-US": { label: "30 minutes" },
    value: 30,
  },
  {
    "es-CL": { label: "45 minutos" },
    "en-US": { label: "45 minutes" },
    value: 45,
  },
  {
    "es-CL": { label: "1 hora" },
    "en-US": { label: "1 hour" },
    value: 60,
  },
  {
    "es-CL": { label: "2 hours" },
    "en-US": { label: "2 hours" },
    value: 120,
  },
  {
    "es-CL": { label: "4 horas" },
    "en-US": { label: "4 hours" },
    value: 240,
  },
  {
    "es-CL": { label: "8 horas" },
    "en-US": { label: "8 hours" },
    value: 480,
  },
  {
    "es-CL": { label: "10 horas" },
    "en-US": { label: "10 hours" },
    value: 600,
  },
  {
    "es-CL": { label: "12 horas" },
    "en-US": { label: "12 hours" },
    value: 720,
  },
  {
    "es-CL": { label: "16 horas" },
    "en-US": { label: "16 hours" },
    value: 960,
  },
  {
    "es-CL": { label: "20 horas" },
    "en-US": { label: "20 hours" },
    value: 1200,
  },
  {
    "es-CL": { label: "24 horas" },
    "en-US": { label: "24 hours" },
    value: 1440,
  },
];