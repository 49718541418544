import { enqueueSnackbar } from "notistack";
import { workersSliceActions as workers } from "../../slices/workers";
import axios from "axios";

export const deleteUsersId = "deleteUsers";

const deleteUsers = (data) => {
  const { server, cloner_key, users, locale } = data;
  const id = deleteUsersId;
  const UIText = {
    "es-CL": {
      success:
        users.length > 1
          ? "Usuarios eliminados exitosamente"
          : "Usuario eliminado exitosamente",
      error: "Error al intentar eliminar el usuario ",
      start: `Eliminando ${users.length} usuarios...`,
    },
    "en-US": {
      success: `User${users.length > 1 ? "s" : ""} deleted successfully`,
      error: "Error trying to delete user: ",
      start: `Deleting ${users.length} users`,
    },
  };
  const { start, success, error } = UIText[locale];

  return async (dispatch) => {
    if (users.length > 1) {
      enqueueSnackbar(start, { variant: "info" });
    }
    dispatch(workers.start(id));
    let currentUser = "";
    try {
      for (const user of users) {
        currentUser = user;
        const url = server + "api/users";
        await axios.delete(url, { params: { user }, headers: { cloner_key } });
      }

      enqueueSnackbar(success, { variant: "success" });
      dispatch(workers.finish({ id }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + currentUser + ": " + e.message, {
        variant: "error",
      });
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default deleteUsers;
