import MoreActions from "../../../../UI2/BasicLayout/Components/MoreActions/MoreActions";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import {
  Description,
  Download,
  History,
  LocalShipping,
  Share,
} from "@mui/icons-material";
import useURLQuery from "../../../../../hooks2/useURLQuery";
import createSharedLink from "../../../../../store/actions2/Shared/createSharedLink";
import loadVersions from "../../../../../store/actions2/Devices/loadVersions";
import useRecoveries from "../../../../../hooks2/useRecoveries";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../../store/actions2/Devices/getDevices";
import { repoDataId } from "../../../../../store/actions2/Devices/getRepositories2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { success: "Recuperación creada exitosamente" },
  "en-US": { success: "Recovery created successfully" },
};

const Actions = (props) => {
  const {
    id,
    handleDownload,
    auth,
    locale,
    type,
    num_versions,
    repo: repoId,
    name,
  } = props;
  const { user, repo, mid } = useURLQuery();
  const { dispatch, navigate, warehouse } = useUIBoilerplate();
  const { initialized } = useRecoveries();
  const devices = warehouse[devicesDataId];
  const repositories = warehouse[repoDataId];
  const { success } = UIText[locale];

  let buttonsData = [];
  if (type !== 99) {
    buttonsData.push({
      icon: <Download sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Descargar",
          fixedTooltip: "Descargar archivo o directorio",
        },
        "en-US": {
          label: "Download",
          fixedTooltip: "Download file or directory",
        },
      },
      onClick: handleDownload,
    });
    buttonsData.push({
      icon: <Share sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Compartir",
          fixedTooltip: "Crear enlace de descarga",
        },
        "en-US": { label: "Share", fixedTooltip: "Create download link" },
      },
      onClick: () => {
        dispatch(
          createSharedLink({
            ...auth,
            user,
            payload: { file: id, repo_id: repo, public: true },
            locale,
          })
        );
      },
    });
  }

  if (type === 99) {
    buttonsData.push({
      icon: <LocalShipping sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": { label: "Recuperar", fixedTooltip: "Recuperar archivo" },
        "en-US": { label: "Recover", fixedTooltip: "Recover file" },
      },
      // disabled: true, // TODO: enable
      onClick: () => {
        const device = devices.find((d) => d.mid === mid);
        // console.log("device", device);
        // console.log("repositories", repositories);

        let newRepositories = [];
        for (const repo of repositories) {
          newRepositories.push({
            id: repo.id,
            metafile: repo.root,
            name: repo.name,
            last_backup: repo.last_backup,
            size: repo.active_space,
            recover: repo.id === repoId,
          });
        }

        const newRecovery = {
          user: device.user,
          mid: device.mid,
          device: device.name,
          repositories: newRepositories,
          fullSize: device.space_active,
          lastBkp: device.lastBkp,
          mode: "advanced",
        };

        if (!initialized) {
          dispatch(recoveries.reset());
          dispatch(recoveries.newRecovery(newRecovery));
          dispatch(recoveries.initialize());
          dispatch(recoveries.dontAskAgain());
          enqueueSnackbar(success, { variant: "success" });
          navigate("/dashboard/recovery/step1");
        } else {
          dispatch(
            UIactions.openModal({
              type: "new-repository-new-request",
              payload: { newRecovery, repoName: name },
            })
          );
        }
      },
    });
  }

  if (type === 1) {
    buttonsData.push({ divider: true });
    buttonsData.push({
      icon: <History sx={{ color: "grey" }} />,
      disabled: num_versions < 2,
      UIText: {
        "es-CL": {
          label: `Versiones ( ${num_versions} )`,
          fixedTooltip: "Ver versiones anteriores",
        },
        "en-US": {
          label: `Versions ( ${num_versions} )`,
          fixedTooltip: "Check previous versions",
        },
      },
      onClick: () => {
        dispatch(
          loadVersions({
            ...auth,
            file: id,
            repo,
          })
        );
        dispatch(
          UIactions.openModal({
            type: "file-versions",
            payload: {},
          })
        );
      },
    });
  }

  if (auth.admin === 1) {
    buttonsData.push({
      icon: <Description sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Información",
          fixedTooltip: "Información del archivo/repositorio",
        },
        "en-US": { label: "Data", fixedTooltip: "File/repository data" },
      },
      onClick: () => {
        dispatch(
          UIactions.openModal({
            type: "file-details",
            payload: { data: props },
          })
        );
      },
    });
  }

  const data = { id, buttonsData };

  return <MoreActions {...data} />;
};

export default Actions;
