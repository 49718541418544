const SingleTwinSelect = (opt1, opt2, selected) => {
  if (!opt1 && !opt2) {
    return null;
  }

  if (!opt2) {
    return opt1;
  }

  if (selected) {
    return opt2;
  }

  return opt1;
};

export default SingleTwinSelect