import { CircularProgress, Grid } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const QRScan = () => {
  const { warehouse } = useUIBoilerplate();
  const { QR } = warehouse;

  const src = "data:image/png;base64," + QR;
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="start"
      height={300}
    >
      <Grid container item xs='auto' sx={{ height: "100%" }} alignItems='center'>
        {QR ? (
          <img
            ng-src={src}
            src={src}
            width="100%"
            height="100%"
            alt="placeholder"
          />
        ) : (
          <CircularProgress size={60}/>
        )}
      </Grid>
    </Grid>
  );
};

export default QRScan;
