import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import VerificationCodeField from "./VerificationCodeField";

const UIText = {
  "es-CL": "Codigo de verificación",
  "en-US": "Verification code",
};

const VerificationCode = () => {
    const {settings} = useUIBoilerplate()
    const label = UIText[settings.locale]

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="space-between"
    //   columnSpacing={2}
    >
      <Grid container item xs={4} alignItems="center">
        <Typography>{label}</Typography>
      </Grid>
      <VerificationCodeField {...{ idIdx: 0 }} />
      <VerificationCodeField {...{ idIdx: 1 }} />
      <VerificationCodeField {...{ idIdx: 2 }} />
      <VerificationCodeField {...{ idIdx: 3 }} />
      <VerificationCodeField {...{ idIdx: 4 }} />
      <VerificationCodeField {...{ idIdx: 5 }} />
    </Grid>
  );
};

export default VerificationCode;
