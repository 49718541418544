import { useDispatch } from "react-redux";
import { UISliceActions } from "../../store/slices/UI2";
import Button from "../UI2/Button";

const UIText = {
  "es-CL": { label: "Editar" },
  "en-US": { label: "Edit" },
};

const Edit = (props) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "device-edit",
        payload: { devices:[props] },
      })
    );
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Edit;
