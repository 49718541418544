import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import buildPath from "../../../utility/buildRemoteSelectionPath";
import { newTreesSliceActions as trees } from "../../slices/trees2";
import { calculateSelection, parseFileTree } from "../../slices/trees2-utils";

const loadRepositoryRoot = (data) => {
  return async (dispatch) => {
    const {
      cpassword: cpass,
      path,
      repository: id,
      server,
      tunnelToken: tunnel_token,
    } = data;
    // console.log("path?", path);
    dispatch(worker.start('root'+path));

    try {
      if (!!id) {
        console.log("fetching Greylist!");
        const response = await axios.get(
          server.slice(0, -1) + ":5107/repo/greylist/get",
          { headers: { tunnel_token }, params: { id, cpass } }
        );
        const rawGreylist = response.data.selection;

        let greylist = [];
        const rawTreeNode = {
          parentIdx: null,
          rawNodeData: rawGreylist,
        };
        parseFileTree(greylist, rawTreeNode);
        calculateSelection(greylist, 0);

        // console.log('greylist',greylist)

        let nodes = [
          {
            idx: 0,
            parentIdx: null,
            childrenIdx: [],
            isDir: true,
            name: path,
            backup: greylist[0].backup,
            status: 0,
          },
        ];


        // console.log("greylist", greylist);

        // here we check the tree to properly set all nodes backmodes. This might need further node downloading
        // if the node backupMode cannot be determined without it
        const setBaseline = async (rIdx, glIdx) => {
          const rNode = nodes.find((x) => x.idx === rIdx);

          // console.log(`Node ${rIdx} backup status: ${rNode.backup}`)

          if (rNode.backup < 3 && rNode.backup > 0) {
            let path = encodeURIComponent(buildPath(nodes, rNode.idx, true));
            const response = await axios.get(
              server.slice(0, -1) + ":5107/files",
              { headers: { tunnel_token }, params: { path } }
            );
            const glChildren = greylist.filter((x) => x.parentIdx === glIdx);
            const children = response.data.children;

            // console.log('children',children)

            for (const child of children) {
              const glChild = glChildren.find((x) => x.name === child.Name);
              const idx = nodes.length;
              let newChild = {
                idx,
                parentIdx: rIdx,
                name: child.Name,
                isDir: child.IsDir,
                size: child.Size,
                status: child.Status,
                childrenIdx: [],
              };
              if (glChild) {
                newChild.backup = glChild.backup;
              } else {
                if (rNode.backup === 2) {
                  newChild.backup = 3;
                } else if (rNode.backup === 1) {
                  newChild.backup = 0;
                } else {
                  console.error("this should not be happening. ever.");
                }
              }
              nodes.push(newChild);
              rNode.childrenIdx.push(newChild.idx);

              if (glChild) {
                await setBaseline(newChild.idx, glChild.idx);
              }
            }
            rNode.loaded = true;
          } else {
            return;
          }
        };

        await setBaseline(0, 0);

        dispatch(trees.loadTree({ id: path, tree: nodes }));
      } else {
        console.log("Repository doesnt exist yet!");
        let nodes = [
          {
            idx: 0,
            parentIdx: null,
            childrenIdx: [],
            isDir: true,
            name: path,
            backup: 0,
            status: 0,
          },
        ];
        dispatch(trees.loadTree({ id: path, tree: nodes }));

      }
      dispatch(worker.finish({ id: 'root'+path }));
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id: 'root'+path, error: e.message }));
      return;
    }
    // dispatch(workersSliceActions.finish({ id: path }));
    // dispatch(statusSliceActions.finishLoading(""));
  };
};

export default loadRepositoryRoot;
