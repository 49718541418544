import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { error: "Fallo al intentar descargar el archivo adjunto " },
  "en-US": { error: "Error trying to download the attached file " },
};

const downloadAttachment = (data) => {
  const { W365server, cloner_key, id, name, locale } = data;
  const { error } = UIText[locale];
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  return async (dispatch) => {
    try {
      let newURL = new URL(`${W365server}download/attachment`);
      newURL.searchParams.append("cloner_key", cloner_key);
      newURL.searchParams.append("attachment_id", id);
      console.log(newURL.toString());

      const anchor = document.createElement("a");
      anchor.href = newURL;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`${error} ${name}: ${e.message}`, { variant: "error" });
    }
  };
};

export default downloadAttachment;
