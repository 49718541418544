import Block from "./Block";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import AditionalOptions, { aditionalOptionUI, recoveryDateUI } from "./AditionalOptions";
import Divider from "../../../../../../UI2/Divider";
import { Grid } from "@mui/material";
import { humanBytes } from "../../../../../../../utility/humanizers";
import Switch from "../../../../../../UI2/Switch2";
import { UISliceActions } from "../../../../../../../store/slices/UI2";
import AditionalDetails, { aditionalInfoUI } from "./AditionalDetails";

const UIText = {
  "es-CL": {
    repoLabel: "Repositorio:",
    sizeLabel: "Tamaño:",
  },
  "en-US": {
    repoLabel: "Repository:",
    sizeLabel: "Size:",
  },
};

const UITextSwitch = {
  "es-CL": { label: "Recuperar repositorio" },
  "en-US": { label: "Recover repository" },
};

export const recoverRepoUI = "recoverRepo";

const Repository = (props) => {
  const { id, name, size, readOnly } = props;
  // console.log("repository", props);
  const {dispatch, settings, UI } = useUIBoilerplate();
  const { repoLabel, sizeLabel } = UIText[settings.locale];
  const recover = UI[`${recoverRepoUI}-${id}`];

  const onChange = () => {
    dispatch(UISliceActions.clear(`${aditionalOptionUI}-${id}`));
    dispatch(UISliceActions.clear(`${recoveryDateUI}-${id}`));
    dispatch(UISliceActions.clear(`${aditionalInfoUI}-${id}`));
  };

  return (
    <Grid container item>
      <Divider />
      <Grid
        container
        item
        {...{
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2,
          xs: 12,
          rowGap: 2,
          alignItems: "center",
        }}
      >
        <Block {...{ label: repoLabel, value: name }} />
        <Block {...{ label: sizeLabel, value: humanBytes(size) }} />
        <Switch
          {...{
            id: `${recoverRepoUI}-${id}`,
            disabled:readOnly,
            UIText: UITextSwitch,
            onChange,
            xl: 4,
            lg: 4,
            md: 4,
            sm: 12,
            xs: 12,
          }}
        />
        {recover ? <AditionalOptions {...{id, readOnly}}/> : null}
        {recover?<AditionalDetails {...{id, readOnly}}/>:null}
      </Grid>
    </Grid>
  );
};

export default Repository;
