const filterObjKeys = (obj,...keys)=>{
    let newObj = {}
    for (const keyY in obj){
        let found =false
        for (const keyZ of keys ){
            if (keyY === keyZ){
                found = true
                break
            }
        }
        if (!found){
            newObj[keyY] = obj[keyY]
        }
    }
    return newObj
}

export default filterObjKeys