// Validaciones basicas
export const validateMailLoose = (email) => {
  // mail must have the @ character inside
  if (!email.match(/\b.+@.+\b/)) {
    return false
  }
  return true
};

export const validatePassword = (pass) => {
  if (pass) {
    if (pass.length < 10) {
      return false
    } else if (
      !pass.match(/[A-Z]/) ||
      !pass.match(/[a-z]/) ||
      !pass.match(/[0-9]/)
    ) {
      return false
    }
  }
  return true
};
