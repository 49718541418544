import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Sender from "./Components/Sender";
import Subject from "./Components/Subject";
import ClickableRow from "../../../UI2/BasicLayout/Components/ClickableRow";
import Date from "./Components/Date";
import Restore from "./Components/Restore";
import { UISliceActions } from "../../../../store/slices/UI2";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const MailRow = (props) => {
  const { dispatch } = useUIBoilerplate();
  const { id, fromM, toRecipients, subject, bodyPreview, receivedDateTime, hasAttachments } = props;

  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "view-mail-content",
        payload: { ...props },
      })
    );
  };

  const data = {
    id,
    elements: [
      { content: <Sender {...{ fromM, toRecipients, fontSize }} />, xs: 2 },
      {
        content: <Subject {...{ subject, bodyPreview, fontSize, hasAttachments }} />,
        lg: 7.5,
        md: 6.7,
        sm: 4.9,
        xs: 4,
      },
      { content: <Date {...{ receivedDateTime, fontSize }} />, xs: true },
      { content: <Restore {...{id}}/>, justify: "end", xs: 0.5 },
    ],
  };

  return <ClickableRow {...data} {...{ onClick }} />;
};

export default MailRow;
