import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { newTreesSliceActions } from "../../slices/trees2";

const loadRepositoryNode = (data) => {
  return async (dispatch) => {
    const { id, root, idx, server, tunnelToken: tunnel_token, path } = data;
    dispatch(worker.start(id));
    try {
      console.log("fetching node!");
      const response = await axios.get(server.slice(0, -1) + ":5107/files", {
        headers: { tunnel_token },
        params: { path: encodeURIComponent(path) },
      });

      const children = response.data.children;

      for (const child of children) {
          dispatch(newTreesSliceActions.addRSnode({ id: root, idx, node: child }))
      }

      dispatch(newTreesSliceActions.RSnodeLoaded({id:root,idx}))
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      //   let msg = errorResolve(error);
      //   dispatch(errorSnackbar(msg));
      dispatch(worker.finish({ id, error: e.message }));
      return;
    }
  };
};

export default loadRepositoryNode;
