import { Laptop } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import getDevices, {
  devicesDataId,
  getDevicesId,
} from "../../../store/actions2/Devices/getDevices";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import { warehouseSliceActions as Warehouse } from "../../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../../store/slices/workers";
import { sortObjByKey } from "../../../utility/sortObjByKey";
import AutoGrid from "../Wrappers/AutoGrid";
import AutoHelp from "../Wrappers/AutoHelp";
import { AutocompleteFilter } from "./utils";

const UIText = {
  "es-CL": {
    normalTxt: "Dispositivos",
    loadingTxt: "Cargando...",
    errorTxt: "Error al obtener dispositivos",
    emptyTxt: "No se encontró dispositivos",
  },
  "en-US": {
    normalTxt: "Devices",
    loadingTxt: "Loading...",
    errorTxt: "Error loading devices",
    emptyTxt: "No devices found",
  },
};

export const devicePickerId = "devicePicker";

const DevicePicker = (props) => {
  const {
    onChange,
    onLoaded,
    onDismount,
    user = "",
    autoload,
    xs,
    disabled,
    whitelist = [], // must be used in combination with useWhitelist
    useWhitelist,
  } = props;
  const { auth, dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { done, working, error } = useWorker(getDevicesId);
  const { normalTxt, loadingTxt, errorTxt, emptyTxt, tooltip } =
    UIText[settings.locale];
  const { devicePicker = { mid: "" } } = UI;
  const { mid } = devicePicker;

  const options = useMemo(() => {
    const devices = warehouse[devicesDataId] ?? [];
    if (devices.length <= 0) {
      return [];
    }
    let out = [...devices]
      .filter((a) => !useWhitelist || !!whitelist.find((b) => a.mid === b))
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return { label: data.name, mid: data.mid };
      });
    return out;
  }, [warehouse, useWhitelist,whitelist]);

  let label = normalTxt;
  if (done && !error && !options.length) {
    label = emptyTxt;
  } else if (done && error) {
    label = errorTxt;
  } else if (working) {
    label = loadingTxt;
  }

  // Load data
  useEffect(() => {
    const controller = new AbortController();
    if (user && !done && autoload) {
      dispatch(getDevices({ controller, ...auth, user }));
    }
    return () => {
      if (!done) {
        controller.abort();
      }
    };
  }, [dispatch, auth, user, done, autoload]);

  // Fill blank
  useEffect(() => {
    if (!!options.length && done && !mid) {
      if (onLoaded) {
        onLoaded(options);
      } else {
        dispatch(UIactions.setValue({ id: devicePickerId, value: options[0] }));
      }
    }
  }, [dispatch, mid, options, done, onLoaded]);

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(Warehouse.unload(devicesDataId));
      dispatch(UIactions.clear(devicePickerId));
      dispatch(workers.clear(getDevicesId));
      if (onDismount) {
        onDismount();
      }
    };
  }, [dispatch, onDismount]);

  const handleChange = (e, value) => {
    dispatch(UIactions.setValue({ id: devicePickerId, value }));
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <AutoGrid {...{ xs }}>
      <AutoHelp {...{ tooltip, disabled: disabled || working }}>
        <Autocomplete
          blurOnSelect
          disableClearable
          fullWidth
          {...{
            disabled: options.length > 1 ? false : true,
            value: mid ? devicePicker : null,
            filterOptions: AutocompleteFilter,
            onChange: handleChange,
            options,
            size: "small",
            loading: working,
            // loadingText: "Cargando dispositivos...",
            // noOptionsText: "No se encontró dispositivos",
            renderOption: (props, option) => {
              return (
                <li {...props} key={option.mid}>
                  {option.label}
                </li>
              );
            },
            renderInput: (params) => {
              params.InputProps.startAdornment = (
                <InputAdornment position="start">
                  <Laptop />
                </InputAdornment>
              );
              if (working) {
                params.InputProps.endAdornment = (
                  <InputAdornment position="end">
                    <CircularProgress size={14} />
                  </InputAdornment>
                );
              }
              return <TextField {...params} label={label} />;
            },
            isOptionEqualToValue: (option, value) => option.mid === value.mid,
          }}
        />
      </AutoHelp>
    </AutoGrid>
  );
};

export default DevicePicker;
