import { ChevronRight } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import {
  UISliceActions,
  UISliceActions as UIactions,
} from "../../../../store/slices/UI2";
import { useEffect } from "react";
import {
  selectedGroupId,
  selectedGroupIdx,
} from "../../../../store/actions2/GroupTraveler/loadGroupsTree";
import { debounce } from "lodash";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { workersSliceActions } from "../../../../store/slices/workers";
import { devicesLoadedId } from "../../../Devices/Devices";
// import { groupsRecursiveId } from "../../../../store/actions2/Devices/getAllDevices";
import { recursiveRequestId } from "../../../Devices/Components/Actions/Recursive";
import { useLocation } from "react-router-dom";
// import useURLQuery from "../../../../hooks2/useURLQuery";
import { travelerOpenUI } from "../../GroupsTraveler";

const ListItem = (props) => {
  const { id, idx, name, childrenIdx, trail, selected, selectedGroup } = props;
  const { dispatch, navigate } = useUIBoilerplate();
  // const { group: group2 } = useURLQuery();
  const location = useLocation();
  // const group = UI[selectedGroupId];

  let bgcolor = undefined;

  if (selected) {
    bgcolor = `rgba(0, 167, 132, 0.2 )`;
  } else if (trail) {
    bgcolor = `rgba(0, 167, 132, 0.1 )`;
  }

  useEffect(() => {
    if (trail || selected) {
      const element = document.getElementById("group-" + id);
      element.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "start",
      });
    }
  }, [id, trail, selected, selectedGroup]);

  const debouncedClick = debounce((e) => {
    if (e.detail === 1) {
      dispatch(UIactions.setValue({ id: selectedGroupId, value: id }));
      dispatch(UIactions.setValue({ id: selectedGroupIdx, value: idx }));
    } else if (e.detail > 1) {
      const navTo = `${location.pathname}?group=${id}`;
      navigate(navTo);
      dispatch(UIactions.setValue({ id: travelerOpenUI, value: false }));
      dispatch(workersSliceActions.clear(devicesLoadedId));
      // dispatch(workersSliceActions.clear(`groupData${group2}`));
      // dispatch(workersSliceActions.clear(groupsRecursiveId));
      dispatch(UISliceActions.clear(recursiveRequestId));
    }
  }, 200);

  const onClick = (e) => {
    debouncedClick(e);
  };

  return (
    <ListItemButton {...{ id: "group-" + id, onClick, sx: { bgcolor } }}>
      <ListItemText
        primary={name}
        primaryTypographyProps={{ fontSize: 13, noWrap: true }}
      />
      {childrenIdx.length ? (
        selected || trail ? (
          <ChevronRight color="primary" fontSize="10px" />
        ) : (
          <ChevronRight fontSize="10px" />
        )
      ) : null}
    </ListItemButton>
  );
};

export default ListItem;
