import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Switch from "../../../UI2/Switch";

const UIText = {
  "es-CL": { label: "Mostrar archivos eliminados" },
  "en-US": { label: "Show deleted files" },
};

export const showDeletedUI = "showDeleted";

const ShowDeleted = (props) => {
  const {  settings } = useUIBoilerplate();

  const { label } = UIText[settings.locale] ?? {};

  return (
    <Grid container item xs="auto" alignItems="center" justifySelf="start">
      <Switch {...{ id: showDeletedUI, size: "small", xs: "auto" }} />
      <Grid container item {...{ xs: "auto" }}>
        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
};

export default ShowDeleted;
