import { UploadFile } from "@mui/icons-material";
import { read, utils } from "xlsx";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import AutoHelp from "../../../UI2/Wrappers/AutoHelp";
import {
  generateHexString,
  generatePassword,
} from "../../../../utility/generateString";
import { Button, Grid, Typography } from "@mui/material";
import { UISliceActions } from "../../../../store/slices/UI2";
import { bkpOneArrayId } from "../UsersList/UserRow/BackupOnedrive";
import { bkpMailArrayId } from "../UsersList/UserRow/BackupMail";
import { enqueueSnackbar } from "notistack";

const UITextUpload = {
  "es-CL": {
    label: "Cargar preferencias",
    fixedTooltip: "Cargar la hoja .xlsx con la información de los usuarios",
  },
  "en-US": {
    label: "Upload settings",
    fixedTooltip: "Upload the .xlsx sheet with the users data",
  },
};

const errMsg = {
  "es-CL": "Usuario no encontrado: ",
  "en-US": "User not found: ",
};

export const c365userchanges = "cloner365userchanges";

const UploadSheet = ({ users }) => {
  const { dispatch, settings } = useUIBoilerplate();
  const { label, fixedTooltip } = UITextUpload[settings.locale];

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        const justUsers = users.map((x) => x.login);
        for (const row of rows) {
          const id = generateHexString(20);
          let login = !!row.usuarios ? row.usuarios.toLowerCase() : undefined;
          let mail = !!row.correo_windows365
            ? row.correo_windows365.toLowerCase()
            : undefined;
          let bkp1 = !!row.respalda_correos
            ? row.respalda_correos.toLowerCase()
            : "";
          let bkp2 = !!row.respalda_onedrive
            ? row.respalda_onedrive.toLowerCase()
            : "";


          console.log("login", login);
          console.log("mail", mail);
          console.log("bkp1", bkp1);
          console.log("bkp2", bkp2);
          console.log("justUsers", justUsers);



          if (justUsers.find((x) => x === login)) {
            dispatch(
              UISliceActions.setField({ id: "365mail-" + login, value: mail })
            );
            if (bkp1.match("si")) {
              dispatch(
                UISliceActions.add({ id: bkpMailArrayId, key: login })
              );
            } else {
              dispatch(
                UISliceActions.remove({ id: bkpMailArrayId, key: login })
              );
            }
            if (bkp2.match("si")) {
              dispatch(UISliceActions.add({ id: bkpOneArrayId, key: login }));
            } else {
              dispatch(
                UISliceActions.remove({ id: bkpOneArrayId, key: login })
              );
            }
          } else {
            let errSnack = errMsg[settings.locale];
            enqueueSnackbar(errSnack+login, { variant: "error" });
          }
          // dispatch(UISliceActions.addObj({ id: c365userchanges, obj: newData }));
        }
      }
    };
    reader.readAsArrayBuffer(file);
    e.target.value = "";
    console.log("finished handling upload");
  };

  return (
    <Grid item>
      <AutoHelp {...{ fixedTooltip }}>
        <Button
          component="label"
          variant="outlined"
          startIcon={<UploadFile />}
          size="small"
        >
          <Typography fontSize="0.8rem" fontWeight="bold">
            {label}
          </Typography>
          <input type="file" hidden onChange={handleUpload}></input>
        </Button>
      </AutoHelp>
    </Grid>
  );
};

export default UploadSheet;
