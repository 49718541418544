import { Typography } from "@mui/material";

const FileDate = ({ fontSize, created, locale }) => {
  const dt = new Date(created);

  return (
    <Typography {...{ fontSize, sx: { cursor: "default" } }}>
      {dt.toLocaleString(locale)}
    </Typography>
  );
};

export default FileDate;
