import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const getGreylistId = "greylist";
export const greylistDataId = 'greylists'

const getGreylist = (data) => {
  return async (dispatch) => {
    const { controller, server, cloner_key, repoId: repoid } = data;
    dispatch(worker.start("greylists"));
    try {
      const response = await axios.get(server + "api/greylist", {
        signal: controller.signal,
        headers: { cloner_key },
        params: { repoid },
      });

      //   dispatch(warehouse.load(response.data));

      const versions = response.data.versions;
      let versionsArray = [];
      for (const version in versions) {
        const milliseconds = Date.parse(versions[version].created);
        const localCreated = new Date(versions[version].created).toLocaleString(
          "es-CL"
        );
        versionsArray.push({
          tree: versions[version].greylist,
          repo: versions[version].repoid,
          size: versions[version].size,
          created: versions[version].created,
          milliseconds,
          localCreated,
        });
      }
      versionsArray.reverse();

      console.log('versionsArray',versionsArray)
      dispatch(warehouse.load({ id: greylistDataId, data: versionsArray }));
      dispatch(worker.finish({ id: getGreylistId }));
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id: getGreylistId, error: e.message }));
    }
  };
};

export default getGreylist;
