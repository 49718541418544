import { useLocation } from "react-router-dom";
import DrawerButton from "./DrawerButton";
import { Share } from "@mui/icons-material";

const UIText = {
  "es-CL": { label: "Compartidos" },
  "en-US": { label: "Shared" },
};

const Shared = () => {
  const location = useLocation().pathname.split("/")[2];
  const selected = location === "shared_files";
  const navTo = "/dashboard/shared_files";
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <Share {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Shared;
