import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};

const Close = () => {
  const { dispatch } = useUIBoilerplate();

  const onClick = () => {
    console.log("click?");
    dispatch(UISliceActions.closeModal());
  };

  return <Button {...{ UIText, onClick, variant:'text' }} />;
};

export default Close;
