import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import IconButton from "../../../../UI2/IconButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import TextField from "../../../../UI2/TextField";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { devicesDataId } from "../../../../../store/actions2/Devices/getDevices";

const UIText = {
  "es-CL": {
    subtitle: "Opciones",
    opt1: "Incluir archivos eliminados",
    opt2: "Recuperar una fecha especifica",
  },
  "en-US": {
    subtitle: "Options",
    opt1: "Include deleted files",
    opt2: "Recover a specific date",
  },
};

const UITextDate = {
  "es-CL": { label: "Fecha de recuperacion" },
  "en-US": { label: "Recovery date" },
};

export const recoveryDateUI = "recoveryDate";
export const recoveryOptionUI = "recoveryOptions";

const Options = () => {
  const { dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const selected = UI[recoveryOptionUI];
  const locale = settings.locale;
  const { subtitle, opt1, opt2 } = UIText[locale];
  const devices = warehouse[devicesDataId] ?? [];

  const onClickDeleted = () => {
    if (selected !== "deleted") {
      dispatch(UIactions.setValue({ id: recoveryOptionUI, value: "deleted" }));
    } else {
      dispatch(UIactions.clear(recoveryOptionUI));
    }
    dispatch(UIactions.clear(recoveryDateUI));
  };

  const onClickDated = () => {
    if (selected !== "dated") {
      dispatch(UIactions.setValue({ id: recoveryOptionUI, value: "dated" }));
    } else {
      dispatch(UIactions.clear(recoveryOptionUI));
    }
    dispatch(UIactions.clear(recoveryDateUI));
  };

  let deletedIcon = <RadioButtonUnchecked />;
  let datedIcon = <RadioButtonUnchecked />;
  if (selected === "deleted") {
    deletedIcon = <RadioButtonChecked color="primary" />;
  }
  if (selected === "dated") {
    datedIcon = <RadioButtonChecked color="primary" />;
  }

  return (
    <Grid container item {...{ rowGap: 2, xs: 12 }}>
      <Grid container item xs={12}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <IconButton
          {...{
            disabled: devices.length === 0,
            onClick: onClickDeleted,
            icon: deletedIcon,
            UIText: { universal: {} },
          }}
        />
        <Typography fontWeight="bold" fontSize="0.9rem" color={devices.length === 0?'grey':undefined}>
          {opt1}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs="auto" alignItems="center">
          <IconButton
            {...{
              disabled: devices.length === 0,
              onClick: onClickDated,
              icon: datedIcon,
              UIText: { universal: {} },
            }}
          />
          <Typography fontWeight="bold" fontSize="0.9rem" color={devices.length === 0?'grey':undefined}>
            {opt2}
          </Typography>
        </Grid>
        <TextField
          {...{
            id: recoveryDateUI,
            type: "datetime-local",
            disabled: selected !== "dated",
            UIText: UITextDate,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Options;
