import { Grid, Typography } from "@mui/material";

const Block = ({ label, value }) => {
  return (
    <Grid
      container
      item
      {...{
        wrap: "nowrap",
        xs: 12,
        sm: 6,
        md: 3,
        lg: 3,
        columnGap: 2,
      }}
    >
      <Typography fontWeight="bold" fontSize='1.05rem'>
        {label}
      </Typography>
      <Typography noWrap fontSize='1rem'>
        {value}
      </Typography>
    </Grid>
  );
};

export default Block;
