import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { useEffect } from "react";
import { userUI } from "../User";
import { usernameUI } from "../Username";
import { pass1UI } from "../Password/Password1";
import { pass2UI } from "../Password/Password2";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import notEmptyField from "../../../../utility/validations/notEmpty";
import notEmptyPassword from "../../../../utility/validations/notEmptyPassword";
import { postUserId } from "../../../../store/actions2/UserForm/postUser";
import { permissionsUI } from "../Permissions/PermissionsSelect";
import { selectedGroupId } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";
import { postUsers } from "../../../../store/actions2/UserForm/postUsers";
import useURLQuery from "../../../../hooks2/useURLQuery";

const UIText = {
  "es-CL": { label: "Crear" },
  "en-US": { label: "Create" },
};

const Create = (props) => {
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { done, working, error: e1 } = useWorker(postUserId);
  const { group: currentGroup } = useURLQuery();
  const open = UI["group-traveler2-open"] ?? false;
  const { value: userLogin, error: e2 } = UI[userUI] ?? {};
  const { value: username, error: e3 } = UI[usernameUI] ?? {};
  const { value: pass, error: e4 } = UI[pass1UI] ?? {};
  const { value: pass2, error: e5 } = UI[pass2UI] ?? {};
  const group = UI[selectedGroupId];
  // const e6 = UI[twinErrUI];
  const locale = settings.locale;

  const permissions = UI[permissionsUI] ?? [];
  const payload = {
    token: auth.token,
    server: auth.server,
    login: userLogin,
    user: userLogin,
    name: username,
    admin: permissions.find((x) => x === "admin") ? "true" : "false",
    has_access: permissions.find((x) => x === "access") ? 1 : 0,
    receives_mail: permissions.find((x) => x === "mails") ? 1 : 0,
    pass,
    pass2,
    group,
  };
  const error = e1 || e2 || e3 || e4 || e5;

  const onClick = () => {
    if (!error) {
      dispatch(
        postUsers({
          ...auth,
          locale,
          reloadId: currentGroup,
          isNew: true,
          users: [payload],
        })
      );
    }
  };

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: userUI,
        force: true,
        fn: notEmptyUser,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: usernameUI,
        force: true,
        fn: notEmptyField,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: pass1UI,
        force: true,
        fn: notEmptyPassword,
        locale,
      })
    );
    dispatch(
      UIactions.validateField({
        id: pass2UI,
        force: true,
        fn: notEmptyPassword,
        locale,
      })
    );
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  return (
    <Button
      {...{ disabled: !!error || open, UIText, onClick, onMouseDown, working }}
    />
  );
};

export default Create;
