/**
 * Renders the Suspended component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.locale - The locale of the component.
 * @returns {JSX.Element} The rendered Suspended component.
 */
import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { useWorker } from "../../../../../hooks2/useWorker";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import HelpTooltip from "../../../../UI2/HelpTooltip";
import Switch from "../../../../UI2/Switch2";

const UIText = {
  "es-CL": { label: "Estado del dispositivo" },
  "en-US": { label: "Device status" },
};

const UITextA = {
  "es-CL": { label: "Suspendido" },
  "en-US": { label: "Suspended" },
};

const UIText1 = {
  "es-CL": {
    tooltip:
      "Suspende el dispositivo para que no pueda continuar respaldando. El dispositivo aun será visible y su información accesible",
  },
  "en-US": {
    tooltip:
      "Suspend the device so it can not continue backing up. The device will still be visible and its information accessible",
  },
};

export const suspendedUI = "suspended";

const Suspended = (props) => {
  const { locale } = props;
  const { working } = useWorker(postDeviceId);
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid container item xs={7} alignItems="center" columnGap={1}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText: UIText1 }} />
      </Grid>
      <Switch
        labelRight
        {...{
          id: suspendedUI,
          UIText: UITextA,
          disabled: working,
          xs: 5,
          labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
        }}
      />
    </Fragment>
  );
};

export default Suspended;
