import { Dialog } from "@mui/material";
import SlimAlert from "../../UI2/SlimAlert";
import Cancel from "./Cancel";
import Confirm from "./Confirm";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import { useDispatch } from "react-redux";
import { useWorker } from "../../../hooks2/useWorker";
import BasicDialogTitle from "../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../UI2/BasicDialog/BasicDialogActions";

const GenericConfirmation = (props) => {
  const {
    header,
    message,
    severity,
    confirmation = () => {
      console.log("no confirmation fn()");
    },
    alternativeText,
    workerId,
  } = props;
  const dispatch = useDispatch();
  const { working } = useWorker(workerId);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <SlimAlert {...{ errors: [message], severity }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel {...{ working }} />
        <Confirm {...{ confirmation, alternativeText, working }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default GenericConfirmation;
