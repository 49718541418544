import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import Block from "./Block";
import { humanBytes, timeDiff } from "../../../../../../utility/humanizers";

const UIText = {
  "es-CL": {
    header: "Resumen",
    userLabel: "Usuario:",
    deviceLabel: "Dispositivo:",
    sizeLabel: "Tamaño total:",
    lastBkpLabel: "Ultimo respaldo:",
  },
  "en-US": {
    header: "Summary",
    userLabel: "User:",
    deviceLabel: "Device:",
    sizeLabel: "Total size:",
    lastBkpLabel: "Last backup:",
  },
};

const Summary = ({ recovery, locale }) => {
  const { user, device, repositories } = recovery;
  const { header, userLabel, deviceLabel, sizeLabel, lastBkpLabel } =
    UIText[locale];
  const { UI } = useUIBoilerplate();

  let size = 0;
  let lastBkp = Date.now();
  let now = lastBkp;

  for (const repo of repositories) {
    const recover = UI[`recoverRepo-${repo.id}`];
    if (!recover) {
      continue;
    }
    size += repo.size;
    const dt = Date.parse(repo.last_backup);

    lastBkp = lastBkp < dt ? lastBkp : dt;
  }

  let lastBkpText = timeDiff(now - lastBkp, locale);
  if (lastBkp === now) {
    lastBkpText = '--'
  }

  return (
    <Grid
      container
      item
      {...{
        paddingTop: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        xs: 12,
        rowGap: 2,
        columnSpacing: 2,
      }}
    >
      <Grid container item {...{ lg: 12 }}>
        <Typography fontWeight="bold" fontSize={18}>
          {header}
        </Typography>
      </Grid>
      <Block {...{ label: userLabel, value: user }} />
      <Block {...{ label: deviceLabel, value: device }} />
      <Block {...{ label: sizeLabel, value: humanBytes(size) }} />
      <Block {...{ label: lastBkpLabel, value: lastBkpText }} />
    </Grid>
  );
};

export default Summary;
