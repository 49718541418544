/**
 * Represents the Interval component.
 * @param {Object} props - The component props.
 * @param {string} props.locale - The locale for the component.
 * @returns {JSX.Element} The rendered Interval component.
 */
import { Grid, Typography } from "@mui/material";
import { options3 as options } from "./selectValues";
import Select from "../../../../UI2/Select";
import SlimAlert from "../../../../UI2/SlimAlert";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import HelpTooltip from "../../../../UI2/HelpTooltip";

const UIText = {
  "es-CL": {
    label: "Intervalo de respaldo",
    tooltip:
      "El intervalo de respaldo se refiere al tiempo de espera entre el termino de un proceso de respaldo y el inicio del siguiente",
  },
  "en-US": {
    label: "Backup intervalo",
    tooltip:
      "The backup interval refers to the time to wait between the end of a backup process and the start of the next one",
  },
};

const UIText2 = {
  "es-CL": {
    warning:
      "El intervalo de respaldo actual no coincide con ninguna de las opciones disponibles. Valor actual: ",
  },
  "en-US": {
    warning:
      "The current backup interval does not match with any available option. Actual value: ",
  },
};

export const intervalUI = "intervalValue";

const Interval = (props) => {
  const { locale } = props;
  const { UI } = useUIBoilerplate();
  const { working } = useWorker(postDeviceId);
  const { label } = UIText[locale];
  const interval = UI[intervalUI];
  const { warning } = UIText2[locale];

  const found = options.findIndex((opt) => opt.value === interval);

  const warningFull =
    found === -1 && typeof interval === "number"
      ? [warning + interval + " min"]
      : [];

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={7} alignItems="center" columnGap={1}>
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText }} />
      </Grid>
      <Select
        {...{
          id: intervalUI,
          UIText,
          disabled: working,
          options,
          xl: 5,
          xs: 5,
          defaultIdx: 5,
        }}
      />
      <SlimAlert
        {...{ errors: warningFull, severity: "warning", paddingTop: 1 }}
      />
    </Grid>
  );
};

export default Interval;
