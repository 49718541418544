import axios from "axios";
import { newTreesSliceActions as trees } from "../../slices/trees2";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as workers } from "../../slices/workers";

export const getRepoId = "getRepositories";
export const repoDataId = "repositories";

const getRepositories = (data) => {
  const { server, cloner_key, user, mid, controller } = data;
  const id = getRepoId;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = server + "api/repositories";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { id: mid, user },
      });
      const repositories = response.data.repositories;
      console.log("repositories", repositories);
      for (const repo of repositories) {
        dispatch(
          trees.createTree({
            id: "repo-" + repo.id,
            type: "navigator",
            data: repo,
            root: {
              id: repo.root,
              type: 99,
              name: repo.name,
              deleted: repo.deleted,
            },
          })
        );
      }
      dispatch(warehouse.load({ id: repoDataId, data: repositories }));
      dispatch(workers.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workers.clear(id));
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default getRepositories;
