import Core from "../../UI2/BasicLayout/Components/Core/Core";

const Core2 = ({ done, working, error, elements = [], workerId }) => {
  const coreData = {
    hasHeaders: true,
    working,
    error: {
      workerId,
      isError: !!error,
      UIText: {
        "es-CL": { message: "Error al cargar las versiones del archivo" },
        "en-US": { message: "Error loading the file versions" },
      },
    },
    empty: {
      isEmpty: !elements.length,
      UIText: {
        "es-CL": { message: "No hay versiones para mostrar" },
        "en-US": { message: "There are to versions to display" },
      },
    },
    done,
    elements,
  };

  return <Core {...coreData} />;
};

export default Core2;
