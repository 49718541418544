import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import Cancel from "./Cancel";
import Save from "./Save";

const Buttons = ({ isNew, users }) => {
  return (
    <BasicDialogActions>
      <Cancel />
      <Save {...{ users }} />
    </BasicDialogActions>
  );
};

export default Buttons;
