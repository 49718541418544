import { Grid } from "@mui/material";
import Empty from "./Empty";
import Loading from "./Loading";
import Error from "./Error";
import useVH from "../../../../../hooks2/useVH";
import { useEffect, useRef } from "react";

const Core = ({
  hasHeaders,
  empty,
  working,
  error,
  done,
  elements,
  contentSize = 65,
}) => {
  const rawVH = hasHeaders ? contentSize - 6 : contentSize;
  const vh = useVH(rawVH);
  const ref = useRef(null);

  useEffect(() => {
    ref.current.scrollTop = 0;
    // console.log("Core hasHeaders", hasHeaders);
  }, [elements]);

  return (
    <Grid
      ref={ref}
      container
      item
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        xs: 12,
        alignContent: "start", //This might be a problem?
        // alignItems:'center', // this also
        sx: {
          maxHeight: vh,
          overflowY: "auto",
          overflowX: "hidden",
        },
      }}
    >
      <Loading {...{ working }} />
      {done && !error.error ? <Empty {...empty} /> : null}
      <Error {...error} />
      {done && !error.error ? elements : null}
    </Grid>
  );
};

export default Core;
