export const validateMail = (email) => {
  // mail must have the @ character inside
  if (email && !email.match(/\b.+@.+\b/)) {
    return "La dirección de correo no tiene la forma requerida (nombre@dominio)";
  } else if (email) {
    const splitMail = email.split("@");
    const local = splitMail[0];
    const domain = splitMail[1];
    // The only allowed characters on the local part are letters, numbers, ( . ), ( - ), ( _ )
    if (local.match(/[^A-Za-z0-9._+-]/)) {
      return "La parte local del correo solo puede contener letras, numeros y los caracteres punto ( . ), guion( - ) y guion bajo ( _ )";
      // There cannot be a ( - ) at the start of the email
    } else if (local.match(/^[-]/)) {
      return "La seccion local del correo no puede empezar con un guion ( - )";
    } else if (
      // There cannot be a ( . ) at the start or end of the email. Also ( .. ) is not allowed
      (local.match(/[.]\b/) || local.match(/\b[.]/) || local.match(/[..]/)) &&
      !local.match(/\b[.]\b/)
    ) {
      return "La seccion local del correo no puede empezar o terminar con un punto ( . ) y tampoco pueden haber 2 puntos consecutivos ( .. )";
    }
    if (
      // There cannot be a ( . ) at the start or end of the email. Two consecutive ( .. ) either
      (domain.match(/[.]\b/) ||
        domain.match(/\b[.]/) ||
        domain.match(/[..]/)) &&
      !domain.match(/\b[.]\b/)
    ) {
      return "El dominion del correo no puede empezar o terminar con un punto ( . ) y tampoco pueden haber 2 puntos consecutivos ( .. )";
      // The domain part cannot start with a hyphen ( - )
    } else if (domain.match(/[-]\b/)) {
      return "El dominio no puede empezar con guión ( - )";
    } else if (domain.match(/[^A-Za-z0-9._-]/)) {
      return "El dominio del correo solo puede contener letras, numeros y los caracteres punto ( . ), guion( - ) y guion bajo ( _ )";
    }
  }
  return "";
};

export const validateMailLoose = (email) => {
  // mail must have the @ character inside
  if (email && !email.match(/\b.+@.+\b/)) {
    return "La dirección de correo no tiene la forma requerida (nombre@dominio)";
  }
  return "";
};

export const validatePassword = (pass) => {
  const msg =
    "La contraseña debe tener un largo mínimo de 10 caracteres y contener al menos una letra mayuscula, minuscula y un numero";
  if (pass) {
    if (pass.length < 10) {
      return msg;
    } else if (
      !pass.match(/[A-Z]/) ||
      !pass.match(/[a-z]/) ||
      !pass.match(/[0-9]/)
    ) {
      return msg;
    }
  }
  return "";
};

export const notEmpty = (value) => {
  if (!value) {
    return "El campo no puede estar vacío";
  }
};

export const notEmptyName = (value) => {
  if (!value) {
    return "El nombre no puede estar vacío";
  }
};

export const notEmptyCustom = (value, msg) => {
  if (!value) {
    return msg;
  }
};

export const notEmptyUser = (user) => {
  let msg = "";
  if (!user){
    return 'El correo no puede estar vacío'
  }
  msg = validateMailLoose(user);
  if (!!msg) {
    return msg;
  }
  return ''
};

export const notEmptyPassword = (x) => {
  let msg = "";
  msg = validatePassword(x);
  if (!!msg) {
    return msg;
  }
  msg = notEmptyCustom(x, "La contraseña no puede estar vacía");
  return msg;
};

export const notEmptyAndNotFound = (value, array, foundMsg) => {
  let msg = "";
  const found = array.find((y) => value === y);
  if (found) {
    return foundMsg;
  }

  msg = notEmpty(value);
  return msg;
};

export const notEmptyQRCode = (value) => {
  if (value.match(/[^0-9]/)) {
    return "El codigo deben ser 6 numeros";
  } else if (value.length !== 6) {
    return "El codigo deben ser 6 numeros";
  } else if (!value) {
    return "El codigo no puede estar vacío";
  }
  return "";
};

export const notEmptyPhone = (value) => {
  if (!value) {
    return "El teléfono no puede estar vacío";
  } else if (value.match(/[^0-9()+-\s]/)) {
    return 'El teléfono solo puede contener numeros, espacios y los caracteres especiales: "(",")","-","+"';
  }
  return "";
};

export const notEmptyUUID = (value) => {
  if (!value) {
    return "Este campo no puede estar vacío";
  } else if (
    !value.match(/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/)
  ) {
    return "El valor no es un UUID válido";
  }
  return "";
};
