import { Grid } from "@mui/material";
import Recover from "./Restore";

const Actions = () => {
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <Recover />
    </Grid>
  );
};

export default Actions;
