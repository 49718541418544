const UIError = (id,locale,UIText)=>{
    if (!id){
        console.error('UI component lacks id!')
        return true
    }

    if (!UIText[locale]){
        console.error('UI component has no UIText or there is no correponding locale')
        return true
    }

    return false
}

export default UIError