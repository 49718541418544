// export const currentSharedId = "currentShared";

const headersData = {
    // sourceId:currentSharedId,
    headers:[
        {
            UIText:{
                'es-CL':{label:'Nombre'},
                'en-US':{label:'Name'}},
            filterKey:'name',
            xs:3,
        },
        {
            UIText:{
                'es-CL':{label:'Protegido'},
                'en-US':{label:'Protected'}},
            filterKey:'has_password',
            justify:'center',
            xs:1,
        },
        {
            UIText:{
                'es-CL':{label:'Fecha de creación'},
                'en-US':{label:'Creation date'}},
            filterKey:'created',
            xs:2,
        },
        {
            UIText:{
                'es-CL':{label:'Enlace de descarga'},
                'en-US':{label:'Download link'}},
            filterKey:'',
            xs:true,
        },
        {
            UIText:{
                'es-CL':{label:'Mas'},
                'en-US':{label:'More'}},
            xs:0.5,
            justify:'end',
        },
    ]

}


export default headersData