import { InsertDriveFile } from "@mui/icons-material";
import { Chip, Grid } from "@mui/material";
import downloadAttachment from "../../../../store/actions2/Mails/downloadAttachment";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const Attachs = ({ hasAttachments, attachments }) => {
  const { auth, dispatch, settings } = useUIBoilerplate();

  if (!hasAttachments) {
    return null;
  }

  const Attachments = attachments.map((data) => {
    const onClick = () => {
      dispatch(
        downloadAttachment({
          ...auth,
          id: data.id,
          name: data.name,
          locale: settings.locale,
        })
      );
    };
    return (
      <Chip {...{key:data.id, onClick, icon: <InsertDriveFile />, label: data.name }} />
    );
  });

  return (
    <Grid
      container
      item
      {...{
        gap: 2,
        padding: 2,
        xs: 12,
        sx: { borderTop: "1px solid lightgrey" },
      }}
    >
      {Attachments}
    </Grid>
  );
};

export default Attachs;
