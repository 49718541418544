import TextField from "../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Desde" },
  "en-US": { label: "From" },
};

export const filterFromUI = "filterFrom";

const FilterFrom = () => {
  return (
    <TextField
      fullWidth
      {...{
        id: filterFromUI,
        UIText,
        type: "datetime-local",
        inputSx: { fontSize: 13 },
      }}
    />
  );
};

export default FilterFrom;
