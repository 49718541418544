import { useLocation } from "react-router-dom";
import DrawerButton from "./DrawerButton";
import { Mail } from "@mui/icons-material";

const UIText = {
  "es-CL": { label: "Correos" },
  "en-US": { label: "Mails" },
};

const Mails = () => {
  const location = useLocation().pathname.split("/")[2];
  const selected = location === "mails";
  const navTo = "/dashboard/mails";
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <Mail {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Mails;
