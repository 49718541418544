import { Collapse, Grid } from "@mui/material";
import PassField, { passFieldId } from "./PassField";
import Submit from "./Submit";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { getConfigId } from "../../../../store/actions2/Devices/getConfig";
import { validateSelectionPassword } from "../../../../store/actions2/RemoteSelection/validateSelectionPassword";
import { selectionBlockUI } from "../../RemoteSelection3";

const Blocked = () => {
  const { dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { value: password } = UI[passFieldId] ?? {};
  const blocked = UI[selectionBlockUI] ?? false
  const { cpassword = "" } = warehouse[getConfigId] ?? {};
  const locale = settings.locale;

  const onSubmit = () => {
    dispatch(validateSelectionPassword({ password, cpassword, locale }));
  };

  return (
    <Grid container item {...{ xs: 12 }} justifyContent="center">
      <Collapse in={blocked} orientation='vertical' unmountOnExit>
        <Grid
          container
          columnGap={3}
          {...{ padding: 3 }}
          justifyContent="center"
        >
          <PassField {...{ onSubmit }} />
          <Submit {...{ onSubmit }} />
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default Blocked;
