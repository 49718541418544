import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365ClientId, w365clientData } from "./getClient";

const UIText = {
  "es-CL": {
    success: "Usuario de Windows365 deshabilitado en Cloner",
    error: "Error al intentar deshabilitar usuario de Windows365 en Cloner: ",
  },
  "en-US": {
    success: "Windows365 Cloner user inactivated successfully",
    error: "Error deactivating Windows365 Cloner user: ",
  },
};

export const deleteClientId = "deleteW365client";

const deleteClient = (data) => {
  const id = deleteClientId;
  const { W365server, cloner_key, user, locale } = data;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      console.log("deleteClient data", data);
      const url = `${W365server}mailapi/client/${encodeURIComponent(user)}`;
      await axios.delete(url, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(worker.clear(getW365ClientId));
      dispatch(warehouse.unload(w365clientData));
    } catch (e) {
      const messsage = `${error} ${e.message}`;
      enqueueSnackbar(messsage, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default deleteClient;
