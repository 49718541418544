import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import deleteUsers from "../../store/actions2/Users/deleteUsers";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

const DeleteUsersConfirmation = () => {
  const { auth,dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { payload } = UI.modal2;
  const { users } = payload;

  const confirmation = () => {
    dispatch(deleteUsers({
      ...auth,
      users,
      locale,

    }))
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteUsersConfirmation;
