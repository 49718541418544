import Logo from "./components/Logo";
import useURLQuery from "../../hooks2/useURLQuery";
import SlimAlert from "../UI2/SlimAlert";
import TextField from "../UI2/TextField";
import Button from "../UI2/Button";
import { CloudDownload } from "@mui/icons-material";
import notEmptyPassword from "../../utility/validations/notEmptyPassword";
import { Fragment, useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import {
  getSharedData,
  getSharedDataId,
  sharedFileData,
} from "../../store/actions2/Shared/getSharedData";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { downloadSharedData } from "../../store/actions2/Shared/downloadSharedData";
import { Grid, Typography } from "@mui/material";
import Locale from "../Dashboard/Components/TopBar/Actions/Locale";

const UIText = {
  "es-CL": { placeholder: "Contraseña de descarga" },
  "en-US": { placeholder: "Download password" },
};
const UITextBtn = {
  "es-CL": { label: "Descargar" },
  "en-US": { label: "Download" },
};

const UIText1 = {
  "es-CL": {
    text1: "Archivo/Directorio: ",
    info: "Este archivo/directorio fue compartido por nuestra plataforma de respaldo CLONER, si deseas conocer más sobre nuestro servicio por favor completa el formulario de contacto. www.cloner.cl",
    linkError:'Lo sentimos! Hubo un error al intentar obtener la información del enlace de descarga. Confirmar que el enlace haya sido copiado correctamente o que no haya sido eliminado',
  },
  "en-US": {
    text1: "File/Directory: ",
    info: "This file/folder was shared through our backup platform CLONER, if you wish to know more about our service please fill the contact form. www.cloner.cl",
    linkError:'Sorry! There was an error trying to fetch the download link data. Verify that the link was copied correctly or that the link has not been deleted',
  },
};

const passwordFieldUI = "password";

const SharedDownload = () => {
  const { dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { user, id } = useURLQuery();
  const locale = settings.locale ?? "es-CL";
  const { done, error } = useWorker(getSharedDataId);
  const { text1, info, linkError } = UIText1[locale];
  const sharedLink = warehouse[sharedFileData] ?? {};
  const passwordField = UI[passwordFieldUI] ?? {};
  const { value: password, error: passError } = passwordField;

  console.log("sharedLink", sharedLink);

  useEffect(() => {
    if (!done) {
      dispatch(getSharedData({ user, id }));
    }
  }, [dispatch, done, id, user]);

  useEffect(() => {
    dispatch(UIactions.clear(passwordFieldUI));
    return () => {
      dispatch(UIactions.clear(passwordFieldUI));
      dispatch(workers.clear(getSharedDataId));
    };
  }, [dispatch]);

  const validate = () => {
    if (sharedLink.has_password) {
      dispatch(
        UIactions.validateField({
          id: passwordFieldUI,
          force: true,
          fn: notEmptyPassword,
          locale,
        })
      );
    }
  };

  // const { server, repoId, file: fileId, id: linkId, password, locale } = data;
  const submit = () => {
    if (!passError) {
      dispatch(
        downloadSharedData({
          ...sharedLink,
          password,
          locale,
        })
      );
    }
  };

  return (
    <Grid
      container
      sx={{ width: "100vw", height: "100vh" }}
      // alignContent="center"
      alignContent="space-between"
      // rowGap={3}
    >
      <Grid
        container
        item
        xs={12}
        padding={2}
        // justifySelf="start"
        justifyContent="end"
      >
        <Locale />
      </Grid>
      <Grid container item xs={12} rowGap={3}>
        <Logo />
        {done && error ? (
          <Grid container item xs={12} justifyContent="center">
            <SlimAlert
              notCollapse
              {...{ errors: [linkError], severity: "error", xs: 4 }}
            />
          </Grid>
        ) : null}
        {done && !error ? (
          <Fragment>
            <Grid container item xs={12} justifyContent="center">
              <Grid item xs="auto">
                <Typography fontSize={20}>
                  {text1}
                  {sharedLink.name}
                </Typography>
              </Grid>
            </Grid>
            {sharedLink.has_password ? (
              <Grid container item xs={12} justifyContent="center">
                <TextField
                  tagError
                  {...{
                    id: passwordFieldUI,
                    UIText,
                    onKeyDown: validate,
                    submit,
                    type: "password",
                    validation: notEmptyPassword,
                    xs: 4,
                  }}
                />
              </Grid>
            ) : null}
            <Grid container item xs={12} justifyContent="center">
              <Button
                {...{
                  disabled: !!passError,
                  UIText: UITextBtn,
                  startIcon: <CloudDownload />,
                  onMouseDown: validate,
                  onClick: submit,
                }}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <SlimAlert
                notCollapse
                {...{ errors: [info], severity: "info", xs: 4 }}
              />
            </Grid>
          </Fragment>
        ) : null}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default SharedDownload;
