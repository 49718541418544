import { Tooltip, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { w365orgsData } from "../../../../../store/actions2/Windows365/getOrganizations";

const Domain = ({ fontSize, group }) => {
  let { warehouse } = useUIBoilerplate();
  let orgs = warehouse[w365orgsData] ?? {}
  let org = orgs[group] ?? {}
  let domain = org.domain ?? ""
  let domainFixed = domain.toLowerCase()

  return (
    <Tooltip title={"@" + domainFixed}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {"@" + domainFixed}
      </Typography>
    </Tooltip>
  );
};

export default Domain;
