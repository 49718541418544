import { Typography } from "@mui/material";

const Version = ({ fontSize, mid, version, croppedVersion }) => {
  return (
    <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {mid && version ? croppedVersion : "--"}
    </Typography>
  );
};

export default Version;
