import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { humanBandwidth } from "../../../utility/humanizers";

const UIText = {
  "es-CL": {
    label: "Velocidad máxima de subida",
  },
  "en-US": {
    label: "Maximum upload speed",
  },
};

const Bandwidth = (props) => {
  const { throttling, network_packet_size, locale } = props;
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid container item xs={6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid container item xs={6}>
        <Typography>
          {throttling ? humanBandwidth(network_packet_size) : "Ilimitada"}
        </Typography>
      </Grid>
    </Fragment>
  );
};

export default Bandwidth;
