import axios from "axios";
import { enqueueSnackbar } from "notistack";

const UIText= {
    'es-CL':{success:'Enlace de descarga creado y copiado al portapapeles', error:'Error al crear enlace de descarga: '},
    'en-US':{success:'Download link created and copied to the clipboard', error:'Error creating download link'}
}

const createSharedLink = (data) => {
  console.log("creating sharing link!");
  return async (dispatch) => {
    // dispatch(statusSliceActions.startSubmit("POST group"));
    const { server, cloner_key, payload, user, locale } = data;
    const {success,error} = UIText[locale]
    try {
      const bodyData = JSON.stringify(payload);
      const url = server + "api/share"
      const response = await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });

      let baseURL = "";
      if (typeof window !== "undefined") {
        baseURL = window.location.origin;
      }
      const downloadLink =
        baseURL +
        "/shared?id=" +
        response.data.id +
        "&user=" +
        encodeURIComponent(user);
      navigator.clipboard.writeText(downloadLink);

      enqueueSnackbar(success,{variant:'success'})
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error+e.message,{variant:'error'})
    }
  };
};

export default createSharedLink;
