import { Card, Grid } from "@mui/material";
import useVH from "../../../hooks2/useVH";

const BasicContainer = (props) => {
  const {
    children,
    height = 92,
    width = "100%",
    // pageScroll,
    xl,
    lg,
    md,
    sm,
    xs,
  } = props;
  const vh = useVH(height);
  const vh2 = useVH(height - 4);

  return (
    <Grid
      container
      {...{
        sx: {
          width: "100%",
          padding: "2vh",
          maxHeight: vh,
          // overflowY: pageScroll ? "scroll" : undefined,
          // overflowX:'hidden',
          // boxSizing:'border-box',
        },
        alignContent: "start",
      }}
    >
      <Grid container item {...{ xl, lg, md, sm, xs }}>
        <Card {...{ sx: { width } }}>
          <Grid
            container
            {...{
              sx: {
                maxHeight: vh2,
                width: "100%",
                // overflow
                overflowY: "auto",
                // boxSizing: "border-box",
                overflowX: "hidden",
                // border: "1px solid red",
              },
            }}
          >
            {children}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default BasicContainer;
