import Category from "./Category";

const UIText = {
  "es-CL": { header: "Documento" },
  "en-US": { header: "Document" },
};

const Document = () => {
  return <Category {...{ category: "document", UIText }} />;
};

export default Document;
