import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Numero", placeholder: "1234" },
  "en-US": { label: "Number", placeholder: "1234" },
};
export const deliveryStreetNumberUI = "deliveryStreetNumber";

const DeliveryStreetNumber = () => {

  return (
    <TextField
    required
      {...{
        id: deliveryStreetNumberUI,
        validation: notEmptyField,
        UIText,
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      }}
    />
  );
};

export default DeliveryStreetNumber
