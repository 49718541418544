import { Divider, Grid } from "@mui/material";
import DownloadSheet from "./DownloadSheet";
import UploadSheet from "./UploadSheet";
import SelectEmpty from "./SelectEmpty";
import VerifyMail from "./VerifyMail";
import SuggestMail from "./SuggestMail";
import More from "./More";

const TopBar = (props) => {
  return (
    <Grid
      container
      item
      {...{
        padding: 2,
        paddingTop: 0,
        paddingBottom: 2,
        columnSpacing: 2,
        rowGap: 2,
        xs: 12,
        justifyContent: "space-between",
      }}
    >
      <Grid container item xs="auto" alignItems="center" columnGap={2}>
        <SelectEmpty {...props} />
        <Divider orientation="vertical" variant="middle" flexItem />
        <VerifyMail {...props} />
        <SuggestMail {...props} />
        <More />
      </Grid>
      <Grid
        container
        item
        xs={true}
        alignItems="center"
        justifyContent="end"
        columnSpacing={2}
      >
        <UploadSheet {...props} />
        <DownloadSheet />
      </Grid>
    </Grid>
  );
};

export default TopBar;
