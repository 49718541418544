import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365OrgId } from "./getOrganization";
// import { getOrgId } from "./getOrganization";

export const UIText = {
  "es-CL": {
    success: "Organización de Office 365 habilitada",
    error: "Error al intentar habilitar organizacion de Office 365: ",
  },
  "en-US": {
    success: "Office 365 organization enabled",
    error: "Error while enabling Office 365 organization: ",
  },
};

export const postOrgId = "post365org";

export const postOrganization = (data) => {
  return async (dispatch) => {
    const id = postOrgId;
    const { W365server, cloner_key, payload,locale } = data;
    dispatch(worker.start(id));
    const bodyData = JSON.stringify(payload);
    const {success,error}= UIText[locale]

    try {
      const url = W365server+"mailapi/organization";
      await axios.post(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(worker.clear(getW365OrgId));
    } catch (e) {
      console.error(e);
      const message = `${error} ${e.message}`;
      enqueueSnackbar(message, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
