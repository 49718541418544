/**
 * Logs in to Cloner with the provided user credentials.
 * @param {Object} data - The user credentials.
 * @param {string} data.locale - The user's locale.
 * @param {string} data.user - The user's username.
 * @param {string} data.pass - The user's password.
 * @returns {Function} - The async function that logs in to Cloner.
 */
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { CustomError } from "../../../utility/response";
import { authenticationSliceActions as auth } from "../../slices/authentication";
import { UISliceActions as UI } from "../../slices/UI2";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": {
    badPassword: "Contraseña equivocada",
    badUser: "Usuario no existe",
  },
  "en-US": { badPassword: "Bad password", badUser: "User doesn't exist" },
};

export const id = "login";
const director = "https://login.cloner.cl/api/server";
export const W365server = "https://c365.cloner.cl:4022/";
export const Dev365server = "https://devel.cloner.cl:4022/";

const loginToCloner = (data) => {
  console.log("Signing in to Cloner!");
  return async (dispatch) => {
    const { locale, user: login, pass } = data;
    const { badPassword, badUser } = UIText[locale];
    dispatch(worker.start(id));
    try {
      // GET login server
      const response = await axios.get(director, { params: { login } });
      const loginServer = response.data;

      // POST login data
      const bodyData = JSON.stringify({ user: login, pass });
      const url = loginServer + "api/user/login";
      const response2 = await axios.post(url, bodyData);

      const rData = response2.data;
      if (rData.resp === 2) {
        throw CustomError(badPassword, "ERR_BAD_LOGIN");
      } else if (rData.resp === 7 && rData.has_tfa === 2) {
        dispatch(UI.setValue({ id: "twofactor", value: true }));
      } else if (response2.data.resp === 0) {
        const url2 = loginServer + "api/groups";
        const response3 = await axios.get(url2, {
          params: { group: response2.data.group },
          headers: { cloner_key: response2.data.token },
        });
        const groupName = response3.data.name;

        let server;
        if (loginServer === "https://zifre.cloner.cl/") {
          server = W365server;
        } else {
          server = Dev365server;
        }
        const url = `${server}mailapi/login`;

        const response4 = await axios.post(url, bodyData, {
          headers: { cloner_key: response2.data.token },
        });

        dispatch(
          auth.setAuthentication({
            data: { ...response2.data, groupName },
            server: loginServer,
          })
        );
        dispatch(auth.setO365Data({ ...response4.data, server }));
      }
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      if (e.code === "ERR_BAD_REQUEST") {
        enqueueSnackbar(badUser, { variant: "error" });
      } else if (e.code === "ERR_BAD_LOGIN") {
        enqueueSnackbar(badPassword, { variant: "error" });
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default loginToCloner;
