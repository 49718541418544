import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365ClientId } from "./getClient";

const UIText = {
  "es-CL": {
    success: "Activacion del usuario de Windows365 exitosa",
    error: "Error al activar usuario de Windows365: ",
  },
  "en-US": {
    success: "Windows365 user activation successful",
    error: "Error while activating Windows365 user: ",
  },
};

export const postClientsId = "post365client";
export const postClientsData = "365clientsData";

const postClients = (data) => {
  const { W365server, cloner_key, payloads, locale } = data;
  const id = postClientsId;
  const { success, error } = UIText[locale];
  return async (dispatch) => {
    // dispatch(worker.start(id));
    for (let payload of payloads) {
      console.log("postClient payload", payload);
      const bodyData = JSON.stringify(payload);
      console.log("postClient bodyData", bodyData);
      try {
        const url = `${W365server}mailapi/client`;
        await axios.post(url, bodyData, {
          headers: { cloner_key },
        });
        //   console.log("CLIENT?", client);
        // dispatch(UISliceActions.clear(authId));
        enqueueSnackbar(success, { variant: "success" });
        // dispatch(worker.finish({ id }));
        // dispatch(worker.clear(getW365ClientId));
      } catch (e) {
        console.error(e);
        enqueueSnackbar(error + e.message, { variant: "error" });
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }
  };
};

export default postClients;
