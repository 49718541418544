import { Fragment } from "react";
import CheckButton from "../../../../../UI2/Specialized/Check";
import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";

const DeviceRow = () => {
    const {UI} = useUIBoilerplate()
    const selectedArray = UI['placeholder2']

    const selected = !!selectedArray.find((x)=> x === 'placeholder' )

  return (
    <Fragment>
      <Grid
        container
        item
        {...{
          alignItems: "center",
          xs: 12,
          sx: {
            backgroundColor: selected?'rgba(0, 167, 132, 0.1)':undefined,
            padding: 0.5,
            paddingLeft:0,
            paddingRight:0,
            borderBottom: "1px solid lightGrey",
          },
        }}
      >
        <CheckButton
          {...{ id: 'placeholder', arrayId: 'placeholder2', xs: "auto", fontSize: 1 }}
        />
        <Grid container item {...{ alignContent: "center", xs: true }}>
          <Typography fontSize={14}>usuario1@cloner.cl</Typography>
        </Grid>
        <Grid container item {...{ alignContent: "center", xs: 3 }}>
          <Typography fontSize={14}>superusuario</Typography>
        </Grid>
        <Grid container item {...{ alignContent: "center", xs: 2 }}>
          <Typography fontSize={14}>100GB</Typography>
        </Grid>
        <Grid container item {...{ alignContent: "center", xs: 3 }}>
          <Typography fontSize={14}>2020-07-03</Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DeviceRow;
