import axios from "axios";
import { sortObjByKey } from "../../../utility/sortObjByKey";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as workers } from "../../slices/workers";
import { createMachineData } from "./utils";

export const getDevicesId = "getDevices";
export const devicesDataId = "devicesInPicker";
export const devicesListId = "devicesList";

const getDevices = (data) => {
  console.log("getting machines data!");
  return async (dispatch) => {
    const { controller, cloner_key, server, user } = data;
    dispatch(workers.start(getDevicesId));
    try {
      const response = await axios.get(server + "api/machines", {
        signal: controller.signal,
        headers: { cloner_key },
        params: { user },
      });
      let newDevices = [];
      for (const machine of response.data.machines_info) {
        newDevices.push(createMachineData(user, 0, machine));
      }
      newDevices.sort((a, b) => sortObjByKey(a, b, "name"));
      dispatch(warehouse.load({ id: devicesDataId, data: newDevices }));
      const newDevices2 = newDevices.filter( x => !!x.mid).map(x => x.mid)
      dispatch(warehouse.load({id:devicesListId, data:newDevices2}))

      dispatch(workers.finish({ id: getDevicesId }));
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id: getDevicesId, error: e.message }));
    }
  };
};

export default getDevices;
