import { Grid } from "@mui/material";
import useVH from "../../../../hooks2/useVH";

const BasicHeader = ({ children }) => {
  const vh1 = useVH(8)
  return (
    <Grid
      container
      item
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: vh1,
        alignItems: "center",
        xs: 12,
        sx: { borderBottom: "1px solid lightgrey" },
        fontSize: 20,
      }}
    >
      {children}
    </Grid>
  );
};


export default BasicHeader