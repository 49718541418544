const HeadersData = [
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey:'mail',
    xs: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Nombre" },
      "en-US": { label: "Name" },
    },
    filterKey:'name',
    xs: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Permisos" },
      "en-US": { label: "Permissions" },
    },
    xs: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Contraseña" },
      "en-US": { label: "Password" },
    },
    xs: true,
  },
];

export default HeadersData