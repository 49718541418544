import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = (props) => {
  const { disabled, confirmation } = props;
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal2());
    confirmation();
  };
  return (
    <Button
      {...{
        disabled,
        UIText,
        onClick,
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Confirm;
