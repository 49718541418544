import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const open = UI["group-traveler2-open"] ?? false;

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ disabled: open, UIText, onMouseUp: onClick, variant:'text' }} />;
};

export default Cancel;
