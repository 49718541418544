import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};

const Close = ({ working }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };
  return (
    <Button
      {...{
        disabled: working,
        UIText,
        onClick,
        // variant: "text",
        labelSx: { fontSize: 13, fontWeight: "bold" },
      }}
    />
  );
};

export default Close;
