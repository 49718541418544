import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { w365orgsData } from "../../../../store/actions2/Windows365/getOrganizations";
import { suggestMailId, suggestW365Mails } from "../../../../store/actions2/Windows365/suggestW365Mails";
import Button from "../../../UI2/Button";
import { arrayId } from "../UsersList/UsersList";

const UITextSuggest = {
  "es-CL": {
    label: "Sugerir correo",
    fixedTooltip:
      "Sugiere un correo para Windows 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "Suggest mail",
    fixedTooltip:
      "Suggest a valid Windows 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const SuggestMail = ({ users }) => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { working } = useWorker(suggestMailId);
  const { cloner_key, W365server } =auth;
  const { locale } = settings;
  const selectedUsers = UI[arrayId] ?? [];

  // console.log("SELECTED USERS", selectedUsers);
  // console.log("USERS", users);

  const handleSuggest = (e) => {
    let tryouts = [];

    for (const user of selectedUsers) {
      for (const user2 of users) {
        if (user2.login === user) {
          tryouts.push({
            login: user2.login,
            org: warehouse[w365orgsData][user2.group],
          });
        }
      }
    }
    // console.log("TRYOUTS", tryouts);

    dispatch(suggestW365Mails({ tryouts, cloner_key, W365server, locale }));
  };


  return (
    <Button
      {...{
        disabled: selectedUsers.length === 0,
        UIText: UITextSuggest,
        onClick: handleSuggest,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SuggestMail;
