import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { recoveriesSliceActions as recoveries } from "../../slices/recoveries";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": {
    success: "Recuperación creada exitosamente",
    error: "Error al obtener los repositorios",
  },
  "en-US": {
    success: "Recovery successfully created",
    error: "Error fetching repositories",
  },
};

export const addRecoveryUI = "addRecovery";

const addRecovery = (data) => {
  const {
    server,
    cloner_key,
    user,
    mid,
    device,
    option,
    bkpDate,
    fullSize,
    lastBkp,
    locale,
  } = data;
  const id = addRecoveryUI;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workers.start({ id }));
    let repositories = [];

    try {
      const url = server + "api/repositories";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: mid, user },
      });
      for (const repo of response.data.repositories) {
        // console.log('repo',repo)
        // dispatch(recoveries.newRepository(repo));
        repositories.push({
          id: repo.id,
          metafile: repo.root,
          name: repo.name,
          option,
          bkpDate,
          last_backup:repo.last_backup,
          size: repo.active_space,
          recover:true,
        });
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`${error}: ${e.message}`, { variant: "error" });
      dispatch(workers.finish({ id, error: e.message }));
    }

    dispatch(
      recoveries.newRecovery({
        user,
        mid,
        device,
        repositories,
        fullSize,
        lastBkp,
        mode: "simple",
      })
    );

    dispatch(recoveries.dontAskAgain())
    enqueueSnackbar(success, { variant: "success" });
    dispatch(workers.finish({ id }));
  };
};

export default addRecovery;
