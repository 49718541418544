import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const UIText = {
  "es-CL": {
    title: "Creacion de usuarios",
    filename: "Resumen.pdf",
    headers: ["User", "Name", "Permisos", "Contraseña", "Grupo", "Estado"],
    permissions: {
      admin: "Administrador",
      access: "Accede a la plataforma",
      mail: "Recibe correos",
    },
  },
  "en-US": {
    title: "Users creation",
    filename: "Summary.pdf",
    headers: ["User", "Name", "Permissions", "Password", "Group", "Status"],
    permissions: {
      admin: "Admin",
      access: "Access platform",
      mail: "Receives mails",
    },
  },
};

export const createClientsPDF = (data) => {
  return async (dispatch) => {
    const { pdfData, locale } = data;
    const { title, filename, headers, permissions } = UIText[locale];
    const { admin, access, mail } = permissions;

    const doc = new jsPDF({
      orientation: "l", //set orientation
      unit: "pt", //set unit for document
      format: "letter", //set document standard
    });
    doc.text(title, 40, 50);
    doc.setFontSize(13);
    // doc.text(`Grupo Destino: ${group}`, 40, 70);

    let bodyData = [];
    for (const user of pdfData) {

      let newPermissions = "";

      if (user.admin === "true") {
        newPermissions = newPermissions ? newPermissions + ", " + admin : admin;
      }
      if (user.has_access) {
        newPermissions = newPermissions
          ? newPermissions + ", " + access
          : access;
      }
      if (user.receives_mail) {
        newPermissions = newPermissions ? newPermissions + ", " + mail : mail;
      }

      const newRow = [
        user.user,
        user.name,
        newPermissions,
        user.pass ?? "",
        user.groupPath,
        user.status,
      ];
      bodyData.push(newRow);
    }

    autoTable(doc, {
      startY: 80,
      head: [
        [
          headers[0],
          headers[1],
          headers[2],
          headers[3],
          headers[4],
          headers[5],
        ],
      ],
      body: bodyData,
    });
    doc.save(filename);
  };
};
