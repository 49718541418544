import { AddToQueue } from "@mui/icons-material";
import ActionButton from "../../../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Agregar dispositivo" },
  "en-US": { label: "Add device" },
};

const AddDevice = () => {
  const { dispatch } = useUIBoilerplate();

  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "new-recovery-device",
        payload: {},
      })
    );
  };

  return (
    <ActionButton
      {...{
        onClick,
        UIText,
        icon: <AddToQueue />,
      }}
    />
  );
};

export default AddDevice;
