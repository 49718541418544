import { Dialog } from "@mui/material";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import Close from "./Components/Close";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Data from "./Components/Data";
import { useEffect } from "react";
import getRepositories, {
  getRepoId,
  repoDataId,
} from "../../store/actions2/Devices/getRepositories2";
import { warehouseSliceActions } from "../../store/slices/warehouse";
import { workersSliceActions } from "../../store/slices/workers";

const UIText = {
  "es-CL": "Dispositivo: ",
  "en-US": "Device: ",
};

const DeviceDataPop = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const locale = settings.locale;
  const text = UIText[locale];
  const { payload } = UI.modal ?? {};
  const { data } = payload ?? {};
  const { name, user, mid } = data ?? {};

  const repositories = warehouse[repoDataId] ?? [];

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getRepositories({ ...auth, mid, user, controller }));
    return ()=>{
      controller.abort()
    }
  }, [dispatch, auth, mid, user]);

  useEffect(() => {
    return () => {
      dispatch(workersSliceActions.clear(getRepoId));
      dispatch(warehouseSliceActions.unload(repoDataId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UISliceActions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header: text + name }} />
      <BasicDialogContent>
        <Data {...{ data, repositories, locale }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeviceDataPop;
