import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const getQRCodeId = "qrFetch";
export const QRData = "QR";

export const getQRCode = (data) => {
  const { server, cloner_key, user } = data;
  const id = getQRCodeId;
  return async (dispatch) => {
    try {
      dispatch(worker.start(id));
      const url = server + "api/me";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { user },
      });
        console.log('response',response)
      const keyTFA = response.data.user.key_tfa;
      const url2 = server + "api/user/qr-tfa";
      const qr = await axios.get(url2, {
        headers: { cloner_key },
        params: { keyTFA, user },
      });

      const { resp, Qr } = qr.data;

      if (resp === 0) {
        dispatch(warehouse.load({ id: QRData, data: Qr }));
        dispatch(worker.finish({ id }));
      }
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
