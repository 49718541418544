import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";

const Select = (props) => {
  const {
    id,
    UIText,
    options = [],
    size = "small",
    required,
    xl,
    lg,
    md,
    sm,
    xs,
    disabled,
    defaultIdx,
    // defaultIdx = 0,
    onChange = () => {},
    hide,
    fontSize = 14,
  } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const filteredProps = filterObjKeys(
    props,
    "UIText",
    "xs",
    "size",
    "required",
    "defaultIdx",
    "onChange",
    "hide",
    "fontSize"
  );
  const { label, tooltip, fixedTooltip } = UIText[locale];
  let value = UI[id] ?? (defaultIdx >= 0 && options[defaultIdx].value) ?? "";

  const x = options.findIndex((opt) => opt.value === value);
  if (x === -1) {
    value = "";
  }

  useEffect(() => {
    if (defaultIdx >= 0) {
      dispatch(UIactions.setValue({ id, value: options[defaultIdx].value }));
    }
  }, [dispatch, defaultIdx,id,options]);

  const items = useMemo(() => {
    return options.map((x, idx) => {
      const { label } = x[locale] ?? x.universal;
      return <MenuItem {...{ key: idx, value: x.value }}>{label}</MenuItem>;
    });
  }, [options, locale]);

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(UIactions.setValue({ id, value }));
    onChange(e);
  };

  return (
    <AutoGrid {...{ xl, lg, md, sm, xs }}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <FormControl
          fullWidth
          {...{
            size,
            required,
            sx: { visibility: hide ? "hidden" : undefined },
          }}
        >
          <InputLabel sx={{ fontSize }}>{label}</InputLabel>
          <MUISelect
            fullWidth
            {...filteredProps}
            {...{
              onChange: handleChange,
              MenuProps: { PaperProps: { sx: { maxHeight: 200 } } },
              label,
              value,
              sx: { fontSize },
            }}
          >
            {items}
          </MUISelect>
        </FormControl>
      </AutoHelp>
    </AutoGrid>
  );
};

export default Select;
