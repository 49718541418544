import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const Top = () => {
  const { settings } = useUIBoilerplate();

  const UIText = {
    "es-CL": { header: "Modificar exclusiones" },
    "en-US": { header: "Modify exclusions" },
  };

  const { header } = UIText[settings.locale];

  // const onClick = () => {
  //   dispatch(UISliceActions.clear(exclusionDevicesUI));
  // };

  return (
    <Grid
      container
      item
      {...{ padding: 1, paddingLeft: 2, paddingRight: 2, xs: 12 }}
      columnGap={2}
      alignItems="center"
    >
      {/* <IconButton
        {...{
          UIText: { universal: {} },
          navTo: -1,
          icon: <ArrowBack sx={{ fontSize: 18 }} />,
          onClick,
          size: "small",
        }}
      /> */}
      <Typography fontSize={22}>{header}</Typography>
    </Grid>
  );
};

export default Top;
