import { Grid } from "@mui/material";
import Divider from "../../../UI2/Divider";
import Interval from "./Fields/Interval";
import StealthMode from "./Fields/StealthMode";
import Bandwidth from "./Fields/Bandwidth";
import SelectionBlock from "./Fields/SelectionBlock";
import General from "./Fields/General";
import NewName from "./Fields/NewName";
import Suspended from "./Fields/Suspended";
import Load from "./Fields/Load";
import Multiupload from "./Fields/Multiupload";
import Security from "./Fields/Security";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const Form = (props) => {
  const { auth } = useUIBoilerplate();
  const { multi, locale } = props;

  return (
    <Grid container {...{ sx: { width: "100%" } }}>
      <Grid container item rowGap={2} alignItems="center">
        <General {...{ locale }} />
        {multi ? null : <NewName {...{ locale }} />}
        <Interval {...{ locale }} />
        {auth.admin === 1 ? <Suspended {...{ locale }} /> : null}
        <StealthMode {...{ locale }} />
        <Divider />
        <Load {...{ locale }} />
        <Bandwidth {...{ locale }} />
        {false ?<Multiupload {...{ locale }} />:null}
        <Divider />
        <Security {...{ locale }} />
        <SelectionBlock {...{ locale }} />
      </Grid>
    </Grid>
  );
};

export default Form;
