// export const currentMailsWID = "currentMails";

const headersData = {
  // warehouseId: currentMailsWID,
  headers: [
    {
      UIText: {
        "es-CL": { label: "De/Para:" },
        "en-US": { label: "From/To" },
      },
      filterKey: "",
      xs: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Asunto" },
        "en-US": { label: "Subject" },
      },
      filterKey: "",
      lg:7.5, md: 6.7, sm:4.9, xs: 4,
    },
    {
      UIText: {
        "es-CL": { label: "Fecha" },
        "en-US": { label: "Date" },
      },
      filterKey: "receivedDateTime",
      xs: true,
    },
    {
      UIText: {
        "es-CL": { label: "" },
        "en-US": { label: "" },
      },
      xs: 0.5,
      justify: "end",
    },
  ],
};

export default headersData;
