import { Grid } from "@mui/material";
import CheckButton from "../../Specialized/Check";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { defaultSelected } from "../../genericIDs";
import useVH from "../../../../hooks2/useVH";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };

const BasicRow = (props) => {
  const {
    id,
    disabled,
    elements = [],
    arrayId = defaultSelected,
    error,
  } = props;
  const { UI } = useUIBoilerplate();
  const height1 = useVH(6)
  const height2 = useVH(2)
  const selectedArray = UI[arrayId] ?? [];
  const selected = selectedArray.find((x) => x === id);

  let backgroundColor;
  if (error) {
    backgroundColor = "rgba(255, 0, 0, 0.1)";
  } else if (selected) {
    backgroundColor = "rgba(0, 167, 132, 0.1)";
  }

  const gridProps = {
    alignItems: "center",
    columnGap: 1,
    xs: 12,
    wrap: 'nowrap',
    sx: {
      fontSize: 20,
      height: height1,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
      backgroundColor,
    },
  };

  const columns = elements.map((data, idx) => {
    const { xl, lg, md, sm, xs, content, justify } = data;
    return (
      <Grid
        container
        item
        {...{ key: idx, xl, lg, md, sm, xs, justifyContent: justify, wrap: 'nowrap' }}
      >
        {content}
      </Grid>
    );
  });

  return (
    <Grid container item {...gridProps}>
      {id || disabled ? (
        <CheckButton
          {...{
            id,
            arrayId,
            disabled,
            fontSize: 1,
            xs: "auto",
          }}
        />
      ) : null}
      {columns}
    </Grid>
  );
};

export default BasicRow;
