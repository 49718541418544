import { CircularProgress } from "@mui/material";
import IconButton from "../../../../UI2/IconButton";
import { CloudSync } from "@mui/icons-material";
import restoreMails from "../../../../../store/actions2/Mails/restoreMails";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";

const UIText = { universal: {} };

const Restore = ({ id }) => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { working } = useWorker("restore-" + id);

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(
      restoreMails({
        ...auth,
        workerId: "restore-" + id,
        mailIDs: [id],
        locale: settings.locale,
      })
    );
  };

  const icon = working ? (
    <CircularProgress size={20} />
  ) : (
    <CloudSync sx={{ fontSize: 20 }} />
  );

  return (
    <IconButton
      {...{
        disabled: working,
        UIText,
        onClick,
        icon,
        sx:{
          '&:hover':{
            color:'primary.main'
          }
        }
      }}
    />
  );
};

export default Restore;
