import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { useWorker } from "../../../../hooks2/useWorker";
import { id } from "../../../../store/actions2/Login/loginToCloner";
import Button from "../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Login" },
};

const LoginBtn = (props) => {
  const { hardValidation: onMouseDown, login: onClick } = props;
  const { UI } = useUIBoilerplate("login");
  const { working } = useWorker(id);
  const { locale: lang = "es-CL" } = useURLQuery();
  const { user = {}, password = {} } = UI;
  const disabled = !!user.error || !!password.error;

  return (
    <Grid container item xs={12} justifyContent="center">
      <Button
        fullWidth
        {...{ UIText, lang, onMouseDown, onClick, working, disabled, xl:3,lg: 3, md: 4, sm: 6,xs: 8 }}
      />
    </Grid>
  );
};

export default LoginBtn;
