import { Grid } from "@mui/material";
import CustomDrawer from "./Components/Drawer/Drawer";
import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import DashBody from "./Components/DashBody";
import SecondaryModalsOutlet from "../Modals/ModalsOutletSecondary";
import ModalsOutlet from "../Modals/ModalsOutlet";
import { viewportHeightUI } from "../../App";

const Dashboard = () => {
  const { auth, navigate, UI } = useUIBoilerplate();
  const height = UI[viewportHeightUI];

  useEffect(() => {
    if (!auth.token) {
      navigate("/login", { replace: true });
    }
  }, [navigate, auth.token]);

  return (
    <Grid container {...{ sx: { height, columnGap:0 } }}>
      <SecondaryModalsOutlet />
      <ModalsOutlet />
      <CustomDrawer />
      <DashBody height={height} />
    </Grid>
  );
};

export default Dashboard;
