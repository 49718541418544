import { IconButton as MUIIconButton } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import filterObjKeys from "../../utility/filterObjectKeys";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";
// const UIText = {
//   "es-CL": {
//     tooltip: "",
//   },
// };

const IconButton = (props) => {
  const {
    disabled,
    onClick,
    navTo,
    navReplace,
    icon,
    UIText = {},
    xs,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "icon",
    "UIText",
    "navTo",
    "navReplace",
    "xs"
  );
  const { navigate, settings } = useUIBoilerplate();
  const locale = settings.locale

  const X = UIText[locale]??UIText.universal
  const { tooltip, fixedTooltip } = X

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navTo) {
      navigate(navTo, {
        replace: navReplace,
      });
    }
  };

  return (
    <AutoGrid {...{ xs, alignContent:'center' }}>
      <AutoHelp {...{ disabled, tooltip, fixedTooltip }}>
        <MUIIconButton {...filteredProps} {...{ onClick: handleClick }}>
          {icon}
        </MUIIconButton>
      </AutoHelp>
    </AutoGrid>
  );
};

export default IconButton;
