import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};

const Close = ({ modal }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Close;
