import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": { error: "Error al descargar informacion de archivos" },
  "en-US": { error: "Error while loading files information" },
};

// export const getFilesId = "getFiles";
export const filesDataId = "files";
export const breadcrumbsId = "breadcrumbs";

export const getFiles = (data) => {
  return async (dispatch) => {
    const { server, cloner_key, file, repo, locale } = data;
    const { error } = UIText[locale];
    dispatch(worker.start('file-'+file));
    try {
      const url = server + "api/files";
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: file, repoid: repo },
      });
      dispatch(warehouse.load({id:filesDataId, data:response.data.files}))
      dispatch(warehouse.load({id:breadcrumbsId, data:[...response.data.breadcrumb].reverse()}))
      dispatch(worker.finish({ id: 'file-'+file }));
    } catch (e) {
      console.log(e);
      dispatch(worker.finish({ id: 'file-'+file, error: e.message }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};
