import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import {
  workersSliceActions as worker,
  workersSliceActions,
} from "../../slices/workers";

export const getUsersId = "getAllUsers";
export const usersDataId = "users";

export const getAllUsers = (data) => {
  return async (dispatch) => {
    const { server, cloner_key, group } = data;
    const id = getUsersId;
    dispatch(worker.start(id));
    try {
      const response = await axios.get(server + "api/all_users", {
        headers: { cloner_key },
        params: { group },
      });

      dispatch(warehouse.load({ id: usersDataId, data: response.data }));
      dispatch(workersSliceActions.finish({ id }));
    } catch (e) {
      console.error(e.message);
      dispatch(workersSliceActions.finish({ id, error: e.message }));
    }
  };
};
