import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { authenticationSliceActions as authentication } from "../../slices/authentication";

const UIText = {
  "es-CL": {
    success: "Exito al modificar el usuario",
    error: "Fallo al crear/modificar usuario: ",
  },
  "en-US": {
    success: "Success modifying the user",
    error: "Failure while creating/modifying user: ",
  },
};

export const postSelfId = "postSelf";

// POST user data
export const postSelf = (data) => {
  const { server, cloner_key, payload, locale } = data;
  const { error, success } = UIText[locale];
  const id = postSelfId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      const bodyData = JSON.stringify(payload);
      await axios.post(server + "api/users", bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });
      const msg = success + payload.user;
      dispatch(worker.finish({ id }));
      dispatch(authentication.setUsername(payload.name))
      enqueueSnackbar(msg, { variant: "success" });
    } catch (e) {
      console.error(e)
      enqueueSnackbar(error+e.message, { variant: "error" });
      dispatch(worker.finish({ id, error: e }));
    }
  };
};
