import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

export const getMailsId = "getMails";
export const mailsData = "mails";

const getMails = (data) => {
  const { W365server, cloner_key, query = "", folderId, controller } = data;
  const id = getMailsId;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url =
        W365server +
        `mailapi/folder/${folderId}/message` +
        (query ? query + "&limit=999999" : "?limit=999999");
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
      });

      const data = response.data;
      if (data) {
        dispatch(warehouse.load({ id: mailsData, data: data.messages }));
      } else {
        dispatch(warehouse.unload(mailsData));
      }
      dispatch(workers.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workers.clear(id));
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default getMails;
