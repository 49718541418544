import Button from "../../../../UI2/Button";

const UIText1 = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};
const UIText2 = {
  "es-CL": { label: "Volver" },
  "en-US": { label: "Back" },
};

const Cancel = ({ readOnly }) => {
  return (
    <Button
      {...{
        UIText: readOnly ? UIText2 : UIText1,
        navTo: `/dashboard/recovery/${readOnly?'step3':'step1'}`,
      }}
    />
  );
};

export default Cancel;
