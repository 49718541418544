import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import {
  getGreylistId,
  greylistDataId,
} from "../../../../store/actions2/Devices/getGreylist";
import { newTreesSliceActions } from "../../../../store/slices/trees2";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import { warehouseSliceActions as warehouse } from "../../../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { devicePickerId } from "../../../UI2/Specialized/DevicePicker";
import { greylistPickerId } from "../../../UI2/Specialized/GreylistPicker";
import RepositoryPicker from "../../../UI2/Specialized/RepositoryPicker";
import { greylistTreeReady } from "./GreylstPicker";

const RepoPicker = (props) => {
  const { user } = props;
  const { dispatch, UI } = useUIBoilerplate();
  const { mid = "" } = UI[devicePickerId] ?? {};

  const onChange = () => {
    dispatch(workers.clear(getGreylistId));
    dispatch(workers.clear(greylistTreeReady));
    dispatch(warehouse.unload(greylistDataId));
    dispatch(UIactions.clear(greylistPickerId));
    dispatch(newTreesSliceActions.clearForest());
  };

  return <RepositoryPicker {...{ user, mid, onChange, xs: 6 }} />;
};

export default RepoPicker;
