/**
 * Forces a rescan of devices by sending a request to the server.
 * @param {Object} data - The data required to perform the rescan.
 * @param {string} data.server - The URL of the server to send the request to.
 * @param {string} data.cloner_key - The key required to authenticate the request.
 * @param {Array} data.payload - The data to be sent in the request body.
 * @param {string} data.locale - The locale to use for displaying messages.
 * @returns {Function} - The async function that sends the request and dispatches actions based on the response.
 */
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as workers } from "../../slices/workers";
import axios from "axios";

export const forceRescanId = "forceRescan";

const UIText = {
  "es-CL": {
    start: "Forzando el reescaneo de equipos",
    success: "Reescaneo solicitado exitosamente",
    serverError: "Servidor retornó error",
    error: "Error al intentar solicitar el rescaneo de un equipo",
  },
  "en-US": {
    start: "Forcing devices to rescan data",
    success: "Rescan requested successfully",
    serverError: "Server returned error",
    error: "Error while trying to request a rescan",
  },
};

const forceRescan = (data) => {
  const { server, cloner_key, payload, locale } = data;
  return async (dispatch) => {
    const { start, success, serverError, error } = UIText[locale];
    dispatch(workers.start(forceRescanId));
    enqueueSnackbar(start, { variant: "info" });
    try {
      for (const item of payload) {
        const bodyData = JSON.stringify(item);
        const url = server + "api/rescan";
        const response = await axios.post(url, bodyData, {
          headers: { cloner_key },
        });
        if (!response.data) {
          throw new Error(serverError);
        }
      }
      dispatch(workers.finish({ id: forceRescanId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id: forceRescanId, error: e.msg }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default forceRescan;
