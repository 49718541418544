import { Fragment } from "react";
import Divider from "../../UI2/Divider";
import { Grid } from "@mui/material";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Seleccionar" },
  "en-US": { label: "Select" },
};
const Bottom = ({ close }) => {
  return (
    <Fragment>
      <Divider />
      <Grid container item padding={1} justifyContent="end">
        <Button {...{ UIText, onClick: close }} />
      </Grid>
    </Fragment>
  );
};

export default Bottom;
