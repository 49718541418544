import { Delete, Edit, Tune } from "@mui/icons-material";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import MoreActions from "../../../../../../UI2/BasicLayout/Components/MoreActions/MoreActions";
import { UISliceActions } from "../../../../../../../store/slices/UI2";
import { recoveriesSliceActions } from "../../../../../../../store/slices/recoveries";
import { defaultSelected } from "../../../../../../UI2/genericIDs";

const Actions = (props) => {
  const { id } = props;
  const { dispatch, navigate } = useUIBoilerplate();

  let data = {
    id,
    buttonsData: [
      {
        icon: <Edit sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Editar" },
          "en-US": { label: "Edit" },
        },
        onClick: () => {
          dispatch(
            UISliceActions.openModal({
              type: "edit-recovery-device",
              payload: { ...props },
            })
          );
        },
      },
      {
        icon: <Tune sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Avanzado" },
          "en-US": { label: "Advanced" },
        },
        onClick: () => {
          navigate(`/dashboard/recovery/advanced?id=${id}`);
        },
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Remover" },
          "en-US": { label: "Remove" },
        },
        onClick: () => {
          dispatch(recoveriesSliceActions.deleteRecoveries([id]));
          dispatch(recoveriesSliceActions.removeUnusedRepositories());
          dispatch(UISliceActions.remove({ id: defaultSelected, key: id }));
        },
      },
    ],
  };

  return <MoreActions {...data} />;
};

export default Actions;
