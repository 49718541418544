import {
  ChevronRight,
  KeyboardDoubleArrowLeft,
  Laptop,
  MoreHoriz,
  Storage,
} from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../hooks2/useURLQuery";
import Button from "../../../UI2/Button";
import { Grid } from "@mui/material";
import IconButton from "../../../UI2/IconButton";
import { UISliceActions } from "../../../../store/slices/UI2";

const UIText = {
  "es-CL": { fixedTooltip: "Volver al inicio" },
  "en-US": { fixedTooltip: "Return home" },
};
const UIText2 = {
  "es-CL": { fixedTooltip: "Expandir el rastro" },
  "en-US": { fixedTooltip: "Expand breadcrumbs" },
};
const UIText3 = {
  "es-CL": { fixedTooltip: "Colapsar el rastro" },
  "en-US": { fixedTooltip: "Collapse breadcrumbs" },
};

// export const breadcrumbsId = 'breadcrumbs'
const expandedBC = "expanded";

const Breadcrumbs = () => {
  const { dispatch, navigate, warehouse, UI } = useUIBoilerplate();
  const { user, mid, repo, file } = useURLQuery();
  const { breadcrumbs = [] } = warehouse;
  const expanded = UI[expandedBC];

  let items = [];
  let first = true;
  if (file && !!breadcrumbs.length) {
    for (const idx in breadcrumbs) {
      const onClick = () => {
        // dispatch(UIactions.clear(filesSortId));
        // dispatch(UIactions.clear(filesFilterId));
        // dispatch(UIactions.clear("versionsFilter"));
        navigate(
          `?user=${encodeURIComponent(user)}&mid=${mid}&repo=${repo}&file=${
            breadcrumbs[idx].id
          }`
        );
      };

      const isLast = breadcrumbs.length - 1 === parseInt(idx);
      const key = breadcrumbs[idx].id;
      const labelSx = { textTransform: "none" };

      let label = "";
      if (breadcrumbs[idx].name === "/") {
        label = "[Root]";
      } else {
        label = breadcrumbs[idx].name;
      }

      const UIText = {
        "es-CL": { label },
        "en-US": { label },
      };

      items.push(
        <Grid container item xs="auto" key={`div-${idx}`} alignItems="center">
          <ChevronRight color="disabled" />
        </Grid>
      );
      if (first) {
        first = false;
        items.push(
          <Button
            {...{
              UIText,
              variant: "text",
              key,
              startIcon: <Storage />,
              onClick,
              labelSx,
              xs: "auto",
              // disabled: isLast,
            }}
          />
        );
      } else {
        items.push(
          <Button
            {...{
              UIText,
              variant: "text",
              key,
              onClick,
              labelSx,
              xs: "auto",
              disabled: isLast,
              // sx: { maxWidth: 300 },
            }}
          />
        );
      }
    }
  }

  const handleHomeClick = () => {
    // dispatch(UIactions.clear(filesSortId));
    // dispatch(UIactions.clear(filesFilterId));
    // dispatch(UIactions.clear("versionsFilter"));
    navigate(`?user=${encodeURIComponent(user)}&mid=${mid}`);
  };

  const toggleBC = () => {
    dispatch(UISliceActions.toggle(expandedBC));
  };

  let finalItems = [];
  // console.log()
  if (!expanded && items.length > 6) {
    finalItems.push(
      <Grid container item xs="auto" key={`collapse1`} alignItems="center">
        <ChevronRight color="disabled" />
      </Grid>
    );
    finalItems.push(
      <Grid container item xs="auto" key={`collapse2`} alignItems="center">
        <IconButton
          {...{
            onClick: toggleBC,
            UIText: UIText3,
            icon: <MoreHoriz sx={{ fontSize: 18 }} />,
            size: "small",
            sx: { borderRadius: "5px" },
          }}
        />
      </Grid>
    );
    finalItems = [finalItems, ...items.slice(items.length - 6)];
    // console.log('sliced',items.slice(4))
  } else {
    finalItems = items;
    if (items.length > 6) {
      finalItems.push(
        <Grid container item xs="auto" key={`collapse2`} alignItems="center">
          <IconButton
            {...{
              onClick: toggleBC,
              UIText: UIText2,
              icon: <KeyboardDoubleArrowLeft sx={{ fontSize: 18 }} />,
              size: "small",
            }}
          />
        </Grid>
      );
    }
  }

  return (
    <Grid
      container
      item
      xs={true}
      alignContent="center"
      sx={{
        overflowY: "hidden",
        overflowX: "auto",
        borderRight: expanded?"1px solid lightgrey":undefined,
      }}
      wrap="nowrap"
    >
      <Grid item xs="auto">
        <IconButton
          {...{
            UIText,
            icon: <Laptop />,
            color: "primary",
            onClick: handleHomeClick,
            disabled: !file,
            sx: { borderRadius: "5px" },
          }}
        />
      </Grid>
      {finalItems}
    </Grid>
  );
};

export default Breadcrumbs;
