import axios from "axios";
import { UISliceActions } from "../../slices/UI2";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const tunnelTokenId = "tunnelToken";
export const rootsDataId = "roots";

const getMachineStatus = (data) => {
  const { user, mid, server, cloner_key } = data;
  return async (dispatch) => {
    dispatch(worker.start(tunnelTokenId));
    console.log("Adquiring Tunnel Token!");
    try {
      let formData = new FormData();
      formData.append("user", user);
      formData.append("machine", mid);
      const response = await axios.post(
        server.slice(0, -1) + ":5107/startSession",
        formData,
        { headers: { "Content-Type": "multipart/form-data", cloner_key } }
      );
      dispatch(
        UISliceActions.setValue({ id: tunnelTokenId, value: response.data.token })
      );

      console.log("Adquiring Machine Status!");
      const tunnel_token = response.data.token;
      const response2 = await axios.get(server.slice(0, -1) + ":5107/status", {
        headers: { tunnel_token },
      });

      console.log("Adquiring Machine Roots!");
      const response3 = await axios.get(server.slice(0, -1) + ":5107/roots", {
        headers: { tunnel_token },
      });

      const repos = response2.data.repos;
      const roots = response3.data.roots;

      let newRoots = [];
      for (const root of roots) {
        const found = repos.find((x) => x.name === root);
        let newRoot = { id: null, root };
        if (found) {
          newRoot.id = found.id;
        }
        newRoots.push(newRoot);
      }
      dispatch(warehouse.load({ id: rootsDataId, data: newRoots }));

    } catch (e) {
      console.error(e)
      dispatch(worker.finish({ id: tunnelTokenId, error: e.message }));
    }
    dispatch(worker.finish({ id: tunnelTokenId}));
  };
};

export default getMachineStatus;
