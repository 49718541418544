import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { newTreesSliceActions as newTrees } from "../../slices/trees2";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": { error: "Error al descargar informacion de archivos" },
  "en-US": { error: "Error while loading files information" },
};

// export const getFilesId = "getFiles";
export const filesDataId = "files";
export const breadcrumbsId = "breadcrumbs";

const getTreeFiles = (data) => {
  return async (dispatch) => {
    const { server, cloner_key, file, repo, locale,idx } = data;
    const { error } = UIText[locale];
    dispatch(worker.start('node-'+file));
    try {
      const url = server + "api/files";
      // console.log('URL?',url)
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { id: file, repoid: repo },
      });
      console.log(response.data)
      dispatch(newTrees.setNodeKey({id:'repo-'+repo,idx,key:'loaded',value:true}))
      for(const file of response.data.files){
        if (!file.type){
          dispatch(newTrees.addFVNode({id:'repo-'+repo,idx,data:{
            id:file.id,
            name:file.name,
            type:0,
            deleted:file.deleted,
          }}))
        }
      }
      dispatch(worker.finish({ id: 'node-'+file }));
    } catch (e) {
      console.log(e);
      dispatch(worker.finish({ id: 'node-'+file, error: e.message }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default getTreeFiles