import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import Cancel from "./Cancel";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import Confirm from "./Confirm";
import { useWorker } from "../../../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { useEffect } from "react";
import { deleteClientId } from "../../../../store/actions2/Windows365/deleteClient";

const W365UserDelete = () => {
  const { auth,dispatch, settings } = useUIBoilerplate();
  const { done, error } = useWorker(deleteClientId);
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header: `Remover las credenciales de Windows365 del usuario ${auth.user} de Cloner?`,
    },
    "en-US": { header: `Remove Windows365 from cloner user ${auth.user}?` },
  };
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(workers.clear(deleteClientId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm />
      </BasicDialogActions>
    </Dialog>
  );
};

export default W365UserDelete;
