import { Grid } from "@mui/material";
import TTChip from "./TTChip";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { from: "De: ", to: "Para: " },
  "en-US": { from: "From: ", to: "To: " },
};

const FromTo = ({ fromM, toRecipients }) => {
  const { settings } = useUIBoilerplate();
  const { from, to } = UIText[settings.locale];

  const fromData = JSON.parse(fromM);
  const toData = JSON.parse(toRecipients);
  const toArray = toData.map((data, idx) => (
    <TTChip {...{ key: idx, data: data.emailAddress }} />
  ));

  return (
    <Grid
      container
      item
      {...{
        rowGap: 1,
        padding: 2,
        paddingTop: 1,
        paddingBottom: 1,
        borderTop: "1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Grid
        container
        item
        xs={12}
        columnGap={1}
        rowGap={0.5}
        alignItems="center"
      >
        {from}
        <TTChip {...{ data: fromData.emailAddress }} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        columnGap={1}
        rowGap={0.5}
        alignItems="center"
      >
        {to}
        {toArray}
      </Grid>
    </Grid>
  );
};

export default FromTo;
