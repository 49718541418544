/**
 * A component that renders a text input field for the user's username, with validation and debouncing logic.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.hardValidation - A function to perform hard validation on the input field.
 * @param {Function} props.login - A function to perform login logic on the input field.
 * @param {boolean} props.disabled - A flag to disable the input field.
 *
 * @returns {JSX.Element} - The rendered User component.
 */
import React, { useEffect, useRef } from "react";
import { Grid, TextField } from "@mui/material";
import PersistSession from "./PersistSession";
import { debounce } from "lodash";
import SlimAlert from "../../../UI2/SlimAlert";
import { UISliceActions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import { useWorker } from "../../../../hooks2/useWorker";
import { id } from "../../../../store/actions2/Login/loginToCloner";

const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "usuario@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "user@cloner.cl",
  },
};

const User = (props) => {
  const { hardValidation, login, disabled: dsbl } = props;
  const { dispatch, UI, settings } = useUIBoilerplate();
  const locale = settings.locale;
  const { working } = useWorker(id);
  const ref = useRef(null);
  const { user = { value: "" } } = UI;
  const { value } = user;

  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  const { label, placeholder } = UIText[locale];

  const handleValidation = () => {
    const payload = { id: "user", fn: notEmptyUser, locale };
    dispatch(UISliceActions.validateField(payload));
  };

  const debouncedChange = debounce((e) => {
    const value = e.target.value;
    dispatch(UISliceActions.setField({ id: "user", value }));
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedHardValidation = debounce((e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      hardValidation();
    }
  }, 200);

  const handleHardValidation = (e) => {
    debouncedHardValidation(e);
  };

  const debouncedLogin = debounce((e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      login();
    }
  }, 200);
  const handleLogin = (e) => {
    debouncedLogin(e);
  };

  const disabled = working || dsbl;

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item xs={12} justifyContent="center">
        <Grid item {...{ lg: 3, md: 4, sm: 6, xs: 8 }}>
          <TextField
            fullWidth
            required
            autoFocus
            {...{
              label,
              placeholder,
              inputRef: ref,
              error: !!user.error,
              onKeyDown: handleHardValidation,
              onKeyUp: handleLogin,
              onBlur: handleValidation,
              onChange: handleChange,
              disabled,
              size: "small",
              InputProps: {
                endAdornment: <PersistSession {...{ disabled }} />,
                sx: {
                  paddingRight: 0.3,
                  borderRadius: !!user.error ? "4px 4px 0px 0px" : undefined,
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid container item {...{ justifyContent: "center", xs: 12 }}>
        <Grid container item {...{ lg: 3, md: 4, sm: 6, xs: 8 }}>
          <SlimAlert messages={[user.error]} xs={12} fuseTop />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default User;
