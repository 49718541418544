import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";
import {
  deleteOrgId,
  deleteOrganization,
} from "../../../../../store/actions2/Windows365/deleteOrganization";
import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = () => {
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const { working } = useWorker(deleteOrgId);
  const { payload } = UI.modal2 ?? {};
  const { orgId } = payload ?? {};
  const locale = settings.locale;

  const onClick = () => {
    dispatch(deleteOrganization({ ...auth, orgId, locale }));
  };

  return <Button {...{ UIText, onClick, working }} />;
};

export default Confirm;
