import React, { Fragment, useRef, useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  ContactSupport,
  HelpOutline,
  Logout,
  Settings,
} from "@mui/icons-material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { authenticationSliceActions as authentication } from "../../../../../store/slices/authentication";
import getAvatar from "./getAvatar";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { viewmodeUI } from "../../../../../App";
import { useLocation } from "react-router-dom";

const UIText = {
  "es-CL": {
    setting: "Preferencias",
    faq: "Preguntas frecuentes",
    support: "Soporte",
    exit: "Salir",
  },
  "en-US": {
    setting: "Settings",
    faq: "FAQ",
    support: "Support",
    exit: "Exit",
  },
};

const UserOptions = () => {
  const { auth, dispatch, navigate, settings, UI } = useUIBoilerplate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const location = useLocation() ?? {};
  const viewmode = UI[viewmodeUI];
  const { name = "", user } = auth;
  const { setting, faq, support, exit } = UIText[settings.locale];

  const avatar = getAvatar(name);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    setOpen(false);
    localStorage.clear("remember-session");
    localStorage.clear("persist:auth");
    sessionStorage.clear("persist:auth");
    dispatch(authentication.clearAuthentication());
    navigate("/login");
  };

  const handleFAQ = () => {
    setOpen(false);
    navigate("/dashboard/support");
  };

  const handleSupport = () => {
    dispatch(
      UIactions.openModal({
        type: "contact-support",
      })
    );
  };

  const handleSettings = () => {
    setOpen(false);
    navigate("/dashboard/settings");
  };

  let show = true;
  switch (location.pathname) {
    case "/dashboard/users":
      show = false;
      break;
    case "/dashboard/devices":
      show = false;
      break;
    case "/dashboard/reports":
      show = false;
      break;
    case "/dashboard/files":
      show = false;
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <Grid container item xs="auto">
        <Grid item xs="auto">
          <IconButton ref={anchorRef} onClick={handleToggle}>
            {avatar}
          </IconButton>
        </Grid>
        {viewmode === "desktop" || show ? (
          <Grid item xs={true} padding={0.8}>
            <Typography>{name}</Typography>
            <Typography fontSize={13} color="secondary.light">
              {user}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Menu
        {...{
          anchorEl: anchorRef.current,
          id: "account-menu",
          open: open,
          onClose: handleToggle,
          onClick: handleToggle,
          transformOrigin: { horizontal: "right", vertical: "top" },
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
          PaperProps: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleSettings} sx={{ color: "DimGray" }}>
          <Settings sx={{ marginRight: 1 }} /> {setting}
        </MenuItem>
        <MenuItem onClick={handleSupport} sx={{ color: "DimGray" }}>
          <ContactSupport sx={{ marginRight: 1 }} /> {support}
        </MenuItem>
        {false ? ( // TODO: ENABLE
          <MenuItem onClick={handleFAQ} sx={{ color: "DimGray" }}>
            <HelpOutline sx={{ marginRight: 1 }} /> {faq}
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleLogout} sx={{ color: "DimGray" }}>
          <Logout sx={{ marginRight: 1 }} /> {exit}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserOptions;
