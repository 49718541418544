import { Grid, Typography } from "@mui/material";
import PermissionsSelect from "./PermissionsSelect";
import CollapseGrid from "../../../UI2/CollapseGrid";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import ChangePermissions, { changePermissionsUI } from "./ChangePermissions";

const UIText = {
  "es-CL": { label: "Permisos" },
  "en-US": { label: "Permissions" },
};

const Permissions = ({ isNew, users }) => {
  const { UI, settings } = useUIBoilerplate();
  const changePermissions = UI[changePermissionsUI];
  const locale = settings.locale;
  const {label} = UIText[locale];

  return (
    <Grid container item xs={12}>
      {users.length > 1 ? (
        <ChangePermissions {...{ locale }} />
      ) : (
        <Grid container item xs={12}>
          <Typography fontWeight="bold">{label}</Typography>
        </Grid>
      )}
      <CollapseGrid
        open={changePermissions || users.length === 1 || isNew}
        outerProps={{ xs: 12 }}
        innerProps={{ paddingTop:1 }}
      >
        <PermissionsSelect />
      </CollapseGrid>
    </Grid>
  );
};

export default Permissions;
