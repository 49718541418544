import { Collapse, Grid } from "@mui/material";

const CollapseGrid = (props) =>{
    const { open, outerProps , innerProps, children } = props
    // console.log(props)
    // console.log('outerProps',outerProps)
    // console.log('innerProps',innerProps)
  return (
    <Grid container item {...outerProps}>
      <Collapse in={open} sx={{ width: "100%" }}>
        <Grid container {...innerProps}>
          {children}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CollapseGrid;
