import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Especifique el motivo" },
  "en-US": { label: "Specify the reason" },
};

export const otherReasonUI = "otherReason";

const OtherReason = () => {
  return (
    <TextField
      required
      {...{
        id: otherReasonUI,
        UIText,
        validation:notEmptyField,
        xl: 8,
        lg: 8,
        md: 8,
        sm: 8,
        xs: 12,
      }}
    />
  );
};

export default OtherReason;
