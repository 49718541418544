import { Grid } from "@mui/material";
import Locale from "../Actions/Locale";
import HelpMode from "../Actions/HelpMode";
import UserOptions from "../Actions/UserOptions";
import UserPicker from "./Components/UserPicker";
import DevicePicker from "./Components/DevicePicker";
import GoToGroup from "./Components/GoToGroup";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const FilesTop = ({ height }) => {
  const { auth } = useUIBoilerplate();
  const barHeight = (height * 8) / 100;

  console.log("FilesTop auth", auth);

  return (
    <Grid
      container
      item
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        xs: 12,
        borderBottom: "1px lightgrey solid",
      }}
    >
      <Grid container item {...{ columnGap: 2, xs: true }}>
        <UserPicker />
        <DevicePicker />
        {auth.admin === 1 ? <GoToGroup /> : null}
      </Grid>
      <Grid container item {...{ alignItems: "center", xs: "auto" }}>
        <Locale />
        <HelpMode />
        <UserOptions />
      </Grid>
    </Grid>
  );
};

export default FilesTop;
