import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { UISliceActions } from "../../../store/slices/UI2";
import filterObjKeys from "../../../utility/filterObjectKeys";
import SingleTwinSelect from "../Utilities/SingleTwinSelect";
import AutoGrid from "../Wrappers/AutoGrid";
import { IconButton as MUIIconButton } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { defaultSelected } from "../genericIDs";

const CheckButton = (props) => {
  const { arrayId = defaultSelected,fullArray = [],size = "small", xs, fontSize } = props;
  const filteredProps = filterObjKeys(props, "xs",'arrayId','sourceId','warehouseId','fullArray');
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI[arrayId] ?? [];
  const total = fullArray


  const handleClick = () => {
    dispatch(UISliceActions.toggleAll({ id:arrayId, batch: total }));
  };
  const isSelected = selected.length === total.length && selected.length > 0

  const icon = SingleTwinSelect(
    <CheckBoxOutlineBlank {...{ sx: {fontSize:`${fontSize}rem`} }} />,
    <CheckBox color="primary" {...{ sx: {fontSize:`${fontSize}rem`} }} />,
    isSelected
  );

  return (
    <AutoGrid {...{ xs }}>
      <MUIIconButton {...filteredProps} {...{ onClick: handleClick, size }}>
        {icon}
      </MUIIconButton>
    </AutoGrid>
  );
};

export default CheckButton;
