import { Delete } from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import { sharedFilesData } from "../../../../store/actions2/Shared/getSharedFiles";

const UIText = {
  "es-CL": { label: "Eliminar", fixedTooltip: "Eliminar enlace" },
  "en-US": { label: "Delete", fixedTooltip: "Delete link" },
};
const DeleteLink = () => {
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const sharedFiles = warehouse[sharedFilesData] ?? [];
  const selected = UI.selected ?? [];

  const onClick = () => {
    let links = [];
    for (const id of selected) {
      const link = sharedFiles.find((x) => x.id === id);
      links.push(link);
    }

    dispatch(
      UIactions.openModal({
        type: "delete-shared-links",
        payload: { links },
      })
    );
  };

  return (
    <ActionButton
      {...{ disabled: !selected.length, onClick, UIText, icon: <Delete /> }}
    />
  );
};

export default DeleteLink;
