import { Grid, Typography } from "@mui/material";
import BasicContainer from "../UI2/BasicLayout/BasicContainer";
import BasicHeader from "../UI2/BasicLayout/Components/Header";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { useEffect } from "react";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import BasicFooter from "../UI2/BasicLayout/Components/BasicFooter";
import Save from "./Components/Save";
import Back from "./Components/Back";
import TFA, { tfaSwitch } from "./Components/TFA";
import Password, { pass1UI, pass2UI } from "./Components/Password";
import User, { userUI, usernameUI } from "./Components/User";
import W365 from "./Components/W365";

const UIText = {
  "es-CL": {
    header: "Configuración",
    st1: "Usuario",
    st2: "Cambiar contraseña",
    st3: "Seguridad",
    st4: "Integraciones",
  },
  "en-US": {
    header: "Settings",
    st1: "User",
    st2: "Change password",
    st3: "Security",
    st4: "Integrations",
  },
};

const Settings = () => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { header, st1, st2, st3, st4 } = UIText[settings.locale];

  useEffect(() => {
    dispatch(UIactions.setField({ id: userUI, value: auth.user }));
    dispatch(UIactions.setField({ id: usernameUI, value: auth.name }));
    if (auth.has_tfa === 2) {
      dispatch(UIactions.setValue({ id: tfaSwitch, value: true }));
    }
    return () => {
      dispatch(UIactions.clear(userUI));
      dispatch(UIactions.clear(usernameUI));
      dispatch(UIactions.clear(pass1UI));
      dispatch(UIactions.clear(pass2UI));
      dispatch(UIactions.clear(tfaSwitch));
    };
  }, [dispatch, auth]);

  return (
    <BasicContainer width="100%" {...{ xl: 6, lg: 6, md: 8, sm: 10, xs: 12 }}>
      <BasicHeader>{header}</BasicHeader>
      <Grid container item xs={12} padding={2} rowGap={2}>
        <Grid item xs={12}>
          <Typography fontSize={18}>{st1}</Typography>
        </Grid>
        <User />
        <Grid item xs={12}>
          <Typography fontSize={18}>{st2}</Typography>
        </Grid>
        <Password />
        <Grid item xs={12}>
          <Typography fontSize={18}>{st3}</Typography>
        </Grid>
        <TFA />
        {/* <Grid item xs={12}>
          <Typography fontSize={18}>{st4}</Typography>
        </Grid> */}
        {/* <W365 /> */}
      </Grid>
      <BasicFooter noPagination>
        <Back />
        <Save />
      </BasicFooter>
    </BasicContainer>
  );
};

export default Settings;
