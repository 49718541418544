import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";
import { Wifi, WifiOff } from "@mui/icons-material";
import { timeDiff } from "../../../../../utility/humanizers";

const UIText = {
  "es-CL": { since: "hace ", empty: "Usuario no tiene dispositivos" },
  "en-US": { since: "since ", empty: "User does not have devices" },
};

const Online = ({ fontSize, mid, settings, lastConnected, connected }) => {
  const locale = settings.locale
  const { since, empty } = UIText[locale];
  const now = Date.now();

  const fixedTooltip = mid ? since + timeDiff((now - lastConnected),locale) : empty;

  return (
    <AutoHelp {...{ fixedTooltip }}>
      {connected ? <Wifi color="primary" /> : <WifiOff color="disabled" />}
    </AutoHelp>
  );
};

export default Online;
