import { Grid } from "@mui/material";
import TextField from "../../../../UI2/SimpleTextField";
import CheckButton from "../../../../UI2/Specialized/Check";
import { arrayId } from "../UsersList";

const UIText365Mail = {
  universal: {},
};

export const bkpOneArrayId = "bkpOne";

const BackupOnedrive = ({ login, fontSize }) => {
  return (
    <Grid container item {...{xs:12, justifyContent:"center"}}>
      <CheckButton {...{ id: login, arrayId: bkpOneArrayId, xs: "auto", fontSize:1 }} />
    </Grid>
  )
};

export default BackupOnedrive;
