import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { authenticationSliceActions } from "../../slices/authentication";
import { workersSliceActions as worker } from "../../slices/workers";
import { UISliceActions } from "../../slices/UI2";
import { tfaSwitch } from "../../../components/Settings/Components/TFA";

const UIText = {
  "es-CL": {
    success: "Autentificación de dos factores desactivada exitosamente",
    error: "Error al intentar desactivar la autentificación: ",
  },
  "en-US": {
    success: "Two factor authentication successfully activated",
    error: "Error while trying to deactivate authentication: ",
  },
};

export const disableTFAId = "disableTFA";

export const disableTFA = (data) => {
  const { server, cloner_key, user: login, name, locale } = data;
  const { success, error } = UIText[locale];
  const id = disableTFAId;

  return async (dispatch) => {
    const payload = { name, email: "", option: "0", login, server };
    const bodyData = JSON.stringify(payload);
    dispatch(worker.start(id));
    try {
      const url = server + "api/users";
      await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });
      enqueueSnackbar(success, { variant: "success" });
      dispatch(authenticationSliceActions.setTFA(0));
      dispatch(worker.finish({ id }));
      dispatch(UISliceActions.setValue({ id: tfaSwitch, value: false }));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
