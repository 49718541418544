import { Grid, Typography } from "@mui/material";
import FilterField from "../../../UI2/FilterField";
import Select from "../../../UI2/Select";
import { options } from "./selectValues";
import Button from "../../../UI2/Button";
import { UISliceActions } from "../../../../store/slices/UI2";
import { selectedCommonUI } from "../Category/Category";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { placeholder: "Buscar..." },
  "en-US": { placeholder: "Search..." },
};
const UIText2 = {
  "es-CL": { label: "Filtrar por categoría" },
  "en-US": { label: "Filter by category" },
};
const UIText3 = {
  "es-CL": { label: "Borrar selección" },
  "en-US": { label: "Clear selection" },
};
const UIText4 = {
  "es-CL": { header: "Extensiones comunes" },
  "en-US": { header: "Common extensions" },
};

export const categoryFilterUI = "categoryFilter";

const Filters = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const { header } = UIText4[settings.locale];

  const clearAllSelections = () => {
    dispatch(UISliceActions.clear(selectedCommonUI));
  };

  return (
    <Grid container item {...{ padding: 2, xs: 12, rowGap: 1 }}>
      <Typography fontSize='1.1rem'>{header}</Typography>
      <Grid container item gap={1} paddingLeft={2} paddingRight={2}>
        <Grid container item xs={12} columnSpacing={2}>
          <FilterField {...{ UIText, xs: 3 }} />
          <Select
            {...{ id: categoryFilterUI, UIText: UIText2, options, xs: 3, fontSize:16 }}
          />
          <Button
            {...{
              UIText: UIText3,
              onClick: clearAllSelections,
              xs: "auto",
              variant: "text",
              labelSx: { fontSize: 14 },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Filters;
