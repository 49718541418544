import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Calle", placeholder: "Av. Vitacura" },
  "en-US": { label: "Street", placeholder: "Vitacura Avenue" },
};
export const deliveryStreetUI = "deliveryStreet";

const DeliveryStreet = () => {

  return (
    <TextField
    required
      {...{
        id: deliveryStreetUI,
        validation: notEmptyField,
        UIText,
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
      }}
    />
  );
};

export default DeliveryStreet;
