import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storage:{
  }
};
const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    load(state,action){
        const {id,data} = action.payload
        state.storage[id] = data
    },
    unload(state,action){
        const id = action.payload
        delete state.storage[id]
    },
    clear(state){
      state.storage = {}
    },
    add(state,action){
      const {id,key,data}= action.payload
      if (!state.storage[id]){
        state.storage[id]=[]
      }

      let newObj = {...state.storage[id]}
      newObj[key] = data

      state.storage[id]= newObj
      // box[key] = data
    },
    remove(state,action){
      const {id,key}= action.payload
      delete state.storage[id][key]
    }
  },
});

export const warehouseSliceActions = warehouseSlice.actions;
export default warehouseSlice.reducer;