import { createSlice } from "@reduxjs/toolkit";
import { generateHexString } from "../../utility/generateString";

const initialState = {
  data: {
    initialized: false,
    skipAsk: false,
    // requests: [],
    recoveries: [],
    repositories: [],
    trees: [],
    deliveryData: {},
  },
};
const recoveriesSlice = createSlice({
  name: "recoveries",
  initialState,
  reducers: {
    reset(state) {
      state.data = {
        initialized: false,
        skipAsk: true,
        recoveries: [],
        trees: [],
        deliveryData: {},
      };
    },
    initialize(state){
      state.data.initialized = true
    },
    askAgain(state) {
      state.data.skipAsk = false;
    },
    dontAskAgain(state) {
      state.data.skipAsk = true;
    },
    newRecovery(state, action) {
      const newRecovery = { ...action.payload, id: generateHexString(16) };
      state.data.recoveries.push(newRecovery);
    },
    updateRecovery(state, action) {
      const newRecovery = action.payload;
      const recovery = state.data.recoveries.find((x) => x.id === newRecovery.id);
      if (recovery) {
        Object.assign(recovery, newRecovery);
      }
    },
    deleteRecoveries(state, action) {
      const ids = action.payload;
      state.data.recoveries = state.data.recoveries.filter(
        (x) => !ids.find((id) => id === x.id)
      );
    },
    removeUnusedRepositories(state) {
      const usedRepoIds = state.data.recoveries.flatMap((recovery) => recovery.repositories.map((repo) => repo.id));
      state.data.repositories = state.data.repositories.filter((repo) => usedRepoIds.includes(repo.id));
    },
    newRepository(state, action) {
      const newRepository = { ...action.payload };
      
      const existingRepository = state.data.repositories.find(repo => repo.id === newRepository.id);
      if (!existingRepository) {
        state.data.repositories.push(newRepository);
      }
    },
    setDeliveryData(state,action){
      state.data.deliveryData = action.payload
    }

  },
});

export const recoveriesSliceActions = recoveriesSlice.actions;
export default recoveriesSlice.reducer;
