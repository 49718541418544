/**
 * A component that renders an export button for reports.
 * @returns {JSX.Element} The export button component.
 */
import {  Download } from "@mui/icons-material";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../store/actions2/Devices/getAllDevices";
import { useWorker } from "../../../../hooks2/useWorker";
import { forceRescanId } from "../../../../store/actions2/Reports/forceRescan";
import exportReport from "../../../../store/actions2/Reports/exportReport";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = () => {
  const { dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { working } = useWorker(forceRescanId);
  const selected = UI.selected ?? [];
  const devicesObjs = warehouse[devicesDataId]??{}


  const onClick = () => { 
    let devices = []
    for (const key in devicesObjs){
      devices.push(devicesObjs[key])
    }
    let onlySelected = []
    for (const mid of selected){
      const dev = devices.find(x=> x.mid === mid)
      onlySelected.push(dev)
    }
    dispatch(exportReport({devices,onlySelected,locale:settings.locale}))
  };


  return (
    <ActionButton
      {...{
        working,
        onClick,
        UIText,
        icon: <Download />,
        variant:'outlined',
      }}
    />
  );
};

export default Export;
