import { Grid } from "@mui/material";
import CheckButton from "../../Specialized/CheckAll";
import SortHeader from "../../SortHeader2";
import useVH from "../../../../hooks2/useVH";

// EXAMPLE headers
//   { sourceId:'',
//     warehouseId:'',
//     headers:[{
//      UIText: data.UIText, ===> {'locale':{label:''}}
//      filterKey: data.filterKey,
//      xs: data.xs,
//      justify: data.justify,
//     }]
//   }}

const BasicHeaders = ({
  sortId,
  arrayId,
  sourceId,
  warehouseId,
  headers = [],
  fullArray,
}) => {

  const height1 = useVH(6);
  const height2 = useVH(2);

  const gridProps = {
    alignItems: "center",
    columnGap: 1,
    xs: 12,
    wrap: "nowrap",
    sx: {
      height: height1,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
    },
  };

  const columns = headers.map((data, idx) => (
    <SortHeader
      {...{
        id: sortId,
        key: idx,
        UIText: data.UIText,
        filterKey: data.filterKey,
        xl: data.xl,
        lg: data.lg,
        md: data.md,
        sm: data.sm,
        xs: data.xs,
        disabled: !data.filterKey,
        justify: data.justify,
      }}
    />
  ));

  return (
    <Grid
      container
      item
      {...{
        sx: {
          height: height1,
          paddingLeft: height2,
          paddingRight: height2,
        },
      }}
    >
      <Grid container item {...gridProps}>
        {sourceId || warehouseId || fullArray ? (
          <CheckButton
            {...{
              arrayId,
              sourceId,
              warehouseId,
              fullArray,
              fontSize: 1,
              xs: "auto",
            }}
          />
        ) : null}
        {columns}
      </Grid>
    </Grid>
  );
};

export default BasicHeaders;
