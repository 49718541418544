import { Grid } from "@mui/material";
import FilterField from "../../../FilterField";
import useVH from "../../../../../hooks2/useVH";

const UIText = {
  "es-CL": { placeholder: "Filtrar" },
  "en-US": { placeholder: "Filter" },
};

const FilterPlusActions = ({ children }) => {
  const vh = useVH(8);
  return (
    <Grid
      container
      item
      {...{
        height: vh,
        alignItems: "center",
        xs: 12,
        columnGap: 2,
      }}
    >
      <Grid container item {...{ xs: 3, paddingLeft:'2vh' }}>
        <FilterField fullWidth {...{ UIText }} />
      </Grid>
      <Grid container item {...{ columnGap: 2, justifyContent: "end", xs: true, paddingRight:'2vh' }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FilterPlusActions;
