import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";

const DrawerButton = (props) => {
  const {
    UIText,
    icon,
    fontSize = 16,
    selected,
    navTo,
    accentColor,
    handleClick = () => {},
  } = props;
  const { navigate, settings } = useUIBoilerplate();
  const { label } = UIText[settings.locale];

  const onClick = () => {
    if (selected) {
      return;
    }
    handleClick();
    if (navTo) {
      navigate(navTo);
    }
  };

  return (
    <ListItemButton
      {...{
        sx: {
          backgroundColor: selected ? "select.main" : undefined,
          height: "6vh",
        },
        onClick,
      }}
    >
      <ListItemIcon> {icon} </ListItemIcon>
      <ListItemText
        {...{
          primary: label,
          sx: { color: accentColor },
          primaryTypographyProps: { sx: { fontSize } },
        }}
      />
    </ListItemButton>
  );
};

export default DrawerButton;
