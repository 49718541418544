import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { appIdUI, clientIdUI, emailUI, secretKeyUI } from "./Form";
import useURLQuery from "../../../hooks2/useURLQuery";
import { postOrgId, postOrganization } from "../../../store/actions2/Windows365/postOrganization";
import { useWorker } from "../../../hooks2/useWorker";

const UIText = {
  "es-CL": { label: "Activar" },
  "en-US": { label: "Activate" },
};

export const validationCodeUI = "validationCode";
const Activate = () => {
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { working } = useWorker(postOrgId);
  const locale = settings.locale;

  const { value: client_id } = UI[appIdUI] ?? {};
  const { value: client_secret_id } = UI[clientIdUI] ?? {};
  const { value: client_secret } = UI[secretKeyUI] ?? {};
  const { value: client_mail } = UI[emailUI] ?? {};
  const vc0 = UI["vc0"] ?? "";
  const vc1 = UI["vc1"] ?? "";
  const vc2 = UI["vc2"] ?? "";
  const vc3 = UI["vc3"] ?? "";
  const vc4 = UI["vc4"] ?? "";
  const vc5 = UI["vc5"] ?? "";
  const mail_code = vc0 + vc1 + vc2 + vc3 + vc4 + vc5;

  const onClick = () => {
    dispatch(
      postOrganization({
        ...auth,
        locale,
        payload: {
          client_id,
          client_secret_id,
          client_secret,
          group_id: group,
          client_mail,
          mail_code: "elcodigosecreto1234", // THIS SHOULD BE TAKEN OUT LATER IT IS ONLY FOR TESTING
        },
      })
    );
  };

  const disabled =
    !client_id ||
    !client_secret_id ||
    !client_secret ||
    !client_mail ||
    !vc0 ||
    !vc1 ||
    !vc2 ||
    !vc3 ||
    !vc4 ||
    !vc5;

  return <Button {...{ disabled, working, UIText, onClick, sx:{width:100} }} />;
};

export default Activate;
