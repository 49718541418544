import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365OrgId } from "./getOrganization";

export const updateOrgId = "updateOrganization";

const UIText1 = {
  "es-CL": {
    success:
      "La organización de office 365 ahora está siendo compartida a los subgrupos",
    error: "No se pudo compartir la organizacion de office 365: ",
  },
  "en-US": {
    success: "The office 365 organization is being shared to child subgroups",
    error: "Office 365 organization couldn't be shared: ",
  },
};
const UIText2 = {
  "es-CL": {
    success:
      "Se dejó de compartir la organización de office 365 a los subgrupos",
    error: "No se pudo dejar de compartir la organizacion de office 365: ",
  },
  "en-US": {
    success: "Office 365 organization sharing was stopped",
    error: "Office 365 organization sharing couln't be stopped: ",
  },
};

const changeW365Sharing = (data) => {
  const id = updateOrgId;
  return async (dispatch) => {
    const { W365server, cloner_key, locale, payload } = data;
    const UIText = payload.prevent_propagation ? UIText2 : UIText1;

    const { success, error } = UIText[locale];
    const bodyData = JSON.stringify(payload);
    dispatch(worker.start(id));
    try {
      const url = W365server + "mailapi/organization";
      console.log("URL", url);
      console.log("bodyData", bodyData);
      await axios.put(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(worker.clear(getW365OrgId));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id, error }));
    }
  };
};

export default changeW365Sharing;
