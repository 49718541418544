import { Grid } from "@mui/material";
import SortHeader from "../../../../UI2/SortHeader";
import CheckButton from "../../../../UI2/Specialized/CheckAll";

const UITextUser = {
  "es-CL": { label: "Usuario" },
  "en-US": { label: "User" },
};
const UITextDevice = {
  "es-CL": { label: "Dispositivo" },
  "en-US": { label: "Device" },
};
const UITextSize = {
  "es-CL": { label: "Tamaño" },
  "en-US": { label: "Size" },
};
const UITextBackup = {
  "es-CL": { label: "Respaldo" },
  "en-US": { label: "Backup" },
};

const Headers = () => {
  return (
    <Grid
      container
      item
      {...{
        alignItems: "center",
        xs: 12,
        sx: {
          fontSize: 14,
          paddingBottom: 1,
          paddingTop: 1,
          borderBottom: "1px solid lightGrey",
        },
      }}
    >
      <CheckButton
        {...{
          arrayId: 'placeholder1',
          sourceId: 'placeholder2',
          fontSize: 1,
          xs: "auto",
        }}
      />
      <SortHeader
        {...{ UIText: UITextUser, Key: "login", xs: true, size: 0.85 }}
      />
      <SortHeader {...{ UIText: UITextDevice, Key: "name", xs: 3, size: 0.85 }} />
      <SortHeader {...{ UIText: UITextSize, Key: "devices", xs: 2, size: 0.85 }}
      />
      <SortHeader {...{ UIText: UITextBackup, Key: "connected", xs: 3, size: 0.85 }}
      />
    </Grid>
  );
};

export default Headers;
