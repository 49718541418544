/**
 * A component that renders a button to request logs for selected devices.
 *
 * @returns {JSX.Element} The Logs component.
 */
import {  Telegram } from "@mui/icons-material";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../store/actions2/Devices/getAllDevices";
import requestLogs, { requestLogsId } from "../../../../store/actions2/Reports/requestLogs";
import { useWorker } from "../../../../hooks2/useWorker";

const Logs = () => {
  const { auth, dispatch, UI, warehouse, settings } = useUIBoilerplate();
  const { working } = useWorker(requestLogsId);
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesDataId];

  /**
   * Handles the click event of the request logs button.
   */
  const onClick = () => { 
    let payload = [];
    for (const mid of selected) {
      const device = devices[mid];
      const payloadItem = {
        user: device.user,
        machine: device.mid,
        token: auth.token,
      };
      payload.push(payloadItem);
    }
    dispatch(
      requestLogs({
        ...auth,
        payload,
        locale: settings.locale,
      })
    );
  };

  const UIText = {
    "es-CL": { label: "Solicitar logs" },
    "en-US": { label: "Request logs" },
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Telegram />,
        variant:'outlined',
      }}
    />
  );
};

export default Logs;
