import { Grid } from "@mui/material";
import Divider from "../UI2/Divider";
import Interval from "./Fields/Interval";
import Status from "./Fields/Status";
import Version from "./Fields/Version";
import StealthMode from "./Fields/StealthMode";
import Bandwidth from "./Fields/Bandwidth";
import Upload from "./Fields/Upload";
import SelectionBlock from "./Fields/SelectionBlock";
import General from "./Fields/General";
import Load from "./Fields/Load";
import Security from "./Fields/Security";

const Details = (props) => {
  const {
    scan_interval,
    service_status,
    version,
    stealth_mode,
    throttling,
    network_packet_size,
    cpassword,
    multiple = 1,
    locale,
  } = props;

  return (
    <Grid container {...{ sx: { width: "100%" } }}>
      <Grid container item rowGap={2} alignItems="center">
        <General {...{ locale }} />
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Interval {...{ scan_interval, locale }} />
        </Grid>
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Status {...{ service_status, locale }} />
        </Grid>
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Version {...{ version, locale }} />
        </Grid>
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <StealthMode {...{ stealth_mode, locale }} />
        </Grid>
        <Divider />
        <Load {...{ locale }} />
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Bandwidth {...{ network_packet_size, throttling, locale }} />
        </Grid>
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Upload {...{ multiple, locale }} />
        </Grid>
        <Divider />
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <Security {...{ locale }} />
        </Grid>
        <Grid container item {...{ xs: 12, sx: { paddingLeft: 2 } }}>
          <SelectionBlock {...{ cpassword, locale }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Details;
