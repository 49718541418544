import { Dialog, Grid } from "@mui/material";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import TopBar from "./Components/TopBar/TopBar";
import Create from "./Components/Create";
import Cancel from "./Components/Cancel";
import UsersList, { sortId } from "./Components/UsersList/UsersList";
import { newUsersUI } from "./Components/TopBar/UploadSheet";
import Empty from "./Components/Empty";
import { useEffect } from "react";
import { useWorker } from "../../hooks2/useWorker";
import { postUserId } from "../../store/actions2/UserForm/postUsers";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { rowErrorsUI } from "./Components/UsersList/UserRow";

const UITextHeader = {
  "es-CL": "Crear usuarios",
  "en-US": "Create users",
};

const UsersUpload = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { done } = useWorker(postUserId);
  const locale = settings.locale;
  const header = UITextHeader[locale];
  const users = UI[newUsersUI] ?? [];

  useEffect(() => {
    if (done) {
      dispatch(UIactions.openModal({ type: "download-creation-report" }));
    }
  }, [dispatch, done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(sortId));
      dispatch(UIactions.clear(rowErrorsUI));
      dispatch(UIactions.clear(newUsersUI));
      dispatch(workers.clear(postUserId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    console.log("reason", reason);
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container {...{ sx: { width: "100%" } }}>
          <TopBar />
          {!!users.length ? <UsersList /> : <Empty />}
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Create />
      </BasicDialogActions>
    </Dialog>
  );
};

export default UsersUpload;
