import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

export const getSharedFilesId = "sharedFiles";
export const sharedFilesData = "sharedFiles";

export const getSharedFiles = (data) => {
  const { server, cloner_key, user: login, controller } = data;
  const id = getSharedFilesId;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = server + "api/shares";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { login },
      });
      let list = [];
      let simpleList = [];
      for (const item of response.data) {
        list.push(item);
        simpleList.push(item.id);
      }
      dispatch(warehouse.load({ id: sharedFilesData, data: list }));
      // dispatch(
      //   UISliceActions.setValue({ id: currentSharedId, value: simpleList })
      // );
      dispatch(workers.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workers.clear(id));
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};
