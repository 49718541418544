import { Tooltip, Typography } from "@mui/material";

const Username = ({ fontSize, login }) => {
  return (
    <Tooltip title={login}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {login}
      </Typography>
    </Tooltip>
  );
};

export default Username;
