import axios from "axios";
import { enqueueSnackbar } from "notistack";
import {
  workersSliceActions as worker,
  workersSliceActions,
} from "../../slices/workers";
import { UISliceActions } from "../../slices/UI2";
import { reloadUI } from "../../../components/GroupsTraveler/GroupsTraveler";

const UIText = {
  "es-CL": {
    success: "Grupo guardado exitosamente",
    error: "Error al guardar grupo: ",
  },
  "en-US": {
    success: "Group successfully saved",
    error: "Error saving group: ",
  },
};

export const postGroupId = "postGroup";

export const postGroup = (data) => {
  console.log("posting group data!");
  return async (dispatch) => {
    const { server, cloner_key, payload, locale } = data;
    const { success, error } = UIText[locale];
    const id = postGroupId;
    dispatch(worker.start(id));
    try {
      const bodyData = JSON.stringify(payload);
      const url = server + "api/groups";
      await axios.post(url, bodyData, { headers: { cloner_key } });
      dispatch(worker.finish({ id }));
      dispatch(UISliceActions.setValue({ id: reloadUI, value: true }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      dispatch(workersSliceActions.finish({ id, error: e.message }));
      const message = error + e.message;
      enqueueSnackbar(message, { variant: "error" });
    }
  };
};
