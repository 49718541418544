import Button from "../../../../UI2/Button";
import { useWorker } from "../../../../../hooks2/useWorker";
import changeW365Sharing, { updateOrgId } from "../../../../../store/actions2/Windows365/changeW365Sharing";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import changeW365GroupLock from "../../../../../store/actions2/Windows365/changeW365Lock";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = ({ payload, locale }) => {
  const { auth, dispatch } = useUIBoilerplate();
  const { working } = useWorker(updateOrgId);

  const onClick = () => {
    dispatch(
      changeW365GroupLock({
        ...auth,
        payload,
        locale,
      })
    );
  };

  return <Button {...{ UIText, onClick, working }} />;
};

export default Confirm;
