/**
 * Renders the username component for a device row.
 * @param {Object} props - The component props.
 * @param {string} props.fontSize - The font size for the username.
 * @param {string} props.user - The username to display.
 * @param {boolean} props.suspended - Whether the device is suspended.
 * @param {Object} props.settings - The user settings object.
 * @param {string} props.settings.locale - The user's locale.
 * @returns {JSX.Element} - The rendered component.
 */
import { CloudOff } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";
import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";

const Username = ({ fontSize, user, suspended, settings }) => {

  const UIText = {
    "es-CL": { suspendedTTP: "Equipo suspendido" },
    "en-US": { suspendedTTP: "Suspended device" },
  };

  const { suspendedTTP } = UIText[settings.locale];

  return (
    <Grid container item alignItems="center" columnGap={2} wrap="nowrap">
      <Tooltip title={user}>
        <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
          {user}
        </Typography>
      </Tooltip>
      {suspended ? (
        <AutoHelp {...{ fixedTooltip: suspendedTTP }}>
          <CloudOff color="warning" />
        </AutoHelp>
      ) : null}
    </Grid>
  );
};

export default Username;
