/**
 * A password input field component with validation and twin field validation.
 * @param {Object} props - The props object containing various properties for the component.
 * @param {string} props.id - The unique identifier for the component.
 * @param {function} [props.onChange=()=>{}] - The function to be executed when the input value changes.
 * @param {function} [props.onBlur=()=>{}] - The function to be executed when the input loses focus.
 * @param {function} [props.onKeyDown=()=>{}] - The function to be executed when a key is pressed down while the input is focused.
 * @param {string} [props.size="small"] - The size of the input field.
 * @param {string} [props.fontSize="1rem"] - The font size of the input field.
 * @param {number} [props.xl] - The number of columns the component should occupy on extra large screens.
 * @param {number} [props.lg] - The number of columns the component should occupy on large screens.
 * @param {number} [props.md] - The number of columns the component should occupy on medium screens.
 * @param {number} [props.sm] - The number of columns the component should occupy on small screens.
 * @param {number} [props.xs] - The number of columns the component should occupy on extra small screens.
 * @param {function} [props.validation] - The validation function to be executed on the input value.
 * @param {function} [props.twinValidation] - The twin field validation function to be executed on the input value.
 * @param {string} [props.twinId] - The unique identifier of the twin field for twin field validation.
 * @param {boolean} [props.tagError] - Whether to display the error message as a tag.
 * @param {boolean} [props.disabled] - Whether the input field is disabled.
 * @param {boolean} [props.forceError] - Whether to force display the error message.
 * @param {boolean} [props.forceShrink] - Whether to force shrink the input label.
 * @param {boolean} [props.hardValidate] - Whether to hard validate the input value on enter key press.
 * @param {function} [props.submit] - The function to be executed when the enter key is pressed while the input is focused.
 * @param {Object} [props.UIText={}] - The object containing the localized text for the component.
 * @param {string} [props.fullWidth] - Whether the input field should occupy the full width of its container.
 * @param {function} [props.constraint] - The constraint function to be executed on the input value.
 * @returns {JSX.Element} - The PassField component.
 */
import { TextField as MUITextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { UISliceActions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import SlimAlert from "./SlimAlert";
import VisibilityInsert from "./TextFieldInserts/VisibilityInsert";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";

/* e.g. UIText
    UIText = {
        es-CL:{
            label:'',
            placeholder:'',
            tooltip:'',
        }
    }
*/

const twinFieldErrorMsg = {
  "es-CL": "Los campos no coinciden",
  "en-US": "The fields do not match",
};

const PassField = (props) => {
  const {
    id,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    size = "small",
    fontSize = "1rem",
    xl,
    lg,
    md,
    sm,
    xs,
    validation,
    twinValidation,
    twinId,
    tagError,
    disabled,
    forceError,
    forceShrink,
    hardValidate,
    submit,
    UIText = {},
    fullWidth,
    constraint,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "validation",
    "twinValidation",
    "twinId",
    "tagError",
    "forceLowerCase",
    "forceError",
    "hardValidate",
    "submit",
    "help",
    "UIText",
    "fontSize",
    "forceShrink"
  );

  const ref = useRef(null);
  const { dispatch, UI, settings } = useUIBoilerplate();
  const locale = settings.locale
  const show = UI[id + "-show"] ?? false;
  const { value = null, error } = UI[id] ?? {};
  const { label, tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;

  // Update non-bound field to reflect an existing value
  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  // Update error message if locale changes
  useEffect(() => {
    if (locale && validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
    if (locale && twinValidation) {
      dispatch(
        UISliceActions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg: twinFieldErrorMsg,
          locale,
        })
      );
    }
  }, [dispatch, locale, id, twinId, validation, twinValidation]);

  // Validate field on blur. Also execute any onBlur function set from outside
  const handleBlur = () => {
    if (validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
    if (twinValidation) {
      dispatch(
        UISliceActions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg: twinFieldErrorMsg,
          locale,
        })
      );
    }
    onBlur();
  };

  // Hard validate field on enter key press
  const handleHardValidate = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      onKeyDown();
      if (hardValidate) {
        dispatch(
          UISliceActions.validateField({
            id,
            fn: validation,
            force: true,
            locale,
          })
        );
      }
    }
  };

  const debouncedChange = debounce((e) => {
    let value = e.target.value;
    if (constraint){
      value = constraint(value);
    }
    dispatch(UISliceActions.setField({ id, value }));
    onChange(e);
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedSubmit = debounce((e) => {
    if (submit && (e.key === "Enter" || e.key === "NumpadEnter")) {
      submit(e);
    }
  }, 200);

  const handleSubmit = (e) => {
    debouncedSubmit(e);
  };

  let typeX = "password";

  if (show) {
    typeX = "text";
  }

  return (
    <AutoGrid
      {...{ xl, lg, md, sm, xs }}
      {...{ alignContent: tagError ? "start" : undefined }}
    >
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <MUITextField
          {...filteredProps}
          {...{
            fullWidth:
              xl || lg || sm || md || xs || fullWidth ? true : undefined,
            label,
            placeholder,
            inputRef: ref,
            onChange: handleChange,
            onBlur: handleBlur,
            onKeyDown: handleHardValidate,
            onKeyUp: handleSubmit,
            size,
            type: typeX,
            error: !!error || forceError,
            InputLabelProps: {
              ...filteredProps.InputLabelProps,
              shrink: value || forceShrink ? true : undefined,
            },
            InputProps: {
              ...filteredProps.InputProps,
              endAdornment: <VisibilityInsert {...{ id, disabled }} />,
              sx: {
                fontSize,
                paddingRight: 0.3,
                borderRadius:
                  !!error && tagError ? "4px 4px 0px 0px" : undefined,
              },
            },
            // sx: { visibility: hide ? "hidden" : undefined },
          }}
        />
      </AutoHelp>
      <SlimAlert errors={!!tagError ? [error] : []} fuseTop />
    </AutoGrid>
  );
};

export default PassField;
