import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Telefono de contacto",placeholder:'+56 9 12345678' },
  "en-US": { label: "Contact phone", placeholder:'+56 9 12345678' },
};

export const contactPhoneUI = "contactPhone";

const ContactPhone = () => {
  return (
    <TextField
      required
      {...{
        id: contactPhoneUI,
        validation: notEmptyField,
        UIText,
        xl: 4,
        lg: 4,
        md: 4,
        sm: 12,
        xs:12,
      }}
    />
  );
};

export default ContactPhone;
