import { containsEscaped } from "../../../utility/filter";

// for regular autocomplete
export const AutocompleteFilter = (options, state) => {
  const filteredOptions = options.filter((option) => {
    for (const key in option) {
      const found = containsEscaped(option[key], state.inputValue);
      if (found) {
        return true;
      }
    }
    return false;
  });
  return filteredOptions;
};

// for GroupPicker autocomplete
export const AutocompleteFilter2 = (options, state) => {
  if (!!state.inputValue) {
    const filteredOptions = options.filter((option) => {
      // console.log("option", option);

      const found = containsEscaped(option.label, state.inputValue);
      return found;
    });
    return filteredOptions;
  } else {
    return options;
  }
};
