import { Alert, Grid } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { passRecoveryId } from "../../../../store/actions2/Login/resetPassword";

const UIText = {
  "es-CL": {
    content1: "Se envió un correo al usuario",
    content2: "con un enlace para realizar el cambio de la contraseña.",
  },
  "en-US": {
    content1: "An email was sent to the user",
    content2: "that contains a link to execute the password change.",
  },
};

const Done = () => {
  const { UI, settings } = useUIBoilerplate(passRecoveryId);
  const { user = { value: "" } } = UI;

  const { content1, content2 } = UIText[settings.locale];

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid container item {...{xl:3,lg:4,md:6,sm:8,xs:10}}>
        <Alert severity="info">
          {content1} {user.value} {content2}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default Done;
