import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { content: "Resetear contraseña" },
  "en-US": { content: "Reset password" },
};

const Header = () => {
  const { settings } = useUIBoilerplate();
  const { content } = UIText[settings.locale];

  return (
    <Grid container item {...{ xs: 12, justifyContent: "center" }}>
      <Typography
        {...{ fontSize:'1.5rem',textTransform: "uppercase", component: "h1", variant: "h5" }}
      >
        {content}
      </Typography>
    </Grid>
  );
};

export default Header;
