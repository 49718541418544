import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

export const getPlatformAndServicesId = "platform&services";
export const platformServices = "platformServices";

export const getPlatformsAndServices = (data) => {
  const id = platformServices;
  const { W365server, cloner_key } = data;

  console.log("trying to get platforms and services");

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      const url = `${W365server}mailapi/platform`;
      const response = await axios.get(url, {
        headers: { cloner_key },
      });

      const data = response.data;

      let services = [];

      for (const platform of data) {
        const url = `${W365server}mailapi/services?platform_id=${platform.id}`;
        const response2 = await axios.get(url, {
          headers: { cloner_key },
        });

        for (const service of response2.data) {
          services.push(service);
        }
      }

      dispatch(warehouse.load({ id: platformServices, data: services }));

      dispatch(worker.finish({ id }));
    } catch (e) {
      // if (e.response.status === 404) {
      //   console.log("Organization not found");
      // } else {
      console.error(e);
      // }
      // // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
