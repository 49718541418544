import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";
import loginWithGAuth, {
  id,
} from "../../../../../store/actions2/Login/loginWithGAuth";
import notEmptyQRCode from "../../../../../utility/validations/notEmptyQRCode";
import TextField from "../../../../UI2/SimpleTextField";
import onlyNumbers from "../../../../../utility/constraints/onlyNumbers";

const UIText = {
  "es-CL": {
    label: "Codigo de autentificación",
    placeholder: "123456",
  },
  "en-US": {
    label: "Authentication code",
    placeholder: "123456",
  },
};

const AuthCode = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { authCode = {}, user = {} } = UI;
  const { working } = useWorker(id);

  const handleAuthentication = () => {
    dispatch(
      loginWithGAuth({
        user: user.value,
        code: authCode.value,
        locale: settings.locale,
      })
    );
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <TextField
        hardValidate
        required
        tagError
        autoFocus
        {...{
          id: "authCode",
          UIText,
          constraint: onlyNumbers,
          validation: notEmptyQRCode,
          submit: handleAuthentication,
          disabled: working,
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
        }}
      />
    </Grid>
  );
};

export default AuthCode;
