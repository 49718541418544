import { Grid, Typography } from "@mui/material";
import Breadcrumbs from "./Breadcrumbs";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const UIText2={
  'es-CL':{ header:'Archivos'  },
  'en-US':{ header:'Files'}
}

export const filesFilterUI = "filesFilter";

const Header = () => {
  const {settings} = useUIBoilerplate()
  const {header}= UIText2[settings.locale]

  return (
    <Grid
      container
      item
      {...{padding:0.4, paddingLeft:2, xs: 12 }}
      columnGap={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography fontSize={20}>
        {header}
      </Typography>
      <Breadcrumbs />
    </Grid>
  );
};

export default Header;
