import { PersonAddAlt1, UploadFile } from "@mui/icons-material";
import MoreActions from "../../../UI2/BasicLayout/Components/MoreActions2/MoreActions";
import { UISliceActions } from "../../../../store/slices/UI2";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": { label: "Crear" },
  "en-US": { label: "Create" },
};

const Create = () => {
  const dispatch = useDispatch();
  const { group } = useURLQuery();

  const buttonsData = [
    {
      icon: <PersonAddAlt1 sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": { label: "Crear usuario" },
        "en-US": { label: "Create user" },
      },
      onClick: () => {
        dispatch(
          UISliceActions.openModal({
            type: "create-new-user",
            payload: { group },
          })
        );
      },
    },
    {
      icon: <UploadFile sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": { label: "Cargar usuarios" },
        "en-US": { label: "Upload users" },
      },
      // disabled: true,
      onClick: () => {
        dispatch(
          UISliceActions.openModal({
            type: "upload-users",
            payload: { group },
          })
        );
      },
    },
  ];

  return <MoreActions {...{ id: "userCreation", buttonsData, UIText }} />;
};

export default Create;
