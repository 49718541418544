import { Avatar, CircularProgress, Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Switch from "../../../UI2/Switch";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import {
  getW365OrgId,
  w365orgData,
} from "../../../../store/actions2/Windows365/getOrganization";
import useURLQuery from "../../../../hooks2/useURLQuery";
import Share, { w365shareUI } from "./Share";
import { useWorker } from "../../../../hooks2/useWorker";
import GroupLock, { w365fetchUI } from "./GroupLock";

const UIText = {
  'es-CL':'Sin credenciales disponibles',
  'en-US':'No credentials available'
}

export const w365switchUI = "w365active";

const Windows365 = () => {
  const { auth, dispatch, warehouse,settings } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { working } = useWorker(getW365OrgId);
  const { done } = useWorker(getW365OrgId);
  const noCreds = UIText[settings.locale]

  const w365org = warehouse[w365orgData] ?? {};

  console.log('w365org',w365org)

  const own = w365org.own ?? {};
  const parent = w365org.parent ?? {};

  const isRoot = parseInt(group) === auth.group;
  const ownCert = parent.id === own.id ? parent.client_id : null;
  const domain = parent.mail_account ? parent.mail_account.split("@")[1] : null;

  useEffect(() => {
    // console.log("again?");
    dispatch(
      UIactions.setValue({ id: w365switchUI, value: isRoot && ownCert })
    );
    if (own.id) {
      dispatch(
        UIactions.setValue({
          id: w365shareUI,
          value: !own.prevent_propagation,
        })
      );
      dispatch(
        UIactions.setValue({
          id: w365fetchUI,
          value: !own.group_locked,
        })
      );
    }
    // console.log('own',own)
    // console.log('own.preventPro',own)

    if (auth.group !== parseInt(group)) {
      dispatch(
        UIactions.setValue({
          id: "ph2",
          value: ownCert ? !own.group_locked : false,
        })
      );
    }

    return () => {
      dispatch(UIactions.clear(w365switchUI));
      dispatch(UIactions.clear(w365shareUI));
      dispatch(UIactions.clear(w365fetchUI));
    };
  }, [
    dispatch,
    auth,
    isRoot,
    ownCert,
    group,
    own.prevent_propagation,
    own.group_locked,
    own.id,
  ]);

  const onChange = (value) => {
    if (value) {
      // dispatch(UIactions.setValue({ id: w365switchUI, value: true }));
      dispatch(UIactions.openModal2({ type: "get-w365code", payload: {} }));
    } else {
      dispatch(UIactions.openModal2({ type: "w365-org-delete", payload: {orgId:own.id} }));
      // dispatch(UIactions.setValue({ id: w365switchUI, value: false }));
    }
  };

  return (
    <Fragment>
      <Grid container item xs={12} justifyContent="space-between">
        <Grid container item alignItems="center" columnSpacing={2} xs={true}>
          {auth.group === parseInt(group) ? (
            <Grid container item xs={2} justifyContent="center">
              {working ? (
                <CircularProgress size={20} />
              ) : (
                <Switch
                  disableDefault
                  {...{ disabled: working, id: w365switchUI, onChange }}
                />
              )}
            </Grid>
          ) : null}
          <Grid
            container
            item
            xs={auth.group === parseInt(group) ? 10 : 12}
            alignItems="center"
            columnGap={2}
          >
            <Avatar
              alt="Windows365-icon"
              src="https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg"
              variant="rounded"
              sx={{ height: 30, width: 27.2 }}
            />
            <Typography fontSize={18} fontWeight="bold">
              Windows 365:
            </Typography>
            <Typography fontSize={18}>{done?(domain?domain:noCreds):''}</Typography>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" columnGap={2} xs="auto">
          {auth.group !== parseInt(group) ? (
            <GroupLock {...{ domain, own, working }} />
          ) : null}
          <Share {...{ domain, own, working }} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Windows365;
