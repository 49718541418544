const minutesToTime = (minutes) => {
  if (typeof minutes === "undefined") {
    return "";
  }
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const hoursString = hours > 9 ? hours : "0" + hours;
  const minutesString = minutesLeft > 9 ? minutesLeft : "0" + minutesLeft;
  return `${hoursString}:${minutesString}`;
};

export default minutesToTime;
