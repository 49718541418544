/**
 * A button component that, when clicked, opens a modal to delete selected devices.
 *
 * @returns {JSX.Element} The DeleteBtn component.
 */
import { Delete } from "@mui/icons-material";
import { UISliceActions } from "../../../../store/slices/UI2";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { devicesData } from "../../Devices";

const UIText = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};

const DeleteBtn = () => {
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesData] ?? {};

  const onClick = () => {
    let selectedDevices = [];
    for (const mid of selected) {
      const device = devices[mid];
      selectedDevices.push(device);
    }

    dispatch(
      UISliceActions.openModal({
        type: "delete-devices",
        payload: { devices: selectedDevices },
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Delete />,
      }}
    />
  );
};

export default DeleteBtn;
