import { Dialog, Grid } from "@mui/material";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Close from "./Components/Close";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import Options, {
  recoveryDateUI,
  recoveryOptionUI,
} from "./Components/Options";
import useRecoveries from "../../../../hooks2/useRecoveries";
import Warning from "./Components/Warning";
import { useEffect } from "react";
import Save from "./Components/Save";

const EditRecoveries = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const requestData = useRecoveries();
  const { recoveries = [] } = requestData;
  const { payload } = UI.modal ?? {};
  const { devices = [] } = payload ?? {};

  const UIText = {
    "es-CL": `Editar ${devices.length} dispositivos`,
    "en-US": `Edit ${devices.length} devices`,
  };
  const header = UIText[settings.locale];
  // console.log("payload", recoveries);

  const handleClose = () => {
    dispatch(UIactions.closeModal());
  };

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(recoveryDateUI));
      dispatch(UIactions.clear(recoveryOptionUI));
    };
  }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose: handleClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid
          container
          item
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <Warning {...{ recoveries }} />
          <Options />
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Save {...{recoveries}} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default EditRecoveries;
