import BasicRow from "../../../UI2/BasicLayout/Components/BasicRow";
import Username from "./Components/Username";
import Actions from "./Components/Actions";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Device from "./Components/Device";
import Size from "./Components/Size";
import Backup from "../../../Exclusions/Components/DevicesList/DeviceRow/Components/Backup";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const DeviceRow = (props) => {
  const { settings } = useUIBoilerplate();
  const { mid } = props;

  const data = {
    id: mid,
    elements: [
      { content: <Username {...props} {...{ fontSize,settings }} />, xs: true },
      { content: <Device {...props} {...{ fontSize }} />, xs: 3 },
      { content: <Size {...props} {...{ fontSize }} />, xs: 2 },
      { content: <Backup {...props} {...{ fontSize, settings }} />, xs: 2 },
      { content: <Actions {...props} />, justify: "end", xs: 1 },
    ],
  };

  return <BasicRow {...data} />;
};

export default DeviceRow;
