import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { groupNameId } from "./GroupName";
import {
  postGroup,
  postGroupId,
} from "../../../store/actions2/GroupForm/postGroup";
import { useWorker } from "../../../hooks2/useWorker";
import { useEffect } from "react";
import notEmptyField from "../../../utility/validations/notEmpty";
import { groupsTreeId, selectedGroupId2 } from "../../../store/actions2/GroupTraveler/loadGroupsTree";

const UIText = {
  "es-CL": { label: "Crear" },
  "en-US": { label: "Create" },
};
const UIText2 = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Send = (props) => {
  const { isNew, groupId } = props;
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { done, working, error: e1 } = useWorker(postGroupId);
  const { error } = useWorker(groupsTreeId);
  const open = UI["group-traveler2-open"] ?? false;
  const { value, error: e2 } = UI[groupNameId] ?? {};
  const group = UI[selectedGroupId2];
  const locale = settings.locale;

  const onClick = () => {
    const payload = { parent: parseInt(group), name: value,  group: isNew?undefined:groupId };
    dispatch(postGroup({ ...auth, payload, locale }));
  };

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: groupNameId,
        force: true,
        fn: notEmptyField,
        locale,
      })
    );
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  return (
    <Button
      {...{
        disabled: !!e2 || error || open,
        UIText: isNew ? UIText : UIText2,
        onMouseDown,
        onClick,
        working,
      }}
    />
  );
};

export default Send;
