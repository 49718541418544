import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { id as loginId } from "../../../../store/actions2/Login/loginToCloner";
import { id as gauthId } from "../../../../store/actions2/Login/loginWithGAuth";
import Button from "../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Recuperar contraseña" },
  "en-US": { label: "Recover password" },
};

const ResetBtn = () => {
  const { UI, settings } = useUIBoilerplate();
  const { working: w1 } = useWorker(loginId);
  const { working: w2 } = useWorker(gauthId);
  const working = w1 || w2;
  const { user = {} } = UI;

  const url = `/newpass?lang=${settings.locale}${
    user.value ? "&user=" + user.value : ""
  }`;

  return (
    <Grid container item xs={12} justifyContent="center">
      <Button
        fullWidth
        {...{
          disabled: working,
          UIText,
          navTo: url,
          labelSx: { fontSize: 12 },
          xl: 3,
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
          variant: "outlined",
        }}
      />
    </Grid>
  );
};

export default ResetBtn;
