import { TextField as MUITextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { UISliceActions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import SlimAlert from "./SlimAlert";
import VisibilityInsert from "./TextFieldInserts/VisibilityInsert";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";

/* e.g. UIText
    UIText = {
        es-CL:{
            label:'',
            placeholder:'',
            tooltip:'',
        }
    }
*/

const errorMsg = {
  "es-CL": "Los campos no coinciden",
  "en-US": "The fields do not match",
};

const TextField = (props) => {
  const {
    id,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    size = "small",
    fontSize = "1rem",
    xl,
    lg,
    md,
    sm,
    xs,
    validation,
    twinValidation,
    twinId,
    tagError,
    forceLowerCase,
    type = "text",
    disabled,
    forceError,
    forceShrink,
    hardValidate,
    submit,
    UIText = {},
    inputSx,
    lang,
    hide,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "validation",
    "twinValidation",
    "twinId",
    "tagError",
    "forceLowerCase",
    "forceError",
    "hardValidate",
    "submit",
    "help",
    "UIText",
    "fontSize",
    "inputSx",
    "hide",
    'forceShrink',
  );

  const ref = useRef(null);
  const { dispatch, UI, settings } = useUIBoilerplate();
  // console.log('UI?',UI)
  const show = UI[id + "-show"] ?? false;
  const { value = "", error } = UI[id] ?? {};
  const locale = lang ?? settings.locale;
  // console.log('????',UIText,locale)
  const { label, tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;

  // Update non-bound field to reflect an existing value
  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  // Update error message if locale changes
  useEffect(() => {
    if (locale && validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
    if (locale && twinValidation) {
      dispatch(
        UISliceActions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg,
          locale,
        })
      );
    }
  }, [dispatch, locale, id, twinId, validation, twinValidation]);

  // Validate field on blur. Also execute any onBlur function set from outside
  const handleBlur = () => {
    if (validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
    if (twinValidation) {
      dispatch(
        UISliceActions.twinFieldValidate({
          id1: id,
          id2: twinId,
          fn: twinValidation,
          errorMsg,
          locale,
        })
      );
    }
    onBlur();
  };

  // Hard validate field on enter key press
  const handleHardValidate = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      onKeyDown();
      if (hardValidate) {
        dispatch(
          UISliceActions.validateField({
            id,
            fn: validation,
            force: true,
            locale,
          })
        );
      }
    }
  };

  const debouncedChange = debounce((e) => {
    const value = e.target.value;
    dispatch(UISliceActions.setField({ id, value }));
    onChange(e);
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedSubmit = debounce((e) => {
    if (submit && (e.key === "Enter" || e.key === "NumpadEnter")) {
      submit(e);
    }
  }, 200);

  const handleSubmit = (e) => {
    debouncedSubmit(e);
  };

  // console.log(`id: ${id} | error: ${error} | show:${show}`)

  let typeX = "text";

  if (type !== "text" && type !== "password") {
    typeX = type;
  } else if (type === "password" && show) {
    typeX = "text";
  } else if (type === "password" && !show) {
    typeX = "password";
  }

  return (
    <AutoGrid
      {...{ xl, lg, md, sm, xs }}
      {...{ alignContent: tagError ? "start" : undefined }}
    >
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <MUITextField
          {...filteredProps}
          {...{
            fullWidth: xs || lg || sm || md || xs ? true : undefined,
            label,
            placeholder,
            inputRef: ref,
            onChange: handleChange,
            onBlur: handleBlur,
            onKeyDown: handleHardValidate,
            onKeyUp: handleSubmit,
            size,
            type: typeX,
            error: !!error || forceError,
            InputLabelProps: {
              ...filteredProps.InputLabelProps,
              shrink: value || type === "datetime-local" || forceShrink? true : undefined,
            },
            InputProps: {
              ...filteredProps.InputProps,
              endAdornment:
                type === "password" ? (
                  <VisibilityInsert {...{ id, disabled }} />
                ) : undefined,
              sx: {
                fontSize,
                padding: type === "password" ? 0.3 : undefined,
                borderRadius:
                  !!error && tagError
                    ? "4px 4px 0px 0px"
                    : undefined /*, ...inputSx*/,
              },
            },
            inputProps: {
              sx: {
                textTransform: forceLowerCase ? "lowercase" : undefined,
                ...inputSx,
              },
            },
            sx: { visibility: hide ? "hidden" : undefined },
          }}
        />
      </AutoHelp>
      <SlimAlert errors={!!tagError ? [error] : []} fuseTop />
    </AutoGrid>
  );
};

export default TextField;
