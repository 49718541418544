import { Grid } from "@mui/material";
import SlimAlert from "../../UI2/SlimAlert";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL":
    "Cargue la información de los usuarios utilizando el templado .xlsx disponible",
  "en-US": "Upload users data through the .xlsx sheet template available",
};
const Empty = () => {
  const { settings } = useUIBoilerplate();
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      padding={4}
    >
      <SlimAlert
        notCollapse
        {...{
          severity: "info",
          errors: [UIText[settings.locale]],
          xs: 6,
        }}
      />
    </Grid>
  );
};

export default Empty;
