/**
 * Renders the User component.
 * @param {Object} props - The component props.
 * @param {boolean} props.isNew - Indicates if the user is new.
 * @returns {JSX.Element} The rendered User component.
 */
import { pad } from "lodash";
import notEmptyUser from "../../../utility/validations/notEmptyUser";
import TextField from "../../UI2/SimpleTextField";


const UIText = {
  "es-CL": {
    label: "Usuario",
    placeholder: "usuario1@cloner.cl",
  },
  "en-US": {
    label: "User",
    placeholder: "user1@cloner.cl",
  },
};

export const userUI = "userLogin";

const User = (props) => {
  const { isNew } = props;

  return (
    <TextField
      tagError
      {...{
        id: userUI,
        UIText,
        required: isNew,
        disabled: !isNew,
        validation: notEmptyUser,
        xs: 12,
        sx: { marginTop: 1, paddingBottom: 2 },
      }}
    />
  );
};

export default User;
