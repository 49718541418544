import { CircularProgress, Grid, Typography } from "@mui/material";
import Switch from "../../UI2/Switch";
import { useWorker } from "../../../hooks2/useWorker";
import {
  disableTFA,
  disableTFAId,
} from "../../../store/actions2/TFA/disableTFA";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const UIText = {
    'es-CL':{label:'Autentificación de 2 factores'},
    'en-US':{label:'Two factor authentication'}
}

export const tfaSwitch = "tfaSwitch";

const TFA = () => {
  const {auth,dispatch,settings} = useUIBoilerplate();
  const { working } = useWorker(disableTFAId);
  const locale = settings.locale
  const {label} = UIText[locale]

  const onChange = (value) => {
    if (value) {
      dispatch(UIactions.setValue({ id: tfaSwitch, value: true }));
      dispatch(UIactions.openModal({ type: "enable-tfa", payload: {} }));
    } else {
      dispatch(disableTFA({ ...auth, locale }));
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      columnGap={2}
      sx={{ height: "5vh" }}
    >
      <Grid container item xs={1} alignItems="center" justifyContent="center">
        {working ? (
          <CircularProgress size={20} />
        ) : (
          <Switch disableDefault {...{ id: tfaSwitch, onChange }} />
        )}
      </Grid>
      <Typography fontSize={16}>{label}</Typography>
    </Grid>
  );
};

export default TFA;
