import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const getConfigId = "deviceConfig";
export const configDataId = "deviceConfig";

const getDeviceConfig = (data) => {
  console.log("getting device config");
  const id = getConfigId;
  return async (dispatch) => {
    const { server, cloner_key, controller, payload } = data;
    dispatch(worker.start(id));
    try {
      const bodyData = JSON.stringify(payload);
      const url = server + "api/user/getconf";
      const response = await axios.post(url, bodyData, {
        signal: controller.signal,
        headers: { cloner_key },
      });
      dispatch(warehouse.load({ id: configDataId, data: response.data }));
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.message, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default getDeviceConfig;
