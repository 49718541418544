import { Divider as MUIDivider } from "@mui/material";
import AutoGrid from "./Wrappers/AutoGrid";

// Divider
const Divider = (props) => {
  const { xs = 12, color } = props;

  return (
    <AutoGrid {...{container:false, xs }}>
      <MUIDivider {...{sx:{bgcolor: color}}}/>
    </AutoGrid>
  );
};

export default Divider;
