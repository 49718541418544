import { Dialog, Grid } from "@mui/material";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import SlimAlert from "../../../UI2/SlimAlert";
import Close from "./Components/Close";
import Continue from "./Components/Continue";

const NewDevicesNewRequest = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { payload } = UI.modal ?? {};
  const { devices = [] } = payload ?? {};
  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const UIText = {
    "es-CL": {
      header: `Iniciar una nueva solicitud de recuperacion con los ${devices.length} equipos seleccionados?`,
      alert:
        "Actualmente existe una solicitud de recuperación que no ha sido enviada. Si continua se iniciará una nueva solicitud de recuperación y la solicitud actual se perderá.",
    },
    "en-US": {
      header: `Start a new recovery request with the ${devices.length} selected devices?`,
      alert:
        "Currently exists a recovery request that hasn't been sent. If you continue a new recovery request will be started and the current request will be lost.",
    },
  };

  const { header, alert } = UIText[settings.locale];

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid
          container
          item
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <SlimAlert
            notCollapse
            {...{ xs: 12 }}
            {...{ errors: [alert], severity: "warning" }}
          />
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Continue {...{ devices }} />
        {/* <Close />
        <Add /> */}
      </BasicDialogActions>
    </Dialog>
  );
};

export default NewDevicesNewRequest;
