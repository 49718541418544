import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import MoreActions from "../../../UI2/BasicLayout/Components/MoreActions2/MoreActions";
import { bkpMailArrayId } from "../UsersList/UserRow/BackupMail";
import { bkpOneArrayId } from "../UsersList/UserRow/BackupOnedrive";
import { arrayId } from "../UsersList/UsersList";

const UITextMore = {
  "es-CL": {
    label: "Más",
    // fixedTooltip: "Sugiere un correo para Windows 365 valido, utilizando el usuario como referencia",
  },
  "en-US": {
    label: "More",
    // fixedTooltip: "Suggest a valid Windows 365 email, using the user as reference",
  },
};

export const newUsersUI = "newUsers";

const More = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const selected = UI[arrayId] ?? [];


  const buttonsData = [
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "Respaldar correo" },
        "en-US": { label: "Backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.add({ id: bkpMailArrayId, key: user }));
        }
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "No respaldar correo" },
        "en-US": { label: "Don't backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.remove({ id: bkpMailArrayId, key: user }));
        }
        //
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "Respaldar Onedrive" },
        "en-US": { label: "Backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.add({ id: bkpOneArrayId, key: user }));
        }
      },
    },
    {
      noIcon: true,
      UIText: {
        "es-CL": { label: "No respaldar Onedrive" },
        "en-US": { label: "Don't backup mail" },
      },
      onClick: () => {
        for (const user of selected) {
          dispatch(UIactions.remove({ id: bkpOneArrayId, key: user }));
        }
      },
    },
  ];

  return <MoreActions {...{ id: "multiIntegrations", buttonsData, UIText:UITextMore, startIcon:null }} />;
  // return (
  //   <Grid item>
  //     <AutoHelp {...{ fixedTooltip }}>
  //       <Button
  //         component="label"
  //         variant="outlined"
  //         // startIcon={<UploadFile />}
  //         size="small"
  //       >
  //         <Typography fontSize="0.8rem" fontWeight="bold">
  //           {label}
  //         </Typography>
  //         <input type="file" hidden onChange={handleSelect}></input>
  //       </Button>
  //     </AutoHelp>
  //   </Grid>
  // );
};

export default More;
