import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import IconButton from "../../../../UI2/IconButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import TextField from "../../../../UI2/TextField";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";

const UIText = {
  "es-CL": {
    subtitle: "Opciones",
    warning:
      "La recuperación ha sido configurada en modo avanzado, por lo que recomendamos utilizar esa modalidad para alterar esta recuperación.",
    opt1: "Incluir archivos eliminados",
    opt2: "Recuperar una fecha especifica",
  },
  "en-US": {
    subtitle: "Options",
    warning:
      "The recovery has been configured in advanced mode, so we recommend using that mode to alter this recovery.",
    opt1: "Include deleted files",
    opt2: "Recover a specific date",
  },
};

const UITextDate = {
  "es-CL": { label: "Fecha de recuperacion" },
  "en-US": { label: "Recovery date" },
};

export const recoveryDateUI = "recoveryDate";
export const recoveryOptionUI = "recoveryOptions";

const Options = ({ repositories = [] }) => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const selected = UI[recoveryOptionUI];
  const locale = settings.locale;
  const { subtitle, opt1, opt2 } = UIText[locale];

  // const sameOptionAndBkpDate = useMemo(() => {
  //   if (repositories.length === 0) {
  //     return true;
  //   }
  //   const firstRepo = repositories[0];
  //   for (let i = 1; i < repositories.length; i++) {
  //     const repo = repositories[i];
  //     if (
  //       repo.option !== firstRepo.option ||
  //       repo.bkpDate !== firstRepo.bkpDate
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // }, [repositories]);

  // useEffect(() => {
  //   if (sameOptionAndBkpDate) {
  //     dispatch(
  //       UIactions.setValue({
  //         id: recoveryOptionUI,
  //         value: repositories[0].option,
  //       })
  //     );
  //     dispatch(
  //       UIactions.setField({
  //         id: recoveryDateUI,
  //         value: repositories[0].bkpDate,
  //       })
  //     );
  //   } else {
  //     dispatch(UIactions.clear(recoveryOptionUI));
  //     dispatch(UIactions.clear(recoveryDateUI));
  //   }
  // }, [dispatch, sameOptionAndBkpDate, repositories]);

  const onClickDeleted = () => {
    if (selected !== "deleted") {
      dispatch(UIactions.setValue({ id: recoveryOptionUI, value: "deleted" }));
    } else {
      dispatch(UIactions.clear(recoveryOptionUI));
    }
    dispatch(UIactions.clear(recoveryDateUI));
  };

  const onClickDated = () => {
    if (selected !== "dated") {
      dispatch(UIactions.setValue({ id: recoveryOptionUI, value: "dated" }));
    } else {
      dispatch(UIactions.clear(recoveryOptionUI));
    }
    dispatch(UIactions.clear(recoveryDateUI));
  };

  let deletedIcon = <RadioButtonUnchecked />;
  let datedIcon = <RadioButtonUnchecked />;
  if (selected === "deleted") {
    deletedIcon = <RadioButtonChecked color="primary" />;
  }
  if (selected === "dated") {
    datedIcon = <RadioButtonChecked color="primary" />;
  }

  return (
    <Grid container item {...{ rowGap: 1, xs: 12 }}>
      <Grid container item xs={12}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid>
      <Grid container item xs={12} alignItems="center">
        <IconButton
          {...{
            onClick: onClickDeleted,
            icon: deletedIcon,
            UIText: { universal: {} },
          }}
        />
        <Typography fontWeight="bold" fontSize="0.9rem">
          {opt1}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container item xs="auto" alignItems="center">
          <IconButton
            {...{
              onClick: onClickDated,
              icon: datedIcon,
              UIText: { universal: {} },
            }}
          />
          <Typography fontWeight="bold" fontSize="0.9rem">
            {opt2}
          </Typography>
        </Grid>
        <TextField
          {...{
            id: recoveryDateUI,
            type: "datetime-local",
            disabled: selected !== "dated",
            UIText: UITextDate,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Options;
