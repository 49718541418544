import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../../store/actions2/Devices/getDevices";
import { devicePickerId } from "../../../../UI2/Specialized/DevicePicker";
import { humanBytes, timeDiff } from "../../../../../utility/humanizers";

const UIText = {
  "es-CL": { subtitle: "Detalles", size: "Tamaño", bkp: "Último respaldo" },
  "en-US": { subtitle: "Details", size: "Size", bkp: "Last backup" },
};
const Details = () => {
  const { settings, UI, warehouse } = useUIBoilerplate();
  const locale = settings.locale
  const { mid } = UI[devicePickerId]??{}
  const devices = warehouse[devicesDataId]??[]
  const device = devices.find((x) => x.mid === mid)??{}
  const {  size, bkp } = UIText[locale];

  return (
    <Grid container item {...{ rowGap: 2, xs: 12 }}>
      <Grid container item xs={12} rowSpacing={2}>
        <Grid container item xs={6} columnGap={2}>
          <Typography fontWeight="bold">{size}:</Typography>
          <Typography> {humanBytes(device.space_active)}</Typography>
        </Grid>
        <Grid container item xs={6} columnGap={2}>
          <Typography fontWeight="bold">{bkp}:</Typography>
          <Typography> {timeDiff(Date.now() -device.lastBkp, locale)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Details;
