import { CircularProgress, Grid } from "@mui/material";
import { useWorker } from "../../../hooks2/useWorker";
import { getMailId } from "../../../store/actions2/Mails/getMail";

const Loading = () => {
  const { working } = useWorker(getMailId);

  if (working) {
    return (
      <Grid container justifyContent="center" alignContent="center" padding={8}>
        <CircularProgress size={30} />
      </Grid>
    );
  }

  return null;
};

export default Loading;
