import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Back = () => {
  return (
    <Button
      {...{ UIText, navTo: -1, xs: "auto", variant: "outlined" }}
    />
  );
};

export default Back;
