import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import useURLQuery from "../../hooks2/useURLQuery";
import { deleteGroupId } from "../../store/actions2/Users/deleteGroup";
import { UISliceActions } from "../../store/slices/UI2";
import GenericConfirmation from "./GenericConfirmation/GenericConfirmation";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { useLocation } from "react-router-dom";
import { getGroupsListId, groupsTreeId, selectedGroupId, selectedGroupIdx } from "../../store/actions2/GroupTraveler/loadGroupsTree";
import useTrees from "../../hooks2/useNewTrees";

const DeleteGroup = (props) => {
  const { dispatch, navigate, settings } = useUIBoilerplate();
  const location = useLocation()
  const { group } = useURLQuery();
  const {done,error} = useWorker(deleteGroupId)
  const trees = useTrees()??{}
  const groups = trees[groupsTreeId]??[]
  const selectedGroup = groups.find(g=>g.id===parseInt(group))
  const { name, parentId:parent } = selectedGroup;
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header: `¿Esta seguro que desea eliminar el grupo "${name}"?`,
      message:
        "Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, el grupo será eliminado y no podrá ser recuperado",
    },
    "en-US": {
      header: `Are you sure you want delete the group "${name}"?`,
      message:
        "Important: Beware that this operation is definitive and irreversible. Once confirmed, the group will be deleted and cannot be recovered",
    },
  };
  const { header, message } = UIText[locale];

  useEffect(()=>{
    if (done && !error){
      dispatch(UISliceActions.closeModal())
      dispatch(workers.clear(getGroupsListId))
      dispatch(UISliceActions.clear(selectedGroupId))
      dispatch(UISliceActions.clear(selectedGroupIdx))
      navigate(`${location.pathname}?group=${parent}`)
    }
  },[dispatch,navigate,done,error, parent, location.pathname])

  useEffect(()=>{
    return ()=>{
      dispatch(workers.clear(deleteGroupId))
    }
  },[dispatch])

  const confirmation = () => {
    dispatch(
      UISliceActions.openModal2({
        type: "delete-group-confirmation",
        payload: { group, parent },
      })
    );
  };


  return (
    <GenericConfirmation
      alternativeText
      {...{ header, message, severity: "warning", confirmation, workerId:deleteGroupId }}
    />
  );
};

export default DeleteGroup;
