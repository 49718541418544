import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const getW365ClientId = "getW365Client";
export const w365clientData = "windows365client";

const getClient = (data) => {
  const { W365server, cloner_key, user: cloner_user } = data;
  const id = getW365ClientId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      const url = `${W365server}mailapi/client`;
      const response = await axios.get(url, {
        headers: { cloner_key },
        params: { cloner_user },
      });
      const data = response.data.data;
      console.log("data", data);
      dispatch(warehouse.load({ id: w365clientData, data }));
      dispatch(worker.finish({ id }));
    } catch (error) {
      dispatch(worker.finish({ id, error }));
    }
  };
};

export default getClient;
