import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Oficina/Depto", placeholder: "A-123" },
  "en-US": { label: "Office/Apartment", placeholder: "A-123" },
};
export const deliveryOfficeUI = "deliveryOffice";

const DeliveryOffice = () => {

  return (
    <TextField
      {...{
        id: deliveryOfficeUI,
        validation: notEmptyField,
        UIText,
        xl: 6,
        lg: 6,
        md: 6,
        sm: 6,
        xs: 6,
      }}
    />
  );
};

export default DeliveryOffice
