import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import GreylistPicker, {
  greylistPickerId,
} from "../../../UI2/Specialized/GreylistPicker";
import { repositoryPickerId } from "../../../UI2/Specialized/RepositoryPicker";
import { greylistDataId } from "../../../../store/actions2/Devices/getGreylist";
import { newTreesSliceActions as trees } from "../../../../store/slices/trees2";
import { workersSliceActions as workers } from "../../../../store/slices/workers";

export const greylistTreeReady = "greylistTreeReady";

const Greylist = () => {
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const repoId = UI[repositoryPickerId] ?? "";
  const currentGreylist = UI[greylistPickerId] ?? 0;
  //   console.log("current greylist", currentGreylist);

  const greylist = useMemo(() => {
    const greylists = warehouse[greylistDataId] ?? [];
    return greylists.find((gl) => {
      return gl.milliseconds === currentGreylist;
    });
  }, [warehouse, currentGreylist]);

  useEffect(() => {
    if (greylist && repoId) {
      dispatch(trees.clearForest());
      dispatch(
        trees.createTree({
          id: "greylist-" + repoId,
          type: "history",
          data: {
            created: greylist.created,
            local: greylist.localCreated,
            milliseconds: greylist.milliseconds,
            repository: greylist.repo,
            size: greylist.size,
          },
          root: {},
        })
      );
      dispatch(
        trees.loadGreylistTree({
          id: "greylist-" + repoId,
          rawTree: greylist.tree,
        })
      );
    }
    dispatch(workers.finish({ id: greylistTreeReady }));
  }, [dispatch, greylist, repoId]);

  const onChange = () => {
    dispatch(workers.clear(greylistTreeReady));
  };

  return <GreylistPicker {...{ repoId, onChange, xs: true }} />;
};

export default Greylist;
