import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { deleteGroup } from "../../store/actions2/Users/deleteGroup";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

const DeleteGroupConfirmation = () => {
  const { auth,dispatch, settings, UI } = useUIBoilerplate();
  const {server,cloner_key} = auth
  const locale = settings.locale;
  const { payload } = UI.modal2;
  const { group } = payload;

  const confirmation = () => {
    dispatch(deleteGroup({
      group,
      server,
      cloner_key,
      locale,
    }))
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteGroupConfirmation;
