import { Grid } from "@mui/material";
import DownloadSheet from "./DownloadSheet";
import UploadSheet from "./UploadSheet";

const TopBar = () => {
  return (
    <Grid
      container
      item
      {...{
        padding: 2,
        paddingTop: 0,
        paddingBottom: 2,
        columnSpacing: 2,
        xs: 12,
        justifyContent: "space-between",
      }}
    >
        <UploadSheet />
        <DownloadSheet />
    </Grid>
  );
};

export default TopBar;
