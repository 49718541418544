import { Dialog } from "@mui/material";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import Form, { emailUI } from "./Components/Form";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Close from "./Components/Close";
import Activate from "./Components/Activate";
import { useEffect } from "react";
import { useWorker } from "../../hooks2/useWorker";
import { postClientId } from "../../store/actions2/Windows365/postClient";
import { workersSliceActions as workers} from "../../store/slices/workers";

const UIText = {
  "es-CL": "Registrar organización de Windows 365",
  "en-US": "Register Windows 365 organization",
};

const Windows365UserEnable = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const { done, error } = useWorker(postClientId);

  const header = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, error]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(emailUI));
      dispatch(UIactions.clear("vc0"));
      dispatch(UIactions.clear("vc1"));
      dispatch(UIactions.clear("vc2"));
      dispatch(UIactions.clear("vc3"));
      dispatch(UIactions.clear("vc4"));
      dispatch(UIactions.clear("vc5"));
      dispatch(workers.clear(postClientId))
    };
  }, [dispatch]);

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Form />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Activate />
      </BasicDialogActions>
    </Dialog>
  );
};

export default Windows365UserEnable;
