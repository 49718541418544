import { Alert, Grid } from "@mui/material";
import gauthLogo from "../../../../Resources/gauth-logo.png";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": {
    content:
      "Esta cuenta se encuentra protegida mediante autentificación de 2 factores de Google. Por favor ingrese el codigo para poder continuar.",
  },
  "en-US": {
    content:
      "This account is protected by Google's two factors authentication. Please enter the code to proceed. ",
  },
};
const Top = () => {
  const { settings } = useUIBoilerplate();
  const { content } = UIText[settings.locale];

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        {...{ xl: 4, lg: 4, md: 6, sm: 8, xs: 10 }}
      >
        <Grid container item xs={2} justifyContent="center">
          <img src={gauthLogo} width="80%" alt="Google Auth" />
        </Grid>
        <Grid container item xs={10} alignItems="center">
          <Alert severity="info">{content}</Alert>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Top;
