import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import IconButton from "../../../../../UI2/IconButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import TextField from "../../../../../UI2/TextField";
import { UISliceActions } from "../../../../../../store/slices/UI2";
import notEmptyField from "../../../../../../utility/validations/notEmpty";
import SlimAlert from "../../../../../UI2/SlimAlert";

const UIText = {
  "es-CL": {
    subtitle: "Opciones adicionales",
    opt1: "Recuperar fecha especifica",
    opt2: "Incluir archivos eliminados",
  },
  "en-US": {
    subtitle: "Aditional options",
    opt1: "Recover specific date",
    opt2: "Include deleted files",
  },
};

const UIText2 = { universal: {} };

const UIText3 = {
  "es-CL": { label: "Fecha de los datos", placeholder: "DD/MM/YY" },
  "en-US": { label: "Backup date", placeholder: "DD/MM/YY" },
};

export const backupDateUI = "backupDate";
export const backupOptionUI = "backupOptions";

const Options = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { subtitle, opt1, opt2 } = UIText[settings.locale];

  const optValue = UI[backupOptionUI];
  const {error} = UI[backupDateUI]??{}

  const toggle = (value) => {
    dispatch(
      UISliceActions.exclusiveOptionalToggle({ id: backupOptionUI, value })
    );
    dispatch(UISliceActions.clear(backupDateUI))
  };

  return (
    <Grid container item xs={12} sx={{ padding: 2 }}>
      <Grid container item xs={12}>
        <Typography fontSize={15} fontWeight="bold">
          {subtitle}
        </Typography>
      </Grid>
      <Grid container item alignItems="center" padding={1} xs={12}>
        <IconButton
          {...{
            onClick: () => {
              toggle("dated");
            },
            UIText: UIText2,
            icon:
              optValue === "dated" ? (
                <RadioButtonChecked sx={{ color: "primary.main" }} />
              ) : (
                <RadioButtonUnchecked />
              ),
            size: "small",
            xs: "auto",
          }}
        />
        <Grid container item xs={true}>
          <Typography fontSize={15}>{opt1}</Typography>
        </Grid>
        <Grid container item xs={6}>
          <TextField
            {...{
              id: backupDateUI,
              type: "date",
              UIText: UIText3,
              xs: 12,
              hide: optValue !== "dated",
              validation:notEmptyField,
            }}
          />
        </Grid>
        <SlimAlert {...{errors:[error],sx:{paddingTop:1},xs:12}}/>
      </Grid>
      <Grid container item alignItems="center" padding={1} xs={12}>
        <IconButton
          {...{
            onClick: () => {
              toggle("deleted");
            },
            UIText: UIText2,
            icon:
              optValue === "deleted" ? (
                <RadioButtonChecked sx={{ color: "primary.main" }} />
              ) : (
                <RadioButtonUnchecked />
              ),
            size: "small",
            xs: "auto",
          }}
        />
        <Grid container item xs={true}>
          <Typography fontSize={15}>{opt2}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Options;
