import { createSlice } from "@reduxjs/toolkit";
import { defaultPagination } from "../../components/UI2/genericIDs";

const initialState = {
  UI: {},
};
const UISlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    fullClear(state) {
      state.UI = {};
    },
    clear(state, action) {
      const id = action.payload;
      delete state.UI[id];
    },
    setValue(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = value;
    },
    setField(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = {
        value,
        error: "",
        touched: true,
      };
    },
    setFieldError(state, action) {
      const { id, value } = action.payload;
      state.UI[id].error = value;
    },
    //==================================== Modals ==================================================
    openModal(state, action) {
      const { type, payload } = action.payload;
      state.UI.modal = {
        type,
        open: true,
        payload,
      };
    },
    closeModal(state) {
      state.UI.modal = {
        type: "",
        open: false,
        payload: undefined,
      };
    },
    hideModal(state) {
      state.UI.modal.open = false;
    },
    openModal2(state, action) {
      const { type, payload } = action.payload;
      state.UI.modal2 = {
        type,
        open: true,
        payload,
      };
    },
    closeModal2(state) {
      state.UI.modal2 = {
        type: "",
        open: false,
        payload: undefined,
      };
    },
    //==================================== Toggles ==================================================
    toggle(state, action) {
      const id = action.payload;
      if (state.UI[id]) {
        state.UI[id] = false;
      } else {
        state.UI[id] = true;
      }
    },
    toggleSort(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { value: "", descending: false };
      }
      state.UI[id].descending =
        state.UI[id].value === key ? !state.UI[id].descending : false;
      state.UI[id].value = key;
    },
    exclusiveOptionalToggle(state, action) {
      const { id, value } = action.payload;
      state.UI[id] = state.UI[id] === value ? "" : value;
    },
    //==================================== Pagination ==================================================
    setPagination(state, action) {
      const {
        id = defaultPagination,
        rowsPerPage = 50,
        count,
      } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { count: 0, rowsPerPage: 0, page: 0 };
      }

      state.UI[id] = {
        count,
        rowsPerPage,
        page:
          state.UI[id].page >= Math.ceil(count / rowsPerPage)
            ? 0
            : state.UI[id].page,
        // : 0,
      };
    },
    nextPage(state, action) {
      const id = action.payload;
      const { page, count, rowsPerPage } = state.UI[id];
      if ((page + 1) * rowsPerPage < count) {
        state.UI[id].page++;
      }
    },
    previousPage(state, action) {
      const id = action.payload;
      const { page, rowsPerPage } = state.UI[id];
      if ((page + 1) * rowsPerPage > 0) {
        state.UI[id].page--;
      }
    },

    // setPage(state, action) {
    //   const { id, page } = action.payload;
    //   const pg = state.elements[id];
    //   pg.page = page;
    //   pg.from = page * pg.rowsPerPage;
    //   pg.to = (page + 1) * pg.rowsPerPage;
    // },

    //==================================== Toggle Arrays ===================================================
    add(state, action) {
      const { id, key } = action.payload;

      if (!id || !key) {
        console.log("UISliceActions.add missing id or key!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (!found) {
        state.UI[id].push(key);
      }
    },
    remove(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (found) {
        state.UI[id] = state.UI[id].filter((x) => x !== key);
      }
    },
    toggleSingle(state, action) {
      const { id, key } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x === key);
      if (typeof found !== "undefined") {
        state.UI[id] = state.UI[id].filter((x) => x !== key);
      } else {
        state.UI[id].push(key);
      }
    },
    toggleAll(state, action) {
      const { id, batch } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      if (state.UI[id].length === batch.length) {
        state.UI[id] = [];
      } else if (state.UI[id].length < batch.length) {
        state.UI[id] = batch;
      } else if (state.UI[id].length > batch.length) {
        console.error(
          "This case shouldn't be happening",
          state.UI[id].length,
          batch.length
        );
      }
    },
    //==================================== Complex Array ============================================
    addObj(state, action) {
      const { id, obj = {} } = action.payload;

      if (!id || !obj.id) {
        console.log("UISliceActions.add missing id or obj.id!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x.id === obj.id);
      if (!found) {
        state.UI[id].push(obj);
      }
    },
    removeObj(state, action) {
      const { id, key } = action.payload;

      if (!id || !key) {
        console.log("UISliceActions.add missing id or key!");
        return;
      }

      if (!state.UI[id]) {
        state.UI[id] = [];
      }
      const found = state.UI[id].find((x) => x.id === key);
      if (found) {
        state.UI[id] = state.UI[id].filter((x) => x.id !== key);
      }
    },

    //==================================== Validations ==============================================
    validateField(state, action) {
      const { id, force, fn, locale } = action.payload;
      if (!state.UI[id]) {
        state.UI[id] = { value: "", error: "", touched: false };
      }
      if (force || state.UI[id].touched) {
        state.UI[id].error = fn(state.UI[id].value, locale);
      }
    },
    twinFieldValidate(state, action) {
      const { id1, id2, fn, errorMsg, force, locale } = action.payload;

      let UI1 = state.UI[id1] ?? {};
      let UI2 = state.UI[id2] ?? {};

      if (UI1.value || UI2.value || force) {
        if (UI1.touched || force) {
          UI1.error = fn(UI1.value, locale);
        }
        if (UI2.touched || force) {
          UI2.error = fn(UI2.value, locale);
        }
        if (
          !UI1.error &&
          !UI2.error &&
          UI1.value !== UI2.value &&
          UI1.touched &&
          UI2.touched
        ) {
          UI1.error = errorMsg[locale];
          UI2.error = errorMsg[locale];
        }
      } else {
        console.log('reset?')
        UI1.touched = false;
        UI1.error = "";
        UI2.touched = false;
        UI2.error = "";
      }
      
      state.UI[id1] = UI1
      state.UI[id2] = UI2
    },

    // //==========================================================================================
    // fullClear(state) {
    //   state.elements = {
    //     trees: {},
    //   };
    // },
    // clear(state, action) {
    //   const id = action.payload;
    //   delete state.elements[id];
    // },
    // clearGroup(state, action) {
    //   const substring = action.payload;
    //   for (const key in state.elements) {
    //     if (lowerMatch(key, substring)) {
    //       delete state.elements[key];
    //     }
    //   }
    // },
    // set(state, action) {
    //   const { id, value } = action.payload;
    //   state.elements[id] = value;
    // },
    // setError(state,action){
    //   const { id, value } = action.payload;
    //   state.elements[id].error = value;
    // },
    // push(state, action) {
    //   const { id, value } = action.payload;
    //   if (typeof state.elements[id] === 'undefined'){
    //     state.elements[id] = []
    //   }

    //   if (typeof value === "string") {
    //     state.elements[id].push(value);
    //   } else if (typeof value === "object") {
    //     state.elements[id] = [...state.elements[id], ...value];
    //   } else {
    //     console.error(
    //       "uiSliceActions.push value is neither a string not an object"
    //     );
    //   }
    // },
    // pluck(state, action) {
    //   const { id, values } = action.payload;
    //   let newArray = [];
    //   for (const x of state.elements[id]) {
    //     let found = false;
    //     for (const val of values) {
    //       if (val === x) {
    //         found = true;
    //         break;
    //       }
    //     }
    //     if (!found) {
    //       newArray.push(x);
    //     }
    //   }
    //   state.elements[id] = [...newArray];
    // },
    // pluckObj(state, action) {
    //   const { id, values, key } = action.payload;
    //   let newArray = [];
    //   for (const x of state.elements[id]) {
    //     let found = false;
    //     for (const val of values) {
    //       if (val[key] === x[key]) {
    //         found = true;
    //         break;
    //       }
    //     }
    //     if (!found) {
    //       newArray.push(x);
    //     }
    //   }
    //   state.elements[id] = [...newArray];
    // },
    // setField(state, action) {
    //   const { id, value } = action.payload;
    //   state.elements[id] = {
    //     value,
    //     touched: true,
    //     error: "",
    //   };
    // },
    // setInObj(state, action) {
    //   const { id, key, value } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = {};
    //   }
    //   state.elements[id][key] = value;
    // },
    // clearInObj(state, action) {
    //   const { id, key } = action.payload;
    //   delete state.elements[id][key];
    // },
    // loadField(state, action) {
    //   const { id, value } = action.payload;
    //   state.elements[id] = {
    //     value,
    //     touched: false,
    //     error: "",
    //   };
    // },
    // toggle(state, action) {
    //   const id = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = true;
    //   } else {
    //     state.elements[id] = false;
    //   }
    // },
    // toggleSort(state, action) {
    //   const { id, key } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = { value: "", descending: false };
    //   }
    //   state.elements[id].descending =
    //     state.elements[id].value === key
    //       ? !state.elements[id].descending
    //       : false;
    //   state.elements[id].value = key;
    // },
    // //==========================================================================================
    // add(state, action) {
    //   const { id, key } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = [];
    //   }
    //   const found = state.elements[id].find((x) => x === key);
    //   if (!found) {
    //     state.elements[id].push(key);
    //   }
    // },
    // remove(state, action) {
    //   const { id, key } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = [];
    //   }
    //   const found = state.elements[id].find((x) => x === key);
    //   if (found) {
    //     state.elements[id] = state.elements[id].filter((x) => x !== key);
    //   }
    // },
    // toggleSingle(state, action) {
    //   const { id, key } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = [];
    //   }
    //   const found = state.elements[id].find((x) => x === key);
    //   if (typeof found !== 'undefined') {
    //     state.elements[id] = state.elements[id].filter((x) => x !== key);
    //   } else {
    //     state.elements[id].push(key);
    //   }
    // },
    // toggleAll(state, action) {
    //   const { id, batch } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = [];
    //   }
    //   if (state.elements[id].length === batch.length) {
    //     state.elements[id] = [];
    //   } else if (state.elements[id].length < batch.length) {
    //     state.elements[id] = batch;
    //   } else if (state.elements[id].length > batch.length) {
    //     console.error("This case shouldn't be happening");
    //   }
    // },
    // //==========================================================================================
    // setPagination(state, action) {
    //   const { id, rowsPerPage, count } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = { count: 0, rowsPerPage: 0, page: 0 };
    //   }

    //   state.elements[id] = {
    //     count,
    //     rowsPerPage,
    //     page:
    //       state.elements[id].page >= Math.ceil(count / rowsPerPage)
    //         ? state.elements[id].page
    //         : 0,
    //     from: state.elements[id].page * rowsPerPage,
    //     to: (state.elements[id].page + 1) * rowsPerPage,
    //   };
    // },
    // setPage(state, action) {
    //   const { id, page } = action.payload;
    //   const pg = state.elements[id];
    //   pg.page = page;
    //   pg.from = page * pg.rowsPerPage;
    //   pg.to = (page + 1) * pg.rowsPerPage;
    // },
    // //==========================================================================================
    // validate(state, action) {
    //   const { id, force, valFn } = action.payload;
    //   let UI = state.elements[id];
    //   if (!UI) {
    //     state.elements[id] = { value: "", error: "", touched: false };
    //     UI = state.elements[id];
    //   }
    //   if (force || UI.touched) {
    //     UI.error = valFn(UI.value);
    //   }
    // },
    // twinValidate(state, action) {
    //   const { id1, id2, valFn, errorMsg, force } = action.payload;
    //   let UI1 = state.elements[id1];
    //   let UI2 = state.elements[id2];

    //   if (!UI1) {
    //     state.elements[id1] = { value: "", error: "", touched: false };
    //     UI1 = state.elements[id1];
    //   }
    //   if (!UI2) {
    //     state.elements[id2] = { value: "", error: "", touched: false };
    //     UI2 = state.elements[id2];
    //   }

    //   if (UI1.value || UI2.value || force) {
    //     if (UI1.touched || force) {
    //       UI1.error = valFn(UI1.value);
    //     }
    //     if (UI2.touched || force) {
    //       UI2.error = valFn(UI2.value);
    //     }
    //     if (
    //       !UI1.error &&
    //       !UI2.error &&
    //       UI1.value !== UI2.value &&
    //       UI1.touched &&
    //       UI2.touched
    //     ) {
    //       UI1.error = errorMsg;
    //       UI2.error = errorMsg;
    //     }
    //   } else {
    //     UI1.touched = false;
    //     UI1.error = "";
    //     UI2.touched = false;
    //     UI2.error = "";
    //   }
    // },
    // customSet(state, action) {
    //   const { id, fn, defaultVal } = action.payload;
    //   if (!state.elements[id]) {
    //     state.elements[id] = defaultVal;
    //   }
    //   state.elements[id] = fn(state.elements[id]);
    // },
    // // ===========================================================================================
    // setCompareFix(state, action) {
    //   const { id, id2, fixFn } = action.payload;
    //   console.log("SCF", action.payload);
    //   state.elements[id] = fixFn(state.elements[id], state.elements[id2]);
    // },
    // // ================================= Filetree Functions ======================================
    // initFileTree(state, action) {
    //   const { treeId, fileId, name, size } = action.payload;
    //   state.elements.trees[treeId] = [
    //     {
    //       idx: 0,
    //       id: fileId,
    //       parent: null,
    //       name: name,
    //       type: 99,
    //       children: [],
    //       empty: true,
    //       size,
    //     },
    //   ];
    // },
    // loadTree(state, action) {
    //   const { treeId, nodes } = action.payload;
    //   state.elements.trees[treeId] = nodes;
    // },
    // removeTree(state, action) {
    //   const treeId = action.payload;
    //   delete state.elements.trees[treeId];
    // },
    // loadTreeNodes(state, action) {
    //   const { treeId, fileId, newFiles } = action.payload;
    //   const tree = state.elements.trees[treeId];
    //   const node = tree.find((x) => x.id === fileId);

    //   for (let newNode of newFiles) {
    //     // console.log('newNode',newNode)
    //     node.children.push(newNode.id);
    //     newNode.parent = fileId;
    //     newNode.children = [];
    //     newNode.selected = node.selected;
    //     newNode.empty = !node.selected;
    //     tree.push(newNode);
    //   }

    //   node.loaded = true;
    //   node.expanded = true;
    // },
    // loadRSTreeNode(state, action) {
    //   const { treeId, idx, children, expand } = action.payload;
    //   console.log("RS LOAD", action.payload);
    //   const tree = state.elements.trees[treeId];
    //   const node = tree.find((x) => x.idx === idx);
    //   console.log("node", node.name);

    //   for (const child of children) {
    //     const newIdx = tree.length;
    //     let newNode = {
    //       idx: newIdx,
    //       parentIdx: idx,
    //       name: child.Name,
    //       isDir: child.IsDir,
    //       status: child.Status,
    //       children: [],
    //       backupMode: node.backupMode === 3 ? 3 : 0,
    //     };
    //     tree.push(newNode);
    //     node.children.push(newIdx);
    //   }
    //   node.expanded = expand;
    //   node.loaded = true;
    // },
    // expandTreeNode(state, action) {
    //   const { treeId, fileId = null, idx } = action.payload;
    //   const node = state.elements.trees[treeId].find((x) => {
    //     return x.id === fileId || x.idx === idx;
    //   });
    //   if (node.expanded) {
    //     node.expanded = false;
    //   } else {
    //     node.expanded = true;
    //   }
    // },
    // selectGroupPickerNode(state, action) {
    //   const { treeId, newIdx, prevIdx } = action.payload;
    //   state.elements.trees[treeId][prevIdx].selected = false;
    //   state.elements.trees[treeId][newIdx].selected = true;
    // },
    // toggleFileSelectionNode(state, action) {
    //   const { treeId, fileId } = action.payload;
    //   const node = state.elements.trees[treeId].find((x) => x.id === fileId);

    //   if (node.selected) {
    //     node.selected = false;
    //   } else {
    //     node.selected = true;
    //   }
    // },
    // toggleRemoteSelectionNode(state, action) {
    //   const { treeId, idx } = action.payload;
    //   const tree = state.elements.trees[treeId];
    //   const node = tree.find((x) => x.idx === idx);

    //   switch (node.backupMode) {
    //     case 0:
    //       setSelectionRecursive(tree, idx, true);
    //       break;
    //     case 1:
    //       node.backupMode = 2;
    //       break;
    //     case 2:
    //       setSelectionRecursive(tree, idx, true);
    //       break;
    //     case 3:
    //       setSelectionRecursive(tree, idx, false);
    //       break;
    //     default:
    //       break;
    //   }
    //   calculateSelectionModes(tree, 0);
    //   //   node.merged = true;
    // },
    // changeTree(state, action) {
    //   const { treeId, fileId, change } = action.payload;
    //   let tree = state.elements.trees[treeId];
    //   let node = tree.find((x) => x.id === fileId);
    //   change(tree, node);
    // },
    // // ===================================================================
  },
});

export const UISliceActions = UISlice.actions;
export default UISlice.reducer;
