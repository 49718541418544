import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import { sortObjByKey } from "../../../utility/sortObjByKey";
import AutoGrid from "../Wrappers/AutoGrid";
import AutoHelp from "../Wrappers/AutoHelp";
import useTrees from "../../../hooks2/useNewTrees";
import { AutocompleteFilter2 } from "./utils";
import { theme } from "../../../App";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import {
  groupsTreeId,
  selectedGroupId,
  selectedGroupIdx,
  selectedGroupId2,
  selectedGroupIdx2,
  getGroupsListId,
} from "../../../store/actions2/GroupTraveler/loadGroupsTree";

const UIText = {
  "es-CL": {
    normalTxt: "Buscar grupo",
    loadingTxt: "Cargando...",
    emptyTxt: "No se encontró usuarios",
    errorTxt: "Error al cargar los usuarios",
  },
  "en-US": {
    normalTxt: "Search group",
    loadingTxt: "Loading...",
    emptyTxt: "No users found",
    errorTxt: "Error while loading users",
  },
};

export const userPickerId = "userPicker";
export const fieldValueUI = "fieldValue";

const GroupPicker = (props) => {
  const { onChange, disabled, xs, travelerType } = props;
  const { dispatch, settings } = useUIBoilerplate();
  const inputRef = useRef(null);
  const [fieldValue, fieldChange] = useState("");
  const trees = useTrees();
  const groups = useMemo(() => {
    if (trees) {
      return trees[groupsTreeId] ?? [];
    } else {
      return [];
    }
  }, [trees]);
  const { normalTxt, loadingTxt, emptyTxt, errorTxt, tooltip } =
    UIText[settings.locale];
  const { done, working, error } = useWorker(getGroupsListId); //make dynamic

  const options = useMemo(() => {
    return [...groups]
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return { label: data.name, id: data.idx };
      });
  }, [groups]);

  let label = normalTxt;
  if (done && !error && !options.length) {
    label = emptyTxt;
  } else if (done && error) {
    label = errorTxt;
  } else if (working) {
    label = loadingTxt;
  }

  const handleChange = (e, value) => {
    const group = groups[value.id];
    if (travelerType === "topbar") {
      dispatch(UIactions.setValue({ id: selectedGroupIdx, value: group.idx }));
      dispatch(UIactions.setValue({ id: selectedGroupId, value: group.id }));
    } else if (travelerType === "modal") {
      dispatch(UIactions.setValue({ id: selectedGroupIdx2, value: group.idx }));
      dispatch(UIactions.setValue({ id: selectedGroupId2, value: group.id }));
    }
    if (onChange) {
      onChange(value);
    }
  };

  const handleInputChange = (e, value, reason) => {
    fieldChange(value);
  };

  const handleKeyDown = (e, b) => {
    if (e.key === "Enter") {
      const filteredOptions = AutocompleteFilter2(options, {
        inputValue: fieldValue,
      });
      if (filteredOptions.length === 1) {
        const group = groups[filteredOptions[0].id];
        if (travelerType === "topbar") {
          dispatch(
            UIactions.setValue({ id: selectedGroupIdx, value: group.idx })
          );
          dispatch(
            UIactions.setValue({ id: selectedGroupId, value: group.id })
          );
        } else if (travelerType === "modal") {
          dispatch(
            UIactions.setValue({ id: selectedGroupIdx2, value: group.idx })
          );
          dispatch(
            UIactions.setValue({ id: selectedGroupId2, value: group.id })
          );
        }
        e.preventDefault();
        inputRef.current.blur();
      }
    }
  };

  const newTheme = createTheme(theme, {
    palette: {
      action: {
        hover: "rgba(0, 167, 132 , 0.15)",
        focus: "rgba(0, 167, 132 ,0.15)",
      },
    },
  });

  return (
    <ThemeProvider theme={newTheme}>
      <AutoGrid {...{ xs }}>
        <AutoHelp {...{ tooltip, disabled: disabled || working }}>
          <Autocomplete
            blurOnSelect
            disableClearable
            fullWidth
            {...{
              disabled: options.length > 1 ? false : true,
              value: null,
              filterOptions: AutocompleteFilter2,
              onChange: handleChange,
              onInputChange: handleInputChange,
              onKeyDown: handleKeyDown,
              options,
              size: "small",
              loading: working,

              // loadingText: "Cargando usuarios...",
              // noOptionsText: "No se encontró usuarios",

              renderOption: (props, option) => {
                return (
                  <li {...props} key={option.id} sx={{}}>
                    <Typography noWrap fontSize={13}>
                      {option.label}
                    </Typography>
                  </li>
                );
              },
              renderInput: (params) => {
                params.InputProps.startAdornment = (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                );
                if (working) {
                  params.InputProps.endAdornment = (
                    <InputAdornment position="end">
                      <CircularProgress size={14} />
                    </InputAdornment>
                  );
                }
                return (
                  <TextField {...params} inputRef={inputRef} label={label} />
                );
              },
              isOptionEqualToValue: (option, value) => option.id === value.id,
            }}
          />
        </AutoHelp>
      </AutoGrid>
    </ThemeProvider>
  );
};

export default GroupPicker;
