import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";
import BasicHeader from "../../../UI2/BasicLayout/Components/Header";
import Back from "./Components/Back";
import Next, { miscErrorsUI } from "./Components/Next";
import { RecoverySteps } from "../Steps";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Form from "./Components/Form/Form";
import Reset from "./Components/Reset";
import { useEffect } from "react";
import { UISliceActions } from "../../../../store/slices/UI2";
import { contactNameUI } from "./Components/Form/Components/ContactInfo/ContactName";
import { contactPhoneUI } from "./Components/Form/Components/ContactInfo/ContactPhone";
import { contactEmailUI } from "./Components/Form/Components/ContactInfo/ContactEmail";
import { deliveryOfficeUI } from "./Components/Form/Components/AddressInfo/DeliveryOffice";
import { deliveryStreetUI } from "./Components/Form/Components/AddressInfo/DeliveryStreet";
import { deliveryStreetNumberUI } from "./Components/Form/Components/AddressInfo/DeliveryStreetNumber";
import { regionSelectUI } from "./Components/Form/Components/AddressInfo/Region";
import { districtSelectUI } from "./Components/Form/Components/AddressInfo/District";
import { citySelectUI } from "./Components/Form/Components/AddressInfo/City";
import {
  deliveryWindowFromUI,
  deliveryWindowToUI,
} from "./Components/Form/Components/DeliveryWindow";
import { otherReasonUI } from "./Components/Form/Components/Reason/OtherReason";
import { reasonUI } from "./Components/Form/Components/Reason/Reasons";
import useRecoveries from "../../../../hooks2/useRecoveries";
import timeToMinutes from "../../../../utility/timeToMinutes";
import { defaultRadioSelect } from "../../../UI2/genericIDs";

const RecoveryStep2 = (props) => {
  const { dispatch, settings } = useUIBoilerplate();
  const recoveries = useRecoveries();

  const steps = RecoverySteps[settings.locale];
  const header = steps[1];

  useEffect(() => {
    const deliveryData = recoveries.deliveryData;
    if (deliveryData.contactName) {
      dispatch(
        UISliceActions.setField({
          id: contactNameUI,
          value: deliveryData.contactName,
        })
      );
    }
    if (deliveryData.contactPhone) {
      dispatch(
        UISliceActions.setField({
          id: contactPhoneUI,
          value: deliveryData.contactPhone,
        })
      );
    }
    if (deliveryData.contactEmail) {
      dispatch(
        UISliceActions.setField({
          id: contactEmailUI,
          value: deliveryData.contactEmail,
        })
      );
    }
    if (deliveryData.deliveryOffice) {
      dispatch(
        UISliceActions.setField({
          id: deliveryOfficeUI,
          value: deliveryData.deliveryOffice,
        })
      );
    }
    if (deliveryData.deliveryStreet) {
      dispatch(
        UISliceActions.setField({
          id: deliveryStreetUI,
          value: deliveryData.deliveryStreet,
        })
      );
    }
    if (deliveryData.deliveryStreetNumber) {
      dispatch(
        UISliceActions.setField({
          id: deliveryStreetNumberUI,
          value: deliveryData.deliveryStreetNumber,
        })
      );
    }
    if (deliveryData.region) {
      dispatch(
        UISliceActions.setValue({
          id: regionSelectUI,
          value: deliveryData.region,
        })
      );
    }
    if (deliveryData.district) {
      dispatch(
        UISliceActions.setValue({
          id: districtSelectUI,
          value: deliveryData.district,
        })
      );
    }
    if (deliveryData.city) {
      dispatch(
        UISliceActions.setValue({
          id: citySelectUI,
          value: deliveryData.city,
        })
      );
    }
    if (deliveryData.deliveryWindowFrom) {
      dispatch(
        UISliceActions.setField({
          id: deliveryWindowFromUI,
          value: timeToMinutes(deliveryData.deliveryWindowFrom),
        })
      );
    }
    if (deliveryData.deliveryWindowTo) {
      dispatch(
        UISliceActions.setField({
          id: deliveryWindowToUI,
          value: timeToMinutes(deliveryData.deliveryWindowTo),
        })
      );
    }
    if (deliveryData.otherReason) {
      dispatch(
        UISliceActions.setField({
          id: otherReasonUI,
          value: deliveryData.otherReason,
        })
      );
    }
    if (deliveryData.reason) {
      dispatch(
        UISliceActions.setValue({
          id: reasonUI,
          value: deliveryData.reason,
        })
      );
    }
    if (!deliveryData.deliveryMethod){
      dispatch(
        UISliceActions.setValue({
          id: defaultRadioSelect,
          value: "link",
        })
      )
    } else {
      dispatch(
        UISliceActions.setValue({
          id: defaultRadioSelect,
          value: deliveryData.deliveryMethod,
        })
      )
    }

    return () => {
      dispatch(UISliceActions.clear(contactNameUI));
      dispatch(UISliceActions.clear(contactPhoneUI));
      dispatch(UISliceActions.clear(contactEmailUI));
      dispatch(UISliceActions.clear(defaultRadioSelect));
      dispatch(UISliceActions.clear(deliveryOfficeUI));
      dispatch(UISliceActions.clear(deliveryStreetUI));
      dispatch(UISliceActions.clear(deliveryStreetNumberUI));
      dispatch(UISliceActions.clear(regionSelectUI));
      dispatch(UISliceActions.clear(districtSelectUI));
      dispatch(UISliceActions.clear(citySelectUI));
      dispatch(UISliceActions.clear(deliveryWindowFromUI));
      dispatch(UISliceActions.clear(deliveryWindowToUI));
      dispatch(UISliceActions.clear(otherReasonUI));
      dispatch(UISliceActions.clear(reasonUI));
      dispatch(UISliceActions.clear(miscErrorsUI));
    };
  }, [dispatch,recoveries.deliveryData]);

  return (
    <Grid
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
          overflow: "scroll",
          width: "100%",
        },
        alignContent: "start",
      }}
    >
      <Grid
        container
        item
        {...{
          alignItems: "center",
          sx: { height: "12vh" },
        }}
      >
        <Stepper activeStep={1} alternativeLabel sx={{ width: "100%" }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Card {...{ sx: { maxWidth: 800, width: "100%" } }}>
          <Grid
            container
            {...{
              sx: {
                // maxHeight: "88vh",
                width: "100%",
              },
            }}
          >
            <BasicHeader>{header}</BasicHeader>
            <Form />
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        item
        {...{
          justifyContent: "space-between",
          alignItems: "center",
          sx: { height: "6vh" },
        }}
      >
        <Back />
        <Reset />
        <Next />
      </Grid>
    </Grid>
  );
};

export default RecoveryStep2;
