import { Dialog } from "@mui/material";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import RequestSummary from "./Components/RequestSummary";
import Keep from "./Components/Keep";
import Reset from "./Components/Reset";

const UIText = {
  "es-CL":
    "Comenzar una solicitud de recuperación nueva o continuar con la existente?",
  "en-US": "Start a new recovery request or continue with the existing one?",
};

const ResetRequest = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const header = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <RequestSummary />
      </BasicDialogContent>
      <BasicDialogActions>
        <Reset />
        <Keep />
      </BasicDialogActions>
    </Dialog>
  );
};

export default ResetRequest;
