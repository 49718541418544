/**
 * Represents a slim alert component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.errors - The array of error messages.
 * @param {Array} props.messages - The array of messages.
 * @param {string} props.severity - The severity of the alert (default: "error").
 * @param {number} props.xl - The number of columns the component should occupy on extra large screens.
 * @param {number} props.lg - The number of columns the component should occupy on large screens.
 * @param {number} props.md - The number of columns the component should occupy on medium screens.
 * @param {number} props.sm - The number of columns the component should occupy on small screens.
 * @param {number} props.xs - The number of columns the component should occupy on extra small screens.
 * @param {Object} props.sx - The additional styles for the component.
 * @param {boolean} props.fuseTop - Whether to fuse the top border of the alert (default: false).
 * @param {boolean} props.notCollapse - Whether to disable the collapse animation (default: false).
 * @param {number} props.paddingTop - The top padding of the component.
 * @param {number} props.paddingBottom - The bottom padding of the component.
 * @returns {JSX.Element} The rendered slim alert component.
 */
import { Alert, Collapse, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import AutoGrid from "./Wrappers/AutoGrid";

const timeout = 200;

const SlimAlert = (props) => {
  const {
    errors = [],
    messages = [],
    severity = "error",
    xl,
    lg,
    md,
    sm,
    xs,
    sx,
    fuseTop,
    notCollapse,
    paddingTop,
    paddingBottom,
  } = props;
  const [bufferedError, setError] = useState(null);
  const [bufferedSeverity, setSeverity] = useState(undefined);
  const [open, setOpen] = useState(false);

  const msgs = messages.length ? messages : errors;

  const finalMessages = useMemo(() => {
    let resultingErrors = [];
    for (const m of msgs) {
      if (m && !resultingErrors.find((x) => x === m)) {
        resultingErrors.push(m);
      }
    }

    if (resultingErrors.length === 1) {
      return resultingErrors[0];
    } else if (resultingErrors.length > 1) {
      const list = resultingErrors.map((err, idx) => <li key={idx}>{err}</li>);
      return <ul>{list}</ul>;
    } else {
      return null;
    }
  }, [msgs]);


  useEffect(() => {
    if (finalMessages) {
      setError(finalMessages);
      setSeverity(severity);
      setOpen(true);
    }
  }, [setError, setOpen, setSeverity, finalMessages,severity]);

  useEffect(() => {
    if (!finalMessages) {
      setOpen(false);
    }
  }, [setOpen, finalMessages]);

  const handleExit = () => {
    // if (!open) {
      setSeverity(undefined);
      setError(null);
    // }
  };

  // console.log('XXX',bufferedError)
  let color;
  switch (bufferedSeverity) {
    case "info":
      color = "blue";
      break;
    case "error":
      color = "red";
      break;
    case "success":
      color = "green";
      break;
    case "warning":
      color = "orange";
      break;
    case "disabled":
      color = "grey";
      break;
    default:
      color = "grey";
  }

  // if (!bufferedError) {
  //   return null;
  // } else {
    return (
      <AutoGrid item {...{ xl, lg, md, sm, xs, sx }}>
        <Collapse
          unmountOnExit
          {...{
            in: open || (notCollapse && !!bufferedError),
            sx: { width: "100%" },
            timeout,
            onExited: handleExit,
          }}
        >
          <Grid container item sx={{ width: "100%",paddingTop,paddingBottom }}>
            <Alert
              {...{
                severity:bufferedSeverity,
                sx: {
                  padding: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                  width: "100%",
                  border: `1px solid ${color}`,
                  borderRadius: fuseTop ? "0px 0px 4px 4px" : undefined,
                  borderTop: fuseTop ? "0px" : undefined,
                  alignItems: "center",
                  "& ul": {
                    margin: 0,
                  },
                },
              }}
            >
              {bufferedError}
            </Alert>
          </Grid>
        </Collapse>
      </AutoGrid>
    );
  // }
};

export default SlimAlert;
