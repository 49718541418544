import { validatePassword } from "./stringValidations2";

const UIText = {
  "es-CL": {
    error1: "La contraseña no puede esta vacía",
    error2: 'La contraseña debe tener un largo mínimo de 10 caracteres y contener al menos una letra mayuscula, minuscula y un numero',
  },
  "en-US": {
    error1: "The email cannot be empty",
    error2: 'The password must be at least 10 characters long and include uppercase, lowercase and numbers',
  },
};

const notEmptyPassword = (password, locale) => {
  const { error1, error2 } = UIText[locale];

  if (!password) {
    return error1
  }
  if (!validatePassword(password)) {
    return error2
  }
  return "";
};

export default notEmptyPassword;
