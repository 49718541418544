import { Grid, ListItem, ListItemButton } from "@mui/material";
import Actions from "./Components/Actions";
import Name from "./Components/Name";
import FileDate from "./Components/FileDate";
import Size from "./Components/Size";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { warehouseSliceActions as warehouse } from "../../../../store/slices/warehouse";
import { breadcrumbsId } from "../../../../store/actions2/Devices/getFiles";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useURLQuery from "../../../../hooks2/useURLQuery";
import downloadFile from "../../../../store/actions2/Devices/downloadFile";

const fontSize = 13;

const FilesRow = (props) => {
  const { id, created, size, type, repo, deleted  } = props;
  const { dispatch, navigate, auth,settings } = useUIBoilerplate();
  const { user, mid } = useURLQuery();
  const locale = settings.locale;

  const gridProps = {
    alignItems: "center",
    columnGap: 0.5,
    sx: {
      fontSize: 20,
      height: "6vh",
      paddingLeft: "2vh",
      paddingRight: "2vh",
      borderBottom: "1px solid lightGrey",
      width: "100%",
    },
  };

  const handleDownload = () => {
    console.log("handling donwload!");
    dispatch(
      downloadFile({
        ...auth,
        fileId: id,
        repoId: repo,
        type,
        deleted,
        locale,
      })
    );
  };

  const onClick = () => {
    if (type === 0 || type === 99) {
      if (type === 99) {
        dispatch(warehouse.unload(breadcrumbsId));
      }
      dispatch(workers.clearGroup("file"));
      dispatch(warehouse.unload("files"));
      dispatch(UIactions.clear("filter"));
      navigate(
        "?user=" +
          encodeURIComponent(user) +
          "&mid=" +
          mid +
          "&repo=" +
          repo +
          "&file=" +
          id
      );
    } else {
      handleDownload();
    }
  };

  return (
    <ListItem disablePadding secondaryAction={<Actions {...props} {...{handleDownload,auth, locale,repo}}/>}>
      <ListItemButton
        {...{
          onClick,
          sx: { padding: 0, "&.MuiListItemButton-root": { paddingRight: 0 } },
        }}
      >
        <Grid container {...gridProps}>
          <Grid
            container
            item
            {...{ columnSpacing: 1, xs: 6, alignItems: "center" }}
          >
            <Name {...props} {...{ fontSize }} />
          </Grid>
          <Grid container item {...{ xs: 3 }}>
            <FileDate {...{ created, fontSize, locale }} />
          </Grid>
          <Grid container item {...{ xs: 2 }}>
            <Size {...{ size, fontSize }} />
          </Grid>
          <Grid item xs={true}></Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
};

export default FilesRow;
