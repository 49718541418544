import { useLocation } from "react-router-dom";
// import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import DrawerButton from "./DrawerButton";
import { LocalShipping } from "@mui/icons-material";

const UIText = {
  "es-CL": { label: "Recuperación" },
  "en-US": { label: "Recovery" },
};

// This component generates the button for the Recovery section of the Drawer
const Recovery = () => {
  const location = useLocation().pathname.split("/")[2];
  const selected = location === "recovery";
  const navTo = `/dashboard/recovery/step1`;
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <LocalShipping {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Recovery;
