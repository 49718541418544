import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": {
    starting: "Eliminando grupo...",
    success: "Grupo eliminado exitosamente",
    error: "Error al eliminar grupo: ",
  },
  "en-US": {
    starting: "Deleting group...",
    success: "Group deleted successfully",
    error: "Error deleting group: ",
  },
};

export const deleteGroupId = "deleteGroup";

export const deleteGroup = (data) => {
  return async (dispatch) => {
    const { group, cloner_key, server, locale } = data;
    const { success, error } = UIText[locale];

    dispatch(worker.start(deleteGroupId));
    try {
      // GET group data
      // console.log("data", data);
      const url = server + "api/groups";
      await axios.delete(url, { params: { group }, headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id: deleteGroupId }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id: deleteGroupId, error: e.message }));
    }
  };
};
