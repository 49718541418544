import { Divider as MUIDivider } from "@mui/material";
import AutoGrid from "./Wrappers/AutoGrid";

// Divider
const VerticalDivider = (props) => {
  const { xs = 12, color } = props;

  return (
    <AutoGrid {...{ xs }}>
      <MUIDivider flexItem {...{orientation:"vertical", sx:{bgcolor: color}}}/>
    </AutoGrid>
  );
};

export default VerticalDivider;
