import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import TextField from "../../../../UI2/TextField";
import Divider from "../../../../UI2/Divider";
import useRecoveries from "../../../../../hooks2/useRecoveries";

const UITextG = {
  "es-CL": {
    subtitle1: "Administrador solicitante",
    subtitle2: "Contacto de recepción",
    subtitle3: "Dirección de envío",
    subtitle4: "Horario de recepción",
    subtitle5: "Metodo de envío:",
    contactName: "Nombre",
    contactPhone: "Teléfono",
    contactEmail: "Correo electrónico",
    mode1: "Vínculo de descarga",
    mode2: "Disco duro",
  },
  "en-US": {
    subtitle1: "Requesting administrator",
    subtitle2: "Reception contact",
    subtitle3: "Shipping address",
    subtitle4: "Reception schedule",
    subtitle5: "Delivery method:",
    contactName: "Name",
    contactPhone: "Phone",
    contactEmail: "Email",
    mode1: "Download link",
    mode2: "Hard drive",
  },
};

export const adminNameUI = "adminName";
export const adminUserUI = "adminUser";
export const organizationUI = "organization";

const DeliverySummary = () => {
  const { auth, settings } = useUIBoilerplate();
  const requestData = useRecoveries() ?? {};
  const { deliveryData } = requestData ?? {};
  const locale = settings.locale;
  const {
    subtitle1,
    subtitle2,
    subtitle3,
    subtitle4,
    subtitle5,
    contactName,
    contactPhone,
    contactEmail,
    mode1,
    mode2,
  } = UITextG[locale];

  const UIText1 = {
    "es-CL": { label: "Nombre", placeholder: auth.name },
    "en-US": { label: "Name", placeholder: auth.name },
  };

  const UIText2 = {
    "es-CL": { label: "Usuario", placeholder: auth.user },
    "en-US": { label: "User", placeholder: auth.user },
  };

  const UIText3 = {
    "es-CL": { label: "Organización", placeholder: auth.groupName },
    "en-US": { label: "Organization", placeholder: auth.groupName },
  };

  return (
    <Grid
      container
      item
      xs={12}
      {...{ padding: 2, rowGap: 2, columnSpacing: 2 }}
    >
      <Grid
        container
        item
        {...{ alignItems: "center", xl: 2, lg: 3, md: 12, sm: 12, xs: 12 }}
      >
        <Typography fontWeight="bold">{subtitle1}</Typography>
      </Grid>
      <TextField
        fullWidth
        forceShrink
        {...{
          id: adminNameUI,
          disabled: auth.admin !== 1,
          UIText: UIText2,
          xl: 2,
          lg: 3,
          md: 4,
          sm: 12,
          xs: 12,
        }}
      />
      <TextField
        fullWidth
        forceShrink
        {...{
          id: adminUserUI,
          disabled: auth.admin !== 1,
          UIText: UIText1,
          xl: 2,
          lg: 3,
          md: 4,
          sm: 12,
          xs: 12,
        }}
      />
      <TextField
        fullWidth
        forceShrink
        {...{
          id: organizationUI,
          disabled: auth.admin !== 1,
          UIText: UIText3,
          xl: 2,
          lg: 3,
          md: 4,
          sm: 12,
          xs: 12,
        }}
      />
      <Divider {...{ xs: 12 }} />
      <Grid container item {...{ xs: 12 }}>
        <Typography fontWeight="bold">{subtitle2}</Typography>
      </Grid>
      <Grid
        container
        item
        {...{ wrap: "nowrap", xl: 2, lg: 3, md: 4, sm: 12, xs: 12 }}
        columnGap={2}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactName}:
        </Typography>
        <Typography noWrap>{deliveryData.contactName}</Typography>
      </Grid>
      <Grid
        container
        item
        {...{ wrap: "nowrap", xl: 2, lg: 3, md: 4, sm: 12, xs: 12 }}
        columnGap={2}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactPhone}:
        </Typography>
        <Typography noWrap>{deliveryData.contactPhone}</Typography>
      </Grid>
      <Grid
        container
        item
        {...{ wrap: "nowrap", xl: 8, lg: 6, md: 4, sm: 12, xs: 12 }}
        columnGap={2}
      >
        <Typography fontWeight="bold" fontSize="0.95rem">
          {contactEmail}:
        </Typography>
        <Typography noWrap>{deliveryData.contactEmail}</Typography>
      </Grid>
      <Grid container item {...{ xs: 12, columnGap:2}}>
        <Typography fontWeight="bold">{subtitle5}</Typography>
        <Typography>
          {deliveryData.recoveryMode === "hdd" ? mode2 : mode1}
        </Typography>
      </Grid>
      {deliveryData.recoveryMode === "hdd" ? (
        <Grid container item {...{ xs: 12, gap: 2 }}>
          <Typography fontWeight="bold">{subtitle3}:</Typography>
          <Typography>
            {deliveryData.deliveryStreet} {deliveryData.deliveryStreetNumber}{" "}
            {deliveryData.deliveryOffice
              ? `( ${deliveryData.deliveryOffice} )`
              : ""}
            , {deliveryData.district}, {deliveryData.city},{" "}
            {deliveryData.region}.
          </Typography>
        </Grid>
      ) : null}
      {deliveryData.recoveryMode === "hdd" ? (
        <Grid container item {...{ xs: 12, columnGap: 2 }}>
          <Typography fontWeight="bold">{subtitle4}:</Typography>
          <Typography>
            {deliveryData.deliveryWindowFrom} - {deliveryData.deliveryWindowTo}
          </Typography>
        </Grid>
      ) : null}
      {/* <Divider {...{ xs: 12 }} /> */}
    </Grid>
  );
};

export default DeliverySummary;
