import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { humanBytes, timeDiff } from "../../../../../utility/humanizers";

const UIText = {
  "es-CL": {
    size: "Tamaño",
    bkp: "Último respaldo",
    never: "Nunca completado",
  },
  "en-US": {
    subtitle: "Details",
    size: "Size",
    bkp: "Last backup",
    never: "Never completed",
  },
};
const Details = ({ full_size, lastBkp }) => {
  const { settings} = useUIBoilerplate();
  const locale = settings.locale;
  const { size, bkp, never } = UIText[locale];

  return (
    <Grid container item {...{ xs: 12 }}>
      <Grid container item xs={6} columnGap={2}>
        <Typography fontWeight="bold">{size}:</Typography>
        <Typography> {humanBytes(full_size)}</Typography>
      </Grid>
      <Grid container item xs={6} columnGap={2}>
        <Typography fontWeight="bold">{bkp}:</Typography>
        <Typography>
          {" "}
          {lastBkp >= 0 ? timeDiff(Date.now() - lastBkp, locale) : never}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Details;
