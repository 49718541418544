import { useDispatch } from "react-redux";
import useURLQuery from "../../../../hooks2/useURLQuery";
import Button from "../../../UI2/Button";
import { UISliceActions } from "../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Volver a version anterior" },
  "en-US": { label: "Return to previous version" },
};

const LegacyBtn = (props) => {
  const dispatch = useDispatch()
  const { locale: lang = "es-CL" } = useURLQuery();

  const onClick = () => {
    dispatch(UISliceActions.openModal({ type: "" }))
  };

  return (
      <Button
        {...{ UIText, lang, onClick  }}
      />
  );
};

export default LegacyBtn;
