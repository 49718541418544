import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import { getMailId, mailData } from "../../../store/actions2/Mails/getMail";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import { useRef } from "react";

export const iframeHeightUI = "frameHeight";

const Mail = () => {
  const iframeRef = useRef(null);
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const { done, error } = useWorker(getMailId);
  const mailContent = warehouse[mailData] ?? {};
  const { payload = {} } = UI.modal ?? {};
  const { subject } = payload;
  const height = UI[iframeHeightUI] ?? 0

  const frameLoaded = () => {
    const iframeElement = iframeRef.current;
    const value =
      iframeElement.contentWindow.document.body.scrollHeight + 30 + "px";
    dispatch(UIactions.setValue({ id: iframeHeightUI, value }));
  };

  if (done && !error) {
    return (
      <Grid
        container
        item
        {...{ sx: { maxHeight: "75vh", overflow: "scroll" }, xs: 12 }}
      >
        <iframe
          title={subject}
          ref={iframeRef}
          onLoad={frameLoaded}
          style={{ height }}
          srcDoc={mailContent.content}
          width="100%"
          frameBorder="0"
        ></iframe>
      </Grid>
    );
  }

  return null
};

export default Mail;
