import { AddBoxOutlined, ArrowDropDown, CheckBoxOutlined, CropSquareOutlined, IndeterminateCheckBoxOutlined, Remove } from "@mui/icons-material";

export const BoxStateIcon = (backupMode) => {
  switch (backupMode) {
    case 0:
      return <CropSquareOutlined sx={{ fontSize: 17, marginRight: 1 }} />;
    case 1:
      return (
        <IndeterminateCheckBoxOutlined
          color="warning"
          sx={{ fontSize: 17, marginRight: 1 }}
        />
      );
    case 2:
      return (
        <AddBoxOutlined color="success" sx={{ fontSize: 17, marginRight: 1 }} />
      );
    case 3:
      return (
        <CheckBoxOutlined color="info" sx={{ fontSize: 17, marginRight: 1 }} />
      );
    default:
      break;
  }
};

export const ExpandIcon = (expand) => {
  if (expand) {
    return <ArrowDropDown color="disabled" sx={{ marginRight: 1 }} />;
  } else {
    return <Remove color="disabled" sx={{ marginRight: 1 }} />;
  }
};
