import { DialogTitle, Grid } from "@mui/material";
import FilterFrom from "./FilterFrom";
import FilterTo from "./FilterTo";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { header: "Versiones antiguas" },
  "en-US": { header: "Old versions" },
};

const Header = () => {
  const { settings } = useUIBoilerplate();
  const { header } = UIText[settings.locale];

  return (
    <DialogTitle>
      <Grid container justifyContent="space-between" alignItems="center">
        {header}
        <Grid container item xs="auto" columnGap={1}>
          <FilterFrom />
          <FilterTo />
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default Header;
