export const options = [
  { name: { universal: "\\Users\\*\\AppData" }, value: "\\Users\\*\\AppData" },
  {
    name: { universal: "\\Users\\*\\AppData\\Local\\Google\\Chrome" },
    value: "\\Users\\*\\AppData\\Local\\Google\\Chrome",
  },
  {
    name: { universal: "\\System Volume Information" },
    value: "\\System Volume Information",
  },
  {
    name: { universal: "\\Users\\*\\OneDrive" },
    value: "\\Users\\*\\OneDrive",
  },
  { name: { universal: "\\Users\\*\\Dropbox" }, value: "\\Users\\*\\Dropbox" },
  {
    name: { universal: "\\Users\\*\\Music\\iTunes" },
    value: "\\Users\\*\\Music\\iTunes",
  },
  {
    name: { universal: "\\Users\\*\\Documents\\OneDrive" },
    value: "\\Users\\*\\Documents\\OneDrive",
  },
];
