import BasicHeaders from "../../UI2/BasicLayout/Components/BasicHeaders"

const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "Nombre" },
        "en-US": { label: "Name" },
      },
      filterKey: "name",
      xs: 6,
    },
    {
      UIText: {
        "es-CL": { label: "Fecha" },
        "en-US": { label: "Date" },
      },
      filterKey: "created",
      xs: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Tamaño" },
        "en-US": { label: "Size" },
      },
      filterKey: "size",
      xs: 2,
    },
    {
      UIText: {
        "es-CL": { label: "Mas" },
        "en-US": { label: "More" },
      },
      justify: "end",
      filterKey: "",
      xs: true,
    },
  ],
};

const Headers = ()=>{
    return (
        <BasicHeaders {...headersData}/>
    )
}

export default Headers