/**
 * Renders a text field component for entering a username.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.disabled - Determines if the text field is disabled.
 * @param {boolean} props.isNew - Determines if the username is for a new user.
 * @returns {JSX.Element} The rendered Username component.
 */
import notEmptyField from "../../../utility/validations/notEmpty";
import TextField from "../../UI2/SimpleTextField";

const UIText = {
  "es-CL": { label: "Nombre del usuario", placeholder: "Juan Soto" },
  "en-US": { label: "Username", placeholder: "John Doe" },
};

export const usernameUI = "username";

const Username = (props) => {
  const { disabled, isNew } = props;

  return (
    <TextField
      tagError
      {...{
        id: usernameUI,
        UIText,
        required: isNew,
        validation: notEmptyField,
        disabled,
        xs: 12,
      }}
    />
  );
};

export default Username;
