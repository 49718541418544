import Button from "../../../UI2/Button";
import { useWorker } from "../../../../hooks2/useWorker";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import deleteClient, { deleteClientId } from "../../../../store/actions2/Windows365/deleteClient";

const UIText = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const Confirm = () => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const locale = settings.locale;
  const { working } = useWorker(deleteClientId);

  const onClick = () => {
    dispatch(deleteClient({ ...auth, locale }));
  };

  return <Button {...{ UIText, onClick, working }} />;
};

export default Confirm;
