import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";
import updateExclusions, {
  updateExclusionsUI,
} from "../../../../../store/actions2/Devices/updateExclusions";
import Button from "../../../../UI2/Button";
import { selectedCommonUI } from "../../Category/Category";
import { customExtensionsUI } from "../../CustomExtensions/Top/NewExtension";
import { exclusionDevicesData } from "../../DevicesList/DevicesList";
import { exclusionDevicesUI } from "../../DevicesList/Headers2";
import { problematicUI } from "../../Problematic/Problematic";
import { useEffect } from "react";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Save = () => {
  const { auth, dispatch, navigate, UI, warehouse, settings } = useUIBoilerplate();
  const { done, working, error } = useWorker(updateExclusionsUI);
  const locale = settings.locale;
  const customExtensions = UI[customExtensionsUI] ?? [];
  const standartExtensions = UI[selectedCommonUI] ?? [];
  const problematicExtensions = UI[problematicUI] ?? [];
  const exclusionDevices = UI[exclusionDevicesUI] ?? [];
  const devices = warehouse[exclusionDevicesData];

  useEffect(()=>{
    if (done && !error){
        navigate(-1)
    }
  },[navigate,done,error])


  const handleClick = () => {
    // save all
    let newExclusionsArray = [
      ...standartExtensions.map((x) => `*.${x}`),
      ...customExtensions.map((x) => `*.${x}`),
      ...problematicExtensions,
    ];
    let newExclusions = newExclusionsArray.join("\n");

    let updateData = [];
    for (const mid of exclusionDevices) {
      const device = devices.find((x) => x.mid === mid);
      if (device) {
        updateData.push({
          user: device.user,
          mid,
          exclusions: newExclusions,
          token: auth.token,
        });
      }
    }
    dispatch(updateExclusions({ ...auth, updateData, locale }));
  };

  return <Button {...{ UIText, onClick: handleClick, working, xs: "auto" }} />;
};

export default Save;
