import { Dialog, Grid } from "@mui/material";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import {
  UISliceActions as UIactions,
  UISliceActions,
} from "../../store/slices/UI2";
import TopBar from "./Components/TopBar/TopBar";
import Create from "./Components/Create";
import Cancel from "./Components/Cancel";
import UsersList, { sortId } from "./Components/UsersList/UsersList";
import { newUsersUI } from "./Components/TopBar/UploadSheet";
import Empty from "./Components/Empty";
import { useEffect, useMemo } from "react";
import { useWorker } from "../../hooks2/useWorker";
import { postUserId } from "../../store/actions2/UserForm/postUsers";
import {
  workersSliceActions as workers,
  workersSliceActions,
} from "../../store/slices/workers";
import { rowErrorsUI } from "./Components/UsersList/UserRow/UserRow";
import {
  getOrganizations,
  getW365OrgsId,
  w365orgsData,
} from "../../store/actions2/Windows365/getOrganizations";
import getClients, {
  getW365ClientsId,
  w365clientsData,
} from "../../store/actions2/Windows365/getClients";
import { warehouseSliceActions } from "../../store/slices/warehouse";
import { getW365OrgId } from "../../store/actions2/Windows365/getOrganization";
import {
  getPlatformAndServicesId,
  getPlatformsAndServices,
  platformServices,
} from "../../store/actions2/Windows365/getPlatformsAndServices";
import { bkpOneArrayId } from "./Components/UsersList/UserRow/BackupOnedrive";
import { bkpMailArrayId } from "./Components/UsersList/UserRow/BackupMail";
import { mailLocksId } from "./Components/UsersList/UserRow/W365mail";

const UITextHeader = {
  "es-CL": "Respaldar información de nubes externas",
  "en-US": "Backup external cloud information",
};

const UsersCloudIntegration = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { cloner_key, W365server } = auth;
  const { done } = useWorker(postUserId);
  const { done: done2 } = useWorker(getW365ClientsId);
  const { done: done3 } = useWorker(getPlatformAndServicesId);
  const locale = settings.locale;
  const header = UITextHeader[locale];
  const { payload } = UI.modal;
  const { group, users = [] } = payload;
  // console.log("warehouse", warehouse);
  const w365organizations = warehouse[w365orgsData] ?? {};
  const clients = warehouse[w365clientsData] ?? {};
  const services = warehouse[platformServices] ?? [];

  let uniqueGroups = useMemo(() => {
    let x = [];
    for (const user of users) {
      if (!x.includes(user.group)) {
        x.push(user.group);
      }
    }
    return x;
  }, [users]);

  useEffect(() => {
    dispatch(
      getOrganizations({ groups: uniqueGroups, cloner_key, W365server, locale })
    );
  }, [dispatch, uniqueGroups, cloner_key, W365server, locale]);

  useEffect(() => {
    if (!done3) {
      dispatch(getPlatformsAndServices({ W365server, cloner_key, locale }));
    }
  }, [dispatch, done3, W365server, cloner_key, locale]);

  console.log("windows365organizations", w365organizations);
  useEffect(() => {
    if (!done2) {
      console.log("trying to get clients");
      let orgIDs = [];
      for (const key in w365organizations) {
        orgIDs.push(w365organizations[key].id);
      }
      // console.log("orgIDs", orgIDs);

      if (orgIDs.length > 0) {
        dispatch(
          getClients({
            cloner_key,
            W365server,
            orgIDs,
          })
        );
      }
    }
  }, [dispatch, w365organizations, cloner_key, W365server, done2]);

  useEffect(() => {
    console.log("users", users);
    console.log("clients", clients);
    if (clients.length > 0 && users.length > 0) {
      for (const user of users) {
        for (const client of clients) {
          if (user.login === client.cloner_user) {
            console.log("match", user, client);
            dispatch(
              UISliceActions.add({
                id: mailLocksId,
                key: user.login,
              })
            );
            dispatch(
              UISliceActions.setField({
                id: "365mail-" + user.login,
                value: client.client_mail.split("@")[0],
              })
            );

            for (const cService of client.services) {
              for (const service of services) {
                if (service.id === cService.service_id) {
                  console.log("service match", cService);
                  switch (service.name) {
                    case "Microsoft Drive":
                      if (cService.enabled) {
                        dispatch(
                          UISliceActions.add({
                            id: bkpOneArrayId,
                            key: user.login,
                          })
                        );
                      } else {
                        dispatch(
                          UISliceActions.remove({
                            id: bkpOneArrayId,
                            key: user.login,
                          })
                        );
                      }
                      break;
                    case "Microsoft Mail":
                      if (cService.enabled) {
                        dispatch(
                          UISliceActions.add({
                            id: bkpMailArrayId,
                            key: user.login,
                          })
                        );
                      } else {
                        dispatch(
                          UISliceActions.remove({
                            id: bkpMailArrayId,
                            key: user.login,
                          })
                        );
                      }
                      break;
                    case "Google Drive":
                      break;
                    case "Google Mail":
                      break;
                  }
                }
              }
            }

            // let w365servicemail = services.find((x) => {
            //   if (x.name === "Microsoft Mail") {
            //     return x.id;
            //   }
            // });

            // let w365servicedrive = services.find((x) => {
            //   if (x.name === "Microsoft Drive") {
            //     return x.id;
            //   }
            // });
          }
        }
      }
    }
  }, [dispatch, clients, users]);

  // console.log("uniqueGroups", uniqueGroups);

  // console.log("users", users);

  useEffect(() => {
    if (done) {
      // dispatch(UIactions.openModal({ type: "download-creation-report" })); // THIS COULD BE USED AS-IS BUT REPURPOSING IT FOR THE NEW OPERATION
    }
  }, [dispatch, done]);

  useEffect(() => {
    return () => {
      dispatch(workersSliceActions.clear(getW365OrgsId));
      dispatch(workersSliceActions.clear(getW365ClientsId));
      dispatch(warehouseSliceActions.unload(w365clientsData));
      // dispatch(UIactions.clear(sortId));
      // dispatch(UIactions.clear(rowErrorsUI));
      // dispatch(UIactions.clear(newUsersUI));
      // dispatch(workers.clear(postUserId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    console.log("reason", reason);
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "lg" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container {...{ sx: { width: "100%" } }}>
          <TopBar {...{ users }} />
          {!!users.length ? <UsersList {...{ users }} /> : <Empty />}
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Create {...{ users }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default UsersCloudIntegration;
