import { Avatar, CircularProgress, Grid, Typography } from "@mui/material";
import Switch from "../../../UI2/Switch";
import { useWorker } from "../../../../hooks2/useWorker";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useEffect } from "react";
import {
  getOrganization,
  getW365OrgId,
  w365orgData,
} from "../../../../store/actions2/Windows365/getOrganization";
import getClient, {
  getW365ClientId,
  w365clientData,
} from "../../../../store/actions2/Windows365/getClient";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { warehouseSliceActions as warehouseActions } from "../../../../store/slices/warehouse";

const UIText = {
  "es-CL": { label: "Google Suite", noDomain: "Grupo sin dominio" },
  "en-US": { label: "Google Suite", noDomain: "Group lacks domain" },
};

export const w365Switch = "w365Switch";

const GoogleCloud = () => {
  const { auth, dispatch, settings, warehouse } = useUIBoilerplate();
  const { working } = useWorker(getW365OrgId);
  const { done, working: w2 } = useWorker(getW365ClientId);
  const locale = settings.locale;
  const { label, noDomain } = UIText[locale];

  const w365org = warehouse[w365orgData] ?? {};

  const own = w365org.own ?? {};
  const parent = w365org.parent ?? {};

  // const isRoot = parseInt(group) === auth.group;
  const availableCert = own.client_id ? own : parent;
  const domain = availableCert.mail_account
    ? availableCert.mail_account.split("@")[1]
    : null;

  const client = warehouse[w365clientData] ?? {};
  // console.log("client", client);

  useEffect(() => {
      console.log("getOrganization3 dispatched");
    dispatch(
      getOrganization({
        ...auth,
      })
    );
  }, [dispatch, auth]);

  useEffect(() => {
    if (domain !== noDomain && !done) {
      dispatch(
        getClient({
          ...auth,
        })
      );
    }
  }, [dispatch, auth, noDomain, done, domain]);

  useEffect(() => {
    if (client.client_mail) {
      dispatch(UIactions.setValue({ id: w365Switch, value: true }));
    } else {
      dispatch(UIactions.setValue({ id: w365Switch, value: false }));
    }
  }, [dispatch, client.client_mail]);

  const onChange = (value) => {
    // if (value) {
    //   dispatch(
    //     UIactions.openModal({
    //       type: "enable-w365user",
    //       payload: { domain, orgId: availableCert.id },
    //     })
    //   );
    // } else {
    //   dispatch(
    //     UIactions.openModal({
    //       type: "delete-w365user",
    //       payload: {},
    //     })
    //   );
    // }
  };

  // useEffect(() => {
  //   return () => {
  //     dispatch(warehouseActions.unload(w365orgData));
  //     dispatch(warehouseActions.unload(w365clientData));
  //     dispatch(UIactions.clear(w365Switch));
  //     dispatch(workers.clear(getW365ClientId));
  //     dispatch(workers.clear(getW365OrgId));
  //   };
  // }, [dispatch]);

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      columnGap={2}
      sx={{ height: "5vh" }}
    >
      <Grid container item xs={1} alignItems="center" justifyContent="center">
        <Switch
          disableDefault
          {...{ id: w365Switch, disabled: !domain, onChange }}
        />
      </Grid>
      <Avatar
        alt="Windows365-icon"
        src="https://fonts.gstatic.com/s/i/productlogos/drive_2020q4/v8/web-64dp/logo_drive_2020q4_color_2x_web_64dp.png"
        variant="rounded"
        sx={{ height: 30, width: 27.2 }}
      />
      <Typography fontSize={16}>
        {label}
        {working || w2
          ? null
          : `( ${domain ? "@" : ""}${domain ?? noDomain} )`}
      </Typography>
    </Grid>
  );
};

export default GoogleCloud;
