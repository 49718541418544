import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import GenericConfirmation from "./GenericConfirmation/GenericConfirmation";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { getSharedFilesId } from "../../store/actions2/Shared/getSharedFiles";
import { deleteSharedLinksId } from "../../store/actions2/Shared/deleteSharedLinks";

const DeleteSharedLinks = (props) => {
  const { dispatch, navigate, settings, UI } = useUIBoilerplate();
  const { done, error } = useWorker(deleteSharedLinksId);
  const locale = settings.locale;
  const { payload } = UI.modal ?? {};
  const { links = [] } = payload;

  console.log("links", links);

  const UIText = {
    "es-CL": {
      header:
        links.length > 1
          ? `¿Esta seguro que desea eliminar estos ${links.length} enlaces de descarga?`
          : `¿Esta seguro que desea eliminar el enlace de descarga para el archivo "${links[0].name}"?`,
      message: `Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, ${
        links.length > 1
          ? `los enlaces de descarga seran eliminados`
          : `el enlace de descarga será eliminado`
      }`,
    },
    "en-US": {
      header:
        links.length > 1
          ? `Are you sure you want delete these ${links.length} download links?`
          : `Are you sure you want delete the download link for the file "${links[0].name}"?`,
      message: `Important: Beware that this operation is definitive and irreversible. Once confirmed, the link${
        links.length > 1 ? "s" : ""
      } will be deleted`,
    },
  };
  const { header, message } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UISliceActions.closeModal());
      dispatch(UISliceActions.clear("selected"));
      dispatch(workers.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(deleteSharedLinksId));
    };
  }, [dispatch]);

  const confirmation = () => {
    let toBeDeleted = [...links];
    dispatch(
      UISliceActions.openModal2({
        type: "delete-shared-links-confirmation",
        payload: { ids: toBeDeleted },
      })
    );
  };

  return (
    <GenericConfirmation
      alternativeText
      {...{
        header,
        message,
        severity: "warning",
        confirmation,
        workerId: deleteSharedLinksId,
      }}
    />
  );
};

export default DeleteSharedLinks;
