const UIText = {
  "es-CL": {
    empty: "El campo no puede estar vacio",
  },
  "en-US": {
    empty: "The field cannot be empty",
  },
};

const notEmptyField = (value, locale) => {
  const { empty } = UIText[locale];

  if (!value) {
    return empty;
  }
  return "";
};

export default notEmptyField;
