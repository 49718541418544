import { Grid } from "@mui/material";
import { Pagination } from "../../Pagination";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { defaultPagination } from "../../genericIDs";
import useVH from "../../../../hooks2/useVH";

const BasicFooter = ({ children, noPagination }) => {
  const { UI } = useUIBoilerplate();
  const vh = useVH(6);
  const pagination = UI[defaultPagination] ?? { count: 0 };

  return (
    <Grid
      container
      item
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: vh,
        alignItems: "center",
        justifyContent: "end",
        xs: 12,
        columnGap: 2,
        // sx: { borderTop: "1px solid lightgrey" },
      }}
    >
      {children}
      {noPagination || !pagination.count ? null : (
        <Pagination {...{ xs: "auto" }} />
      )}
    </Grid>
  );
};

export default BasicFooter;
