import { Grid, Typography } from "@mui/material";

const UIText = {
  "es-CL": { label: "Contraseña" },
  "en-US": { label: "Password" },
};

const Subtitle = ({ locale }) => {
  const { label } = UIText[locale];
  return (
    <Grid container item xs={12}>
      <Typography fontWeight="bold">{label}</Typography>
    </Grid>
  );
};

export default Subtitle;
