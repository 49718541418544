import { Grid } from "@mui/material";
import ContactInfo from "./Components/ContactInfo/ContactInfo";
import Reason from "./Components/Reason/Reason";
import DeliveryMode from "./Components/DeliveryMode/DeliveryMode";

const Form = () => {

  return (
    <Grid
      container
      item
      {...{ padding: 2, columnSpacing: 2, rowGap: 2, xs: 12 }}
    >
      <ContactInfo />
      <DeliveryMode />
      {/* <DeliveryWindow /> */}
      <Reason />
    </Grid>
  );
};

export default Form;
