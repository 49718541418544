import { createSlice } from "@reduxjs/toolkit";
import {
  calculateSelection,
  calculateSelection2,
  parseFileTree,
  recursiveNodeChange,
} from "./trees2-utils";

const initialState = {
  data: {},
};
const newTreesSlice = createSlice({
  name: "newTrees",
  initialState,
  reducers: {
    // ======================= Basics ===========================
    clearForest(state) {
      state.data = {};
    },
    createTree(state, action) {
      const { id, type, data, root } = action.payload;
      state.data[id] = {
        data,
        type,
        nodes: [{ ...root, idx: 0, parentIdx: null, childrenIdx: [] }],
      };
    },
    removeTree(state, action) {
      const id = action.payload;
      delete state.data[id];
    },
    removeTreeGroup(state, action) {
      const group = action.payload;
      for (const key in state.data) {
        if (key.match(group)) {
          delete state.data[key];
        }
      }
    },
    loadTree(state, action) {
      const { id, tree } = action.payload;
      state.data[id] = tree;
    },
    toggleExpand(state, action) {
      const { id, idx } = action.payload;
      // console.log("payload", action.payload);
      const node = state.data[id][idx];
      if (node.expanded) {
        node.expanded = false;
      } else {
        node.expanded = true;
      }
    },
    setNodeKey(state, action) {
      const { id, idx, key, value } = action.payload;
      state.data[id].nodes[idx][key] = value;
    },
    // ======================= Basics ===========================
    // ===================== FileViewer =========================
    addFVNode(state, action) {
      const { id, idx, data } = action.payload;
      // console.log('payload',action.payload)

      let tree = state.data[id];

      // console.log('???',tree.data.name)
      let node = tree.nodes[idx];

      const newIdx = tree.nodes.length;

      tree.nodes.push({
        ...data,
        idx: newIdx,
        parentIdx: idx,
        childrenIdx: [],
      });
      node.childrenIdx.push(newIdx);
    },
    // ===================== FileViewer =========================
    // ===================== FileViewer Tree =========================
    toggleExpandFVT(state, action) {
      const { id, idx } = action.payload;
      // console.log("payload", action.payload);
      const node = state.data[id].nodes[idx];
      if (node.expanded) {
        node.expanded = false;
      } else {
        node.expanded = true;
      }
    },

    // ===================== FileViewer Tree =========================
    // =================== GreylistViewer =========================
    loadGreylistTree(state, action) {
      const { id, rawTree } = action.payload;

      let tree = state.data[id];

      let nodes = [];

      const rawTreeNode = {
        // idx:0,
        parentIdx: null,
        // childrenIdx:[],
        rawNodeData: rawTree,
      };

      parseFileTree(nodes, rawTreeNode);
      calculateSelection(nodes, 0);

      tree.nodes = nodes;
    },
    // =================== GreylistViewer =========================
    // ================== Remote Selection ========================
    addRSnode(state, action) {
      // console.log('adding RS node:',action.payload)
      const { id, idx, node } = action.payload;

      let tree = state.data[id];

      let parent = tree[idx];

      const newIdx = tree.length;

      let newChild = {
        idx: newIdx,
        parentIdx: idx,
        name: node.Name,
        isDir: node.IsDir,
        size: node.Size,
        status: node.Status,
        childrenIdx: [],
      };

      if (parent.backup >= 2) {
        newChild.backup = 3;
      } else if (parent.backup <= 1) {
        newChild.backup = 0;
      } else {
        console.error("this should not be happening. ever.");
      }

      parent.childrenIdx.push(newIdx);
      tree.push(newChild);
    },
    RSnodeLoaded(state, action) {
      const { id, idx } = action.payload;
      state.data[id][idx].loaded = true;
      state.data[id][idx].expanded = true;
    },
    RSnodeSelect(state, action) {
      const { id, idx } = action.payload;

      const tree = state.data[id];
      const node = tree[idx];

      if (node.backup === 3) {
        recursiveNodeChange(tree, idx, false);
      } else if (node.backup === 0 || node.backup === 2) {
        recursiveNodeChange(tree, idx, true);
      } else if (node.backup === 1) {
        node.backup = 2;
      }
      calculateSelection2(tree, 0);
    },

    // ================== Remote Selection ========================

    // clear(state, action) {
    //   const id = action.payload;
    // },
    // toggleExpand(state,action){
    //     const {treeId,nodeIdx} = action.payload
    //     const node = state.trees[treeId][nodeIdx]
    //     if (node.expanded){
    //         node.expanded = false
    //     } else {
    //         node.expanded = true
    //     }
    // },
    // set(state,action){
    //   const { treeId, idx, key, value } = action.payload;
    //   state.trees[treeId][idx][key] = value
    // },
    // changeNode(state,action){
    //   const {treeId, idx, fn} = action.payload
    //   const node = state.trees[treeId][idx]
    //   fn(node)
    // },
    // changeTree(state,action){
    //     const {treeId,fn} = action.payload
    //     const tree = state.trees[treeId]
    //     fn(tree)
    // },
    // load(state,action){
    //     const {treeId,nodes} = action.payload
    //     state.trees[treeId] = nodes
    // },
    // //======================= Group Picker ========================================
    // selectGroup(state,action){
    //   const {treeId,currentIdx,nextIdx} = action.payload

    //   console.log('XXXX',currentIdx,nextIdx)
    //   state.trees[treeId][currentIdx].selected = false
    //   state.trees[treeId][nextIdx].selected = true
    // },
    // //======================= File Picker =========================================
    // newNode(state, action) {
    //   const { treeId, node } = action.payload;
    // //   console.log('PAYLOAD',action.payload)
    //   const {parentIdx} = node
    //   const tree = state.trees[treeId]

    //   const newIdx = tree.length
    //   const newNode = {...node}
    //   newNode.idx = newIdx

    //   tree.push(newNode)

    //   if (parentIdx === null){
    //     return
    //   }

    //   tree[parentIdx].childrenIdx.push(newIdx)
    // },
  },
});

export const newTreesSliceActions = newTreesSlice.actions;
export default newTreesSlice.reducer;
