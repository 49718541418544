/**
 * Renders the core component for displaying users in a group.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.users - The array of user data.
 * @param {Array} props.allUsers - The array of all user data.
 * @param {boolean} props.done - Indicates if the data loading is done.
 * @param {boolean} props.working - Indicates if the data is being loaded.
 * @param {string} props.error - The error message, if any.
 * @param {string} props.workerId - The ID of the worker.
 * @returns {JSX.Element} The rendered core component.
 */
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { currentGroupnameId } from "../../../store/actions2/GroupTraveler/loadGroupsTree";
import BasicLayout from "../../UI2/BasicLayout/BasicLayout";
import Actions from "./Actions/Actions";
import headersData from "./Headers";
import UserRow from "./UserRow/UserRow";

const Core = (props) => {
  const { users, allUsers, done,working,error, workerId } = props;
  const { settings, UI } = useUIBoilerplate();
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": `Usuarios del grupo ${groupname}`,
    "en-US": `Users in group ${groupname}`,
  };
  const header = UIText[settings.locale];
  const plusActions = <Actions />;

  const elements = users.map((data, idx) => <UserRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": { message: "Error al cargar los usuarios: " + error },
        "en-US": { message: "Error loading the users: " + error },
      },
    },
    empty: {
      isEmpty: !users.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay usuarios disponibles" },
        "en-US": { message: "There are no users available" },
      },
    },
    done,
    elements,
  };

  return <BasicLayout {...{ plusActions, header, headersData, fullArray:allUsers, coreData }} />;
};

export default Core;
