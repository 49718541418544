import { Dialog, Typography } from "@mui/material";
import Cancel from "./Cancel";
import Confirm from "./Confirm";
import {
  UISliceActions,
  UISliceActions as UIactions,
} from "../../../store/slices/UI2";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import TextField from "../../UI2/TextField";
import { useEffect } from "react";
import BasicDialogTitle from "../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../../UI2/BasicDialog/BasicDialogActions";
import BasicDialogContent from "../../UI2/BasicDialog/BasicDialogContent";

const UIText = {
  "es-CL": {
    header: "Confirmación de eliminación",
    instructions: "Escribe ELIMINAR para continuar",
    safeguard: "ELIMINAR",
    helper: "Ingresa el texto exactamente como se indica",
  },
  "en-US": {
    header: "Deletion confirmation",
    instructions: "Type DELETE to continue",
    safeguard: "DELETE",
    helper: "Type the text exactly as it is displayed",
  },
};

const UIText2 = { universal: {} };

export const confirmationFieldId = "confirmationField";

const DeleteConfirmation = (props) => {
  const {
    confirmation = () => {
      console.log("fn()");
    },
  } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  const field = UI[confirmationFieldId] ?? {};
  const { header, instructions, safeguard, helper } = UIText[settings.locale];

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  const disabled = field.value !== safeguard;

  useEffect(() => {
    return () => {
      dispatch(UISliceActions.clear(confirmationFieldId));
    };
  }, [dispatch]);

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose,
        maxWidth: "xs",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Typography fontSize={13} paddingBottom={1}>
          {instructions}
        </Typography>
        <TextField
          autoFocus
          {...{
            id: confirmationFieldId,
            UIText: UIText2,
            helperText: helper,
            size: "medium",
            xs: 12,
          }}
        />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ disabled, confirmation }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
