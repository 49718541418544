import { CheckCircle, Error, RemoveCircle, Warning } from "@mui/icons-material";

const UIText = {
  "es-CL": {
    case0: "Usuario no tiene dispositivos",
    case1: "Respaldo al día",
    case2: "Dispositivo no ha enviado informacion nueva en mas de 4 días",
    case3: "Dispositivo no ha completado un respaldo en mas de 4 días",
    case4: "Dispositivo no se ha conectado en mas de 4 días",
    case5: "Dispositivo nunca ha completado un respaldo",
    default: "Este caso no debería estar ocurriendo",
  },
  "en-US": {
    case0: "User does not have devices",
    case1: "Backup is up-to-date",
    case2: "Device has not sent new information in more than 4 days",
    case3: "Device has not completed a backup in more than 4 days",
    case4: "Device has not connected in more than 4 days",
    case5: "Device has never completed a backup",
    default: "This should not be happening",
  },
};

const getStatus = ({status,locale})=>{
  const localized = UIText[locale]

  let icon;
  let fixedTooltip;
  switch (status) {
    case 0:
      icon = <RemoveCircle color="disabled" />;
      fixedTooltip = localized.case0
      break;
    case 1:
      icon = <CheckCircle color="primary" />;
      fixedTooltip = localized.case1
      break;
    case 2:
      icon = <Error color="error" />;
      fixedTooltip = localized.case2
      break;
    case 3:
      icon = <Error color="error" />;
      fixedTooltip = localized.case3
      break;
    case 4:
      icon = <Error color="error" />;
      fixedTooltip = localized.case4
      break;
    case 5:
      icon = <Error {...{sx:{color:"secondary.light"}}}       />;
      fixedTooltip = localized.case5
      break;
    default:
      icon = <Warning color="error" />;
      fixedTooltip = localized.default
      break;
  }

  return {icon,fixedTooltip}
 
}

export default getStatus