/**
 * A customizable text input field component.
 *
 * @param {Object} props - The props object containing configuration for the text field.
 * @param {string} props.id - The unique identifier for the text field.
 * @param {function} [props.onChange] - The function to be called when the text field value changes.
 * @param {function} [props.onBlur] - The function to be called when the text field loses focus.
 * @param {function} [props.onKeyDown] - The function to be called when a key is pressed down while the text field is in focus.
 * @param {string} [props.size="small"] - The size of the text field.
 * @param {string} [props.fontSize="1rem"] - The font size of the text field.
 * @param {number} [props.xl] - The number of columns the text field should span on extra-large screens.
 * @param {number} [props.lg] - The number of columns the text field should span on large screens.
 * @param {number} [props.md] - The number of columns the text field should span on medium screens.
 * @param {number} [props.sm] - The number of columns the text field should span on small screens.
 * @param {number} [props.xs] - The number of columns the text field should span on extra-small screens.
 * @param {function} [props.validation] - The function to be called to validate the text field value.
 * @param {function} [props.twinValidation] - The function to be called to validate the value of a twin text field.
 * @param {string} [props.twinId] - The unique identifier of the twin text field.
 * @param {string} [props.tagError] - The error message to display when the text field value is invalid.
 * @param {boolean} [props.forceLowerCase] - Whether to force the text field value to be in lowercase.
 * @param {boolean} [props.forceError] - Whether to force the text field to display an error message.
 * @param {boolean} [props.forceShrink] - Whether to force the text field label to shrink.
 * @param {boolean} [props.hardValidate] - Whether to perform a hard validation of the text field value.
 * @param {function} [props.submit] - The function to be called when the user submits the text field value.
 * @param {Object} [props.UIText={}] - The object containing the text field label, placeholder, and tooltip for each supported locale.
 * @param {boolean} [props.fullWidth] - Whether to make the text field full width.
 * @param {Object} [props.inputSx] - The style object to apply to the text field input.
 * @param {function} [props.constraint] - The function to be called to constrain the text field value.
 *
 * @returns {JSX.Element} The text field component.
 */
import { TextField as MUITextField } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { UISliceActions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import SlimAlert from "./SlimAlert";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";

/* e.g. UIText
    UIText = {
        es-CL:{
            label:'',
            placeholder:'',
            tooltip:'',
        }
    }
*/


const TextField = (props) => {
  const {
    id,
    onChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    size = "small",
    fontSize = "1rem",
    xl,
    lg,
    md,
    sm,
    xs,
    validation,
    twinValidation,
    twinId,
    tagError,
    forceLowerCase,
    disabled,
    forceError,
    forceShrink,
    hardValidate,
    submit,
    UIText = {},
    fullWidth,
    inputSx,
    constraint,
    // hide,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xl",
    "lg",
    "md",
    "sm",
    "xs",
    "validation",
    "twinValidation",
    "twinId",
    "tagError",
    "forceLowerCase",
    "forceError",
    "hardValidate",
    "submit",
    "help",
    "UIText",
    "fontSize",
    "inputSx",
    "hide",
    'forceShrink',
  );

  const ref = useRef(null);
  const { dispatch, UI, settings } = useUIBoilerplate();
  const [forceUpdate, setForceUpdate] = useState(false);
  const locale = settings.locale
  const { value = null, error } = UI[id] ?? {};
  const { label, tooltip, fixedTooltip, placeholder } =
    UIText[locale] ?? UIText.universal;

  // Update non-bound field to reflect an existing value
  useEffect(() => {
    ref.current.value = value;
    setForceUpdate(false)
  }, [value,forceUpdate]);

  // Update error message if locale changes
  useEffect(() => {
    if (locale && validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
  }, [dispatch, locale, id, twinId, validation, twinValidation]);

  // Validate field on blur. Also execute any onBlur function set from outside
  const handleBlur = () => {
    if (validation) {
      dispatch(UISliceActions.validateField({ id, fn: validation, locale }));
    }
    onBlur();
  };

  // Hard validate field on enter key press
  const handleHardValidate = (e) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      onKeyDown();
      if (hardValidate) {
        dispatch(
          UISliceActions.validateField({
            id,
            fn: validation,
            force: true,
            locale,
          })
        );
      }
    }
  };

  const debouncedChange = debounce((e) => {
    let value = e.target.value;
    if (constraint){
      value = constraint(value);
    }
    setForceUpdate(true)
    dispatch(UISliceActions.setField({ id, value }));
    onChange(e);
  }, 200);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedSubmit = debounce((e) => {
    if (submit && (e.key === "Enter" || e.key === "NumpadEnter")) {
      submit(e);
    }
  }, 200);

  const handleSubmit = (e) => {
    debouncedSubmit(e);
  };

  return (
    <AutoGrid
      {...{ xl, lg, md, sm, xs }}
      {...{ alignContent: tagError ? "start" : undefined }}
    >
      <AutoHelp {...{ tooltip, fixedTooltip, disabled }}>
        <MUITextField
          {...filteredProps}
          {...{
            fullWidth: xl || lg || sm || md || xs || fullWidth? true : undefined,
            label,
            placeholder,
            inputRef: ref,
            onChange: handleChange,
            onBlur: handleBlur,
            onKeyDown: handleHardValidate,
            onKeyUp: handleSubmit,
            size,
            error: !!error || forceError,
            InputLabelProps: {
              ...filteredProps.InputLabelProps,
              shrink: value ||  forceShrink? true : undefined,
            },
            InputProps: {
              ...filteredProps.InputProps,
              sx: {
                fontSize,
                borderRadius:
                  !!error && tagError
                    ? "4px 4px 0px 0px"
                    : undefined /*, ...inputSx*/,
              },
            },
            inputProps: {
              sx: {
                textTransform: forceLowerCase ? "lowercase" : undefined,
                ...inputSx,
              },
            },
            // sx: { visibility: hide ? "hidden" : undefined },
          }}
        />
      </AutoHelp>
      <SlimAlert errors={!!tagError ? [error] : []} fuseTop />
    </AutoGrid>
  );
};

export default TextField;
