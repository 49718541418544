import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../../../../../../../hooks2/useUIBoilerplate";
import { sortObjByKey } from "../../../../../../../../utility/sortObjByKey";
import Select from "../../../../../../../UI2/Select";
import { districtSelectUI } from "./District";
import { addressData, removeArrayDuplicates } from "./addressUtils";
import { UISliceActions } from "../../../../../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Ciudad" },
  "en-US": { label: "City" },
};

export const citySelectUI = "citySelect";

// comments
const City = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const district = UI[districtSelectUI] ?? "";

  const cities = useMemo(() => {
    const rawCities = addressData
      .filter((x) => x.comuna === district)
      .map((x) => ({
        label: x.ciudad,
        value: x.ciudad,
      }));

    const deduplicatedCities = removeArrayDuplicates(rawCities);

    const sortedCities = deduplicatedCities.sort((a, b) =>
      sortObjByKey(a, b, "label")
    );

    const cities = sortedCities.map((x) => ({ universal: x, value: x.value }));

    return cities;
  }, [district]);

  useEffect(() => {
    if (cities.length === 1) {
      dispatch(
        UISliceActions.setValue({
          id: citySelectUI,
          value: cities[0].value,
        })
      );
    }
  }, [dispatch, cities]);

  return (
    <Select
      {...{
        id: citySelectUI,
        options: cities,
        UIText,
        disabled: cities.length <= 1,
        xl: 4,
        lg: 4,
        md: 4,
        sm: 12,
        xs: 12,
      }}
    />
  );
};

export default City;
