import Select from "../../../../UI2/Select";
import { options } from "./selectValues";
import { humanBandwidth } from "../../../../../utility/humanizers";
import SlimAlert from "../../../../UI2/SlimAlert";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import { useWorker } from "../../../../../hooks2/useWorker";
import Switch from "../../../../UI2/Switch2";
import { Grid } from "@mui/material";
import HelpTooltip from "../../../../UI2/HelpTooltip";

const UIText = {
  "es-CL": {
    label: "Ancho de banda",
    tooltip:
      "Establece un limite al ancho de banda que puede ser utilizado durante el respaldo de información",
  },
  "en-US": {
    label: "Bandwidth",
    tooltip:
      "Set a limit to the bandwidth that can be used during the backup process",
  },
};
// const UIText2 = {
//   "es-CL": { label: "Limitar velocidad de subida" },
//   "en-US": { label: "Throttle upload speed" },
// };
const UIText3 = {
  "es-CL": {
    warning:
      "El intervalo de respaldo actual no coincide con ninguna de las opciones disponibles. Valor actual: ",
  },
  "en-US": {
    warning:
      "The current backup interval does not match with any available option. Actual value: ",
  },
};

export const throttleActiveUI = "throttleActive";
export const bandwidthLimitUI = "bandwidthLimit";

const Bandwidth = (props) => {
  const { locale } = props;
  const { dispatch, UI } = useUIBoilerplate();
  const { working } = useWorker(postDeviceId);
  const bandwidth = UI[bandwidthLimitUI];
  const active = UI[throttleActiveUI];
  const { warning } = UIText3[locale];

  const found = options.findIndex((opt) => opt.value === bandwidth);

  const warningFull =
    found === -1 && typeof bandwidth === "number" && active
      ? [warning + humanBandwidth(bandwidth)]
      : [];

  const reset = () => {
    dispatch(
      UIactions.setValue({ id: bandwidthLimitUI, value: options[0].value })
    );
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={7} alignItems="center" columnGap={1}>
        <Switch
          labelRight
          {...{
            id: throttleActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            xs: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid>
      <Select
        {...{
          id: bandwidthLimitUI,
          UIText,
          disabled: working,
          options,
          xs: 5,
          hide: !active,
        }}
      />
      <SlimAlert
        {...{ errors: warningFull, severity: "warning", paddingTop: 1 }}
      />
    </Grid>
  );
};

export default Bandwidth;
