import { Grid, Typography } from "@mui/material";
import React from "react";

/**
 * Header component for the Login2 page.
 * Contains an object with UI text labels for different languages.
 */
const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Login" },
};

const Header = ({locale}) => {
  const { label } = UIText[locale];

  return (
    <Grid container item {...{ xs: 12, justifyContent: "center" }}>
      <Typography
        {...{ fontSize:'1.5rem',textTransform: "uppercase", component: "h1", variant: "h5" }}
      >
        {label}
      </Typography>
    </Grid>
  );
};

export default Header;
