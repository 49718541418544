import MultiSelect from "../../../UI2/MultiSelect";

const UIText = {
  "es-CL": { label: "Permisos" },
  "en-US": { label: "Permissions" },
};

const options = [
  { 
    name: { "es-CL": "Administrador", "en-US": "Admin" }, 
    value: "admin" 
  },
  {
    name: { "es-CL": "Recibir correos", "en-US": "Receive mails" },
    value: "mails",
  },
  // {
  //   name: { "es-CL": "Acceder a la plataforma", "en-US": "Access web page" },
  //   value: "access",
  // },
];

export const permissionsUI = "userPermissions";

const PermissionsSelect = () => {
  return <MultiSelect {...{ id: permissionsUI, UIText, options, xs:12 }} />;
};

export default PermissionsSelect;
