import { useLocation } from "react-router-dom";
import UsersTop from "./Users/UsersTop";
import Blank from "./Blank/Blank";
import FilesTop from "./Files/FilesTop";

const TopBar = ({ height }) => {
  const location = useLocation() ?? {};
  switch (location.pathname) {
    case "/dashboard/users":
      return <UsersTop height={height} />;
    case "/dashboard/devices":
      return <UsersTop height={height} />;
    case "/dashboard/reports":
      return <UsersTop height={height} />;
    case "/dashboard/files":
      return <FilesTop height={height} />;
    default:
      return <Blank height={height} />;
  }
};

export default TopBar;
