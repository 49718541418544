import { Grid } from "@mui/material";
import { Pagination } from "../../UI2/Pagination";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

export const versionsPaginationUI = "versionsPagination";

const Footer = () => {
  const { UI } = useUIBoilerplate();
  const pagination = UI[versionsPaginationUI] ?? { count: 0 };

  return (
    <Grid
      container
      item
      {...{
        paddingLeft: "2vh",
        paddingRight: "2vh",
        height: "6vh",
        alignItems: "center",
        justifyContent: "end",
        xs: 12,
        columnGap: 2,
        // sx: { borderTop: "1px solid lightgrey" },
      }}
    >
      {!pagination.count ? null : (
        <Pagination {...{ id: versionsPaginationUI, xs: "auto" }} />
      )}
    </Grid>
  );
};

export default Footer;
