import {
  AddBoxOutlined,
  ArrowDropDown,
  ArrowRight,
  CheckBoxOutlined,
  Folder,
  IndeterminateCheckBoxOutlined,
  InsertDriveFile,
  Remove,
  SquareOutlined,
  Storage,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

export const BoxStateIcon = (backupMode, working) => {
  if (!working) {
    switch (backupMode) {
      case 0:
        return <SquareOutlined sx={{ fontSize: 17 }} />;
      case 1:
        return (
          <IndeterminateCheckBoxOutlined
            color="warning"
            sx={{ fontSize: 17 }}
          />
        );
      case 2:
        return <AddBoxOutlined color="success" sx={{ fontSize: 17 }} />;
      case 3:
        return <CheckBoxOutlined color="info" sx={{ fontSize: 17 }} />;
      default:
        break;
    }
  } else {
    return <CircularProgress size={17} />;
  }
};

export const ExpandedIcon = (loaded, expanded, isDir, hasChildren) => {
  if ((loaded && !hasChildren) || !isDir) {
    return <Remove sx={{ fontSize: 20 }} />;
  } else if (expanded) {
    return <ArrowDropDown sx={{ fontSize: 20 }} />;
  } else {
    return <ArrowRight sx={{ fontSize: 20 }} />;
  }
};

export const TypeIcon = (idx, isDir, status, working) => {
  const color = working ? "lightgrey" : "primary.main";
  if (idx === 0) {
    return <Storage {...{ sx: { fontSize: 18, color } }} />;
  }
  if (isDir) {
    if (status) {
      return <Folder {...{ sx: { fontSize: 18, color:'lightgrey' } }} />;
    } else {
      return <Folder {...{ sx: { fontSize: 18, color } }} />;
    }
  }
  return <InsertDriveFile {...{ sx: { fontSize: 18, color } }} />;
};
