import { Delete } from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../store/slices/UI2";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";

const UIText = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};
const DeleteUser = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI.selected ?? [];


  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "delete-users",
        payload: { users: selected},
      })
    );
  };

  return (
    <ActionButton
      {...{ disabled: !selected.length, onClick, UIText, icon: <Delete /> }}
    />
  );
};

export default DeleteUser;
