import notEmptyPassword from "../../../../utility/validations/notEmptyPassword";
import PassField from "../../../UI2/PassField";
import { pass2UI } from "./Password2";

const UIText = {
  "es-CL": {
    label: "Contraseña",
    placeholder: "*********",
    tooltip:
      "Campo de contraseña. Debe tener al menos 10 caracteres que incluyan mayusculas, minusculas y numeros",
  },
  "en-US": {
    label: "Password",
    placeholder: "**********",
    tooltip:
      "Password field. It must have at least characters including uppercase, lowercase and numbers",
  },
};

export const pass1UI = "password1";

const Password1 = (props) => {
  const { isNew } = props;

  return (
      <PassField
        fullWidth
        tagError
        {...{
          id: pass1UI,
          type: "password",
          UIText,
          // validation: notEmptyPassword,
          required: isNew,
          twinId: pass2UI,
          twinValidation: notEmptyPassword,
          xs: 12,
        }}
      />
  );
};

export default Password1;
