import { Grid, Typography } from "@mui/material";
import SlimAlert from "../../../../UI2/SlimAlert";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { notEmptyName } from "../../../../../utility/validations/notEmptyName";
import { useWorker } from "../../../../../hooks2/useWorker";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import TextField from "../../../../UI2/SimpleTextField";
import HelpTooltip from "../../../../UI2/HelpTooltip";

const UIText = {
  "es-CL": { tooltip: "Nombre asignado al dispositivo respaldado" },
  "en-US": { tooltip: "Name assigned to the backed up device" },
};
const UIText1 = {
  "es-CL": { label: "Nuevo nombre" },
  "en-US": { label: "New name" },
};
const UIText2 = {
  "es-CL": { label: "Nombre del dispositivo" },
  "en-US": { label: "Device name" },
};

export const deviceNameUI = "deviceName";

const NewName = (props) => {
  const { locale } = props;
  const { UI } = useUIBoilerplate();
  const { working } = useWorker(postDeviceId);
  const field = UI[deviceNameUI] ?? {};
  const error = field.error;
  const { label } = UIText1[locale];

  return (
    <Grid container item xs={12} alignItems="center">
      <Grid container item xs={7} columnGap={1} alignItems="center">
        <Typography>{label}</Typography>
        <HelpTooltip {...{ UIText }} />
      </Grid>
      <TextField
        {...{
          id: deviceNameUI,
          UIText:UIText2,
          disabled: working,
          validation: notEmptyName,
          xs: 5,
        }}
      />
      <SlimAlert {...{ messages: [error], xs:12, paddingTop:1 }} />
    </Grid>
  );
};

export default NewName;
