import MoreActions from "../../../../UI2/BasicLayout/Components/MoreActions/MoreActions";
import {
  Description,
  Download,
  Share,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import useURLQuery from "../../../../../hooks2/useURLQuery";
import createSharedLink from "../../../../../store/actions2/Shared/createSharedLink";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";

const Actions = (props) => {
  const { id, handleDownload, auth, locale, type } = props;
  const { user, repo } = useURLQuery();
  const dispatch = useDispatch();

  let buttonsData = [];
  if (type !== 99) {
    buttonsData.push({
      icon: <Download sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Descargar",
          fixedTooltip: "Descargar archivo o directorio",
        },
        "en-US": {
          label: "Download",
          fixedTooltip: "Download file or directory",
        },
      },
      onClick: handleDownload,
    });
    buttonsData.push({
      icon: <Share sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Compartir",
          fixedTooltip: "Crear enlace de descarga",
        },
        "en-US": { label: "Share", fixedTooltip: "Create download link" },
      },
      onClick: () => {
        dispatch(
          createSharedLink({
            ...auth,
            user,
            payload: { file: id, repo_id: repo, public: true },
            locale,
          })
        );
      },
    });
  }

  if (auth.admin === 1) {
    buttonsData.push({
      icon: <Description sx={{ color: "grey" }} />,
      UIText: {
        "es-CL": {
          label: "Información",
          fixedTooltip: "Información del archivo/repositorio",
        },
        "en-US": { label: "Data", fixedTooltip: "File/repository data" },
      },
      onClick: () => {
        dispatch(
          UIactions.openModal2({
            type: "file-details",
            payload: { data: props },
          })
        );
      },
    });
  }

  const data = { id: "version-" + id, buttonsData };

  return <MoreActions {...data} />;
};

export default Actions;
