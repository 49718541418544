import { useDispatch } from "react-redux";
import { getGreylistId, greylistDataId } from "../../../../store/actions2/Devices/getGreylist";
import { getRepoId, repoDataId } from "../../../../store/actions2/Devices/getRepositories";
import { UISliceActions as UIactions} from "../../../../store/slices/UI2";
import { newTreesSliceActions } from "../../../../store/slices/trees2";
import { warehouseSliceActions as warehouse } from "../../../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import DevicePicker from "../../../UI2/Specialized/DevicePicker";
import { greylistPickerId } from "../../../UI2/Specialized/GreylistPicker";
import { repositoryPickerId } from "../../../UI2/Specialized/RepositoryPicker";
import { greylistTreeReady } from "./GreylstPicker";

const Device = (props) => {
    const {user} = props
    const dispatch = useDispatch()

  const onChange = ()=>{
    dispatch(workers.clear(getRepoId))
    dispatch(workers.clear(getGreylistId))
    dispatch(workers.clear(greylistTreeReady))
    dispatch(warehouse.unload(repoDataId))
    dispatch(warehouse.unload(greylistDataId))
    dispatch(UIactions.clear(repositoryPickerId))
    dispatch(UIactions.clear(greylistPickerId))
    dispatch(newTreesSliceActions.clearForest())
  }

  return <DevicePicker autoload {...{ user, onChange, xs: 12 }} />;
};

export default Device;