import { Grid, Switch as MUISwitch, Typography } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";

const Switch = (props) => {
  const {
    id,
    onChange: fn = () => {},
    disableDefault,
    UIText,
    xl,
    lg,
    md,
    sm,
    xs,
    labelProps = {},
    labelRight,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xs",
    "onChange",
    "disableDefault",
    "UIText",
    'labelRight',
    'labelProps',
  );
  const { dispatch, UI, settings } = useUIBoilerplate();
  const active = UI[id];
  const { label, fixedTooltip, tooltip } = UIText[settings.locale];

  const onChange = (e, value) => {
    if (!disableDefault) {
      dispatch(UIactions.setValue({ id, value }));
    }
    fn(value);
  };

  return (
    <AutoGrid {...{ wrap: "nowrap", xl, lg, md, sm, xs, alignItems: "center" }}>
      <AutoHelp {...{ fixedTooltip, tooltip }}>
        <Grid container alignItems='center'>
          {labelRight ? null : (
            <Typography
              {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
            >
              {label}
            </Typography>
          )}
          <MUISwitch {...filteredProps} {...{ onChange, checked: !!active }} />
          {labelRight ? (
            <Typography
              {...{
                fontWeight: "bold",
                fontSize: "0.9rem",
                sx: { cursor: "default" },
                ...labelProps,
              }}
            >
              {label}
            </Typography>
          ) : null}
        </Grid>
      </AutoHelp>
    </AutoGrid>
  );
};

export default Switch;
