import { Download } from "@mui/icons-material";
import Button from "../../../UI2/Button";
import { downloadTemplate, downloadTemplateUI } from "../../../../store/actions2/UserForm/downloadTemplate";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";

const UITextDownload = {
  "es-CL": {
    label: "Descargar .xlsx",
    fixedTooltip: "Descargar hoja .xslx templado para la carga de usuarios",
  },
  "en-US": {
    label: "Download .xlsx",
    fixedTooltip: "Download .xslx template sheet needed to upload users",
  },
};

const DownloadSheet = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const {working} = useWorker(downloadTemplateUI)

  const onClick = () => {
    dispatch(downloadTemplate(settings.locale));
  };

  return (
    <Button
      {...{
        onClick,
        UIText: UITextDownload,
        working,
        variant: "text",
        startIcon: <Download />,
        labelSx:{ fontSize: "0.8rem", fontWeight: "bold" },
        xs: "auto",
      }}
    />
  );
};

export default DownloadSheet;
