import { Grid, Typography } from "@mui/material";
import { extensions } from "./extensions";
import ExtChip from "./ExtChip";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { categoryFilterUI } from "../Filters/Filters";
import { containsEscaped } from "../../../../utility/filter";

export const selectedCommonUI = "selectedCommon";

const Category = ({ category, UIText }) => {
  const { UI, settings } = useUIBoilerplate();
  const { header } = UIText[settings.locale];
  const selected = UI[selectedCommonUI] ?? [];
  const categoryFilter = UI[categoryFilterUI] ?? "all";
  const searchFilter = UI.filter ?? "";

  const audioExts = extensions
    .filter((ext) => ext.type === category)
    .filter((ext) => ext.type === categoryFilter || categoryFilter === "all")
    .filter((ext) => containsEscaped(ext.name, searchFilter));

  const audioChips = audioExts.map((ext) => (
    <ExtChip
      {...{
        label: ext.name.toUpperCase(),
        value: ext.name,
        key: ext.name,
        selected: !!selected.find((x) => x === ext.name),
      }}
    />
  ));

  if (!audioChips.length) {
    return;
  }

  return (
    <Grid container item {...{ padding: 2, xs: 12, rowGap: 1 }}>
      <Typography fontSize={16}>{header}</Typography>
      <Grid container item gap={1} paddingLeft={2} paddingRight={2}>
        {audioChips}
      </Grid>
    </Grid>
  );
};

export default Category;
