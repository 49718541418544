import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import RadioSelect from "../../../../../../UI2/RadioSelect";
import TextField from "../../../../../../UI2/TextField";
import { UISliceActions } from "../../../../../../../store/slices/UI2";

const UIText = {
  "es-CL": {
    aditionalOptions: "Opciones adicionales",
    includeDeleted: "Incluir archivos eliminados",
    recoverDate: "Fecha especifica",
  },
  "en-US": {
    aditionalOptions: "Additional options",
    includeDeleted: "Include deleted files",
    recoverDate: "Specific date",
  },
};

const UITextDate = {
  "es-CL": { label: "Fecha de recuperacion" },
  "en-US": { label: "Recovery date" },
};

export const aditionalOptionUI = "aditionalOption";
export const recoveryDateUI = "recoveryDate";

const radioSizing = { lg: 3, md: 3, sm: 6, xs: 12 };
const radioSizing2 = { lg: 3, md: 3, sm: 6, xs: 12 };

const AditionalOptions = ({ id, readOnly }) => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const selected = UI[`${aditionalOptionUI}-${id}`];
  const { value: selectedDate } = UI[`${recoveryDateUI}-${id}`] ?? {};
  const { aditionalOptions, includeDeleted, recoverDate } =
    UIText[settings.locale];

  const onClick = () => {
    dispatch(UISliceActions.clear(`${recoveryDateUI}-${id}`));
  };

  let dateError;
  if (!selectedDate || new Date(selectedDate) > Date.now()) {
    dateError = true;
  }

  return (
    <Grid container item {...{ alignItems: "center", xs: 12 }}>
      <Grid item {...{ lg: 3, md: 3, sm: 12, xs: 12 }}>
        <Typography fontWeight="bold" fontSize="1rem">
          {aditionalOptions}
        </Typography>
      </Grid>
      <RadioSelect
        allowEmpty
        labelLeft
        {...{
          id: `${aditionalOptionUI}-${id}`,
          disabled:readOnly,
          value: "deleted",
          label: includeDeleted,
          onClick,
          labelProps: { noWrap: true },
          ...radioSizing,
        }}
      />
      <RadioSelect
        allowEmpty
        labelLeft
        {...{
          id: `${aditionalOptionUI}-${id}`,
          disabled:readOnly,
          value: "dated",
          label: recoverDate,
          onClick,
          labelProps: { noWrap: true },
          ...radioSizing2,
        }}
      />
      {selected !== "dated" ? null : (
        <TextField
          {...{
            id: `${recoveryDateUI}-${id}`,
            type: "datetime-local",
            disabled: selected !== "dated" || readOnly,
            UIText: UITextDate,
            lg: "auto",
            md: 3,
            sm: 6,
            xs: 12,
            forceError: dateError,
          }}
        />
      )}
    </Grid>
  );
};

export default AditionalOptions;
