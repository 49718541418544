/**
 * Requests logs from devices.
 *
 * @param {Object} data - The data needed to make the request.
 * @param {string} data.server - The server URL to make the request to.
 * @param {string} data.cloner_key - The key needed to authenticate the request.
 * @param {Array} data.payload - The payload to send with the request.
 * @param {string} data.locale - The locale to use for the UI text.
 * @returns {Function} - The async function that makes the request.
 */
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as workers } from "../../slices/workers";
import axios from "axios";

export const requestLogsId = "requestLogs";

const UIText = {
  "es-CL": {
    start: "Solicitando logs de equipos",
    success: "Logs solicitados exitosamente",
    serverError: "Servidor retornó error",
    error: "Error al intentar solicitar un log",
  },
  "en-US": {
    start: "Requesting logs from devices",
    success: "Log requested successfully",
    serverError: "Server returned error",
    error: "Error while trying to request a log",
  },
};

const requestLogs = (data) => {
  const { server, cloner_key, payload, locale } = data;
  return async (dispatch) => {
    const { start, success, serverError, error } = UIText[locale];
    dispatch(workers.start(requestLogsId));
    enqueueSnackbar(start, { variant: "info" });
    try {
      for (const item of payload) {
        const bodyData = JSON.stringify(item);
        const url = server + "api/pulllog";
        const response = await axios.post(url, bodyData, {
          headers: { cloner_key },
        });
        if (!response.data) {
          throw new Error(serverError);
        }
      }
      dispatch(workers.finish({ id: requestLogsId }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id: requestLogsId, error: e.msg }));
      enqueueSnackbar(error, { variant: "error" });
    }
  };
};

export default requestLogs;
