import { Grid } from "@mui/material";
import { UISliceActions } from "../../../../store/slices/UI2";
import { pass1UI } from "./Password1";
import { pass2UI } from "./Password2";
import { useDispatch } from "react-redux";
import Switch from "../../../UI2/Switch2";

const UIText = {
  "es-CL": { label: "Cambiar contraseña" },
  "en-US": { label: "Change password" },
};

export const changePasswordUI = "changePassword";

const ChangePassword = () => {
  const dispatch = useDispatch()

  const onChange = () => {
    dispatch(UISliceActions.clear(pass1UI));
    dispatch(UISliceActions.clear(pass2UI));
  };

  return (
    <Grid container item xs={12} alignItems="center" columnGap={2}>
      <Switch
        labelRight
        {...{
          id: changePasswordUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          xs: "auto",
        }}
      />
    </Grid>
  );
};

export default ChangePassword;
