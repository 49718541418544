import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { error: "Error al intentar descargar el archivo " },
  "en-US": { error: "Error trying to download file " },
};

const downloadFile = (data) => {
  const { server, token, repoId, fileId, filename, locale } = data;
  const { error } = UIText[locale];
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  return async (dispatch) => {
    console.log('data',data)
    try {
      let newURL = new URL("api/download", server);
      newURL.searchParams.append("token", token);
      newURL.searchParams.append("repoid", repoId);
      newURL.searchParams.append("id", fileId);
      console.log(newURL.toString());

      const anchor = document.createElement("a");
      anchor.href = newURL;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`${error} ${filename}: ${e.message}`, {
        variant: "error",
      });
    }
  };
};

export default downloadFile;
