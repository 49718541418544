import { useEffect } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { tunnelTokenId } from "../../../../store/actions2/RemoteSelection/getMachineStatus";
import loadRepositoryRoot from "../../../../store/actions2/RemoteSelection/loadRepositoryRoot";
import SelectionNode from "./SelectionNode/SelectionNode";
import useTrees from "../../../../hooks2/useNewTrees";
import { passFieldId } from "../Blocked/PassField";

const Repository = (props) => {
  const { id, root } = props;
  const { auth, dispatch, UI } = useUIBoilerplate();
  const { server } = auth;
  // const { tunnelToken = "", password = "", trees = {} } = UI;
  const tunnelToken = UI[tunnelTokenId] ?? "";
  const { value: password } = UI[passFieldId] ?? "";
  const trees = useTrees();
  const tree = trees[root];

  useEffect(() => {
    if (tunnelToken) {
      dispatch(
        loadRepositoryRoot({
          server,
          tunnelToken,
          cpassword: password,
          repository: id,
          path: root,
        })
      );
    }
  }, [dispatch, server, tunnelToken, password, id, root]);

  // return
  let rootNodeData = tree
    ? tree[0]
    : {
        backup: 0,
        childrenIdx: [],
        idx: 0,
        isDir: true,
        name: root,
        parentIdx: null,
        status: 0,
      };

  return (
    <SelectionNode
      {...rootNodeData}
      {...{ level: 0, root, repo: id, path: "" }}
    />
  );

};

export default Repository;
