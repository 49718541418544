import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../../../hooks2/useURLQuery";
import { usersDataId } from "../../../../../../store/actions2/Users/getAllUsers";
import { UISliceActions as UIactions } from "../../../../../../store/slices/UI2";
import { warehouseSliceActions as warehouseActions } from "../../../../../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../../../../../store/slices/workers";
import { devicePickerId } from "../../../../../UI2/Specialized/DevicePicker";
import { devicesDataId } from "../../../../../../store/actions2/Devices/getAllDevices";
import {
  getRepoId,
  repoDataId,
} from "../../../../../../store/actions2/Devices/getRepositories";
import { getDevicesId } from "../../../../../../store/actions2/Devices/getDevices2";
import { newTreesSliceActions as newTrees } from "../../../../../../store/slices/trees2";
import {
  default as CustomUserPicker,
  userPickerId,
} from "../../../../../UI2/Specialized/UserPicker";

const UserPicker = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBoilerplate();
  const { group, admin} =auth
  const { user } = useURLQuery();
  const userPicker = UI[userPickerId] ?? {};
  const users = useMemo(() => {
    return warehouse[usersDataId] ?? [];
  }, [warehouse]);

  // user changes and does not match current picked user, overwrite
  useEffect(() => {
    if (!!user && !!userPicker.id && user !== userPicker.id) {
      const usr = users.find((u) => u.login === user);
      const value = { id: usr.login, label: usr.name };
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));
      dispatch(warehouseActions.unload(devicesDataId));
      dispatch(warehouseActions.unload(repoDataId));
      dispatch(workers.clear(getDevicesId));
      dispatch(workers.clear(getRepoId));
      navigate(`?user=${encodeURIComponent(value.id)}`, { replace: true });
    }
  }, [dispatch, navigate, user, userPicker.id, users]);

  const onLoaded = (users) => {
    if (user) {
      // if url user is set and the picker is not set yet
      const option = users.find((u) => u.id === user);

      // if user found set it, if not set the first entry
      const value = option ?? users[0];
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));

      // if user not found change url to match
      if (!option) {
        navigate("?user=" + encodeURIComponent(value.id), { replace: true });
      }
    } else if (!user && users.length > 0) {
      // no url user. set first entry and modify URL
      const value = { label: users[0].name, id: users[0].login };
      dispatch(UIactions.setValue({ id: userPickerId, value }));
      dispatch(UIactions.clear(devicePickerId));
      navigate("?user=" + encodeURIComponent(value.id), { replace: true });
    }
  };

  const onChange = (value) => {
    dispatch(UIactions.clear(devicePickerId));
    dispatch(warehouseActions.unload(devicesDataId));
    dispatch(warehouseActions.unload(repoDataId));
    dispatch(newTrees.clearForest());
    dispatch(workers.clear(getDevicesId));
    dispatch(workers.clear(getRepoId));
    navigate(`?user=${encodeURIComponent(value.id)}`);
  };

  return <CustomUserPicker autoload {...{ onChange, onLoaded, xs: 4, disabled: admin !== group  }} />;
};

export default UserPicker;
