import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import {
  postSelf,
  postSelfId,
} from "../../../store/actions2/UserForm/postSelf";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";
import Button from "../../UI2/Button";
import { pass1UI, pass2UI } from "./Password";
import { usernameUI } from "./User";

const UITextSend = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};
const UITextError = {
  "es-CL": "Los campos no coinciden",
  "en-US": "The fields do not match",
};

const Save = () => {
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { working } = useWorker(postSelfId);
  const locale = settings.locale;
  const { error: e1, value: username } = UI[usernameUI] ?? {};
  const { error: e2, value: pass1 } = UI[pass1UI] ?? {};
  const { error: e3, value: pass2 } = UI[pass2UI] ?? {};

  const disabled = !!e1 || !!e2 || !!e3;

  const onMouseDown = () => {
    dispatch(
      UIactions.twinFieldValidate({
        id1: pass1UI,
        id2: pass2UI,
        fn: notEmptyPassword,
        errorMsg: UITextError,
        locale,
      })
    );
  };

  const onClick = () => {
    const passOK = !!pass1 && !!pass2 && !e1 && !e2;
    dispatch(
      postSelf({
        ...auth,
        payload: {
          name: username,
          email: "",
          option: `${auth.has_tfa}`,
          login: auth.user,
          server: auth.server,
          pass: passOK ? pass1 : undefined,
          pass2: passOK ? pass2 : undefined,
        },
        locale,
      })
    );
  };

  return <Button {...{ disabled, working, UIText: UITextSend, onMouseDown, onClick }} />;
};

export default Save;
