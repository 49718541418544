import Category from "./Category";

const UIText = {
  "es-CL": { header: "Ejecutable" },
  "en-US": { header: "Executable" },
};

const Executables = () => {
  return <Category {...{ category: "executable", UIText }} />;
};

export default Executables;
