import { CircularProgress, Grid } from "@mui/material";

const Loading = ({ working }) => {
  if (!working) {
    return;
  }

  //   return <Skeleton {...{ height: "56vh", xs: 12 }} />;
  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      {...{ sx: { height: "30vh" }, xs: 12 }}
    >
      <CircularProgress size={60}/>
    </Grid>
  );
};

export default Loading;
