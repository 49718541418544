import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locale: 'es-CL',
};
const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLocale(state,action){
        const newValue = action.payload
        state.locale = newValue
    },
    clearLocale(state){
        state.locale = 'es-CL'
    },
  },
});

export const settingsSliceActions = settingsSlice.actions;
export default settingsSlice.reducer;