import DevicePicker from "./DevicePicker";
import Legend from "./Legend";
import { Fragment } from "react";

const TopBar = ({user}) => {
  return (
    <Fragment>
      <DevicePicker {...{user}}/>
      <Legend />
    </Fragment>
  );
};

export default TopBar;
