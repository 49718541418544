import { ArrowDropDown, ArrowRight, Remove } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import getTreeFiles from "../../../../store/actions2/Devices/getTreeFiles";
import { newTreesSliceActions as trees } from "../../../../store/slices/trees2";
import IconButton from "../../../UI2/IconButton";

const Expand = (props) => {
  const {
    loaded,
    auth,
    id: file,
    repository: repo,
    expanded,
    noChildren,
    idx,
    locale,
  } = props;
  const dispatch = useDispatch();

  // handle expand click
  const onClick = () => {
    if (!loaded) {
      dispatch(getTreeFiles({ ...auth, idx, file, repo, locale }));
    }
    dispatch(trees.toggleExpandFVT({ id: 'repo-'+repo, idx }));
  };

  let icon;
  if (!loaded) {
    icon = <ArrowRight sx={{ fontSize: 19 }} />;
  } else {
    if (noChildren) {
      icon = <Remove sx={{ fontSize: 19 }} color="disabled" />;
    } else if (expanded) {
      icon = <ArrowDropDown sx={{ fontSize: 19 }} />;
    } else {
      icon = <ArrowRight sx={{ fontSize: 19 }} />;
    }
  }

  return (
    <IconButton
      {...{
        UIText: { universal: {} },
        disabled: loaded && noChildren,
        onClick,
        icon,
        sx: { padding: 0.1 },
        xs: "auto",
      }}
    />
  );
};

export default Expand;
