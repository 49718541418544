import { Grid } from "@mui/material";
import clonerWhite from "../../Resources/CLONER.png";

const Logo = (props) => {
  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item {...{ lg: 4, md: 6, sm: 8, xs: 10 }}>
        <img src={`${clonerWhite}`} alt="cloner-logo" width="100%" />
      </Grid>
    </Grid>
  );
};

export default Logo;
