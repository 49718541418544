import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { devicesDataId } from "../../../../../store/actions2/Devices/getDevices";
import addRecovery from "../../../../../store/actions2/Recoveries/addRecovery";
import Button from "../../../../UI2/Button";
import { devicePickerId } from "../../../../UI2/Specialized/DevicePicker";
import { userPickerId } from "../../../../UI2/Specialized/GroupPicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UIText = {
  "es-CL": { label: "Agregar recuperación" },
  "en-US": { label: "Add recovery" },
};

const Add = () => {
  const { auth, dispatch, UI, settings, warehouse } = useUIBoilerplate();
  const locale = settings.locale;
  const { id } = UI[userPickerId] ?? {};
  const { mid, label } = UI[devicePickerId] ?? {};
  const { value: bkpDate } = UI[recoveryDateUI] ?? "";
  const option = UI[recoveryOptionUI] ?? "";
  const devices = warehouse[devicesDataId] ?? [];
  const device = devices.find((d) => d.mid === mid) ?? {};

  const onClick = () => {
    dispatch(
      addRecovery({
        ...auth,
        user: id,
        mid,
        device: label,
        option,
        bkpDate,
        fullSize: device.space_active,
        lastBkp: device.lastBkp,
        locale,
      })
    );
  };

  let disabled = false;
  if (option === "dated" && !bkpDate) {
    disabled = true;
  } else if (devices.length === 0) {
    disabled = true;
  }

  return <Button {...{ disabled, UIText, onClick }} />;
};

export default Add;
