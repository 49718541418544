import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../hooks2/useWorker";
import loginWithGAuth, {
  id,
} from "../../../../../store/actions2/Login/loginWithGAuth";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import notEmptyQRCode from "../../../../../utility/validations/notEmptyQRCode";
import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Autenticar codigo" },
  "en-US": { label: "Authenticate code" },
};

const AuthenticateBtn = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { authCode = {}, user = {} } = UI;
  const { working } = useWorker(id);

  const handleHardValidation = () => {
    const payload = { id: "authCode", fn: notEmptyQRCode, force: true };
    dispatch(UIactions.validateField(payload));
  };

  const handleAuthentication = () => {
    const payload = { user: user.value, code: authCode.value, locale:settings.locale };
    dispatch(loginWithGAuth(payload));
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Button
        fullWidth
        {...{
          UIText,
          onMouseDown: handleHardValidation,
          onClick: handleAuthentication,
          working,
          disabled: !!authCode.error,
          xl: 3,
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
        }}
      />
    </Grid>
  );
};

export default AuthenticateBtn;
