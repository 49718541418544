import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import Close from "./Components/Close";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Data from "./Components/Data";

const UIText = {
  "es-CL": { repo: "Repositorio", file: "Archivo", dir: "Directorio" },
  "en-US": { repo: "Repository", file: "File", dir: "Directory" },
};

const FileDataPop = (props) => {
  const { modal } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;

  let payload;
  if (modal === "1") {
    const { payload: p } = UI.modal;
    payload = p;
  } else if (modal === "2") {
    const { payload: p } = UI.modal2;
    payload = p;
  }
  const { data } = payload;
  const { repo, file, dir } = UIText[locale];
  console.log("filedatapop", data);

  const onClose = (e, reason) => {
    // console.log("reason", reason);
    if (reason !== "backdropClick") {
      if (modal === "1") {
        dispatch(UISliceActions.closeModal());
      } else if (modal === "2") {
        dispatch(UISliceActions.closeModal2());
      }
    }
  };

  let fType = "";
  if (data.type === 99) {
    fType = repo;
  } else if (data.type === 1) {
    fType = file;
  } else if (data.type === 0) {
    fType = dir;
  }

  const header = `${fType}: ${data.name}`;

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header, locale }} />
      <BasicDialogContent>
        <Data {...{ data, locale }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Close {...{ modal }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default FileDataPop;
