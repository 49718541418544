import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Save = (props) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { value: bkpDate } = UI[recoveryDateUI] ?? "";
  const option = UI[recoveryOptionUI] ?? "";

  const repositories = props.repositories.map((repo) => ({
    ...repo,
    bkpDate,
    option,
    recover:true,
  }));

  const newRecovery = { ...props, repositories, mode:'simple' };

  const onClick = () => {
    dispatch(recoveriesSliceActions.updateRecovery(newRecovery));
    dispatch(UISliceActions.closeModal());
  };

  let disabled = false;
  if (option === "dated" && !bkpDate) {
    disabled = true;
  }

  return <Button {...{ disabled, UIText, onClick }} />;
};

export default Save;
