import { useDispatch } from "react-redux";
import { UISliceActions as UI } from "../../../../../store/slices/UI2";
import Button from "../../../../UI2/Button";
import { Grid } from "@mui/material";

const UIText = {
  "es-CL": { label: "Volver" },
  "en-US": { label: "Back" },
};

const Back = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UI.clear("twofactor"));
    dispatch(UI.clear("authCode"));
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Button
        fullWidth
        {...{
          UIText,
          onClick,
          xl: 3,
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
          variant: "outlined",
        }}
      />
    </Grid>
  );
};

export default Back;
