import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { UISliceActions } from "../../../../store/slices/UI2";
import { permissionsUI } from "./PermissionsSelect";
import Switch from "../../../UI2/Switch2";

const UIText = {
  "es-CL": { label: "Cambiar permisos" },
  "en-US": { label: "Change permissions" },
};

export const changePermissionsUI = "changePermissions";

const ChangePermissions = () => {
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(UISliceActions.clear(permissionsUI));
  };

  return (
    <Grid container item xs={12} alignItems="center" columnGap={2}>
      <Switch
        labelRight
        {...{
          id: changePermissionsUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          xs: "auto",
        }}
      />
    </Grid>
  );
};

export default ChangePermissions;
