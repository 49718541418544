import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import getMailCategories, {
  getMailCategoriesId,
} from "../../store/actions2/Mails/getMailCategories";
import { useEffect, useMemo } from "react";
import Core from "./Components/Core";
import getMails, {
  getMailsId,
  mailsData,
} from "../../store/actions2/Mails/getMails";
import useURLQuery from "../../hooks2/useURLQuery";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import CalculatePagination from "../UI2/Utilities/CalculatePagination";
import { sortObjByKey } from "../../utility/sortObjByKey";
import useTrees from "../../hooks2/useNewTrees";
import { newTreesSliceActions as treeActions } from "../../store/slices/trees2";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import { currentCatsUI } from "../Dashboard/Components/Drawer/Components/MailCategories/MailCategories";
import {
  defaultFilter,
  defaultPagination,
  defaultSelected,
  defaultSort,
} from "../UI2/genericIDs";
import { lowerMatch } from "../../utility/filter";
import { useWorker } from "../../hooks2/useWorker";
import SlimAlert from "../UI2/SlimAlert";
import { Grid } from "@mui/material";

const UIText = {
  'es-CL': 'El usuario nunca ha habilitado y respaldado correos utilizando Windows365',
  'en-US': 'The user has never enabled and backed up emails using Windows365',
}

const InboxLocaleArray = ["bandeja de entrada"];

const Mails = () => {
  const { auth, dispatch, navigate, warehouse, UI,settings } = useUIBoilerplate();
  const trees = useTrees();
  const { folderId } = useURLQuery();
  const workerId1 = getMailsId;
  const workerId2 = getMailCategoriesId;
  const { done: d1, working: w1, error: e1 } = useWorker(workerId1);
  const { done: d2, working: w2, error: e2 } = useWorker(workerId2);
  const working = w1 || w2;
  const done = d1 && d2;
  const error = !!e1 ? e1 : e2;
  const workerId = d2 && !e2 ? workerId1 : workerId2;

  const info = UIText[settings.locale];

  const filter = UI.filter ?? "";
  const sort = UI.sort ?? {};
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };

  const query = "";

  useEffect(() => {
    let found = false;
    let inboxId = "";
    for (const key in trees) {
      if (!key.match("mails-")) {
        continue;
      }
      const treeRoot = trees[key][0];
      if (treeRoot.id === folderId) {
        found = true;
      }
      if (
        !!InboxLocaleArray.find(
          (x) => x.toLowerCase() === treeRoot.displayName.toLowerCase()
        )
      ) {
        inboxId = treeRoot.id;
      }
    }
    if (!found) {
      navigate(`/dashboard/mails?folderId=${inboxId}&idx=0`);
    }
  }, [navigate, trees, folderId]);

  useEffect(() => {
    if (!d2) {
      dispatch(getMailCategories({ ...auth }));
    }
  }, [dispatch, auth, d2]);

  useEffect(() => {
    if (folderId && !d1) {
      const controller = new AbortController();
      dispatch(getMails({ ...auth, query, folderId, controller }));
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, query, folderId, auth, d1]);

  // filtering devices
  const filteredMails = useMemo(() => {
    const mails = warehouse[mailsData] ?? [];
    return [...mails]
      .filter(
        (m) =>
          lowerMatch(m.fromM, filter) ||
          lowerMatch(m.toRecipients, filter) ||
          lowerMatch(m.subject, filter) ||
          lowerMatch(m.bodyPreview, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [sort.value, sort.descending, filter, warehouse]);

  // Array to select all
  const allMails = useMemo(() => {
    return filteredMails.map((x) => x.id);
  }, [filteredMails]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredMails.length }));
  }, [dispatch, filteredMails.length]);

  // Trimming devices to only current page ones
  const { from, to } = CalculatePagination(pagination);
  const paginatedMails = useMemo(() => {
    return filteredMails.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredMails]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(defaultPagination));
      dispatch(UIactions.clear(defaultSort));
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(currentCatsUI));
      dispatch(treeActions.removeTreeGroup("mails-"));
      dispatch(warehouseActions.unload(mailsData));
      // dispatch(warehouseActions.unload(currentMailsWID));
      dispatch(workers.clear(getMailCategoriesId));
      dispatch(workers.clear(getMailsId));
      dispatch(workers.clearGroup("restore"));
    };
  }, [dispatch]);

  // console.log("trees",trees)

  let foundCategories = false;
  for (const key in trees) {
    if (!key.match("mails-")) {
      continue;
    }
    foundCategories = true;
    break;
  }

  if (d2 && !e2 && !foundCategories) {
    return (
      <Grid container items xs={12} justifyContent="center" padding={10}>
        <SlimAlert notCollapse severity="info" errors={[info]}/>
      </Grid>
    );
  }
  return (
    <Core
      {...{ mails: paginatedMails, allMails, done, working, error, workerId }}
    />
  );
};
export default Mails;
