/**
 * Password component for the login form.
 *
 * @param {Object} props - The props object.
 * @param {Function} props.login - The login function.
 * @param {Function} props.hardValidation - The hard validation function.
 * @param {boolean} props.disabled - The disabled state of the component.
 * @returns {JSX.Element} - The Password component.
 */
import { Grid } from "@mui/material";
import { useWorker } from "../../../hooks2/useWorker";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";
import { id } from "../../../store/actions2/Login/loginToCloner";
import PassField from "../../UI2/PassField";

const UIText = {
  "es-CL": { label: "Contraseña", placeholder: "**********" },
  "en-US": { label: "Password", placeholder: "**********" },
};

const Password = (props) => {
  const { login, hardValidation, disabled: dsbl } = props;
  const { working } = useWorker(id);

  const disabled = dsbl || working;

  return (
    <Grid container item {...{ rowGap: 1, xs: 12, justifyContent: "center" }}>
      <PassField
        fullWidth
        tagError
        required
        {...{
          id: "password",
          UIText,
          type: "password",
          disabled,
          validation: notEmptyPassword,
          onKeyDown: hardValidation,
          submit: login,
          lg:3,
          md:4,
          sm:6,
          xs: 8,
        }}
      />
    </Grid>
  );
};

export default Password;
