import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";

export const versionsDataId = "versionsData";
export const getVersionsId = "getVersions";

const loadVersions = (data) => {
  console.log("loading versions!");
  const { server, file, repo, cloner_key } = data;
  const id = getVersionsId;

  return async (dispatch) => {
    dispatch(worker.start(getVersionsId));
    try {
      // GET group data
      const url = server + "api/fileshistory";
      const response = await axios.get(url, {
        // signal: data.controller.signal,
        params: { id: file, repoid: repo },
        headers: { cloner_key },
      });
      const data = [...response.data.files].reverse();
      dispatch(warehouse.load({ id: versionsDataId, data }));
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};

export default loadVersions;
