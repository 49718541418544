import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import store from "../..";
import authenticationSlice from "../../slices/authentication";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import recoveriesSlice from "../../slices/recoveries";
import workersSlice from "../../slices/workers";
import warehouseSlice from "../../slices/warehouse";
import settingsSlice from "../../slices/settings";
import UISlice from "../../slices/UI2";
import newTreesSlice from "../../slices/trees2";

const swapReducer = (selected) => {
  return async () => {
    const authPersistConfig = {
      key: "auth",
      storage: selected ? storage : storageSession,
      stateReconciler: hardSet,
    };

    const sessionPersistConfig = {
      key: "root",
      storage: storageSession,
      stateReconciler: hardSet,
    };

    const persistedAuthReducer = persistReducer(
      authPersistConfig,
      authenticationSlice
    );

    const combinedReducers = combineReducers({
      workers: workersSlice,
      recoveries: recoveriesSlice,
      warehouse: warehouseSlice,
      settings: settingsSlice,
      UI: UISlice,
      newTrees: newTreesSlice,
      authentication: persistedAuthReducer,
    });

    const rootPersistedReducer = persistReducer(
      sessionPersistConfig,
      combinedReducers
    );

    store.replaceReducer(rootPersistedReducer);
    if (selected) {
      localStorage.setItem("remember-session", true);
      sessionStorage.clear("persist:auth");
    } else {
      localStorage.setItem("remember-session", false);
      localStorage.clear("persist:auth");
    }
  };
};

export default swapReducer;
