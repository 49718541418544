import axios from "axios";
import { workersSliceActions as worker } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { enqueueSnackbar } from "notistack";
import { UISliceActions as UIactions } from "../../slices/UI2";

export const validateMailId = "windows365validations";
// export const w365orgsData = "windows365organizations";

const UIError = {
  "es-CL": {
    error: "Error al validar correo",
    notFound: "El correo no existe en la organización",
    similar: "El correo no existe en la organización, pero se sugiere",
    found: "Correo encontrado en la organización",
  },
  "en-US": {
    error: "Error validating mail",
    notFound: "Mail not found in the organization",
    similar: "Mail not found in the organization, but suggested",
    found: "Mail found in the organization",
  },
};

export const validateW365Mails = (data) => {
  const id = validateMailId;
  const { W365server, cloner_key, tryouts, locale } = data;

  console.log("sugges mails", tryouts);

  return async (dispatch) => {
    dispatch(worker.start(id));
    // dispatch(warehouse.unload(w365orgsData));
    // const group_id = groupId ?? group;

    let lastError = null;
    for (let attempt of tryouts) {
      const newMail = attempt.field + "@" + attempt.org.domain;
      const url = `${W365server}mailapi/organization/${attempt.org.id}/client/${newMail}`;
      let clientMail = "";
      try {
        console.log("URL", url);
        if (attempt.field === "") {
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: "campo vacío",
            })
          );
        } else {
          const response = await axios.get(url, { headers: { cloner_key } });

          const client = response.data.data;
          console.log("client", client);

          console.log("response", response.status);

          clientMail = client.mail.toLowerCase().split("@")[0];

          if (clientMail === attempt.field.toLowerCase()) {
            // console.log("client", clientMail, attempt.field.toLowerCase());
            enqueueSnackbar(UIError[locale].found + ": " + newMail, {
              variant: "success",
            });
          } else {
            dispatch(
              UIactions.setFieldError({
                id: "365mail-" + attempt.login,
                value: UIError[locale].similar + ": " + clientMail,
              })
            );
            enqueueSnackbar(
              UIError[locale].similar +
                ": " +
                clientMail +
                "@" +
                attempt.org.domain,
              {
                variant: "warning",
              }
            );
          }
          // dispatch(
          //   UIactions.setField({
          //     id: "365mail-" + attempt.login,
          //     value: response.data.data.mail.toLowerCase().split("@")[0],
          //   })
          // );
        }
      } catch (e) {
        lastError = e;
        if (e.response.status === 404) {
          enqueueSnackbar(UIError[locale].notFound + ": " + newMail, {
            variant: "error",
          });
          dispatch(
            UIactions.setFieldError({
              id: "365mail-" + attempt.login,
              value: UIError[locale].notFound,
            })
          );
        } else {
          enqueueSnackbar(UIError[locale].error + ": " + newMail, {
            variant: "error",
          });
        }
        // ACA REVISAR SI LAREQUEST FALLO MAL O SI ES QUE NO EXISTE LA ORGANIZACION
        // dispatch(worker.finish({ id, error: e.message }));
      }
    }

    dispatch(worker.finish({ id }));
  };
};
