import { Dialog, DialogContent, Grid } from "@mui/material";
import Top from "./Components/Top";
import Picker from "./Components/Picker/Picker";
import Bottom from "./Components/Bottom";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";

const GroupsTravelerModal = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const { payload } = UI.modal2 ?? {};
  const { hideGroup } = payload ?? {};

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  const close = () => {
    dispatch(UIactions.closeModal2());
  };

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose,
        maxWidth: "md",
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container {...{ sx: { width: "100%" } }}>
          <Top {...{ close }} />
          <Picker {...{ hideGroup }} />
          <Bottom {...{ close }} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GroupsTravelerModal;
