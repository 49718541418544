import Category from "./Category";

const UIText = {
  "es-CL": { header: "Video" },
  "en-US": { header: "Video" },
};

const Video = () => {
  return <Category {...{ category: "video", UIText }} />;
};

export default Video;
