/**
 * Renders the Users component.
 *
 * This component displays a list of users and provides functionality for filtering, sorting, and pagination.
 * It fetches user data from the server and renders the data in a paginated format.
 *
 * @returns {JSX.Element} The rendered Users component.
 */
import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import useURLQuery from "../../hooks2/useURLQuery";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import getUsersRecursive from "../../store/actions2/Users/getUsersRecursive";
import getGroupData from "../../store/actions2/Users/getGroupData";
import { usersDataId } from "../../store/actions2/Users/getAllUsers";
import { containsEscaped } from "../../utility/filter";
import CalculatePagination from "../UI2/Utilities/CalculatePagination";
import { sortObjByKey } from "../../utility/sortObjByKey";
import Core from "./Components/Core";
import { recursiveRequestId } from "./Components/Actions/Recursive";
import {
  defaultFilter,
  defaultPagination,
  defaultSelected,
  defaultSort,
} from "../UI2/genericIDs";
import { tunnelsDataId } from "../../store/actions2/Users/getTunnelData";
import { createUsersRequestUI } from "../../store/actions2/Recoveries/createUsersRequest";
import { getW365OrgId } from "../../store/actions2/Windows365/getOrganization";

const Users = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBoilerplate();
  const { group } = useURLQuery();
  const workerId = `groupData${group}`;
  const { done, working, error } = useWorker(workerId);
  const { group: authgroup, admin, user } = auth;
  const filter = UI.filter ?? "";
  const sort = UI.sort ?? { value: "login" };
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };
  const recursive = UI[recursiveRequestId];

  const users = useMemo(() => {
    const users = warehouse[usersDataId] ?? [];
    const tunnels = warehouse[tunnelsDataId] ?? {};
    let usersPlus = [];
    for (const user of users) {
      const connected = tunnels[user.login] ? tunnels[user.login].length : 0;
      const newUser = { ...user, connected };
      usersPlus.push(newUser);
    }
    return usersPlus;
  }, [warehouse]);

  // If user is not an admin move him to his files
  useEffect(() => {
    if (!admin) {
      const url = "/dashboard/files?user=" + encodeURIComponent(user);
      navigate(url, { replace: true });
    }
  }, [navigate, user, admin]);

  // Navigate to base group if no group param is set
  useEffect(() => {
    if (!group && admin) {
      navigate("/dashboard/users?group=" + authgroup, { replace: true });
    }
  }, [dispatch, navigate, group, authgroup,admin]);

  // If group changes clear filters, sort and current worker
  useEffect(() => {
    if (group) {
      dispatch(workers.clear(workerId));
      dispatch(workers.clear(getW365OrgId));
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSort));
      dispatch(warehouseActions.clear());
    }
  }, [dispatch, group, workerId]);

  // If group is set, but not done? Get data!
  useEffect(() => {
    const controller = new AbortController();
    if (group && !done) {
      const payload = { ...auth, id: workerId, controller, group };
      if (recursive) {
        dispatch(getUsersRecursive(payload));
      } else {
        dispatch(getGroupData(payload));
      }
    }
    return () => {
      controller.abort();
    };
  }, [dispatch, auth, group, done, workerId, recursive]);

  const filteredUsers = useMemo(() => {
    return users
      .filter(
        (user) =>
          containsEscaped(user.login, filter) || containsEscaped(user.name, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [users, filter, sort.value, sort.descending]);

  // Array to select all
  const allUsers = useMemo(() => {
    return filteredUsers
      .filter((x) => x.login !== auth.user)
      .map((x) => x.login);
  }, [filteredUsers, auth.user]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredUsers.length }));
  }, [dispatch, filteredUsers.length]);

  // Calculating effective pagination
  const { from, to } = CalculatePagination(pagination);
  const paginatedUsers = useMemo(() => {
    return filteredUsers.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredUsers]);

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSort));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(warehouseActions.unload(usersDataId));
      dispatch(warehouseActions.unload(tunnelsDataId));
      dispatch(workers.clear(`groupData${group}`));
      dispatch(workers.clear(createUsersRequestUI));
    };
  }, [dispatch, group]);

  return (
    <Core
      {...{ users: paginatedUsers, allUsers, done, working, error, workerId }}
    />
  );
};

export default Users;
