import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import BasicHeaders from "../../../UI2/BasicLayout/Components/BasicHeaders";

const headersData = [
  {
    UIText: {
      "es-CL": { label: "Dispositivo" },
      "en-US": { label: "Device" },
    },
    filterKey: "name",
    xs: 3,
  },
  {
    UIText: {
      "es-CL": { label: "Usuario" },
      "en-US": { label: "User" },
    },
    filterKey: "user",
    xs: true,
  },
  {
    UIText: {
      "es-CL": { label: "Tamaño" },
      "en-US": { label: "Size" },
    },
    filterKey: "space_active",
    xs: 2,
  },
  {
    UIText: {
      "es-CL": { label: "Último respaldo" },
      "en-US": { label: "Last backup" },
    },
    filterKey: "lastBkp",
    xs: 3,
  },
];

export const selectedDevicesUI = "selectedDevices";
export const exclusionDevicesUI = "exclusionDevices";
export const currentExclusionDevicesUI = "currentExclusionDevices";

const Headers = () => {
  const { UI } = useUIBoilerplate();
  const fullArray = UI[currentExclusionDevicesUI];
  return (
    <BasicHeaders
      {...{ arrayId:exclusionDevicesUI, fullArray, headers: headersData }}
    />
  );
};

export default Headers;
