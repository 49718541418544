import { Collapse, Grid, Typography } from "@mui/material";
import TextField from "../../UI2/TextField";
import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import VerificationCode from "./VerificationCode";
import {
  sendCodeId,
  sendVerificationCode,
} from "../../../store/actions2/Windows365/sendVerificationCode";
import { useWorker } from "../../../hooks2/useWorker";

const UIText4 = {
  "es-CL": {
    label: "Correo Microsoft",
    placeholder: "usuario@cloner.onmicrosoft.com",
  },
  "en-US": {
    label: "Microsoft email",
    placeholder: "user@cloner.onmicrosoft.com",
  },
};
const UIText5 = {
  "es-CL": { label: "Enviar codigo de verificación" },
  "en-US": { label: "Send verification code" },
};

export const emailUI = "orgEmail";
export const validationCodeUI = "validationCode";

const Form = () => {
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const { working } = useWorker(sendCodeId);
  const {payload} = UI.modal??{}
  const {domain} = payload??{}


  const { value: mail } = UI[emailUI] ?? {};

  const onClick = () => {
    dispatch(
      sendVerificationCode({
        ...auth,
        mail:`${mail}@${domain}`,
        locale: settings.locale,
      })
    );
  };

  return (
    <Collapse in={true}>
      <Grid
        container
        item
        {...{ rowGap: 2, columnSpacing: 2, sx: { paddingTop: 0.5 } }}
      >
        <TextField {...{ id: emailUI, UIText: UIText4, xs: true }} />
        <Grid container item xs={'auto'} alignItems='center'>
          <Typography color='grey'>
            @{domain}
          </Typography>
        </Grid>
        <Button
          {...{
            disabled: !mail,
            UIText: UIText5,
            onClick,
            working,
            xs: 12,
            variant: "outlined",
            size:'medium',
          }}
        />
        <VerificationCode />
      </Grid>
    </Collapse>
  );
};

export default Form;
