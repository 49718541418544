import { Grid } from "@mui/material";
import TextField from "../../../../UI2/SimpleTextField";
import CheckButton from "../../../../UI2/Specialized/Check";
import { arrayId } from "../UsersList";

const UIText365Mail = {
  universal: {},
};

export const bkpMailArrayId = "bkpMail";

const BackupMail = ({ login, fontSize }) => {
  return (
    <Grid container item {...{xs:12, justifyContent:"center"}}>
      <CheckButton {...{ id: login, arrayId: bkpMailArrayId, xs: "auto", fontSize:1 }} />
    </Grid>
  )
};

export default BackupMail;
