import { Grid } from "@mui/material";
import Headers from "./Components/Headers";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Divider from "../../../UI2/Divider";
import Options from "./Components/Options/Options";

const UIText = {
  "es-CL": {
    label: "Tamaño total: ",
  },
  "en-US": {
    label: "Total size: ",
  },
};

const Devices = () => {
  const { settings } = useUIBoilerplate();
  const { label } = UIText[settings.locale];

  return (
    <Grid container {...{padding:2, sx: { width: "100%" } }}>
      <Grid
        container
        item
        sx={{ paddingBottom: 0}}
      >
        <Headers />
        <Grid
          container
          item
          sx={{
            height: 200,
            overflow: "scroll",
            borderBottom: "1px lightgrey solid",
          }}
        >
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
          <DeviceRow />
        </Grid>
      </Grid>
      <Grid
        container
        item
        sx={{ padding: 2, paddingLeft: 2 }}
        xs={12}
      >
        {label}
      </Grid>
      <Divider />
      <Options />
    </Grid>
  );
};

export default Devices;
