import { SystemUpdateAlt } from "@mui/icons-material";
import ToggleButton from "../../../UI2/ToggleButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import { currentGroupnameId } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";

const UIText = {
  "es-CL": {
    label: "Obtener",
    fixedTooltip: "Obtener las credenciales desde el grupo padre",
  },
  "en-US": { label: "Fetch", fixedTooltip: "Fetch credentials shared by parent group" },
};

export const w365fetchUI = "windows365fetch";

const GroupLock = ({ domain = null, own,working }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { group } = useURLQuery();
  const groupname = UI[currentGroupnameId];

  console.log("own", own, );


  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "w365-fetch",
        payload: {
          groupname,
          payload: {
            domain,
            id: own.id,
            group_id: group,
            prevent_propagation: domain ? own.prevent_propagation: true,
            group_locked: domain ? !own.group_locked: false,
          },
        },
      })
    );
  };

  return (
    <ToggleButton
      {...{
        id: w365fetchUI,
        UIText,
        onClick,
        startIcon: <SystemUpdateAlt sx={{ marginRight: 1, fontSize: 20 }} />,
        color: "primary",
        variant: "outlined",
      }}
    />
  );
};

export default GroupLock;
