import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import ClearInsert from "./TextFieldInserts/ClearInsert";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";

// const UIText = [
//   {
//     "es-CL": {
//       label: "",
//       placeholder: "",
//       tooltip: "",
//     },
//   },
// ];

const FilterField = (props) => {
  const {
    id = "filter",
    xs,
    size = "small",
    onPressEnter,
    onChange,
    UIText = {},
    disabled,
    fontSize,
    forceLowerCase,
    innerPadding,
    fullWidth,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "xs",
    "size",
    "onPressEnter",
    "UIText",
    "fontSize",
    "forceLowerCase",
    "innerPadding",
  );
  const { dispatch, settings, UI } = useUIBoilerplate();
  const ref = useRef(null);
  const locale = settings.locale
  const value = UI[id] ?? "";

  useEffect(() => {
    ref.current.value = value;
  }, [value]);

  const { tooltip, label, placeholder } = UIText[locale];

  const debouncedChange = debounce((e) => {
    if (onChange) {
      onChange(e);
    } else {
      const value = e.target.value;
      dispatch(UISliceActions.setValue({ id, value }));
    }
  }, 300);

  const handleChange = (e) => {
    debouncedChange(e);
  };

  const debouncedPressEnter = debounce((e) => {
    if (onPressEnter && (e.key === "Enter" || e.key === "NumpadEnter")) {
      onPressEnter(e);
    }
  }, 300);

  const handleEnter = (e) => {
    debouncedPressEnter(e);
  };

  return (
    <AutoGrid xs={xs}>
      <AutoHelp {...{ tooltip, disabled }}>
        <TextField
          {...filteredProps}
          {...{
            label,
            placeholder,
            fullWidth: xs || fullWidth ? true : undefined,
            inputRef: ref,
            onChange: handleChange,
            onKeyUp: handleEnter,
            size,
            InputProps: {
              ...filteredProps.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Search {...{ sx:{fontSize: fontSize+2}}}/>
                </InputAdornment>
              ),
              endAdornment: value ? (
                <ClearInsert {...{ id, fontSize }} />
              ) : null,
              sx: { fontSize, paddingRight:0.5 },
            },
            InputLabelProps:{
              sx:{fontSize:15}
            },
            inputProps: {
              sx: { textTransform: forceLowerCase ? "lowercase" : undefined, padding:innerPadding },
            },
          }}
        />
      </AutoHelp>
    </AutoGrid>
  );
};

export default FilterField;
