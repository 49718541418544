import { Chip } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";

const UIText = {
  "es-CL": { text: "dispositivos",active:'Activos', suspended: "Suspendidos"},
  "en-US": { text: "devices",active:'Active', suspended: "Suspended" },
};

const Devices = ({ devices, suspensions, fontSize }) => {
  const { settings } = useUIBoilerplate();
  const { text,active,suspended } = UIText[settings.locale];

  let color;
  if (suspensions) {
    color = "warning";
  } else if (devices) {
    color = "primary";
  }

  return (
    <AutoHelp {...{fixedTooltip:
      <ul style={{marginTop:5, marginBottom:5,paddingLeft: 15 }}>
        <li>{active}: {devices-suspensions}</li>
        <li>{suspended}: {suspensions}</li>
      </ul>
    }}>
      <Chip
        size="small"
        label={
          <span style={{ cursor: "default" }}>{`${devices} ${text}`}</span>
        }
        sx={{ fontSize: fontSize - 1 }}
        color={color}
      />
    </AutoHelp>
  );
};

export default Devices;
