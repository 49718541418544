import Username from "./Components/Username";
import Device from "./Components/Device";
import Size from "./Components/Size";
import Backup from "./Components/Backup";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import BasicRow from "../../../../UI2/BasicLayout/Components/BasicRow";
import { exclusionDevicesUI } from "../Headers2";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const DeviceRow = (props) => {
  const { settings } = useUIBoilerplate();
  const { mid } = props;

  const data = {
    id: mid,
    arrayId:exclusionDevicesUI,
    elements: [
      { content: <Username {...props} {...{ fontSize }} />, xs: 3 },
      { content: <Device {...props} {...{ fontSize }} />, xs: true },
      { content: <Size {...props} {...{ fontSize }} />, xs: 2 },
      { content: <Backup {...props} {...{ fontSize, settings }} />, xs: 3 },
    ],
  };

  return <BasicRow {...data} />;
};

export default DeviceRow;
