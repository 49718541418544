import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import Reasons, { reasonUI } from "./Reasons";
import OtherReason, { otherReasonUI } from "./OtherReason";
import useUIBoilerplate from "../../../../../../../../hooks2/useUIBoilerplate";
import SlimAlert from "../../../../../../../UI2/SlimAlert";

const UIText = {
  "es-CL": { subtitle4: "Motivo de la solicitud" },
  "en-US": { subtitle4: "Reason for the request" },
};

const Reason = () => {
  const { settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { subtitle4 } = UIText[locale];
  const reason = UI[reasonUI] ?? "";

  const { error } = UI[otherReasonUI] ?? {};

  return (
    <Fragment>
      <Grid container item xs={12}>
        <Typography fontWeight="bold">{subtitle4}</Typography>
      </Grid>
      <Reasons />
      {reason === "Otro" ? <OtherReason /> : null}
      <SlimAlert notCollapse {...{ errors: [error], xs: 12 }} />
    </Fragment>
  );
};

export default Reason;
