import BasicRow from "../../../UI2/BasicLayout/Components/BasicRow";
import Name from "./Components/Name";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Created from "./Components/Created";
import DownloadLink from "./Components/DownloadLink";
import Actions from "./Components/Actions";
import Protected from "./Components/Protected";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };
const fontSize = 13;

const SharedRow = (props) => {
  let { id, name: filename, created, has_password } = props;
  const { settings } = useUIBoilerplate();
  const locale = settings.locale;

  const data = {
    id,
    elements: [
      { content: <Name {...{ name: filename, fontSize }} />, xs: 3 },
      { content: <Protected {...{ has_password, locale, fontSize }} />, justify:'center', xs: 1 },
      {
        content: <Created {...{ created, locale, fontSize }} />,
        xs: 2,
      },
      {
        content: <DownloadLink {...{ id, fontSize }} />,
        xs: true,
      },
      {
        content: <Actions {...props} />,
        justify: "end",
        xs: 0.5,
      },
    ],
  };

  return <BasicRow {...data} />;
};

export default SharedRow;
