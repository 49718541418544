import { Fragment, useEffect, useMemo } from "react";
import Divider from "../../../../../UI2/Divider";
import useTrees from "../../../../../../hooks2/useNewTrees";
// import Category from "./Category2";
import Category from "./Category";
import { Collapse, List } from "@mui/material";
import MoreLess from "../Botones/MoreLess";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../../store/slices/UI2";

export const moreCategoriesUI = "moreCategories";
export const currentCatsUI = "currentCategories";
export const shouldExpandUI = "shouldExpand";

const MailCategories = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const current = UI[currentCatsUI] ?? [];
  const catsCount = current.length;
  const shouldExpand = UI[shouldExpandUI];
  const expanded = UI[moreCategoriesUI];
  const trees = useTrees();
  const categories = useMemo(() => {
    let cats = [];
    for (const key in trees) {
      if (key.match("mails-")) {
        cats.push(trees[key]);
      }
    }
    return cats;
  }, [trees]);

  useEffect(() => {
    if (catsCount > 9 && !shouldExpand) {
      dispatch(UIactions.setValue({ id: shouldExpandUI, value: true }));
    } else if (catsCount <= 9 && shouldExpand) {
      dispatch(UIactions.setValue({ id: shouldExpandUI, value: false }));
      dispatch(UIactions.setValue({ id: moreCategoriesUI, value: false }));
    }
  }, [dispatch, catsCount, shouldExpand]);
  //   console.log("categories", categories);

  const categoriesButtons = categories
    .slice(1)
    .map((tree, idx) => <Category key={idx} {...{ tree, idx: 0, level: 0,root:tree[0].id }} />);

  return (
    <Fragment>
      <Divider {...{ color: "secondary.light" }} />
      <List
        dense
        sx={{
          width: 250,
          height: "49vh",
          overflowY: expanded ? "scroll" : "clip",
        }}
        disablePadding
      >
        <Collapse in={expanded} collapsedSize={shouldExpand ? "48vh" : "54vh"}>
          {categoriesButtons}
        </Collapse>
        <MoreLess />
      </List>
    </Fragment>
  );
};

export default MailCategories;
