import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { BoxStateIcon, ExpandIcon } from "./utils";

const TreeNode = (props) => {
  const { tree, idx, level } = props;

  const { name, childrenIdx, backup } = tree[idx];

  let icon = BoxStateIcon(backup);
  let expandIcon = ExpandIcon(!!childrenIdx.length);

  const children = childrenIdx.map((idx) => (
    <TreeNode {...{ tree, idx, level: level + 1, key: idx }} />
  ));

  return (
    <Fragment>
      <Grid
        item
        container
        {...{
          xs: 12,
          wrap: "nowrap",
          paddingLeft: level * 3.5,
          sx: { backgroundColor: `rgba(39, 181, 245, ${level * 0.05})` },
          alignItems: "center",
        }}
      >
        {icon}
        {expandIcon}
        <Typography noWrap fontSize={14}>
          {name}
        </Typography>
      </Grid>
      {children}
    </Fragment>
  );
};

export default TreeNode;
