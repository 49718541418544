/**
 * A switch component that allows the user to include devices from subgroups.
 * @param {Object} props - The props object for the component.
 * @param {boolean} props.working - A boolean indicating whether the component is currently working.
 * @returns {JSX.Element} - The JSX element for the component.
 */
import { Grid } from "@mui/material";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { devicesLoadedId } from "../../Reports";
import Switch from "../../../UI2/Switch2";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": { label: "Incluir subgrupos", fixedTooltip:'Incluir dispositivos de subgrupos' },
  "en-US": { label: "Include subgroups", fixedTooltip:'Include devices from subgroups' },
};

export const recursiveRequestId = "recursiveRequest";

const RecursiveSwitch = (props) => {
  const { working: disabled } = props;
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(workers.clear(devicesLoadedId));
  };

  return (
    <Grid container item xs={true} alignItems="center" justifySelf="start">
      <Switch 
      labelRight
      {...{
        id: recursiveRequestId,
        UIText,
        disabled,
        onChange,
        size: "small",
        xs: "auto",
      }} />
    </Grid>
  );
};

export default RecursiveSwitch;