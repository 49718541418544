/**
 * Downloads a template file.
 * @param {string} locale - The locale of the user.
 * @returns {Function} - An async function that dispatches actions to download the template file.
 */
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as workers } from "../../slices/workers";

const UIText = {
  "es-CL": { error: "Error descargando archivo .xlsx: " },
  "en-US": { error: "Error downloading .xlsx file: " },
};

export const downloadTemplateUI = "downloadTemplate";

export const downloadTemplate = (locale) => {
  const { error } = UIText[locale];
  const id = downloadTemplateUI;
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  return async (dispatch) => {
    try {
      dispatch(workers.start(id));
      const url = "https://instaladores.cloner.cl/templado_usuarios_cloner.xlsx"

      const anchor = document.createElement("a");
      anchor.href = url;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Dispatch finishDownload action
      dispatch(workers.finish({ id }));
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

    //   let newURL = new URL(
    //     "https://instaladores.cloner.cl/templado_usuarios_cloner.xlsx"
    //   );

    //   const anchor = document.createElement("a");
    //   anchor.href = newURL;

    //   document.body.appendChild(anchor);
    //   anchor.click();
    //   document.body.removeChild(anchor);
    // } catch (e) {
    //   console.error(e)
    //   enqueueSnackbar(error + e.message, { variant: "error" });
    // }
    // dispatch(statusSliceActions.finishSubmit());