export const sortObjByKey = (a, b, key, desc) => {
    const type = typeof a[key];
    let A = a[key];
    let B = b[key];
    if (type === "string") {
      A = A.toLowerCase();
      B = B.toLowerCase();
    }
    if (desc) {
      if (A > B) {
        return -1;
      } else if (A < B) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (A < B) {
        return -1;
      } else if (A > B) {
        return 1;
      } else {
        return 0;
      }
    }
  };
  