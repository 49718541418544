import { CircularProgress, IconButton } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import filterObjKeys from "../../utility/filterObjectKeys";
import SingleTwinSelect from "./Utilities/SingleTwinSelect";
import UIArrayError from "./Utilities/UIArrayError";
import AutoGrid from "./Wrappers/AutoGrid";
import AutoHelp from "./Wrappers/AutoHelp";

// const options = [
//   {
//     icon: <Component />,
//     UIText: {
//       "es-CL": {
//         tooltip: "",
//       },
//     },
//   },
// ];

const ToggleIcon = (props) => {
  const { id, options, color = "info", disabled, onClick, xs, working } = props;
  const filteredProps = filterObjKeys(props, "options", "working");
  const { dispatch, UI, settings } = useUIBoilerplate();
  const selected = !!UI[id];
  const locale = settings["locale"];

  if (UIArrayError(id, locale, options)) {
    return;
  }

  const [opt1, opt2] = options;
  const { icon: icon1, UIText: UITxt1 = {} } = opt1 ?? {};
  const { icon: icon2, UIText: UITxt2 = {} } = opt2 ?? {};
  const { tooltip: ttp1, fixedTooltip: fttp1 } = UITxt1[locale] ?? {};
  const { tooltip: ttp2, fixedTooltip: fttp2 } = UITxt2[locale] ?? {};

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    dispatch(UISliceActions.toggle(id));
  };

  let icon = SingleTwinSelect(icon1, icon2, selected);
  let tooltip = SingleTwinSelect(ttp1, ttp2, selected);
  let fixedTooltip = SingleTwinSelect(fttp1, fttp2, selected);

  if (working) {
    icon = <CircularProgress size={14} />;
  }

  return (
    <AutoGrid {...{ xs }}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled: disabled || working }}>
        <IconButton
          {...filteredProps}
          {...{
            disabled: disabled || working,
            color: selected ? color : undefined,
            onClick: handleClick,
          }}
        >
          {icon}
        </IconButton>
      </AutoHelp>
    </AutoGrid>
  );
};

export default ToggleIcon;
