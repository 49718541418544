import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": {
    success: "Ticket de soporte enviado exitosamente!",
    error: "Error al enviar ticker de soporte: ",
  },
  "en-US": {
    success: "Support ticket successfully sent!",
    error: "Error sending support ticket: ",
  },
};

export const postSupportId = "postSupport";

export const postSupportTicket = (data) => {
  const {
    server,
    cloner_key,
    user,
    contact,
    navigator,
    type,
    scope,
    details,
    locale,
  } = data;
  const id = postSupportId;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = server + "api/support";
      const bodyData = JSON.stringify({
        msg: `Usuario: ${user}\nCorreo de contacto: ${contact}\nNavegador: ${navigator}\nTipo de requerimiento: ${type}\nAlcance del requerimiento: ${scope}\n============== Detalles Adicionales ==============\n${details}`,
      });
      console.log('support msg',bodyData)
      await axios.post(url, bodyData, { headers: { cloner_key } });

      // POST login data
      dispatch(workers.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
  };
};
