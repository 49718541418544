import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { Dialog, DialogContent } from "@mui/material";
import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import getMail, {
  getMailId,
  mailData,
} from "../../store/actions2/Mails/getMail";
import Header from "./Components/Header/Header";
import FromTo from "./Components/FromTo/FromTo";
import Loading from "./Components/Loading";
import Mail, { iframeHeightUI } from "./Components/Mail";
import Attachs from "./Components/Attachs/Attachs";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { warehouseSliceActions as warehouse } from "../../store/slices/warehouse";
import { useWorker } from "../../hooks2/useWorker";

const MailView = () => {
  const { auth, dispatch, UI } = useUIBoilerplate();
  const { done } = useWorker(getMailId);
  const { payload = {} } = UI.modal ?? {};
  const { id: mailId } = payload;

  useEffect(() => {
    if (!done) {
      const controller = new AbortController();
      dispatch(getMail({ ...auth, mailId, controller }));
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, mailId, done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear("modal"));
      dispatch(UIactions.clear(iframeHeightUI));
      dispatch(warehouse.unload(mailData));
      dispatch(workers.clear(getMailId));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    dispatch(UIactions.closeModal());
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "md" }}>
      <Header {...payload} />
      <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
        <FromTo {...payload} />
        <Mail />
        <Loading />
        <Attachs {...payload} />
      </DialogContent>
    </Dialog>
  );
};

export default MailView;
