import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../hooks2/useWorker";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";
import { rowErrorsUI } from "./UsersList/UserRow";
import { newUsersUI } from "./TopBar/UploadSheet";
import { postUserId } from "../../../store/actions2/UserForm/postUsers";

const UIText = {
  "es-CL": { label: "Continuar" },
  "en-US": { label: "Next" },
};

const Create = (props) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { working } = useWorker(postUserId);
  const errors = UI[rowErrorsUI] ?? [];
  const users = UI[newUsersUI] ?? [];

  const onClick = () => {
    dispatch(UIactions.openModal2({ type: "create-users-confirmation" }));
  };

  const disabled = !!errors.length || !users.length;

  return <Button {...{ disabled, UIText, onClick, working }} />;
};

export default Create;
