import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Subtitle from "./Subtitle";
import Password1 from "./Password1";
import Password2 from "./Password2";
import ChangePassword, { changePasswordUI } from "./ChangePassword";
import CollapseGrid from "../../../UI2/CollapseGrid";

const Password = ({ isNew, users }) => {
  const { UI, settings } = useUIBoilerplate();
  const locale = settings.locale;
  const changePassword = UI[changePasswordUI];

  return (
    <Grid container item xs={12}>
      {users.length > 1 ? (
        <ChangePassword {...{ locale }} />
      ) : (
        <Subtitle {...{ locale }} />
      )}
      <CollapseGrid
        open={changePassword || users.length === 1 || isNew}
        outerProps={{ xs: 12 }}
        innerProps={{ rowGap: 2, paddingTop:0.5 }}
      >
        <Password1 {...{ isNew }} />
        <Password2 {...{ isNew }} />
      </CollapseGrid>
    </Grid>
  );
};

export default Password;
