import { Person } from "@mui/icons-material";
import { Avatar } from "@mui/material";

const getAvatar = (name = "") => {
  const words = name.split(" ");

  const getValidInitial = (word = "") => {
    const char = word.charAt(0);
    const valid = char.match(/[A-Za-z]/);
    if (valid) {
      return char.toUpperCase();
    }
    return "";
  };

  if (!words.length) {
    return (
      <Avatar sx={{ bgcolor: "secondary.light" }}>
        <Person />
      </Avatar>
    );
  } else if (words.length === 1) {
    return (
      <Avatar sx={{ bgcolor: "secondary.light" }}>{getValidInitial(words[0])}</Avatar>
    );
  } else {
    return (
      <Avatar sx={{ bgcolor: "secondary.light" }}>
        {getValidInitial(words[0])}
        {getValidInitial(words[1])}
      </Avatar>
    );
  }
};

export default getAvatar;
