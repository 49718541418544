import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { emailUI } from "./Form";
import { useWorker } from "../../../hooks2/useWorker";
import postClient, {
  postClientId,
} from "../../../store/actions2/Windows365/postClient";

const UIText = {
  "es-CL": { label: "Activar" },
  "en-US": { label: "Activate" },
};

export const validationCodeUI = "validationCode";
const Activate = () => {
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { working } = useWorker(postClientId);
  const { payload } = UI.modal ?? {};
  const { domain, orgId } = payload ?? {};
  const locale = settings.locale;

  const { value: mail } = UI[emailUI] ?? {};

  const vc0 = UI["vc0"] ?? "";
  const vc1 = UI["vc1"] ?? "";
  const vc2 = UI["vc2"] ?? "";
  const vc3 = UI["vc3"] ?? "";
  const vc4 = UI["vc4"] ?? "";
  const vc5 = UI["vc5"] ?? "";
  const mail_code = vc0 + vc1 + vc2 + vc3 + vc4 + vc5;

  const onClick = () => {
    dispatch(
      postClient({
        ...auth,
        locale,
        payload: {
          organization_id: orgId,
          client_mail: `${mail}@${domain}`,
          mail_code,
        },
      })
    );
  };

  const disabled = !mail || !vc0 || !vc1 || !vc2 || !vc3 || !vc4 || !vc5;

  return (
    <Button {...{ disabled, working, UIText, onClick, sx: { width: 100 } }} />
  );
};

export default Activate;
