import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import IconButton from "./IconButton";
import CalculatePagination from "./Utilities/CalculatePagination";
import UIError from "./Utilities/UIError";
import { defaultPagination } from "./genericIDs";

const UIText = {
  "es-CL": { of: "de" },
  "en-US": { of: "of" },
};
const UIText2 = {
  "es-CL": { tooltip: "Siguiente" },
  "en-US": { tooltip: "Next" },
};
const UIText3 = {
  "es-CL": { tooltip: "Anterior" },
  "en-US": { tooltip: "Previous" },
};

export const Pagination = (props) => {
  const { id = defaultPagination, xs } = props;
  const { dispatch, UI, settings } = useUIBoilerplate();
  const locale = settings.locale;
  const pagination = UI[id] ?? { page: 0, rowsPerPage: 0, count: 0 };
  const active = UI.activePagination??true

  if (UIError(id, locale, UIText)) {
    return;
  }

  if (!active) {
    return;
  }

  const { of } = UIText[locale];
  const { from, to, total } = CalculatePagination(pagination);

  const handleNextPage = () => {
    dispatch(UISliceActions.nextPage(id));
  };

  const handlePreviousPage = () => {
    dispatch(UISliceActions.previousPage(id));
  };

  return (
    <Grid
      container
      item
      {...{
        direction: "row",
        wrap: "nowrap",
        alignItems: "center",
        columnGap: 1,
        xs,
      }}
    >
      <Grid item {...{ xs: "auto", sx: { cursor: "default" } }}>
        <Typography fontSize="0.8rem">{`${from}-${to} ${of} ${total}`}</Typography>
      </Grid>
      <IconButton
        {...{
          disabled: from <= 1,
          onClick: handlePreviousPage,
          icon: <ChevronLeft sx={{ fontSize: 20 }} />,
          UIText: UIText3,
          sx: { padding: 0.1 },
          xs: "auto",
        }}
      />
      <IconButton
        {...{
          disabled: to >= total || !total,
          onClick: handleNextPage,
          icon: <ChevronRight sx={{ fontSize: 20 }} />,
          UIText: UIText2,
          sx: { padding: 0.1 },
          xs: "auto",
        }}
      />
    </Grid>
  );
};
