import { Shield } from "@mui/icons-material";
import Button from "../../UI2/Button";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import { gauthCodeUI } from "../TwoFactorAuthentication";
import notEmptyField from "../../../utility/validations/notEmpty";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { enableTFA, enableTFAId } from "../../../store/actions2/TFA/enableTFA";
import { useWorker } from "../../../hooks2/useWorker";

const UIText = {
  "es-CL": { label: "Activar" },
  "en-US": { label: "Activate" },
};

const Activate = () => {
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const { working } = useWorker(enableTFAId);
  const { error, value: code } = UI[gauthCodeUI] ?? {};
  const locale = settings.locale;

  const onMouseDown = () => {
    dispatch(
      UIactions.validateField({
        id: gauthCodeUI,
        fn: notEmptyField,
        force: true,
        locale,
      })
    );
  };

  const onClick = () => {
    dispatch(
      enableTFA({
        ...auth,
        code,
        locale,
      })
    );
  };

  const disabled = !!error;

  return (
    <Button
      {...{
        disabled,
        startIcon: <Shield />,
        UIText,
        onClick,
        onMouseDown,
        working,
      }}
    />
  );
};

export default Activate;
