import Button from "../../UI2/Button";
import { UISliceActions } from "../../../store/slices/UI2";
import notEmptyField from "../../../utility/validations/notEmpty";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { backupDateUI } from "./Display/Components/Options/Options";

const UIText = {
  "es-CL": { label: "Iniciar solicitud" },
  "en-US": { label: "Start request" },
};

const Start = (props) => {
  const { dispatch, settings } = useUIBoilerplate();

  const onMouseDown = () => {
    dispatch(
      UISliceActions.validateField({
        id: backupDateUI,
        force: true,
        fn: notEmptyField,
        locale: settings.locale,
      })
    );
  };

  const onClick = () => {
    console.log("");
  };

  return <Button {...{ UIText, onMouseDown, onClick, size: "medium" }} />;
};

export default Start;
