import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as worker } from "../../slices/workers";
import { parseGroupsRecursive } from "./utils";

export const groupsRecursiveId = "groupsRecursive";
export const devicesDataId = "devices";
export const devicesListId = "devicesList";

const getGroupsRecursive = (data) => {
  console.log("getting group data!");
  const id = groupsRecursiveId;
  return async (dispatch) => {
    const { server, cloner_key, group, controller } = data;
    dispatch(worker.start(id));
    try {
      const url = server + "api/groups_recursive";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { group },
      });

      const parsedData = parseGroupsRecursive(response.data);

      dispatch(warehouse.load({ id: devicesDataId, data: parsedData }));
      const newDevices2 = parsedData.filter( x => !!x.mid).map(x => x.mid)
      dispatch(warehouse.load({id:devicesListId, data:newDevices2}))
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id, error: e.message }));
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
};

export default getGroupsRecursive;
