import { ChevronRight } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import { useEffect } from "react";
import { selectedGroupId2, selectedGroupIdx2 } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";

const ListItem = (props) => {
  const { id, idx, name, childrenIdx, trail, selected, selectedGroup } = props;
  const dispatch = useDispatch();

  let bgcolor = undefined;

  if (selected) {
    bgcolor = `rgba(0, 167, 132, 0.2 )`;
  } else if (trail) {
    bgcolor = `rgba(0, 167, 132, 0.1 )`;
  }

  useEffect(() => {
    if (trail || selected) {
      const element = document.getElementById("group-" + id);
      element.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "start",
      });
    }
  }, [id, trail, selected, selectedGroup]);

  const onClick = () => {
    dispatch(UIactions.setValue({ id: selectedGroupId2, value: id }));
    dispatch(UIactions.setValue({ id: selectedGroupIdx2, value: idx }));
  };

  return (
    <ListItemButton {...{ id: "group-" + id, onClick, sx: { bgcolor } }}>
      <ListItemText
        primary={name}
        primaryTypographyProps={{ fontSize: 13, noWrap: true }}
      />
      {childrenIdx.length ? (
        selected || trail ? (
          <ChevronRight color="primary" fontSize="10px" />
        ) : (
          <ChevronRight fontSize="10px" />
        )
      ) : null}
    </ListItemButton>
  );
};

export default ListItem;
