/**
 * Renders the size of a device in a human-readable format.
 * @param {Object} props - The component props.
 * @param {string} props.fontSize - The font size of the text.
 * @param {number} props.space_active - The amount of active space on the device in bytes.
 * @returns {JSX.Element} - The rendered component.
 */
import { Typography } from "@mui/material";
import { humanBytes } from "../../../../../utility/humanizers";

const Size = ({ fontSize, space_active }) => {
  return (
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {humanBytes(space_active)}
      </Typography>
  );
};

export default Size;
