import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";
import { UISliceActions } from "../../slices/UI2";
import { devicesLoadedId } from "../../../components/Devices/Devices";

const UIText = {
  "es-CL": {
    success: "Dispositivo guardado exitosamente",
    error: "Error al modifical el dispositivo:",
  },
  "en-US": {
    success: "Device saved successfully",
    error: "Error trying to modify device:",
  },
};

export const postDeviceId = "postDevice";

const postDevicesInfo = (data) => {
  const { server, cloner_key, worldAdmin, updateData, locale } = data;
  const { success, error } = UIText[locale];
  const id = postDeviceId;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      for (const data of updateData) {
        const { config, password, suspended } = data;
        const bodyData = JSON.stringify(config);
        const url = server + "api/user/setconf";
        await axios.post(url, bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
        // console.log('config',url,bodyData)

        const bodyData2 = JSON.stringify(password);
        const url2 = server + "api/machine/chgcpasswd";
        await axios.post(url2, bodyData2, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
        // console.log('config',url2,bodyData2)

        if (worldAdmin) {
          const bodyData3 = JSON.stringify(suspended);
          const url3 = server + "api/service/status";
          await axios.post(url3, bodyData3, {
            headers: { "Content-Type": "application/json", cloner_key },
          });
        }
        // console.log('config',url3,bodyData3)
      }
      dispatch(workers.finish({ id }));
      dispatch(workers.clear(devicesLoadedId));
      enqueueSnackbar(success, { variant: "success" });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
    dispatch(UISliceActions.clear("selected"));
  };
};

export default postDevicesInfo;
