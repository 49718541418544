export const reasons = [
  {
    value: "Ransomware",
    universal: {
      label: "Ransomware",
    },
  },
  {
    value: "Desvinculación de usuario",
    'es-CL':{label:'Usuario desvinculado'},
    'en-US':{label:'User not working anymore'},
  },
  {
    value: "Baja del servicio",
    'es-CL':{label:'Baja del servicio'},
    'en-US':{label:'Service cancelled'},
  },
  {
    value: "Destrucción/falla del equipo",
    'es-CL':{label:'Destrucción/falla del equipo'},
    'en-US':{label:'Device destroyed/faulty'},
  },
  {
    value: "Perdida/robo del equipo",
    'es-CL':{label:'Perdida/robo del equipo'},
    'en-US':{label:'Device lost/stolen'},
  },
  {
    value: "Eliminación accidental",
    'es-CL':{label:'Eliminación accidental'},
    'en-US':{label:'Accidental deletion'},
  },
  {
    value: "Eliminación intencional/maliciosa",
    'es-CL':{label:'Eliminación intencional/maliciosa'},
    'en-US':{label:'Intentional/malicious deletion'},
  },
  {
    value: "Testeo del servicio",
    'es-CL':{label:'Testeo del servicio'},
    'en-US':{label:'Service testing'},
  },
  {
    value: "Otro",
    'es-CL':{label:'Otro'},
    'en-US':{label:'Other'},
  },
];
