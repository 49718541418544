import { WarningAmber } from "@mui/icons-material"
import useUIBoilerplate from "../../hooks2/useUIBoilerplate"
import AutoHelp from "./Wrappers/AutoHelp"

const WarningTooltip = ({UIText, size='1.2rem'})=>{
    const {settings} = useUIBoilerplate()
    const {tooltip:fixedTooltip} = UIText[settings.locale]

    return (
        <AutoHelp {...{fixedTooltip}}>
            <WarningAmber color="warning" sx={{fontSize:size}}/>
        </AutoHelp>
    )
}

export default WarningTooltip