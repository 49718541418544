import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useUIBoilerplate = ()=>{
  const auth = useSelector(state => state.authentication.data)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UI = useSelector(state => state.UI.UI)
  const settings = useSelector(state => state.settings)
  const warehouse = useSelector(state => state.warehouse.storage)

  return ({auth,navigate,dispatch,UI,settings,warehouse})
}

export default useUIBoilerplate