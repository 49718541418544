import { Grid } from "@mui/material";
import BasicHeaders from "../../../UI2/BasicLayout/Components/BasicHeaders";
import HeadersData from "./Headers";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { newUsersUI } from "../TopBar/UploadSheet";
import { sortObjByKey } from "../../../../utility/sortObjByKey";
import { useMemo } from "react";
import UserRow from "./UserRow/UserRow";

export const arrayId = "integrationUsers";
export const sortId = "createdSort";

const UsersList = (props) => {
  const { UI } = useUIBoilerplate();
  const { users } = props;

  // TODO: enable filters & update .xlsx template
  const usersRows = useMemo(() => {
    let usrs = [...(users ?? [])];
    const sort = UI[sortId] ?? { value: "mail", descending: false };

    return usrs
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending))
      .map((data, idx) => <UserRow {...data} key={idx} arrayId={arrayId} />);
  }, [users]);

  const usersID = users.map((user) => user.login);

  return (
    <Grid container item {...{ xs: 12 }}>
      <BasicHeaders {...{arrayId:arrayId, sortId, fullArray:usersID, headers: HeadersData }} />
      <Grid
        container
        item
        {...{
          paddingLeft: 2,
          paddingRight: 2,
          xs: 12,
          sx: { height: 400, overflow: "scroll" },
        }}
        alignContent="start"
      >
        {usersRows}
      </Grid>
    </Grid>
  );
};

export default UsersList;
