import clonerWhite from "../../../../Resources/iconwhite.png";
import clonerWhiteFull from "../../../../Resources/CLONER-WHITE.png";
import { Box, Collapse, Fade, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { UISliceActions } from "../../../../../store/slices/UI2";

export const closeDrawerUI = "closeDrawer";
export const logoModeUI = "logoMode";

const Logo = (props) => {
  const { open } = props;
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UISliceActions.toggle(closeDrawerUI));
  };

  return (
    <Grid
      container
      item
      {...{
        onClick,
        xs: "auto",
        padding: 1,
        paddingTop:2.5,
        paddingLeft: 2,
        sx: { cursor: "pointer", height: '8vh' },
      }}
    >
      <Grid item xs={12}>
        <Box position="absolute">
          <Collapse orientation="horizontal" in={open} collapsedSize={45}>
            <Fade in={open}>
              <img src={`${clonerWhiteFull}`} alt='cloner-logo-full' height={30} />
            </Fade>
          </Collapse>
        </Box>
        <Box position="absolute" paddingLeft={0} marginTop={-0.5}>
          <Collapse orientation="horizontal" in={open} collapsedSize={45}>
            <Fade in={!open}>
              <img src={`${clonerWhite}`} alt='cloner-logo' height={36} />
            </Fade>
          </Collapse>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Logo;
