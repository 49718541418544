import escapeRegExp from "./escapeRegexp";

export const lowerMatch = (s1, s2) => {
  return s1.toLowerCase().match(s2.toLowerCase());
};

export const lowerMatch2 = (s1, s2) => {
  console.log('escapeRegExp(s1)',s1,escapeRegExp(s1))
  console.log('escapeRegExp(s2)',s2,escapeRegExp(s2))
  console.log('match',)
  return escapeRegExp(s1).toLowerCase().match(escapeRegExp(s2).toLowerCase());
};

export const containsEscaped = (str1, str2) => {
  const escapedStr2 = str2.toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedStr2);
  return regex.test(str1.toLowerCase());
}
