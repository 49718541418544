const buildPath = (nodes, idx, first) => {
  let path 
  if (nodes[idx].parentIdx !== null) {
    path = buildPath(nodes, nodes[idx].parentIdx, false);
  }
  if (first) {
    return path ? path + nodes[idx].name : nodes[idx].name;
  } else {
    return path ? path + nodes[idx].name + "/" : nodes[idx].name;
  }
};

export default buildPath