import { Grid } from "@mui/material";
import { Fragment } from "react";
import Select from "../../../../UI2/Select";
import { options2 as options } from "./selectValues";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { useWorker } from "../../../../../hooks2/useWorker";
import { postDeviceId } from "../../../../../store/actions2/Devices/postDeviceInfo";
import HelpTooltip from "../../../../UI2/HelpTooltip";
import Switch from "../../../../UI2/Switch2";

const UIText = {
  "es-CL": { label: "Archivos simultaneos" },
  "en-US": { label: "Simultaneous files" },
};
// const UIText2 = {
//   "es-CL": { label: "Numero de archivos" },
//   "en-US": { label: "Number of files" },
// };

export const multiUploadActiveUI = "multiUpActive";
export const multiUploadUI = "multiUpload";

const Multiupload = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const { working } = useWorker(postDeviceId);
  const active = UI[multiUploadActiveUI];

  const reset = (value) => {
    if (value) {
      dispatch(UIactions.setValue({ id: multiUploadUI, value: 2 }));
    } else {
      dispatch(UIactions.setValue({ id: multiUploadUI, value: 1 }));
    }
  };

  return (
    <Fragment>
      <Grid container item xs={7} alignItems="center" columnGap={1}>
        <Switch
          labelRight
          {...{
            id: multiUploadActiveUI,
            UIText,
            disabled: working,
            onChange: reset,
            xs: "auto",
            labelProps: { sx: { fontSize: undefined, fontWeight: undefined } },
          }}
        />
        <HelpTooltip {...{ UIText }} />
      </Grid>
      {/* <Grid container item xs={7} alignItems="center">
        <Switch {...{ id: multiUploadActiveUI, disabled:working, onChange: reset }} />
        <Typography>{label}</Typography>
      </Grid> */}
      <Select
        {...{ id: multiUploadUI, UIText, disabled:working, options, xs: 5, hide: !active }}
      />
    </Fragment>
  );
};

export default Multiupload;
