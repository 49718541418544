import { useEffect, useMemo } from "react";
import getAllDevices, {
  devicesDataId,
} from "../../store/actions2/Devices/getAllDevices";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { sortObjByKey } from "../../utility/sortObjByKey";
import { recursiveRequestId } from "./Components/Actions/Recursive";
import CalculatePagination from "../UI2/Utilities/CalculatePagination";
import { containsEscaped } from "../../utility/filter";
import getRootDevices from "../../store/actions2/Devices/getRootDevices";
import useURLQuery from "../../hooks2/useURLQuery";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { useWorker } from "../../hooks2/useWorker";
import Core from "./Components/Core";
import {
  defaultFilter,
  defaultPagination,
  defaultPreFilter,
  defaultSelected,
  defaultSort,
} from "../UI2/genericIDs";
import { requestLogsId } from "../../store/actions2/Reports/requestLogs";
import { forceRescanId } from "../../store/actions2/Reports/forceRescan";

export const devicesLoadedId = "devicesLoaded";

const Reports = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { done, working, error } = useWorker(devicesLoadedId);
  const prefilter = UI[defaultPreFilter];
  const recursive = UI[recursiveRequestId];
  const filter = UI[defaultFilter] ?? "";
  const sort = UI[defaultSort] ?? { value: "user" };
  const pagination = UI[defaultPagination] ?? {};

  useEffect(() => {
    if (auth.admin !== auth.group) {
      navigate("/dashboard/files", { replace: true });
    } else if (!group && auth.admin) {
      navigate("/dashboard/reports?group=" + auth.group, { replace: true });
    }
  }, [navigate, auth, group]);

  useEffect(() => {
    if (prefilter) {
      dispatch(UIactions.setValue({ id: defaultFilter, value: prefilter }));
      dispatch(UIactions.clear(defaultPreFilter));
    }
  }, [dispatch, prefilter]);

  useEffect(() => {
    if (!done && !prefilter) {
      const controller = new AbortController();
      dispatch(warehouseActions.unload(devicesDataId));
      if (!recursive) {
        dispatch(
          getRootDevices({ ...auth, group, controller, includeEmpty: true })
        );
      } else {
        dispatch(
          getAllDevices({ ...auth, group, controller, includeEmpty: true })
        );
      }
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, group, recursive, done, prefilter]);

  const devices = useMemo(() => {
    const rawData = warehouse[devicesDataId];
    let devices = [];
    for (const key in rawData) {
      devices.push(rawData[key]);
    }
    return devices;
  }, [warehouse]);

  const filteredDevices = useMemo(() => {
    return devices
      .filter(
        (dev) =>
          containsEscaped(dev.user, filter) ||
          containsEscaped(dev.name, filter) ||
          containsEscaped(dev.croppedVersion, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [devices, filter, sort.value, sort.descending]);

  // Array to select all
  const allDevices = useMemo(() => {
    return filteredDevices.filter((x) => !!x.mid).map((x) => x.mid);
  }, [filteredDevices]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredDevices.length }));
  }, [dispatch, filteredDevices.length]);

  const { from, to } = CalculatePagination(pagination);
  const paginatedDevices = useMemo(() => {
    return filteredDevices.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredDevices]);

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(defaultSort));
      dispatch(warehouseActions.unload(devicesDataId));
      dispatch(workers.clear(devicesLoadedId));
      dispatch(workers.clear(requestLogsId));
      dispatch(workers.clear(forceRescanId));
    };
  }, [dispatch]);

  return (
    <Core
      {...{
        devices: paginatedDevices,
        allDevices,
        done,
        working,
        error,
        workerId: devicesLoadedId,
      }}
    />
  );
};

export default Reports;
