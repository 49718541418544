/**
 * Object containing localized text for the "Recover" action button.
 * @typedef {Object} UIText
 * @property {Object} es-CL - The localized text for the Spanish (Chile) language.
 * @property {string} es-CL.label - The label for the "Recover" action button in Spanish (Chile).
 * @property {string} es-CL.fixedTooltip - The tooltip text for the "Recover" action button in Spanish (Chile).
 * @property {Object} en-US - The localized text for the English (United States) language.
 * @property {string} en-US.label - The label for the "Recover" action button in English (United States).
 * @property {string} en-US.fixedTooltip - The tooltip text for the "Recover" action button in English (United States).
 */
import { LocalShipping } from "@mui/icons-material";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { devicesData } from "../../Devices";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useRecoveries from "../../../../hooks2/useRecoveries";
import { recoveriesSliceActions as recoveries } from "../../../../store/slices/recoveries";
import addRecoveries, { addRecoveriesUI } from "../../../../store/actions2/Recoveries/addRecoveries";
import { useWorker } from "../../../../hooks2/useWorker";
import { useEffect } from "react";

const UIText = {
  "es-CL": { label: "Recuperar", fixedTooltip: "Recuperar los dispositivos" },
  "en-US": { label: "Recover", fixedTooltip: "Recover the devices" },
};

const Recover = () => {
  const { auth, dispatch, navigate, UI, warehouse, settings } =
    useUIBoilerplate();
  const requestData = useRecoveries();
  const { done, working, error } = useWorker(addRecoveriesUI);
  const { initialized } = requestData;
  const selected = UI.selected ?? [];
  const devices = warehouse[devicesData] ?? {};
  const locale = settings.locale;

  useEffect(() => {
    if (done && !error) {
      dispatch(recoveries.dontAskAgain());
      navigate("/dashboard/recovery/step1");
    }
  }, [dispatch,navigate,done,error]);

  const onClick = () => {
    let selectedDevices = [];
    for (const mid of selected) {
      const device = devices[mid];
      selectedDevices.push(device);
    }

    if (!initialized) {
      dispatch(recoveries.reset());
      dispatch(
        addRecoveries({
          ...auth,
          locale,
          devices: selectedDevices,
        })
      );
    } else {
      if (selectedDevices.length === 1) {
        const selectedDevice = selectedDevices[0];
        const newRecovery = {
          ...auth,
          user: selectedDevice.user,
          mid: selectedDevice.mid,
          device: selectedDevice.name,
          fullSize: selectedDevice.space_active,
          lastBkp: selectedDevice.last_bkp,
          locale,
        };
        dispatch(
          UIactions.openModal({
            type: "new-device-new-request",
            payload: { newRecovery },
          })
        );
      } else {
        dispatch(
          UIactions.openModal({
            type: "new-devices-new-request",
            payload: {
              devices: selectedDevices,
            },
          })
        );
      }
    }
  };

  return (
    <ActionButton
      {...{
        working,
        disabled: !selected.length, 
        onClick,
        UIText,
        icon: <LocalShipping />,
      }}
    />
  );
};

export default Recover;
