import { Grid, Typography } from "@mui/material";

const UIText = {
  "es-CL": { text: "General" },
  "us-CL": { text: "General" },
};

const General = (props) => {
  const { locale } = props;
  const { text } = UIText[locale];

  return (
    <Grid container item xs={12}>
      <Typography fontWeight="bold">{text}</Typography>
    </Grid>
  );
};

export default General;
