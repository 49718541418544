import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { default as CustomDevicePicker } from "../../../../UI2/Specialized/DevicePicker";
import { userPickerId } from "../../../../UI2/Specialized/UserPicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const DevicePicker = () => {
  const { dispatch,UI } = useUIBoilerplate();
  const { id: user } = UI[userPickerId] ?? {};
  const onChange = () => {
    dispatch(UIactions.clear(recoveryOptionUI));
    dispatch(UIactions.clear(recoveryDateUI));
  };
  return <CustomDevicePicker autoload {...{ xs: 12, user, onChange }} />;
};

export default DevicePicker;
