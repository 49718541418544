import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

export const getMailId = "getMail";
export const mailData = "mailData";

const getMail = (data) => {
  const id = getMailId;
  const { mailId, W365server, cloner_key, controller } = data;
  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = W365server + "mailapi/message/" + mailId;
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
      });
      const data = JSON.parse(response.data);

      dispatch(warehouse.load({ id: mailData, data }));
      dispatch(workers.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workers.clear(id));
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default getMail;
