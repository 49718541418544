import { Grid } from "@mui/material";
import Select from "../../../UI2/Select";
import { about, requirements } from "./options";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import notEmptyField from "../../../../utility/validations/notEmpty";
import { useEffect } from "react";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import SlimAlert from "../../../UI2/SlimAlert";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import TextField from "../../../UI2/SimpleTextField";

const UIText = {
  "es-CL": { label: "Requerimiento" },
  "en-US": { label: "Requirement" },
};
const UIText2 = {
  "es-CL": { label: "Respecto a" },
  "en-US": { label: "About" },
};
const UIText3 = {
  "es-CL": { label: "Correo de contacto", placeholder: "usuario@cloner.cl" },
  "en-US": { label: "Contact email", placeholder: "user@cloner.cl" },
};
const UIText4 = {
  "es-CL": {
    label: "Detalles adicionales",
    placeholder: "El equipo no está respaldando!",
  },
  "en-US": {
    label: "Aditional details",
    placeholder: "The device is not doing the backups!",
  },
};
const UIText6 = {
  "es-CL": {
    warning:
      "Por favor verifique que el correo de contacto existe y puede recibir correos externos",
  },
  "en-US": {
    warning:
      "Please make sure that the contact email exists and can receive external emails",
  },
};

export const requirementsUI = "requirements";
export const supportFocusUI = "supportFocus";
export const contactEmailUI = "contactEmail";
export const detailsUI = "details";

const Content = () => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { warning } = UIText6[settings.locale];

  useEffect(() => {
    dispatch(UIactions.setField({ id: contactEmailUI, value: auth.user }));
    return () => {
      dispatch(UIactions.clear(requirementsUI));
      dispatch(UIactions.clear(supportFocusUI));
      dispatch(UIactions.clear(contactEmailUI));
      dispatch(UIactions.clear(detailsUI));
    };
  }, [dispatch, auth]);

  return (
    <BasicDialogContent sx={{ padding: 0 }}>
      <Grid container item {...{ columnSpacing: 2, rowGap: 2 }}>
        <SlimAlert {...{ errors: [warning], severity: "info", xs: 12, sx:{paddingLeft:2} }} />
        <Select
          {...{ id: requirementsUI, UIText, xs: 6, options: requirements }}
        />
        <Select
          {...{ id: supportFocusUI, UIText: UIText2, xs: 6, options: about }}
        />
        <TextField
          required
          tagError
          {...{
            id: contactEmailUI,
            UIText: UIText3,
            xs: 12,
            validation: notEmptyUser,
          }}
        />
        <TextField
          required
          multiline
          tagError
          {...{
            id: detailsUI,
            UIText: UIText4,
            xs: 12,
            minRows: 4,
            validation: notEmptyField,
          }}
        />
      </Grid>
    </BasicDialogContent>
  );
};

export default Content;
