import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";
import { RemoveModerator, Shield } from "@mui/icons-material";

const UIText = {
  "es-CL": { fixedTooltip: "Protegido" },
  "en-US": { fixedTooltip: "Protected" },
};
const UIText2 = {
  "es-CL": { fixedTooltip: "Desprotegido" },
  "en-US": { fixedTooltip: "Unprotected" },
};

const Protected = ({ fontSize, locale, has_password }) => {
  const { fixedTooltip } = has_password ? UIText : UIText2;
  const icon = has_password ? (
    <Shield {...{ sx: { color: "primary.main" } }} />
  ) : (
    <RemoveModerator {...{ sx: { color: "grey" } }} />
  );

  return <AutoHelp {...{ fixedTooltip }}>{icon}</AutoHelp>;
};

export default Protected;
