import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";

export const submitRecoveryId = "submitRecovery";

const sendRecoveryRequest = (data) => {
  const id = submitRecoveryId;
  const { server, token, requestData, senderData } = data;
  const { recoveries, deliveryData } = requestData;
  const { organization, adminUser, adminName } = senderData;
  const { contactName, contactEmail, contactPhone, reason, otherReason, deliveryMethod } =
    deliveryData;

  return async (dispatch) => {
    dispatch(workers.start(id));

    let newRecoveries = [];
    for (const recovery of recoveries) {
      //   console.log("recovery", recovery);
      for (const repository of recovery.repositories) {
        let basePath = repository.name[0] === "/" ? "/" : `/${repository.name[0]}:`;

        let option;
        if (repository.option === "dated") {
          option = `dated: ${repository.bkpDate}`;
        } else {
          option = repository.option;
        }

        let newRecovery = {
          userlogin: recovery.user,
          machine: recovery.device,
          repository: repository.id,
          size: 0,
          root: repository.metafile,
          path: basePath,
          option,
          details: repository.details,
          cloner_key: token,
        };

        if (repository.recover) {
          newRecovery.size = repository.size;
          newRecoveries.push({ ...newRecovery });
        }
      }
    }
    // console.log("new recoveries", newRecoveries);
    //=======================================================================
    const fullAddress = `${deliveryData.deliveryStreet} ${
      deliveryData.deliveryStreetNumber
    } ${deliveryData.deliveryOffice ? `( ${deliveryData.deliveryOffice} )` : ""}
          , ${deliveryData.district}, ${deliveryData.city}, ${
      deliveryData.region
    }.`;
    const timeRange = `${deliveryData.deliveryWindowFrom} - ${deliveryData.deliveryWindowTo}`;

    // console.log("admin", request.admin);
    const payload = {
      request: {
        server,
        issuer_email: adminUser,
        issuer_name: adminName,
        group_name: organization,
        contact_name: contactName,
        contact_email: contactEmail,
        contact_phone: contactPhone,
        delivery_method: deliveryMethod,
        address: fullAddress,
        delivery_hours: timeRange,
        reason: reason === "Otro" ? `Otro: ${otherReason}` : reason,
      },
      recoveries: newRecoveries,
    };

    // console.log("RECOVERY PRE-BODY PAYLOAD", payload);

    const bodyData = JSON.stringify(payload);

    // console.log("PAYLOAD", bodyData);

    try {
      // POST new recovery request
      const url = "https://director.cloner.cl/api/recoveries2";
      await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key: token },
      });
      dispatch(workers.finish({ id }));
      enqueueSnackbar("Recuperación solicitada exitosamente", {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
      enqueueSnackbar("Error al intentar solicitar la recuperación", {
        variant: "error",
      });
    }
  };
};

export default sendRecoveryRequest;
