export const removeArrayDuplicates = (array) => {
    let newArray = [];
    for (const item of array) {
      const found = newArray.find((x) => x.value === item.value);
      if (!found) {
        newArray.push(item);
      }
    }
    return newArray;
  };

export const addressData = [
    {region:"Antofagasta", ciudad:"Antofagasta", comuna:"Antofagasta"},
    {region:"Antofagasta", ciudad:"Calama", comuna:"Calama"},
    {region:"Antofagasta", ciudad:"Tocopilla", comuna:"Tocopilla"},
    {region:"Antofagasta", ciudad:"Mejillones", comuna:"Mejillones"},
    {region:"Antofagasta", ciudad:"Taltal", comuna:"Taltal"},
    {region:"Antofagasta", ciudad:"San Pedro de Atacama", comuna:"San Pedro de Atacama"},
    {region:"Arica y Parinacota", ciudad:"Arica", comuna:"Arica"},
    {region:"Arica y Parinacota", ciudad:"Putre", comuna:"Putre"},
    {region:"Atacama", ciudad:"Copiapó", comuna:"Copiapó"},
    {region:"Atacama", ciudad:"Vallenar", comuna:"Vallenar"},
    {region:"Atacama", ciudad:"Caldera", comuna:"Caldera"},
    {region:"Atacama", ciudad:"Chañaral", comuna:"Chañaral"},
    {region:"Atacama", ciudad:"Tierra Amarilla", comuna:"Tierra Amarilla"},
    {region:"Atacama", ciudad:"Diego de Almagro", comuna:"Diego de Almagro"},
    {region:"Atacama", ciudad:"Huasco", comuna:"Huasco"},
    {region:"Atacama", ciudad:"El Salvador", comuna:"Diego de Almagro"},
    {region:"Aysén del General Carlos Ibáñez del Campo", ciudad:"Coyhaique", comuna:"Coyhaique"},
    {region:"Aysén del General Carlos Ibáñez del Campo", ciudad:"Puerto Aysen", comuna:"Puerto Aysén"},
    {region:"Aysén del General Carlos Ibáñez del Campo", ciudad:"Chile Chico", comuna:"Chile Chico"},
    {region:"Aysén del General Carlos Ibáñez del Campo", ciudad:"Cochrane", comuna:"Cochrane"},
    {region:"Biobío", ciudad:"Concepción", comuna:"Concepción"},
    {region:"Biobío", ciudad:"Talcahuano", comuna:"Talcahuano"},
    {region:"Biobío", ciudad:"Los Ángeles", comuna:"Los Ángeles"},
    {region:"Biobío", ciudad:"San Pedro de la Paz", comuna:"San Pedro de la Paz"},
    {region:"Biobío", ciudad:"Coronel", comuna:"Coronel"},
    {region:"Biobío", ciudad:"Hualpen", comuna:"Hualpén"},
    {region:"Biobío", ciudad:"Chiguayante", comuna:"Chiguayante"},
    {region:"Biobío", ciudad:"Penco", comuna:"Penco"},
    {region:"Biobío", ciudad:"Lota", comuna:"Lota"},
    {region:"Biobío", ciudad:"Tome", comuna:"Tomé"},
    {region:"Biobío", ciudad:"Curanilahue", comuna:"Curanilahue"},
    {region:"Biobío", ciudad:"Mulchén", comuna:"Mulchén"},
    {region:"Biobío", ciudad:"Nacimiento", comuna:"Nacimiento"},
    {region:"Biobío", ciudad:"Lebu", comuna:"Lebu"},
    {region:"Biobío", ciudad:"Hualqui", comuna:"Hualqui"},
    {region:"Biobío", ciudad:"Cañete", comuna:"Cañete"},
    {region:"Biobío", ciudad:"Arauco", comuna:"Arauco"},
    {region:"Biobío", ciudad:"La Laja", comuna:"Laja"},
    {region:"Biobío", ciudad:"Los Álamos", comuna:"Los Álamos"},
    {region:"Biobío", ciudad:"Cabrero", comuna:"Cabrero"},
    {region:"Biobío", ciudad:"Yumbel", comuna:"Yumbel"},
    {region:"Biobío", ciudad:"Santa Juana", comuna:"Santa Juana"},
    {region:"Biobío", ciudad:"Santa Bárbara", comuna:"Santa Bárbara"},
    {region:"Biobío", ciudad:"Huelpin", comuna:"Tucapel"},
    {region:"Biobío", ciudad:"Cabrero", comuna:"Monte Águila"},
    {region:"Biobío", ciudad:"La Araquete", comuna:"Arauco"},
    {region:"Coquimbo", ciudad:"La Serena", comuna:"Coquimbo"},
    {region:"Coquimbo", ciudad:"La Serena", comuna:"La Serena"},
    {region:"Coquimbo", ciudad:"Ovalle", comuna:"Ovalle"},
    {region:"Coquimbo", ciudad:"Illapel", comuna:"Illapel"},
    {region:"Coquimbo", ciudad:"Vicuña", comuna:"Vicuña"},
    {region:"Coquimbo", ciudad:"Los Vilos", comuna:"Los Vilos"},
    {region:"Coquimbo", ciudad:"Salamanca", comuna:"Salamanca"},
    {region:"Coquimbo", ciudad:"Andacollo", comuna:"Andacollo"},
    {region:"Coquimbo", ciudad:"Monte Patria", comuna:"Monte Patria"},
    {region:"Coquimbo", ciudad:"El Palqui", comuna:"Monte Patria"},
    {region:"Coquimbo", ciudad:"Combarbalá", comuna:"Combarbalá"},
    {region:"Coquimbo", ciudad:"Punitaqui", comuna:"Punitaqui"},
    {region:"Coquimbo", ciudad:"Tongoy", comuna:"Coquimbo"},
    {region:"La Araucanía", ciudad:"Temuco", comuna:"Temuco"},
    {region:"La Araucanía", ciudad:"Angol", comuna:"Angol"},
    {region:"La Araucanía", ciudad:"Padre las Casas", comuna:"Padre Las Casas"},
    {region:"La Araucanía", ciudad:"Villarrica", comuna:"Villarrica"},
    {region:"La Araucanía", ciudad:"Victoria", comuna:"Victoria"},
    {region:"La Araucanía", ciudad:"Lautaro", comuna:"Lautaro"},
    {region:"La Araucanía", ciudad:"Labranza", comuna:"Labranza"},
    {region:"La Araucanía", ciudad:"Nueva Imperial", comuna:"Nueva Imperial"},
    {region:"La Araucanía", ciudad:"Pucón", comuna:"Pucón"},
    {region:"La Araucanía", ciudad:"Pitrufquén", comuna:"Pitrufquén"},
    {region:"La Araucanía", ciudad:"Collipulli", comuna:"Collipulli"},
    {region:"La Araucanía", ciudad:"Loncoche", comuna:"Loncoche"},
    {region:"La Araucanía", ciudad:"Traiguén", comuna:"Traiguén"},
    {region:"La Araucanía", ciudad:"Curacautín", comuna:"Curacautín"},
    {region:"La Araucanía", ciudad:"Carahue", comuna:"Carahue"},
    {region:"La Araucanía", ciudad:"Gorbea", comuna:"Gorbea"},
    {region:"La Araucanía", ciudad:"Purén", comuna:"Purén"},
    {region:"La Araucanía", ciudad:"Cunco", comuna:"Cunco"},
    {region:"La Araucanía", ciudad:"Renaico", comuna:"Renaico"},
    {region:"La Araucanía", ciudad:"Vilcún", comuna:"Vilcún"},
    {region:"La Araucanía", ciudad:"Vilcún", comuna:"Cajón"},
    {region:"La Araucanía", ciudad:"Freire", comuna:"Freire"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Rancagua", comuna:"Rancagua"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"San Fernando", comuna:"San Fernando"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Machali", comuna:"Machalí"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Rengo", comuna:"Rengo"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Graneros", comuna:"Graneros"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Santa Cruz", comuna:"Santa Cruz"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"San Vicente de tagua tagua", comuna:"San Vicente de Tagua Tagua"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Chimbarongo", comuna:"Chimbarongo"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Pichilemu", comuna:"Pichilemu"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"San Francisco de Mostazal", comuna:"Mostazal"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Requínoa", comuna:"Requínoa"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Doñihue", comuna:"Lo Miranda"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Nancagua", comuna:"Nancagua"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Peumo", comuna:"Peumo"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Las Cabras", comuna:"Las Cabras"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Doñihue", comuna:"Doñihue"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Quinta de Tilcoco", comuna:"Quinta de Tilcoco"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"La Punta", comuna:"Mostazal"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Codegua", comuna:"Codegua"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Chépica", comuna:"Chépica"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Pichidegua", comuna:"Pichidegua"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Coltauco", comuna:"Coltauco"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Gultro (Rancagua)", comuna:"Olivar"},
    {region:"Libertador General Bernardo O'Higgins", ciudad:"Peralillo", comuna:"Peralillo"},
    {region:"Los Lagos", ciudad:"Puerto Montt", comuna:"Puerto Montt"},
    {region:"Los Lagos", ciudad:"Osorno", comuna:"Osorno"},
    {region:"Los Lagos", ciudad:"Alerce", comuna:"Alerce"},
    {region:"Los Lagos", ciudad:"Castro", comuna:"Castro"},
    {region:"Los Lagos", ciudad:"Ancud", comuna:"Ancud"},
    {region:"Los Lagos", ciudad:"Puerto Varas", comuna:"Puerto Varas"},
    {region:"Los Lagos", ciudad:"Quellón", comuna:"Quellón"},
    {region:"Los Lagos", ciudad:"Calbuco", comuna:"Calbuco"},
    {region:"Los Lagos", ciudad:"Llanquihue", comuna:"Llanquihue"},
    {region:"Los Lagos", ciudad:"Frutillar", comuna:"Frutillar"},
    {region:"Los Lagos", ciudad:"Purranque", comuna:"Purranque"},
    {region:"Los Lagos", ciudad:"Los Muermos", comuna:"Los Muermos"},
    {region:"Los Lagos", ciudad:"Fresia", comuna:"Fresia"},
    {region:"Los Lagos", ciudad:"Dalcahue", comuna:"Dalcahue"},
    {region:"Los Lagos", ciudad:"Río Negro", comuna:"Río Negro"},
    {region:"Los Lagos", ciudad:"Chonchi", comuna:"Chonchi"},
    {region:"Los Lagos", ciudad:"Chaitén", comuna:"Chaitén"},
    {region:"Los Ríos", ciudad:"Valdivia", comuna:"Valdivia"},
    {region:"Los Ríos", ciudad:"La Unión", comuna:"La Unión"},
    {region:"Los Ríos", ciudad:"Río Bueno", comuna:"Río Bueno"},
    {region:"Los Ríos", ciudad:"Paillaco", comuna:"Paillaco"},
    {region:"Los Ríos", ciudad:"Panguipulli", comuna:"Panguipulli"},
    {region:"Los Ríos", ciudad:"San jose de la Mariquina", comuna:"Mariquina"},
    {region:"Los Ríos", ciudad:"Los Lagos", comuna:"Los Lagos"},
    {region:"Los Ríos", ciudad:"Lanco", comuna:"Lanco"},
    {region:"Los Ríos", ciudad:"Futrono", comuna:"Futrono"},
    {region:"Magallanes y de la Antártica Chilena", ciudad:"Punta Arenas", comuna:"Punta Arenas"},
    {region:"Magallanes y de la Antártica Chilena", ciudad:"Puerto Natales", comuna:"Puerto Natales"},
    {region:"Magallanes y de la Antártica Chilena", ciudad:"Porvenir", comuna:"Porvenir"},
    {region:"Magallanes y de la Antártica Chilena", ciudad:"Puerto Williams", comuna:"Cabo de Hornos"},
    {region:"Maule", ciudad:"Talca", comuna:"Talca"},
    {region:"Maule", ciudad:"Curicó", comuna:"Curicó"},
    {region:"Maule", ciudad:"Linares", comuna:"Linares"},
    {region:"Maule", ciudad:"Cauquenes", comuna:"Cauquenes"},
    {region:"Maule", ciudad:"Molina", comuna:"Molina"},
    {region:"Maule", ciudad:"Constitución", comuna:"Constitución"},
    {region:"Maule", ciudad:"Parral", comuna:"Parral"},
    {region:"Maule", ciudad:"Culenar", comuna:"Maule"},
    {region:"Maule", ciudad:"San Javier", comuna:"San Javier"},
    {region:"Maule", ciudad:"San Clemente", comuna:"San Clemente"},
    {region:"Maule", ciudad:"Teno", comuna:"Teno"},
    {region:"Maule", ciudad:"Villa Alegre", comuna:"Villa Alegre"},
    {region:"Maule", ciudad:"Longaví", comuna:"Longaví"},
    {region:"Maule", ciudad:"Maule", comuna:"Maule"},
    {region:"Maule", ciudad:"Colbún", comuna:"Colbún"},
    {region:"Maule", ciudad:"Romeral", comuna:"Romeral"},
    {region:"Maule", ciudad:"Hualañé", comuna:"Hualañé"},
    {region:"Maule", ciudad:"Rauco", comuna:"Rauco"},
    {region:"Maule", ciudad:"Retiro", comuna:"Retiro"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Puente Alto"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Maipú"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Santiago"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"La Florida"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"San Bernardo"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Las Condes"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Peñalolén"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Quilicura"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Ñuñoa"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Pudahuel"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"La Pintana"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"El Bosque"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Recoleta"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Renca"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Providencia"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Estación Central"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Cerro Navia"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Conchalí"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"La Granja"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Macul"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Quinta Normal"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"San Miguel"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Lo Barnechea"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Pedro Aguirre Cerda"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Independencia"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Lo Espejo"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Huechuraba"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Lo Prado"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"San Joaquín"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"La Reina"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"La Cisterna"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Colina"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Vitacura"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Peñaflor"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"San Ramón"},
    {region:"Metropolitana de Santiago", ciudad:"Santiago", comuna:"Cerrillos"},
    {region:"Metropolitana de Santiago", ciudad:"Melipilla", comuna:"Melipilla"},
    {region:"Metropolitana de Santiago", ciudad:"Buin", comuna:"Buin"},
    {region:"Metropolitana de Santiago", ciudad:"Talagante", comuna:"Talagante"},
    {region:"Metropolitana de Santiago", ciudad:"Lampa", comuna:"Lampa"},
    {region:"Metropolitana de Santiago", ciudad:"Paine", comuna:"Paine"},
    {region:"Metropolitana de Santiago", ciudad:"El Monte", comuna:"El Monte"},
    {region:"Metropolitana de Santiago", ciudad:"Curacaví", comuna:"Curacaví"},
    {region:"Metropolitana de Santiago", ciudad:"Valle grande", comuna:"lampa"},
    {region:"Metropolitana de Santiago", ciudad:"Batuco", comuna:"Lampa"},
    {region:"Metropolitana de Santiago", ciudad:"Isla de Maipo", comuna:"Isla de Maipo"},
    {region:"Metropolitana de Santiago", ciudad:"Ciudad de los Valles", comuna:"Pudahuel"},
    {region:"Metropolitana de Santiago", ciudad:"La islita", comuna:"Isla de maipo"},
    {region:"Metropolitana de Santiago", ciudad:"Colina", comuna:"Chicureo"},
    {region:"Metropolitana de Santiago", ciudad:"El Principal", comuna:"Pirque"},
    {region:"Metropolitana de Santiago", ciudad:"Calera de Tango", comuna:"Calera de Tango"},
    {region:"Metropolitana de Santiago", ciudad:"Chamisero", comuna:"Colina"},
    {region:"Metropolitana de Santiago", ciudad:"Alto Jahuel", comuna:"Buin"},
    {region:"Metropolitana de Santiago", ciudad:"San José de Maipo", comuna:"San José de Maipo"},
    {region:"Metropolitana de Santiago", ciudad:"Paine", comuna:"Hospital"},
    {region:"Metropolitana de Santiago", ciudad:"Tiltil", comuna:"Tiltil"},
    {region:"Metropolitana de Santiago", ciudad:"Ballenar", comuna:"Melipilla"},
    {region:"Ñuble", ciudad:"Chillán", comuna:"Chillán"},
    {region:"Ñuble", ciudad:"San Carlos", comuna:"San Carlos"},
    {region:"Ñuble", ciudad:"Chillán Viejo", comuna:"Chillán Viejo"},
    {region:"Ñuble", ciudad:"Bulnes", comuna:"Bulnes"},
    {region:"Ñuble", ciudad:"Yungay", comuna:"Yungay"},
    {region:"Ñuble", ciudad:"Coelemu", comuna:"Coelemu"},
    {region:"Ñuble", ciudad:"Quillón", comuna:"Quillón"},
    {region:"Ñuble", ciudad:"Quirihue", comuna:"Quirihue"},
    {region:"Ñuble", ciudad:"Coihueco", comuna:"Coihueco"},
    {region:"Tarapacá", ciudad:"Iquique", comuna:"Iquique"},
    {region:"Tarapacá", ciudad:"Alto Hospicio", comuna:"Alto Hospicio"},
    {region:"Tarapacá", ciudad:"Pozo Almonte", comuna:"Pozo Almonte"},
    {region:"Valparaíso", ciudad:"Viña del Mar", comuna:"Viña del Mar"},
    {region:"Valparaíso", ciudad:"Valparaíso", comuna:"Valparaíso"},
    {region:"Valparaíso", ciudad:"Quilpue", comuna:"Quilpué"},
    {region:"Valparaíso", ciudad:"Villa Alemana", comuna:"Villa Alemana"},
    {region:"Valparaíso", ciudad:"San Antonio", comuna:"San Antonio"},
    {region:"Valparaíso", ciudad:"Quillota", comuna:"Quillota"},
    {region:"Valparaíso", ciudad:"San Felipe", comuna:"San Felipe"},
    {region:"Valparaíso", ciudad:"Los Andes", comuna:"Los Andes"},
    {region:"Valparaíso", ciudad:"La Calera", comuna:"La Calera"},
    {region:"Valparaíso", ciudad:"Concon", comuna:"Concón"},
    {region:"Valparaíso", ciudad:"Placilla de Peñuelas", comuna:"Placilla de Peñuelas"},
    {region:"Valparaíso", ciudad:"Limache", comuna:"Limache"},
    {region:"Valparaíso", ciudad:"Quintero", comuna:"Quintero"},
    {region:"Valparaíso", ciudad:"Cartagena", comuna:"Cartagena"},
    {region:"Valparaíso", ciudad:"La Cruz", comuna:"La Cruz"},
    {region:"Valparaíso", ciudad:"La Ligua", comuna:"La Ligua"},
    {region:"Valparaíso", ciudad:"Casablanca", comuna:"Casablanca"},
    {region:"Valparaíso", ciudad:"Llaillay", comuna:"Llaillay"},
    {region:"Valparaíso", ciudad:"El Quisco", comuna:"El Quisco"},
    {region:"Valparaíso", ciudad:"Olmué", comuna:"Olmué"},
    {region:"Valparaíso", ciudad:"Cabildo", comuna:"Cabildo"},
    {region:"Valparaíso", ciudad:"San Esteban", comuna:"San Esteban"},
    {region:"Valparaíso", ciudad:"Nogales", comuna:"El Melón"},
    {region:"Valparaíso", ciudad:"Calle Larga", comuna:"Calle Larga"},
    {region:"Valparaíso", ciudad:"Hijuelas", comuna:"Hijuelas"},
    {region:"Valparaíso", ciudad:"Algarrobo", comuna:"Algarrobo"},
    {region:"Valparaíso", ciudad:"Nogales", comuna:"Nogales"},
    {region:"Valparaíso", ciudad:"Puchuncaví", comuna:"Las Ventanas"},
    {region:"Valparaíso", ciudad:"Rinconada", comuna:"Rinconada"},
    {region:"Valparaíso", ciudad:"Catemu", comuna:"Catemu"},
    {region:"Valparaíso", ciudad:"Santa María", comuna:"Santa María"},
    {region:"Valparaíso", ciudad:"Hanga roa", comuna:"Isla de Pascua"},
    {region:"Valparaíso", ciudad:"El Tabo", comuna:"El Tabo"},
    {region:"Valparaíso", ciudad:"Putaendo", comuna:"Putaendo"},
    {region:"Valparaíso", ciudad:"Santo Domingo", comuna:"Santo Domingo"},
    {region:"Valparaíso", ciudad:"Las Cruces (San Antonio)", comuna:"El Tabo"},
    {region:"Valparaíso", ciudad:"Puchuncaví", comuna:"Puchuncaví"}]