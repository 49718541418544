import { Grid } from "@mui/material";
import RecursiveSwitch from "./Recursive";
import Logs from "./Logs";
import Rescan from "./Rescan";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Export from "./Export";

const Actions = () => {
  const { auth } = useUIBoilerplate();
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <RecursiveSwitch />
      <Export />
      {auth.admin === 1 ? <Rescan /> : null}
      {auth.admin === 1 ? <Logs /> : null}
    </Grid>
  );
};

export default Actions;
