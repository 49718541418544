import { Grid } from "@mui/material";
import CheckButton from "../../Specialized/Check";
import useVH from "../../../../hooks2/useVH";

// const rowData = {
//   id: "", // for select
//   disabled, // for select
//   elements: [{ xs, content: "ELEMENT" }],
// };

const ClickableRow = (props) => {
  const { id, disabled, elements = [], onClick = () => {} } = props;

  const click = (e) => {
    onClick();
  };

  const height1 = useVH(6);
  const height2 = useVH(2);

  const gridProps = {
    alignItems: "center",
    columnGap: 0.5,
    xs: 12,
    wrap: "nowrap",
    onClick: click,
    sx: {
      fontSize: 20,
      height: height1,
      paddingLeft: height2,
      paddingRight: height2,
      borderBottom: "1px solid lightGrey",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 167, 132,0.1)",
      },
      // "&:active": {
      //   backgroundColor: "rgba(0, 167, 132,0.2)",
      // },
    },
  };

  const columns = elements.map((data, idx) => {
    const { xl, lg, md, sm, xs, content, justify } = data;
    return (
      <Grid
        container
        item
        {...{ key: idx, xl, lg, md, sm, xs, justifyContent: justify, wrap: 'nowrap' }}
      >
        {content}
      </Grid>
    );
  });

  return (
    <Grid container item {...gridProps}>
      {id || disabled ? (
        <CheckButton
          {...{
            id,
            disabled,
            fontSize: 1,
            xs: "auto",
          }}
        />
      ) : null}
      {columns}
    </Grid>
  );
};

export default ClickableRow;
