import { Card, Collapse, Grid } from "@mui/material";
import { useEffect } from "react";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import getMachineStatus, {
  rootsDataId,
  tunnelTokenId,
} from "../../../../store/actions2/RemoteSelection/getMachineStatus";
import { sortObjByKey } from "../../../../utility/sortObjByKey";
import { devicePickerId } from "../../../UI2/Specialized/DevicePicker";
import Repository from "./Repository";
import { selectionBlockUI } from "../../RemoteSelection3";

const SelectDisplay = ({ user }) => {
  const { auth, dispatch, UI, warehouse } = useUIBoilerplate();
  const devicePicker = UI[devicePickerId] ?? { mid: "" };
  const tunnelToken = UI[tunnelTokenId] ?? "";
  const { mid } = devicePicker;
  const roots = warehouse[rootsDataId] ?? [];
  const blocked = UI[selectionBlockUI]??true

  useEffect(() => {
    if (mid && !tunnelToken) {
      dispatch(
        getMachineStatus({
          ...auth,
          user,
          mid,
        })
      );
    }
  }, [dispatch, auth, mid, user, tunnelToken]);

  console.log("roots", roots)

  const rootsItems = [...roots]
    .sort((a, b) => sortObjByKey(a, b, "root"))
    .map((data, idx) => <Repository key={idx} {...data} />);

  return (
    <Grid item {...{ xs: 12 }}>
      <Collapse in={!blocked} orientation="vertical" unmountOnExit>
        <Card
          {...{
            wrap: "nowrap",
            sx: {
              borderColor: "LightGray",
              borderStyle: "solid",
              borderWidth: "1px",
              boxShadow: "none",
              padding: "0.5rem",
              maxHeight: 400,
              overflowY: "auto",
              overflowX: "hidden",
            },
          }}
        >
          <Grid container>{rootsItems}</Grid>
        </Card>
      </Collapse>
    </Grid>
  );
};

export default SelectDisplay;
