import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { useEffect } from "react";
import { userUI } from "../User";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import { postUserId } from "../../../../store/actions2/UserForm/postUser";
import { postUsers } from "../../../../store/actions2/UserForm/postUsers";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { selectedGroupId2 } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";
import { defaultSelected } from "../../../UI2/genericIDs";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};
const errorMsg = {
  "es-CL": "Los campos no coinciden",
  "en-US": "The fields do not match",
};

const Save = (props) => {
  const { users } = props;
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { done, working, error: e1 } = useWorker(postUserId);
  const { group: currentGroup } = useURLQuery();
  const open = UI["group-traveler2-open"] ?? false;
  // const { value: userLogin, error: e2 } = UI[userUI] ?? {};
  // const { value: username, error: e3 } = UI[usernameUI] ?? {};
  // const { value: pass1, error: e4 } = UI[pass1UI] ?? {};
  // const { value: pass2, error: e5 } = UI[pass2UI] ?? {};
  // const group = UI[selectedGroupId2];
  // const changeGroup = UI[changeGroupUI];
  // const changePassword = UI[changePasswordUI];
  // const changePermissions = UI[changePermissionsUI];

  // const e6 = UI[twinErrUI];
  const locale = settings.locale;

  const onClick = () => {
    let payloads = [];
    // for (const userData of users) {
    //   const rawPermissions = UI[permissionsUI] ?? [];

    //   let permissions = {};
    //   if (changePermissions || users.length === 1) {
    //     permissions = {
    //       admin: rawPermissions.find((x) => x === "admin") ? "true" : "false",
    //       has_access: rawPermissions.find((x) => x === "access") ? 1 : 0,
    //       receives_mail: rawPermissions.find((x) => x === "mails") ? 1 : 0,
    //     };
    //   } else {
    //     permissions = {
    //       admin: userData.group === userData.level ? "true" : "false",
    //       has_access: userData.has_access,
    //       receives_mail: userData.receives_mail,
    //     };
    //   }

    //   const payload = {
    //     ...permissions,
    //     token: auth.token,
    //     server: auth.server,
    //     login: users.length === 1 ? userLogin : userData.login,
    //     user: users.length === 1 ? userLogin : userData.login,
    //     name: users.length === 1 ? username : userData.name,
    //     has_tfa: userData.has_tfa,
    //     key_tfa: userData.key_tfa,
    //     email: userData.email,
    //     group: changeGroup || users.length === 1 ? group : userData.group,
    //   };
    //   if ((!!pass1 && !!pass2) || changePassword) {
    //     payload.pass = pass1;
    //     payload.pass2 = pass2;
    //   }
    //   payloads.push(payload);
    // }
    // dispatch(
    //   postUsers({ ...auth, locale, reloadId: currentGroup, users: payloads })
    // );
    // dispatch(UIactions.clear(defaultSelected));
  };

  const onMouseDown = () => {
    // if (users.length === 1) {
    //   dispatch(
    //     UIactions.validateField({
    //       id: userUI,
    //       force: true,
    //       fn: notEmptyUser,
    //       locale,
    //     })
    //   );
    //   dispatch(
    //     UIactions.validateField({
    //       id: usernameUI,
    //       force: true,
    //       fn: notEmptyField,
    //       locale,
    //     })
    //   );
    // }
    // if (changePassword) {
    //   dispatch(
    //     UIactions.twinFieldValidate({
    //       id1: pass1UI,
    //       id2: pass2UI,
    //       force: true,
    //       errorMsg,
    //       fn: notEmptyPassword,
    //       locale,
    //     })
    //   );
    // }
  };

  useEffect(() => {
    if (done && !e1) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, done, e1]);

  const error = false

  return (
    <Button
      {...{ disabled: !!error , UIText, onClick, onMouseDown, working }}
    />
  );
};

export default Save;
