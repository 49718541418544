import { Edit } from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { usersDataId } from "../../../../store/actions2/Users/getAllUsers";

const UIText = {
  "es-CL": { label: "Editar" },
  "en-US": { label: "Edit" },
};
const EditUsers = () => {
  const { dispatch, UI, warehouse } = useUIBoilerplate();
  const { group } = useURLQuery();
  const usersData = warehouse[usersDataId]??[]
  const selected = UI.selected ?? [];

  let selectedUsers = []
  for (const user of selected){
    const userData = usersData.find(x=> x.login === user)
    selectedUsers.push(userData)
  }

  const onClick = () => {
    console.log("editing users");
    dispatch(
      UIactions.openModal({
        type: "edit-user",
        payload: { group, users: selectedUsers },
      })
    );
  };

  return (
    <ActionButton
      {...{ disabled: !selected.length, onClick, UIText, icon: <Edit /> }}
    />
  );
};

export default EditUsers;
