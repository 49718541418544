import { Delete } from "@mui/icons-material";
import Button from "../../../../UI2/Button";
import { useDispatch } from "react-redux";
import { UISliceActions } from "../../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Eliminar solicitud" },
  "en-US": { label: "Delete request" },
};

const Reset = () => {
  const dispatch = useDispatch()

  const onClick = ()=>{
    dispatch(UISliceActions.openModal({type:'reset-recovery-request',payload:{}}))
  }
  return <Button {...{ UIText, startIcon: <Delete />, onClick }} />;
};

export default Reset;
