import { Chip } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { text: "conectados" },
  "en-US": { text: "connected" },
};

const Connected = ({ connected, fontSize }) => {
  const { settings } = useUIBoilerplate();
  const { text } = UIText[settings.locale];

  return (
    <Chip
      size="small"
      label={
        <span style={{ cursor: "default" }}>{`${connected} ${text}`}</span>
      }
      sx={{ fontSize: fontSize - 1 }}
      color={connected ? "primary" : undefined}
    />
  );
};

export default Connected;
