import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import TopBar from "./TopBar/TopBar";

const DashBody = ({ height }) => {
  return (
    <Grid
      item
      {...{
        component: "main",
        xs: true,
        sx: {
          width: 200,
          flexGrow: 1,
          backgroundColor: "ivory.main",
          height,
          overflow: "clip",
        },
      }}
    >
      <TopBar height={height} />
      <Outlet />
    </Grid>
  );
};

export default DashBody;
