import { Outlet } from "react-router-dom";
import useRecoveries from "../../hooks2/useRecoveries";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../store/slices/recoveries";

const RecoveryRequest = () => {
  const dispatch = useDispatch();
  const recoveriesData = useRecoveries() ?? {};
  const { initialized, skipAsk } = recoveriesData;

  useEffect(() => {
    if (initialized && !skipAsk) {
      dispatch(
        UIactions.openModal({
          type: "reset-recovery-request",
        })
      );
    }
  }, [dispatch, initialized, skipAsk]);

  useEffect(() => {
    return () => {
      dispatch(recoveries.askAgain());
    };
  }, [dispatch]);

  return <Outlet />;
};

export default RecoveryRequest;
