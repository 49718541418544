import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { currentGroupnameId } from "../../../store/actions2/GroupTraveler/loadGroupsTree";
import BasicLayout from "../../UI2/BasicLayout/BasicLayout";
import Actions from "./Actions/Actions";
import headersData from "./Headers";
import ReportRow from "./ReportRow/ReportRow";

const Core = (props) => {
  const { devices, allDevices, done, working, error, workerId } = props;
  const { auth, settings, UI } = useUIBoilerplate();
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": `Reporte del grupo ${groupname}`,
    "en-US": `Report from group ${groupname}`,
  };
  const header = UIText[settings.locale];
  const plusActions = <Actions />;

  const elements = devices.map((data, idx) => (
    <ReportRow key={idx} {...data} />
  ));

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": {
          message: "Error al cargar el reporte: " + error,
        },
        "en-US": { message: "Error loading the report: " + error },
      },
    },
    empty: {
      isEmpty: !devices.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay items disponibles en el reporte" },
        "en-US": { message: "There are no available items on the report" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{
        plusActions,
        header,
        headersData,
        fullArray: auth.admin === 1 ? allDevices : undefined,
        coreData,
      }}
    />
  );
};

export default Core;
