import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { CustomError } from "../../../utility/response";
import { authenticationSliceActions as authentication } from "../../slices/authentication";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": {
    success: "Autentificación de dos factores activada exitosamente",
    error: "Error al intentar activar la autentificación: ",
    error2: "Codigo ingresado incorrecto",
  },
  "en-US": {
    success: "Two factor authentication successfully activated",
    error: "Error while trying to activate authentication: ",
    error2: "Entered code is wrong",
  },
};

export const enableTFAId = "enableTFA";

export const enableTFA = (data) => {
  const { server, cloner_key, code, user, name, locale } = data;
  const id = enableTFAId;
  const { success, error,error2 } = UIText[locale];

  return async (dispatch) => {
    try {
      dispatch(worker.start(id));
      const url = server + "api/user/twofactor";
      const bodyData = JSON.stringify({ user, has_tfa: 2, code, mode: 0 });
      const response = await axios.post(url, bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });

      if (response.data.resp === 9) {
        throw CustomError(error2, 666);
      } else if (response.data.resp === 0) {
        const bodyData = JSON.stringify({
          login: user,
          name,
          email: "",
          option: "2",
          code,
          server,
        });
        await axios.post(server + "api/users", bodyData, {
          headers: { "Content-Type": "application/json", cloner_key },
        });
      }

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(authentication.setTFA(2));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
