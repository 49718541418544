/**
 * A component that renders an export button for reports.
 * @returns {JSX.Element} The export button component.
 */
import { Download } from "@mui/icons-material";
import ActionButton from "../../../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../../../hooks2/useWorker";
import { forceRescanId } from "../../../../../../store/actions2/Reports/forceRescan";
import exportRecovery from "../../../../../../store/actions2/Recoveries/exportRecovery";
import useRecoveries from "../../../../../../hooks2/useRecoveries";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { working } = useWorker(forceRescanId);
  const selected = UI.selected ?? [];
  const recoveries = useRecoveries().recoveries ?? [];

  const onClick = () => {
    let devices = [];

    for (const item of selected) {
      let recovery = recoveries.find((x) => x.id === item);

      for (const repo of recovery.repositories) {
        let newDevice = {
          user: recovery.user,
          device: recovery.device,
          drive: repo.name,
          size: repo.size,
          root: repo.id,
          metafile: repo.metafile,
        };
        devices.push(newDevice);
      }
    }
    dispatch(exportRecovery({ devices, locale: settings.locale }));
  };

  return (
    <ActionButton
      {...{
        working,
        onClick,
        UIText,
        icon: <Download />,
        variant: "outlined",
      }}
    />
  );
};

export default Export;
