import { Download } from "@mui/icons-material";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { forceRescanId } from "../../../../store/actions2/Reports/forceRescan";
import exportFiles from "../../../../store/actions2/Devices/exportFiles";

const UIText = {
  "es-CL": { label: "Exportar" },
  "en-US": { label: "Export" },
};

const Export = ({ allFiles }) => {
  const { dispatch, settings } = useUIBoilerplate();
  const { working } = useWorker(forceRescanId);

  const onClick = () => {
    dispatch(exportFiles({ allFiles, locale: settings.locale }));
  };

  return (
    <ActionButton
      {...{
        working,
        onClick,
        UIText,
        icon: <Download />,
        variant: "outlined",
      }}
    />
  );
};

export default Export;
