import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Mantener", fixedTooltip: "Mantener solicitud actual" },
  "en-US": { label: "Keep", fixedTooltip: "Keep current request" },
};

const Keep = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(recoveries.dontAskAgain());
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Keep;
