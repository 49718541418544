import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useMemo } from "react";
import DeviceRow from "./DeviceRow/DeviceRow";
import Headers, { exclusionDevicesUI } from "./Headers2";

const UIText = {
  "es-CL": {
    many: "Dispositivos a modificar ",
    one: "Dispositivo a modificar: ",
    none: "No hay dispositivos seleccionados para modificar",
  },
  "en-US": {
    many: "Devices to be modified ",
    one: "Device to be modified: ",
    none: "No devices selected for modification",
  },
};

export const exclusionDevicesData = "exclusionDevData";

const DevicesList = () => {
  const { settings, warehouse, UI } = useUIBoilerplate();
  const exclusionDevicesSelected = UI[exclusionDevicesUI]??[]
  const allDevices = warehouse[exclusionDevicesData];
  const { many, one, none } = UIText[settings.locale];

  // console.log('exclusionDevices',exclusionDevices,exclusionDevicesSelected,exclusionDevices2)

  let finalHeader = "";

  if (exclusionDevicesSelected.length > 1) {
    finalHeader = many + `( ${exclusionDevicesSelected.length} )`;
  } else if (exclusionDevicesSelected.length === 1) {
    let deviceName = "";
    for (const key in allDevices) {
      deviceName = allDevices[key].name;
    }
    finalHeader = one + deviceName;
  } else {
    finalHeader = none;
  }

  const devicesList = useMemo(() => {
    let all = [];
    for (const key in allDevices) {
      const data = allDevices[key];
      all.push(<DeviceRow {...{ key: data.mid }} {...data} />);
    }
    return all;
  }, [allDevices]);

  return (
    <Grid
      container
      item
      {...{ padding: 1, paddingLeft: 2, paddingRight: 2, xs: 12 }}
      alignItems="center"
    >
      <Typography fontSize={18} padding={2} paddingLeft={0}>
        {finalHeader}
      </Typography>
      {devicesList.length > 1 ? (
        <Grid container item xs={12}>
          <Headers />
          <Grid
            container
            item
            {...{
              paddingLeft: "2vh",
              paddingRight: "2vh",
              xs: 12,
              sx: { maxHeight: 200, overflow: "scroll" },
            }}
          >
            {devicesList}
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default DevicesList;
