import { Card, Grid } from "@mui/material";
import TreeNode from "./TreeNode";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import useTrees from "../../../hooks2/useNewTrees";
import { repositoryPickerId } from "../../UI2/Specialized/RepositoryPicker";
import { useWorker } from "../../../hooks2/useWorker";
import { getGreylistId } from "../../../store/actions2/Devices/getGreylist";
import { greylistTreeReady } from "./Pickers/GreylstPicker";

const Body = () => {
  const { UI } = useUIBoilerplate();
  const { done, error } = useWorker(getGreylistId);
  const { done: treeReady } = useWorker(greylistTreeReady);
  const repoId = UI[repositoryPickerId] ?? "";
  const trees = useTrees();
  const tree = trees["greylist-" + repoId] ?? { nodes: [] };

  const rootNode = tree.nodes.length ? (
    <TreeNode {...{ tree: tree.nodes, idx: 0, level: 0 }} />
  ) : null;

  return (
    <Grid item xs={12}>
      {done && !error && treeReady ? (
        <Card
          {...{
            wrap: "nowrap",
            sx: {
              borderColor: "LightGray",
              borderStyle: "solid",
              borderWidth: "1px",
              boxShadow: "none",
              padding: "0.5rem",
              maxHeight: 450,
              overflowY: "auto",
              overflowX: "hidden",
            },
          }}
        >
          <Grid container>{rootNode}</Grid>
        </Card>
      ) : null}
    </Grid>
  );
};

export default Body;
