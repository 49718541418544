import { useDispatch } from "react-redux";
import { UISliceActions, UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";
import { adminNameUI, adminUserUI, organizationUI } from "../../RecoveryStep3/Components/DeliverySumary";

const UIText = {
  "es-CL": { label: "Nueva", fixedTooltip: "Iniciar una solicitud nueva" },
  "en-US": { label: "New", fixedTooltip: "Start a new request" },
};

const Reset = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(recoveries.reset());
    dispatch(UISliceActions.clear('selected'));
    dispatch(UISliceActions.clear(adminNameUI));
    dispatch(UISliceActions.clear(adminUserUI));
    dispatch(UISliceActions.clear(organizationUI));
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick, variant:'text' }} />;
};

export default Reset;
