import { useLocation } from "react-router-dom";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import DrawerButton from "./DrawerButton";
import { Tv } from "@mui/icons-material";
import useURLQuery from "../../../../../../hooks2/useURLQuery";

const UIText = {
  "es-CL": { label: "Dispositivos" },
  "en-US": { label: "Devices" },
};

const Devices = () => {
  const { auth } = useUIBoilerplate();
  const location = useLocation().pathname.split("/")[2];
  const { group } = useURLQuery();
  const selected = location === "devices";
  const navTo = `/dashboard/devices?group=${group ? group : auth.group}`;
  const accentColor = selected ? "select.accent" : "primary.light";
  const icon = <Tv {...{ sx: { color: accentColor } }} />;

  return <DrawerButton {...{ UIText, selected, navTo, icon, accentColor }} />;
};

export default Devices;
