import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { errorTxt: "Fallo al intentar descargar el archivo: " },
  "en-US": { errorTxt: "Failure to download file: " },
};

const downloadFile = (data) => {
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  const { errorTxt } = UIText[data.locale];
  return async (dispatch) => {
    try {
      let newURL = new URL("api/download", data.server);
      newURL.searchParams.append("token", data.token);
      newURL.searchParams.append("id", data.fileId);
      newURL.searchParams.append("repoid", data.repoId);
      newURL.searchParams.append("format", data.type === 1 ? "plain" : "zip");
      newURL.searchParams.append("include_deleted", data.deleted);

      const anchor = document.createElement("a");
      anchor.href = newURL;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      enqueueSnackbar(`${errorTxt} ${data.filename}: ${e.message}`, {
        variant: "error",
      });
    }
  };
};

export default downloadFile;
