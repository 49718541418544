import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import deleteDevices from "../../store/actions2/Devices/deleteDevices";
import DeleteConfirmation from "./DeleteConfirmation/DeleteConfirmation";

const DeleteDevicesConfirmation = () => {
  const { auth,dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { payload } = UI.modal2;
  const { devices } = payload;

  const confirmation = () => {
    dispatch(deleteDevices({
      ...auth,
      devices,
      locale,
    }))
  };

  return <DeleteConfirmation {...{ confirmation }} />;
};

export default DeleteDevicesConfirmation;
