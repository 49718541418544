import { Chip, Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import NewExtension, { customExtensionsUI } from "./Top/NewExtension";
import Clear from "./Top/Clear";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";

const UIText = {
  "es-CL": { header: "Extensiones personalizadas" },
  "en-US": { header: "Custom extensions" },
};

const CustomExtensions = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { header } = UIText[settings.locale];
  const extensions = UI[customExtensionsUI] ?? [];

  const items = extensions.map((ext, idx) => {
    return (
      <Chip
        {...{
          color: "primary",
          variant: "outlined",
          sx: { backgroundColor: "rgba(0, 167, 132, 0.1)", border: "none" },
          label: ext.toUpperCase(),
          key: idx,
          onDelete: () => {
            dispatch(
              UIactions.toggleSingle({ id: customExtensionsUI, key: ext })
            );
          },
        }}
      />
    );
  });

  return (
    <Grid container item {...{ padding: 2, xs: 12, rowGap: 1 }}>
      <Typography fontSize={18}>{header}</Typography>
      <Grid container item gap={1} paddingLeft={2} paddingRight={2}>
        <Grid container item xs={12} columnSpacing={2}>
          <NewExtension />
          <Clear />
        </Grid>
      </Grid>
      <Grid container item gap={1} paddingLeft={2} paddingRight={2}>
        {items}
      </Grid>
    </Grid>
  );
};

export default CustomExtensions;
