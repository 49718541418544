import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": {
    label: "Iniciar nueva solicitud",
    success: "Recuperación creada exitosamente",
  },
  "en-US": {
    label: "Start new request",
    success: "Recovery created successfully",
  },
};

const Continue = ({ newRecovery }) => {
  const { dispatch, navigate, settings } = useUIBoilerplate();
  const locale = settings.locale;
  const { success } = UIText[locale];

  const handleClick = () => {
    dispatch(recoveries.reset());
    dispatch(recoveries.newRecovery(newRecovery));
    dispatch(recoveries.initialize());
    dispatch(recoveries.dontAskAgain());
    dispatch(UISliceActions.closeModal());
    enqueueSnackbar(success, { variant: "success" });
    navigate("/dashboard/recovery/step2");
  };

  return <Button {...{ UIText, onClick: handleClick }} />;
};

export default Continue;
