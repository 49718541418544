import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";

const UITextNew = {
  "es-CL": {
    success: "Exito al crear el usuario",
    error: "Fallo al crear el usuario",
  },
  "en-US": {
    success: "User creation successful",
    error: "Failure to create user",
  },
};
const UITextNewMulti = {
  "es-CL": {
    success: "Exito al crear los usuarios",
    error: "Fallo al crear uno o mas usuarios:",
  },
  "en-US": {
    success: "User creation successful",
    error: "Failure to create one or more users:",
  },
};
const UITextSingle = {
  "es-CL": {
    success: "Usuario modificado exitosamente",
    error: "Error al modificar el usuario",
  },
  "en-US": {
    success: "User modification successful",
    error: "Failure to modify user",
  },
};
const UITextMulti = {
  "es-CL": {
    success: "Exito al modificar los usuarios",
    error: "Fallo al modificar uno o mas usuarios: ",
  },
  "en-US": {
    success: "Users modification sucessful",
    error: "Failure to one or more users: ",
  },
};

export const postUserId = "userForm";
export const postedUsersData = "postedUsers";

// POST user data
export const postUsers = (data) => {
  const { server, cloner_key, users, locale, reloadId, isNew } = data;
  let error = "";
  let success = "";
  if (isNew && users.length === 1) {
    const { error: e, success: s } = UITextNew[locale];
    error = e;
    success = s;
  } else if (isNew && users.length > 1) {
    const { error: e, success: s } = UITextNewMulti[locale];
    error = e;
    success = s;
  } else if (!isNew && users.length > 1) {
    const { error: e, success: s } = UITextMulti[locale];
    error = e;
    success = s;
  } else if (!isNew && users.lenght === 1) {
    const { error: e, success: s } = UITextSingle[locale];
    error = e;
    success = s;
  }
  const id = postUserId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    let currentUser = "";
    let errors = [];
    let postedUsers = [];
    for (const payload of users) {
      let userError = false;
      try {
        currentUser = payload.login;
        const bodyData = JSON.stringify(payload);
        const url = server + "api/user/new";
        await axios.post(url, bodyData, { headers: { cloner_key } });
      } catch (e) {
        userError = true;
        console.error(e);
        errors.push(`[${currentUser}] ${e.message}`);
      }

      const userData = { ...payload, status: userError ? "Error" : "Creado" };
      postedUsers.push(userData);
    }
    if (isNew && users.length > 1) {
      dispatch(warehouse.load({ id: postedUsersData, data: postedUsers }));
    }
    if (!errors.length) {
      dispatch(worker.clear(`groupData${reloadId}`));
      dispatch(worker.finish({ id }));
      enqueueSnackbar(success, { variant: "success" });
    } else {
      dispatch(worker.finish({ id, error: errors.join(", ") }));
      enqueueSnackbar(`${error} ${errors.join(", ")}`, { variant: "error" });
    }
  };
};
