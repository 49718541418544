import { CircularProgress, DialogTitle, Grid, Typography } from "@mui/material";
import IconButton from "../../../UI2/IconButton";
import { CloudSync } from "@mui/icons-material";
import restoreMails from "../../../../store/actions2/Mails/restoreMails";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import { getMailId } from "../../../../store/actions2/Mails/getMail";

const UIText = {
  "es-CL": { fixedTooltip: "Restaurar correo" },
  "en-US": { fixedTooltip: "Restore email" },
};

const Header = (props) => {
  const { id: mailId, subject, receivedDateTime, sentDateTime } = props;
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { working } = useWorker("restore-" + mailId);
  const { done, error } = useWorker(getMailId);

  const localDate = new Date(receivedDateTime ?? sentDateTime).toLocaleString(
    settings.locale
  );

  const onClick = (e) => {
    dispatch(
      restoreMails({
        ...auth,
        workerId: "restore-" + mailId,
        mailIDs: [mailId],
        locale: settings.locale,
      })
    );
  };

  const icon = working ? (
    <CircularProgress size={20} />
  ) : (
    <CloudSync sx={{ fontSize: 20 }} />
  );

  const disabled = !done || error || working;

  return (
    <DialogTitle sx={{ padding: 2, paddingTop: 1, paddingBottom: 1 }}>
      <Grid container item alignItems="center" columnGap={2}>
        <Grid item xs={8.5}>
          <Typography fontSize={18} noWrap>
            {subject}
          </Typography>
        </Grid>
        <Grid container item xs={true} justifyContent="end">
          <Typography fontSize={15}>{localDate}</Typography>
        </Grid>
        <Grid container item xs={0.5} justifyContent="end">
          <IconButton
            {...{
              disabled,
              UIText,
              onClick,
              icon,
              sx: {
                "&:hover": {
                  color: "primary.main",
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default Header;
