import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import BasicDialogTitle from "../../../../UI2/BasicDialog/BasicDialogTitle";
import Cancel from "./Cancel";
import BasicDialogActions from "../../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import Confirm from "./Confirm";
import { useWorker } from "../../../../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../../../../store/slices/workers";
import { useEffect } from "react";
import { deleteOrgId } from "../../../../../store/actions2/Windows365/deleteOrganization";
import { currentGroupnameId } from "../../../../../store/actions2/GroupTraveler/loadGroupsTree";

const ClearW365Org = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { done, error } = useWorker(deleteOrgId);
  const locale = settings.locale;
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": {
      header: `Remover las credenciales de Windows365 del grupo ${groupname}?`,
    },
    "en-US": {
      header: `Remove Windows365 credentials from group ${groupname}?`,
    },
  };
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
      dispatch(workers.clear(deleteOrgId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm />
      </BasicDialogActions>
    </Dialog>
  );
};

export default ClearW365Org;
