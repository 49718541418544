import { Mail } from "@mui/icons-material";
import Button from "../../../../UI2/Button";
import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { adminNameUI, adminUserUI, organizationUI } from "./DeliverySumary";
import useRecoveries from "../../../../../hooks2/useRecoveries";
import sendRecoveryRequest, { submitRecoveryId } from "../../../../../store/actions2/Recoveries/sendRecoveryRequest";
import { useWorker } from "../../../../../hooks2/useWorker";

const UIText = {
  "es-CL": { label: "Enviar solicitud" },
  "en-US": { label: "Send request" },
};

const Send = () => {
  const { auth, dispatch, UI } = useUIBoilerplate();
  const {working} = useWorker(submitRecoveryId)
  const adminNameField = UI[adminNameUI]??{}
  const adminUserField = UI[adminUserUI]??{}
  const organizationField = UI[organizationUI]??{}
  const requestData = useRecoveries() ?? {};

  const handleClick = () => {
    dispatch(
      sendRecoveryRequest({
        ...auth,
        requestData,
        senderData: {
          adminName:adminNameField.value??auth.name,
          adminUser:adminUserField.value??auth.user,
          organization:organizationField.value??auth.groupName, 
        },
      })
    );
  };

  return (
    <Grid container item xs={4} justifyContent="end">
      <Button
        {...{
          working,
          UIText,
          onClick:handleClick,
          // navTo: "/dashboard/recovery/finish",
          startIcon: <Mail />,
        }}
      />
    </Grid>
  );
};

export default Send;
