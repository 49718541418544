import Category from "./Category";

const UIText = {
  "es-CL": { header: "Imagen" },
  "en-US": { header: "Image" },
};

const Image = () => {
  return <Category {...{ category: "graphic", UIText }} />;
};

export default Image;
