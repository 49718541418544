import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import Close from "./Components/Cerrar";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { Dialog, Grid } from "@mui/material";
import Windows365, { w365switchUI } from "./Components/Windows365/Windows365";
import { useEffect } from "react";
import { useWorker } from "../../hooks2/useWorker";
import {
  getOrganization,
  getW365OrgId,
  w365orgData,
} from "../../store/actions2/Windows365/getOrganization";
import useURLQuery from "../../hooks2/useURLQuery";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import SlimAlert from "../UI2/SlimAlert";

const UIText = {
  "es-CL": {
    header: "Respaldo de nubes",
    message:
      "Las credenciales utilizadas para el servicio de Windows365 solo aplican a los usuarios directos del grupo, lo que excluye los usuarios pertenecientes a subgrupos del mismo. Para otorgarle acceso a los usuarios de subgrupos es necesario activar la opción 'Compartir' en el grupo padre y la opción 'Obtener' en los grupos hijos.",
  },
  "en-US": {
    header: "Clouds backup",
    message:
      "The credentials used to enable Windows365 credentials only work for users belonging directly to the group, this excludes any user inside children groups. To grant access to subgroup users the 'Share' option must be enabled, as well as the 'Obtain' in any subgroup.",
  },
};

const OrgCloudBackup = () => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { done } = useWorker(getW365OrgId);

  const { header, message } = UIText[settings.locale];

  // useEffect(() => {
  //   if (!done) {
  //     console.log("getOrganization dispatched");
  //     dispatch(
  //       getOrganization({
  //         ...auth,
  //         groupId: group,
  //       })
  //     );
  //   }
  // }, [auth, dispatch, group,done]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(w365switchUI));
      // dispatch(workers.clear(getW365OrgId));
      // dispatch(warehouseActions.unload(w365orgData));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    // if (reason !== "backdropClick") {
    dispatch(UIactions.closeModal());
    // }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "md" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container {...{ rowGap: 2, sx: { width: "100%" } }}>
          <SlimAlert notCollapse {...{ errors: [message], xs: 12, severity:'info' }} />
          <Windows365 />
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
      </BasicDialogActions>
    </Dialog>
  );
};

export default OrgCloudBackup;
