import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";

const UIText = {
  "es-CL": {
    success: "Exito al crear/modificar usuario",
    error: "Fallo al crear/modificar usuario",
  },
  "en-US": {
    success: "Success creating/modifying the user",
    error: "Failure while creating/modifying user",
  },
};

export const postUserId = "userForm";

// POST user data
export const postUser = (data) => {
  return async (dispatch) => {
    dispatch(worker.start(postUserId));
    const { server, cloner_key, payload, locale, reloadId } = data;
    const { error, success } = UIText[locale];
    try {
      const bodyData = JSON.stringify(payload);
      await axios.post(server + "api/user/new", bodyData, {
        headers: { "Content-Type": "application/json", cloner_key },
      });
      const msg = success + payload.user;
      dispatch(worker.finish({ id:postUserId }));
      dispatch(worker.clear(`groupData${reloadId}`));
      enqueueSnackbar(msg, { variant: "success" });
    } catch (e) {
      const msg = error + payload.user + ". " + e;
      enqueueSnackbar(msg, { variant: "error" });
      dispatch(worker.finish({ id:postUserId, error: e }));
    }
  };
};
