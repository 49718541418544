import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import ToggleIcon from "../ToggleIcon";
import UIArrayError from "../Utilities/UIArrayError";

const options = [
  {
    icon: <VisibilityOff sx={{ fontSize: "1rem" }} />,
    UIText: {
      "es-CL": {
        fixedTooltip: "Mostrar contraseña",
      },
      "en-US": {
        fixedTooltip: "Show password",
      },
    },
  },
  {
    icon: <Visibility sx={{ fontSize: "1rem" }} />,
    UIText: {
      "es-CL": {
        fixedTooltip: "Ocultar contraseña",
      },
      "en-US": {
        fixedTooltip: "Hide password",
      },
    },
  },
];

const VisibilityInsert = (props) => {
  const { id, disabled, position = "end" } = props;
  const { settings } = useUIBoilerplate();
  const locale = settings["locale"];

  if (UIArrayError(id, locale, options)) {
    return;
  }

  return (
    <InputAdornment {...{ position }}>
      <ToggleIcon {...{ id: id + "-show", options, disabled }} />
    </InputAdornment>
  );
};

export default VisibilityInsert;
