import { Grid, Typography } from "@mui/material";
import IconButton from "./IconButton";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { defaultRadioSelect } from "./genericIDs";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";

const RadioSelect = (props) => {
  const {
    id = defaultRadioSelect,
    value,
    label,
    xl,
    lg,
    md,
    sm,
    xs,
    onClick = () => {},
    allowEmpty,
    disabled,
    labelLeft,
    labelProps,
  } = props;
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI[id];

  const icon =
    selected === value ? (
      <RadioButtonChecked color="primary" />
    ) : (
      <RadioButtonUnchecked />
    );

  const handleClick = () => {
    if (selected !== value) {
      dispatch(UIactions.setValue({ id, value }));
    } else if (allowEmpty) {
      dispatch(UIactions.clear(id));
    }
    onClick(selected, value, allowEmpty);
  };

  return (
    <Grid
      container
      item
      {...{ wrap: "nowrap", alignItems: "center", xl, lg, md, sm, xs }}
    >
      {labelLeft ? (
        <Typography
          {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
        >
          {label}
        </Typography>
      ) : null}
      <IconButton
        {...{ disabled, onClick: handleClick, icon, UIText: { universal: {} } }}
      />
      {labelLeft ? null : (
        <Typography
          {...{ fontWeight: "bold", fontSize: "0.9rem", ...labelProps }}
        >
          {label}
        </Typography>
      )}
    </Grid>
  );
};

export default RadioSelect;
