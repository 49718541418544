import { Grid, TextField } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Usuario" },
  "en-US": { label: "User" },
};

const User = ({ user }) => {
  const { settings } = useUIBoilerplate();
  const locale = settings.locale;
  const { label } = UIText[locale];

  return (
    <Grid container item xs={12}>
      <TextField disabled fullWidth {...{ label, value: user, size:'small' }} />
    </Grid>
  );
};

export default User;
