import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";
import BasicHeader from "../../../UI2/BasicLayout/Components/Header";
import BasicHeaders from "../../../UI2/BasicLayout/Components/BasicHeaders";
import Core from "../../../UI2/BasicLayout/Components/Core/Core";
import BasicFooter from "../../../UI2/BasicLayout/Components/BasicFooter";
import { RecoverySteps } from "../Steps";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import headersData from "./Components/Headers";
import DeliverySummary from "./Components/DeliverySumary";
import useRecoveries from "../../../../hooks2/useRecoveries";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import Back from "./Components/Back";
import Send from "./Components/Send";
import { useWorker } from "../../../../hooks2/useWorker";
import { submitRecoveryId } from "../../../../store/actions2/Recoveries/sendRecoveryRequest";
import { useEffect } from "react";
import { workersSliceActions } from "../../../../store/slices/workers";

const RecoveryStep3 = () => {
  const { dispatch, navigate, settings } = useUIBoilerplate();
  const { done, error } = useWorker(submitRecoveryId);
  const requestData = useRecoveries() ?? {};
  const { recoveries } = requestData;

  useEffect(() => {
    if (done && !error) {
      navigate("/dashboard/recovery/finished");
      dispatch(workersSliceActions.clear(submitRecoveryId))
    }
  }, [dispatch, navigate, done, error]);

  const devices = recoveries.map((r) => {
    let full_size = 0;
    for (const repo of r.repositories) {
      full_size += repo.size;
    }

    return {
      id: r.id,
      user: r.user,
      mid: r.mid,
      device: r.device,
      full_size,
      lastBkp: r.lastBkp,
      repositories: r.repositories,
      mode: r.mode,
    };
  });

  //   const fullArray = recoveries.map((r) => r.id);
  //   const isEmpty = devices.length === 0;

  const elements = devices.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));
  //   const plusActions = <Actions />;
  const coreData = {
    // working,
    error: {
      //   workerId,
      //   error,
      UIText: { "es-CL": { message: "" }, "en-US": { message: "" } },
    },
    empty: {
      //   isEmpty,
      UIText: {
        "es-CL": { message: "No se ha agregado ninguna recuperación" },
        "en-US": { message: "No recovery has been added" },
      },
    },
    done: true,
    elements,
  };

  const steps = RecoverySteps[settings.locale];
  const header = steps[2];

  return (
    <Grid
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
          overflow: "scroll",
        },
        alignContent: "start",
      }}
    >
      <Grid
        container
        item
        {...{
          alignItems: "center",
          sx: { height: "12vh" },
        }}
      >
        <Stepper activeStep={2} alternativeLabel sx={{ width: "100%" }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container item>
        <Card {...{ sx: { width: "100%" } }}>
          <Grid
            container
            {...{
              sx: {
                // maxHeight: "88vh",
                width: "100%",
              },
            }}
          >
            <BasicHeader>{header}</BasicHeader>
            <DeliverySummary />
            {headersData ? <BasicHeaders {...headersData} /> : null}
            <Core hasHeaders={!!headersData} {...coreData} contentSize={0} />
            <BasicFooter />
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        item
        {...{
          justifyContent: "space-between",
          alignItems: "center",
          sx: { height: "6vh" },
        }}
      >
        <Back />
        <Send />
      </Grid>
    </Grid>
  );
};

export default RecoveryStep3;
