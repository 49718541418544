import { validateMailLoose } from "./stringValidations2";

const UIText = {
  "es-CL": {
    error1: "El correo no puede esta vacío",
    error2: 'El correo no tiene el formato requerido "usuario@cloner.cl"',
  },
  "en-US": {
    error1: "The email cannot be empty",
    error2: 'The email does not meet the required format "user@cloner.cl"',
  },
};

const notEmptyUser = (user, locale) => {
  const { error1, error2 } = UIText[locale];

  if (!user) {
    return error1
  }
  if (!validateMailLoose(user)) {
    return error2
  }
  return "";
};

export default notEmptyUser;
