import { Grid } from "@mui/material";
import Button from "../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Volver" },
  "en-US": { label: "Back" },
};

const BackBtn = () => {
  return (
    <Grid container item xs={12} justifyContent="center">
      <Button
        fullWidth
        {...{
          UIText,
          navTo: "/login",
          lg: 3,
          md: 4,
          sm: 6,
          xs: 8,
          variant: "outlined",
        }}
      />
    </Grid>
  );
};

export default BackBtn;
