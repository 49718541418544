import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as workers } from "../../slices/workers";
import { parseGroupsRecursive } from "../Users/utils";
import { devicesLoadedId } from "../../../components/Devices/Devices";

// export const groupsRecursiveId = "groupsRecursive";
export const devicesDataId = "devices";
// export const devicesListId = "devicesList";

const getAllDevices = (data) => {
  console.log("getting group data!");
  const { server, cloner_key, group, controller, includeEmpty } = data;
  const id = devicesLoadedId;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = server + "api/groups_recursive";
      const response = await axios.get(url, {
        signal: controller.signal,
        headers: { cloner_key },
        params: { group },
      });

      const parsedData = parseGroupsRecursive(response.data);

      for (const newDevice of parsedData) {
        if (newDevice.mid || includeEmpty) {
          dispatch(
            warehouse.add({
              id: "devices",
              key: newDevice.mid ? newDevice.mid : newDevice.user,
              data: newDevice,
            })
          );
        }
      }

      dispatch(workers.finish({ id }));
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        dispatch(workers.clear(id));
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
    }
  };
};

export default getAllDevices;
