import { ArrowDropDown, ArrowRight, Remove } from "@mui/icons-material";

const ExpandedIcon = (
  loaded,
  expanded,
  isDir,
  hasChildren,
  status,
  fontSize = 20
) => {
  if ((loaded && !hasChildren) || !isDir || !!status) {
    return <Remove sx={{ fontSize }} />;
  } else if (expanded) {
    return <ArrowDropDown sx={{ fontSize }} />;
  } else {
    return <ArrowRight sx={{ fontSize }} />;
  }
};

export default ExpandedIcon;
