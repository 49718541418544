import { Button, Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { ChevronRight } from "@mui/icons-material";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useTrees from "../../../../hooks2/useNewTrees";
import { useWorker } from "../../../../hooks2/useWorker";
import useURLQuery from "../../../../hooks2/useURLQuery";
import {
  getGroupsListId,
  groupsTreeId,
  selectedGroupIdx2,
} from "../../../../store/actions2/GroupTraveler/loadGroupsTree";

const GroupButton = ({ disabled, hideGroup, startGroup }) => {
  const { auth, dispatch, UI } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { working } = useWorker(getGroupsListId);
  const isRootAndGroup = parseInt(group) === auth.group && hideGroup;
  const selected = UI[selectedGroupIdx2];
  console.log("selected", selected);
  const tree = useTrees()[groupsTreeId];
  const groupObj = typeof selected === "number" && tree ? tree[selected] : null;
  const groupname = groupObj ? groupObj.name : "Loading...";

  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "groups-traveler",
        payload: { startGroup, hideGroup },
      })
    );
  };

  return (
    <Grid container item xs={12}>
      <Button
        fullWidth
        {...{
          disabled: !!disabled || !!isRootAndGroup || working,
          id: "travelerButton",
          startIcon: (
            <ChevronRight
              sx={{
                color:
                  disabled || !!isRootAndGroup ? "disabled" : "secondary.main",
              }}
            />
          ),
          onClick,
          variant: "outlined",
          style: { justifyContent: "flex-start" },
        }}
      >
        <Typography
          fontSize={15}
          color={disabled || isRootAndGroup ? "disabled" : "secondary.main"}
        >
          {groupname}
        </Typography>
      </Button>
    </Grid>
  );
};

export default GroupButton;
