import { UploadFile } from "@mui/icons-material";
import { read, utils } from "xlsx";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import AutoHelp from "../../../UI2/Wrappers/AutoHelp";
import {
  generateHexString,
  generatePassword,
} from "../../../../utility/generateString";
import { UISliceActions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import { arrayId } from "../UsersList/UsersList";

const UITextUpload = {
  "es-CL": {
    label: "Seleccionar vacíos",
    fixedTooltip:
      "Selecciona todos los usuarios sin correo windows 365 asignado",
  },
  "en-US": {
    label: "Select empty",
    fixedTooltip: "Select all users without a windows 365 email assigned",
  },
};

export const newUsersUI = "newUsers";

const SelectEmpty = ({ users }) => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const userList = UI[arrayId] ?? [];
  const { label, fixedTooltip } = UITextUpload[settings.locale];

  const handleSelect = (e) => {
    let justUsers = users.map((user) => user.login);

    console.log("handleSelect", userList, justUsers);

    let newSelection = []

    for (const user of justUsers) {
      let mailField = UI["365mail-" + user] ?? {};
      console.log('mailField',user, mailField.value)
      if (!mailField.value) {
        newSelection.push(user)
        // dispatch(UISliceActions.add({ id: arrayId, key: user }));
      }
      // if (!userList.includes(user)) {
      // dispatch(UISliceActions.toggleSingle({ id: arrayId, key: user }));
      // }
    }

    dispatch(UISliceActions.setValue({ id: arrayId, value: newSelection }));
    // if (!e.target.files) {
    //   return;
    // }
    // const file = e.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   const wb = read(event.target.result);
    //   const sheets = wb.SheetNames;
    //   if (sheets.length) {
    //     const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
    //     for (const row of rows) {
    //       const id = generateHexString(20);
    //       let mail = !!row.email ? row.email.toLowerCase() : undefined;
    //       let name = !!row.nombre ? row.nombre.toLowerCase() : undefined;
    //       let admin = !!row.es_administrador
    //         ? row.es_administrador.toLowerCase()
    //         : "";
    //       let mails = !!row.recibe_correos
    //         ? row.recibe_correos.toLowerCase()
    //         : "";
    //       let access = !!row.accede_plataforma
    //         ? row.accede_plataforma.toLowerCase()
    //         : "";
    //       let rndPass = !!row.contraseña_aleatoria
    //         ? row.contraseña_aleatoria.toLowerCase()
    //         : "";
    //       let fixPass = !!row.contraseña_fija ? row.contraseña_fija : undefined;
    //       const newUser = {
    //         id,
    //         mail,
    //         name,
    //         admin: !!admin.match("si"),
    //         mails: !!mails.match("si"),
    //         access: !!access.match("si"),
    //         pass: fixPass ?? (!!rndPass ? generatePassword(10) : undefined),
    //       };
    //       dispatch(UISliceActions.addObj({ id: newUsersUI, obj: newUser }));
    //     }
    //   }
    // };
    // reader.readAsArrayBuffer(file);
    // e.target.value = "";
    // console.log("finished handling upload");
  };

  let disabled = false;
  let working = false;

  return (
    <Button
      {...{
        disabled,
        UIText: UITextUpload,
        onClick: handleSelect,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default SelectEmpty;
