import { ChevronRight } from "@mui/icons-material";
import Button from "../../../../UI2/Button";
import useRecoveries from "../../../../../hooks2/useRecoveries";

const UIText = {
  "es-CL": { label: "Siguiente" },
  "en-US": { label: "Next" },
};

const Next = () => {
  const request = useRecoveries();
  const recoveries = request.recoveries ?? [];

  return (
    <Button
      {...{
        disabled: recoveries.length === 0,
        UIText,
        navTo: "/dashboard/recovery/step2",
        endIcon: <ChevronRight />,
      }}
    />
  );
};

export default Next;
