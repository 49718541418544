import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import postDevicesInfo from "../../store/actions2/Devices/postDevicesInfo";
import GenericConfirmation2 from "./GenericConfirmation2/GenericConfirmation";

const UIText = {
  "es-CL": {
    header: "Esta seguro que desea modificar estos dispositivos?",
    message:
      "Las preferencias eligidas serán aplicadas a todos los dispositivos seleccionados",
  },
  "en-US": {
    header: "Are you sure you want to modify these devices?",
    message: "The selected settings will be applied to all selected devices",
  },
};

const ConfigureDevicesConfirmation = (props) => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { payload } = UI.modal2;
  const locale = settings.locale;
  const { header, message } = UIText[locale];
  const { devices, updateData: ud } = payload;

  let updateData = [];
  for (const device of devices) {
    let data = {
      config: { ...ud.config.payload, user: device.user, machine: device.mid },
      suspended: {
        ...ud.suspended.payload,
        user: device.user,
        machine: device.mid,
      },
      password: {
        ...ud.password.payload,
        user: device.user,
        machine: device.mid,
      },
    };
    updateData.push(data);
  }

  const confirmation = () => {
    dispatch(
      postDevicesInfo({
        server: ud.server,
        cloner_key: ud.cloner_key,
        worldAdmin: ud.worldAdmin,
        updateData,
        locale,
      })
    );
  };

  return (
    <GenericConfirmation2
      {...{ header, message, severity: "warning", confirmation }}
    />
  );
};

export default ConfigureDevicesConfirmation;
