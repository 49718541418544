import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { w365orgsData } from "../../../../store/actions2/Windows365/getOrganizations";
import { arrayId } from "../UsersList/UsersList";
import { validateMailId, validateW365Mails } from "../../../../store/actions2/Windows365/validateW365Mails";
import { useWorker } from "../../../../hooks2/useWorker";
import Button from "../../../UI2/Button";

const UITextUpload = {
  "es-CL": {
    label: "Verificar correo",
    fixedTooltip: "Verifica que el correo para Windows 365 es valido",
  },
  "en-US": {
    label: "Verify mail",
    fixedTooltip: "Verify that the email for Windows 365 is valid",
  },
};

export const newUsersUI = "newUsers";

const VerifyMail = ({ users }) => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { W365server, cloner_key } = auth;
  const {working} = useWorker(validateMailId);
  const { locale } = settings;
  const { label, fixedTooltip } = UITextUpload[locale];
  const selectedUsers = UI[arrayId] ?? [];

  const handleVerify = (e) => {
    let tryouts = [];

    for (const user of selectedUsers) {
      for (const user2 of users) {
        let field = UI["365mail-" + user2.login];
        if (user2.login === user) {
          tryouts.push({
            login: user2.login,
            field: field.value,
            org: warehouse[w365orgsData][user2.group],
          });
        }
      }
    }
    dispatch(validateW365Mails({ tryouts, cloner_key, W365server, locale }));
  };

  return (
    <Button
      {...{
        disabled: selectedUsers.length === 0,
        UIText: UITextUpload,
        onClick: handleVerify,
        working,
        variant: "outlined",
        labelSx: { fontSize: "0.8rem", fontWeight: "bold" },
      }}
    />
  );
};

export default VerifyMail;
