import { Grid } from "@mui/material";
import SlimAlert from "../../../SlimAlert";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import Button from "../../../Button";
import { Cached } from "@mui/icons-material";
import { workersSliceActions as workers } from "../../../../../store/slices/workers";

// const x = {
//     hasError: true,
//     UIText: {
//         universal: { message: "????" }
//     }
// };

const UIText2 = {
  "es-CL": { label: "Reintentar" },
  "en-US": { label: "Retry" },
};

const Error = ({ workerId, error, UIText = { universal: {} } }) => {
  const { dispatch,settings } = useUIBoilerplate();
  const { message } = UIText[settings.locale] ?? UIText.universal;

  if (!error) {
    return;
  }

  const onClick = ()=>{
    dispatch(workers.clear(workerId))
  }

  return (
    <Grid
      container
      item
      {...{
        height: "30vh",
        xs: 12,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container item xs={6} rowGap={3} justifyContent='center'>
        <SlimAlert notCollapse {...{ errors: [message], xs: 12 }} />
        <Button
          {...{
            startIcon: <Cached />,
            onClick,
            UIText: UIText2,
            variant: "outlined",
            size: "medium",
            xs: 'auto',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Error;
