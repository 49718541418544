import { Fragment } from "react";
import Divider from "../../UI2/Divider";
import { Grid } from "@mui/material";
import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { useLocation } from "react-router-dom";
import { workersSliceActions } from "../../../store/slices/workers";
import { groupsRecursiveId } from "../../../store/actions2/Users/getGroupsRecursive";
import { UISliceActions } from "../../../store/slices/UI2";
import { selectedGroupId } from "../../../store/actions2/GroupTraveler/loadGroupsTree";
import { devicesLoadedId } from "../../Devices/Devices";
import { recursiveRequestId } from "../../Reports/Components/Actions/Recursive";

const UIText = {
  "es-CL": { label: "Ingresar" },
  "en-US": { label: "Enter" },
};

const Bottom = (props) => {
  const { close } = props;
  const {dispatch,UI} = useUIBoilerplate()
  const location = useLocation()
  const group = UI[selectedGroupId]

  const navTo = `${location.pathname}?group=${group}`
  const onClick = ()=>{
    close()
    dispatch(workersSliceActions.clear(devicesLoadedId))
    dispatch(workersSliceActions.clear(groupsRecursiveId))
    dispatch(UISliceActions.clear(recursiveRequestId))
  }

  return (
    <Fragment>
      <Divider />
      <Grid container item padding={2} justifyContent="end">
        <Button
          {...{ UIText, onClick, navTo,  labelSx: { fontSize: 13, fontWeight: "bold" } }}
        />
      </Grid>
    </Fragment>
  );
};

export default Bottom;
