import { Grid } from "@mui/material";
import { Fragment } from "react";
import Button from "../../UI2/Button";
import Divider from "../../UI2/Divider";
import { Add } from "@mui/icons-material";
import GroupPicker from "../../UI2/Specialized/GroupPicker";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../store/slices/UI2";
import { selectedGroupId } from "../../../store/actions2/GroupTraveler/loadGroupsTree";

const UIText = {
  "es-CL": { label: "Crear grupo" },
  "en-US": { label: "Create group" },
};

const Top = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI[selectedGroupId];

  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "create-new-group",
        payload: { group: selected },
      })
    );
  };

  return (
    <Fragment>
      <Grid
        container
        item
        {...{
          justifyContent: "space-between",
          columnSpacing: 2,
          padding: 2,
          xs: 12,
        }}
      >
        <GroupPicker {...{ xs: 6, travelerType:'topbar' }} />
        <Button
          {...{
            UIText,
            startIcon: <Add />,
            variant: "text",
            labelSx: {
              fontSize: 13,
              fontWeight: "bold",
            },
            onClick,
            xs: 3,
          }}
        />
      </Grid>
      <Divider />
    </Fragment>
  );
};

export default Top;
