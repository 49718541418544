import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import useUIBoilerplate from "../../../../../../../../hooks2/useUIBoilerplate";
import ContactName, { contactNameUI } from "./ContactName";
import ContactEmail, { contactEmailUI } from "./ContactEmail";
import ContactPhone, { contactPhoneUI } from "./ContactPhone";
import SlimAlert from "../../../../../../../UI2/SlimAlert";

const UIText = {
  "es-CL": "Información de contacto",
  "en-US": "Contact information",
};

const ContactInfo = () => {
  const { settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const subtitle = UIText[locale];

  const { error: e1 } = UI[contactNameUI]??{}
  const { error: e2 } = UI[contactPhoneUI]??{}
  const { error: e3 } = UI[contactEmailUI]??{}

  const errors = [e1, e2, e3];

  return (
    <Fragment>
      <Grid container item xs={12}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid>
      <ContactName />
      <ContactPhone />
      <ContactEmail />
      <SlimAlert {...{ errors, xs: 12 }} />
    </Fragment>
  );
};

export default ContactInfo;
