import { notEmptyGroup } from "../../../utility/validations/notEmptyGroup";
import TextField from "../../UI2/SimpleTextField";

const UIText = {
  "es-CL": {
    label: "Nombre del grupo",
    placeholder: "Cloner Spa",
  },
  "en-US": {
    label: "Group name",
    placeholder: "Cloner Spa",
  },
};

export const groupNameId = "groupname";

const GroupName = (props) => {
  const { disabled } = props;

  return (
    <TextField
      tagError
      autoFocus
      {...{
        id: groupNameId,
        UIText,
        validation: notEmptyGroup,
        disabled,
        xs: 12,
      }}
    />
  );
};

export default GroupName;
