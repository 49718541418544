import { Delete, Folder, Storage } from "@mui/icons-material";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { CircularProgress } from "@mui/material";
import { Fragment } from "react";
import useURLQuery from "../../../../hooks2/useURLQuery";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Button from "../../../UI2/Button";

const Name = (props) => {
  const { idx, name, deleted, working, repository, id } = props;
  const {dispatch,navigate} = useUIBoilerplate()
  const { user, mid } = useURLQuery();


  const startIcon = idx ? <Folder /> : <Storage />;
  const endIcon = deleted ? <Delete color="error" /> : null;
  const endIcon2 = working ? <CircularProgress size={14} />:null

  // handle folder click
  const onClick = () => {
    navigate(`?user=${user}&mid=${mid}&repo=${repository}&file=${id}`);
    dispatch(workers.clear(id));
  };

  return (
    <Button
      fullWidth
      {...{
        UIText: { universal: { label: name } },
        onClick,
        startIcon,
        endIcon:<Fragment>{endIcon}{endIcon2}</Fragment>,
        style: { justifyContent: "flex-start" },
        labelSx: { fontSize: 13, textTransform: "none", color:'#555' },
        sx: { padding: 0, paddingLeft: 1, paddingRight:1,marginRight:1 },
        variant: "text",
        xs: 'auto',
        // working,
      }}
    />
  );
};

export default Name;
