import { UploadFile } from "@mui/icons-material";
import { read, utils } from "xlsx";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import AutoHelp from "../../../UI2/Wrappers/AutoHelp";
import {
  generateHexString,
  generatePassword,
} from "../../../../utility/generateString";
import { Button, Grid, Typography } from "@mui/material";
import { UISliceActions } from "../../../../store/slices/UI2";

const UITextUpload = {
  "es-CL": {
    label: "Cargar usuarios",
    fixedTooltip: "Cargar la hoja .xlsx con la información de los usuarios",
  },
  "en-US": {
    label: "Upload users",
    fixedTooltip: "Upload the .xlsx sheet with the users data",
  },
};

export const newUsersUI = "newUsers";

const UploadSheet = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const { label, fixedTooltip } = UITextUpload[settings.locale];

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;

      if (sheets.length) {
        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        for (const row of rows) {
          const id = generateHexString(20);
          let mail = !!row.email ? row.email.toLowerCase() : undefined;
          let name = !!row.nombre ? row.nombre.toLowerCase() : undefined;
          let admin = !!row.es_administrador
            ? row.es_administrador.toLowerCase()
            : "";
          let mails = !!row.recibe_correos
            ? row.recibe_correos.toLowerCase()
            : "";
          let access = !!row.accede_plataforma
            ? row.accede_plataforma.toLowerCase()
            : "";
          let rndPass = !!row.contraseña_aleatoria
            ? row.contraseña_aleatoria.toLowerCase()
            : "";
          let fixPass = !!row.contraseña_fija ? row.contraseña_fija : undefined;

          const newUser = {
            id,
            mail,
            name,
            admin: !!admin.match("si"),
            mails: !!mails.match("si"),
            access: !!access.match("si"),
            pass: fixPass ?? (!!rndPass ? generatePassword(10) : undefined),
          };
          dispatch(UISliceActions.addObj({ id: newUsersUI, obj: newUser }));
        }
      }
    };
    reader.readAsArrayBuffer(file);
    e.target.value = "";
    console.log("finished handling upload");
  };

  return (
    <Grid item>
      <AutoHelp {...{ fixedTooltip }}>
        <Button
          component="label"
          variant="outlined"
          startIcon={<UploadFile />}
          size="small"
        >
          <Typography fontSize="0.8rem" fontWeight="bold">
            {label}
          </Typography>
          <input type="file" hidden onChange={handleUpload}></input>
        </Button>
      </AutoHelp>
    </Grid>
  );
};

export default UploadSheet;
