import { useEffect } from "react";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import {
  addRecoveriesUI,
} from "../../../../../store/actions2/Recoveries/addRecoveries";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";
import { workersSliceActions } from "../../../../../store/slices/workers";
import createUsersRequest from "../../../../../store/actions2/Recoveries/createUsersRequest";

const UIText = {
  "es-CL": { label: "Iniciar nueva solicitud" },
  "en-US": { label: "Start new request" },
};

const Continue = ({ users = [] }) => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const locale = settings.locale;


  useEffect(() => {
    return () => {
      dispatch(workersSliceActions.clear(addRecoveriesUI));
    };
  }, [dispatch]);

  const onClick = () => {
    dispatch(recoveries.reset());
    dispatch(
      createUsersRequest({
        ...auth,
        users,
        locale,
      })
    );
    dispatch(UISliceActions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Continue;
