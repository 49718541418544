import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { error: "Fallo al intentar descargar el archivo: " },
  "en-US": { error: "Failed to download file: " },
};

export const downloadSharedData = (data) => {
  // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
  const { server, repo_id, file: fileId, id: linkId, password, locale } = data;
  const { error } = UIText[locale];
  return async (dispatch) => {
    // console.log("downloading?");
    // dispatch(statusSliceActions.startSubmit(""));
    try {
      let newURL = new URL("api/download", server);
      newURL.searchParams.append("through", linkId);
      newURL.searchParams.append("repoid", repo_id);
      newURL.searchParams.append("id", fileId);
      newURL.searchParams.append("password", password);

      const anchor = document.createElement("a");
      anchor.href = newURL;

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(error + e.message, { variant: "error" });
    }
    // dispatch(statusSliceActions.finishSubmit());
  };
};
