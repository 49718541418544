import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import TimePicker from "../../../../../../UI2/TimePicker";
import SlimAlert from "../../../../../../UI2/SlimAlert";
import { useEffect } from "react";
import { UISliceActions as UIactions } from "../../../../../../../store/slices/UI2";

const UITextDatesError = {
  "es-CL": {
    error1: "Las fechas no pueden ser iguales",
    error2:
      "La hora de inicio para la recepción debe ser anterior a la hora de termino",
  },
  "en-US": {
    error1: "Dates cannot be equal",
    error2: "The start time for the reception must be before the end time",
  },
};

const UIText = {
    'es-CL': {subtitle3: "Horario de recepción (Lunes a Viernes)"},
    'en-US': {subtitle3: 'Delivery window (Lunes a Viernes)'}
}

export const deliveryWindowFromUI = "deliveryWindowFrom";
export const deliveryWindowToUI = "deliveryWindowTo";

const DeliveryWindow = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { value: from, error: e1 } = UI[deliveryWindowFromUI] ?? {};
  const { value: to, error: e2 } = UI[deliveryWindowToUI] ?? {};
  const { error1, error2 } = UITextDatesError[settings.locale];
  const { subtitle3 } = UIText[settings.locale];

  let errors = [e1, e2];
  let forceError  = false
  if (from === to && from >= 0 && to >=0 ) {
    errors.push(error1);
    forceError = true
  } else if (from > to) {
    errors.push(error2);
    forceError = true
  } 

  useEffect(() => {
    if (typeof from === 'undefined') {
        dispatch(UIactions.setField({ id: deliveryWindowFromUI, value: 8*60 }));
    }
    if (typeof to === 'undefined') {
        dispatch(UIactions.setField({ id: deliveryWindowToUI, value: 19*60+30 }));
    }
  }, [dispatch,from, to]);

  return (
    <Grid
      container
      item
      {...{ rowGap: 2, columnSpacing: 2, xl: 12, lg: 12, md: 12, sm: 12, xs: 12, paddingTop:2 }}
    >
      <Grid container item xs={12}>
        <Typography fontWeight="bold">{subtitle3}</Typography>
      </Grid>
      <TimePicker
        {...{
          id: deliveryWindowFromUI,
          forceError,
          min: "08:00",
          max: "18:30",
          UIText: { "es-CL": { label: "Desde:" }, "en-US": { label: "From:" } },
          xs: 6,
        }}
      />
      <TimePicker
        {...{
          id: deliveryWindowToUI,
          forceError,
          min: "09:00",
          max: "19:30",
          UIText: { "es-CL": { label: "Hasta:" }, "en-US": { label: "To:" } },
          xs: 6,
        }}
      />
      <SlimAlert {...{ errors, xs: 12 }} />
    </Grid>
  );
};

export default DeliveryWindow;
