import { Dialog, Grid } from "@mui/material";
import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import getDeviceConfig, {
  configDataId,
  getConfigId,
} from "../../store/actions2/Devices/getConfig";
import {
  UISliceActions as UIactions,
  UISliceActions,
} from "../../store/slices/UI2";
import { warehouseSliceActions as Warehouse } from "../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { devicePickerId } from "../UI2/Specialized/DevicePicker";
import SelectDisplay from "./Components/SelectDisplay/SelectDisplay";
import TopBar from "./Components/TopBar/TopBar";
import {
  rootsDataId,
  tunnelTokenId,
} from "../../store/actions2/RemoteSelection/getMachineStatus";
import { newTreesSliceActions } from "../../store/slices/trees2";
import Blocked from "./Components/Blocked/Blocked";
import Close from "./Components/Close";
import { passFieldId } from "./Components/Blocked/PassField";
import Submit from "./Components/Submit";
import { postGreylistsId } from "../../store/actions2/RemoteSelection/postGreylists";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";

const UIText = {
  "es-CL": { header: "Selección remota" },
  "en-US": { header: "Remote selection" },
};

export const selectionBlockUI = "selectionBlock";

const RemoteSelection = () => {
  const { auth, dispatch, navigate, UI, warehouse, settings } =
    useUIBoilerplate();
  const { payload } = UI.modal;
  const { user } = payload ?? {};
  const devicePicker = UI[devicePickerId] ?? { mid: "" };
  const config = warehouse[getConfigId] ?? { config_hash: "", cpassword: "" };
  const { mid } = devicePicker;
  const { header } = UIText[settings.locale];

  if (!auth.admin) {
    navigate("/dashboard/files?user=" + encodeURIComponent(auth.user), {
      replace: true,
    });
  }

  useEffect(() => {
    const controller = new AbortController();
    if (mid) {
      dispatch(
        getDeviceConfig({
          ...auth,
          controller,
          payload: { user, machine: mid, token: auth.cloner_key },
        })
      );
    }
    return () => {
      controller.abort();
    };
  }, [dispatch, auth, user, mid]);

  useEffect(() => {
    if (mid && config.config_hash) {
      const value = !!config.cpassword;
      dispatch(UIactions.setValue({ id: selectionBlockUI, value }));
    }
  }, [dispatch, mid, config.config_hash, config.cpassword]);

  useEffect(() => {
    return () => {
      dispatch(UISliceActions.clear(selectionBlockUI));
      dispatch(UISliceActions.clear(tunnelTokenId));
      dispatch(UISliceActions.clear(passFieldId));
      dispatch(newTreesSliceActions.clearForest());
      dispatch(Warehouse.unload(configDataId));
      dispatch(Warehouse.unload(rootsDataId));
      dispatch(workers.clear(getConfigId));
      dispatch(workers.clear(tunnelTokenId));
      dispatch(workers.clear(postGreylistsId));
      dispatch(workers.clearGroup("root"));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UISliceActions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container {...{ paddingTop: 0.5, sx: { width: "100%" } }}>
          <TopBar {...{ user }} />
          <Grid item xs={12}>
            <Blocked />
            <SelectDisplay {...{ user }} />
          </Grid>
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Submit />
      </BasicDialogActions>
    </Dialog>
  );
};

export default RemoteSelection;
