import {
  Alert,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { RecoverySteps } from "../Steps";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import NewRecovery from "./Components/NewRecovery";
import Return from "./Components/Return";
import { useEffect } from "react";
import { UISliceActions } from "../../../../store/slices/UI2";
import { recoveriesSliceActions } from "../../../../store/slices/recoveries";
import {
  adminNameUI,
  adminUserUI,
  organizationUI,
} from "../RecoveryStep3/Components/DeliverySumary";

const UIText = {
  "es-CL": {
    pt1: "Su solicitud de recuperación se ha enviado al equipo de soporte de",
    pt2: ", por lo que pronto recibirá un correo de confirmación indicando el número de ticket asociado a la misma.",
    pt3: "Las recuperaciónes de información tienen nuestra",
    pt4: "maxima prioridad",
    pt5: "por lo que ya nos encontramos trabajando para tenerla a su disposición a la brevedad posible.",
    pt6: "Esperamos poder responder a su requerimiento con la mayor rapidez.",
  },
  "en-US": {
    pt1: "Your recovery request has been sent to the",
    pt2: "support team, so you will soon receive a confirmation email indicating the ticket number associated with it.",
    pt3: "Information recoveries have our",
    pt4: "highest priority",
    pt5: ", so we are already working to have it available to you as soon as possible.",
    pt6: "We hope to be able to respond to your request as quickly as possible.",
  },
};

const Finished = () => {
  const { dispatch, settings } = useUIBoilerplate();
  const { pt1, pt2, pt3, pt4, pt5, pt6 } = UIText[settings.locale];
  //   const { recoveries } = requestData;

  const steps = RecoverySteps[settings.locale];
  //   const header = steps[2];
  useEffect(() => {
    return () => {
      dispatch(recoveriesSliceActions.reset());
      dispatch(UISliceActions.clear("selected"));
      dispatch(UISliceActions.clear(adminNameUI));
      dispatch(UISliceActions.clear(adminUserUI));
      dispatch(UISliceActions.clear(organizationUI));
    };
  }, [dispatch]);

  return (
    <Grid
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: "92vh",
          //   overflow: "scroll",
        },
        alignContent: "start",
      }}
    >
      <Grid
        container
        item
        {...{
          alignItems: "center",
          sx: { height: "12vh" },
        }}
      >
        <Stepper activeStep={99} alternativeLabel sx={{ width: "100%" }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignContent="center"
      >
        <Grid
          container
          item
          {...{
            xs: 12,
            alignItems: "center",
            justifyContent: "center",
            sx: { height: "80vh" },
          }}
        >
          <Grid container item xs={12} rowGap={2}>
            <Grid container item {...{ justifyContent: "center", xs: 12 }}>
              <Grid container item {...{ lg: 6, md: 8, sm: 9, xs: 12 }}>
                <Alert severity="info" sx={{ border: "1px solid lightblue" }}>
                  <Grid container item rowGap={2}>
                    <Typography textAlign="justify">
                      {pt1} <b>Cloner</b>
                      {pt2}
                    </Typography>
                    <Typography textAlign="justify">
                      {pt3} <b>{pt4}</b> {pt5}
                    </Typography>
                    <Typography textAlign="justify">{pt6}</Typography>
                  </Grid>
                </Alert>
              </Grid>
            </Grid>
            <Grid container item {...{ justifyContent: "center", xs: 12 }}>
              <Grid
                container
                item
                {...{
                  lg: 6,
                  md: 8,
                  sm: 9,
                  xs: 12,
                  justifyContent: "space-between",
                }}
              >
                <Return />
                <NewRecovery />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Finished;
