import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import Cancel from "./Cancel";
import Clear from "./Clear";
import Create from "./Create";
import Save from "./Save";

const Buttons = ({ isNew, users }) => {
  return (
    <BasicDialogActions>
      {isNew || users.length > 1 ? <Clear /> : null}
      <Cancel />
      {isNew ? <Create /> : <Save {...{ users }} />}
    </BasicDialogActions>
  );
};

export default Buttons;
