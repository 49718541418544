import { Input, Tooltip } from "@mui/material";
import { useWorker } from "../../../../../hooks2/useWorker";
import { suggestMailId } from "../../../../../store/actions2/Windows365/suggestW365Mails";
import { validateMailId } from "../../../../../store/actions2/Windows365/validateW365Mails";
import TextField from "../../../../UI2/SimpleTextField";
import VisibilityInsert from "../../../../UI2/TextFieldInserts/VisibilityInsert";
import { Error, Warning } from "@mui/icons-material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText365Mail = {
  universal: {},
};

export const mailLocksId = "365maillocks";

const W365mail = ({ login, fontSize }) => {
  const { UI } = useUIBoilerplate();
  const { working: w1 } = useWorker(suggestMailId);
  const { working: w2 } = useWorker(validateMailId);
  const mailLocks = UI[mailLocksId] ?? []
  let id = "365mail-" + login;
  let field = UI[id] ?? { value: "" };
  let fieldErr = field.error;

  console.log("mailLocks", mailLocks);

  return (
    <TextField
      {...{
        id: id,
        disabled: w1 || w2,
        UIText: UIText365Mail,
        fontSize,
        xs: 12,
        InputProps: {
          endAdornment: fieldErr ? (
            <Tooltip title={fieldErr}>
              <Error sx={{marginRight:-1, color: "red"}}/>
            </Tooltip>
          ) : null,
        },
      }}
    />
  );
};

export default W365mail;
