import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": {
    success: "Enlace protegido exitosamente",
    removed: "Protección del enlace removida exitosamente",
    error1: "Fallo al intentar quitar la contraseña del enlace",
    error2: "Fallo al intentar proteger el enlace",
  },
  "en-US": {
    success: "Download link protected successfully",
    removed: "Protection removed from download link",
    error1: "Failed to remove password from download link",
    error2: "Failed to protect download link",
  },
};

export const updateSharedLinkId = "updateSharedLink";

const updateSharedLink = (data) => {
  const { server, cloner_key, payload, locale } = data;
  const { success, removed, error1, error2 } = UIText[locale];
  return async (dispatch) => {
    dispatch(workers.start(updateSharedLinkId));
    try {
      const bodyData = JSON.stringify(payload);
      const url = server + "api/share";
      await axios.put(url, bodyData, { headers: { cloner_key } });

      if (!!payload.password) {
        enqueueSnackbar(success,{variant:'success'})
      } else {
        enqueueSnackbar(removed,{variant:'info'})
      }
      dispatch(workers.finish({ id: updateSharedLinkId }));
    } catch (e) {
      if (!!payload.password) {
        enqueueSnackbar(error2,{variant:'error'})
      } else {
        enqueueSnackbar(error1,{variant:'error'})
      }
      dispatch(workers.finish({ id: updateSharedLinkId, error:e.message }));
    }
  };
};

export default updateSharedLink;
