import {
  Button as MUIButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import filterObjKeys from "../../utility/filterObjectKeys";
import AutoHelp from "./Wrappers/AutoHelp";
import AutoGrid from "./Wrappers/AutoGrid";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";

// const UIText = {
//     'es-CL':{
//         label:'',
//         tooltip:'',
//     }
// }

// Button
const Button = (props) => {
  const {
    UIText,
    navTo,
    navReplace,
    working,
    labelSx = { fontSize: "0.8rem" },
    onClick,
    disabled,
    xl,
    lg,
    md,
    sm,
    xs,
    progressSize = 14,
    variant = "contained",
    size = "small",
    lang,
    fullWidth,
  } = props;
  const filteredProps = filterObjKeys(
    props,
    "UIText",
    "navTo",
    "navReplace",
    "working",
    "labelSx",
    "progressSize",
    "lang",
    "xs"
  );
  const { navigate, settings } = useUIBoilerplate();
  const locale = lang ?? settings.locale;

  const X = UIText[locale] ?? UIText.universal;
  const { label, tooltip, fixedTooltip } = X;

  let progress = <CircularProgress size={progressSize} />;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
    if (navTo) {
      navigate(navTo, {
        replace: navReplace,
      });
    }
  };

  return (
    <AutoGrid {...{ xl, lg, md, sm, xs }}>
      <AutoHelp {...{ tooltip, fixedTooltip, disabled: disabled || working }}>
        <MUIButton
          {...filteredProps}
          {...{
            fullWidth:
              xl || lg || md || sm || xs || fullWidth ? true : undefined,
            variant,
            size,
            startIcon: working ? progress : filteredProps.startIcon,
            onClick: handleClick,
            disabled: disabled || working,
          }}
        >
          <Typography noWrap sx={labelSx}>
            {label}
          </Typography>
        </MUIButton>
      </AutoHelp>
    </AutoGrid>
  );
};

export default Button;
