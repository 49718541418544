import { Grid } from "@mui/material";
import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { workersSliceActions } from "../../store/slices/workers";
import BackBtn from "./Components/Buttons/BackBtn";
import ResetBtn from "./Components/Buttons/ResetBtn";
import Header from "./Components/Header";
import Done from "./Components/Info/Done";
import Opener from "./Components/Info/Opener";
import User from "./Components/User";
import { useWorker } from "../../hooks2/useWorker";
import { passRecoveryId } from "../../store/actions2/Login/resetPassword";
import Locale from "./Components/Buttons/Locale";
import Logo from "./Components/Logo2";

const PassRecovery = () => {
  const { dispatch } = useUIBoilerplate();
  const { done, error } = useWorker(passRecoveryId);
  const height = window.innerHeight;

  useEffect(() => {
    return () => {
      dispatch(workersSliceActions.clear(passRecoveryId));
    };
  }, [dispatch]);

  return (
    <Grid
      container
      sx={{ width: "100vw", height }}
      alignContent="space-between"
    >
      <Locale />
      <Grid
        container
        item
        {...{
          justifyContent: "center",
          alignItems: "center",
          spacing: 2,
          xs: 12,
        }}
      >
        <Logo />
        <Header />
        {done && !error ? <Done /> : <Opener />}
        <User />
        <ResetBtn />
        <BackBtn />
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} />
    </Grid>
  );
};

export default PassRecovery;
