import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";
import getStatus from "./utils";

const Status = ({ status, settings }) => {
  const { icon, fixedTooltip } = getStatus({status, locale:settings.locale});

  return <AutoHelp {...{ fixedTooltip }}>{icon}</AutoHelp>;
};

export default Status;
