import { default as CustomUserPicker } from "../../../../UI2/Specialized/UserPicker";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { workersSliceActions as workers } from "../../../../../store/slices/workers";
import {
  devicesDataId,
  getDevicesId,
} from "../../../../../store/actions2/Devices/getDevices2";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import { warehouseSliceActions as warehouse } from "../../../../../store/slices/warehouse";
import { devicePickerId } from "../../../../UI2/Specialized/DevicePicker";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UserPicker = () => {
  const { dispatch } = useUIBoilerplate();

  const onChange = () => {
    dispatch(UIactions.clear(devicePickerId));
    dispatch(UIactions.clear(recoveryOptionUI));
    dispatch(UIactions.clear(recoveryDateUI));
    dispatch(warehouse.unload(devicesDataId));
    dispatch(workers.clear(getDevicesId));
  };

  return <CustomUserPicker autoload {...{ xs: 12, onChange }} />;
};

export default UserPicker;
