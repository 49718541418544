export const extensions = [
    {name:"webm",type:"video",tooltip:"video file format for web video using HTML5"},
    {name:"mpg",type:"video",tooltip:"An MPG file is a common video file that uses a digital video format standardized by the Moving Picture Experts Group (MPEG)"},
    {name:"mp2",type:"video",tooltip:"MPEG Layer 2"},
    {name:"mpeg",type:"video",tooltip:"MPEG file is a video file saved in a digital video format standardized by the Moving Picture Experts Group (MPEG)."},
    {name:"mpe",type:"video",tooltip:"An MPE file is a video file encoded and saved in the MPEG file format."},
    {name:"mpv",type:"video",tooltip:"An MPV file is an MPEG elementary stream video file that consists of raw, uncompressed video data."},
    {name:"ogg",type:"video",tooltip:"A free, open source container format supporting a variety of formats, the most popular of which is the audio format Vorbis."},
    {name:"oga",type:"video",tooltip:"A free, open source container format supporting a variety of formats, the most popular of which is the audio format Vorbis."},
    {name:"mogg",type:"video",tooltip:"A free, open source container format supporting a variety of formats, the most popular of which is the audio format Vorbis."},
    {name:"mp4",type:"video",tooltip:"An MP4 is a common “container format” for video files that allows you to store a lot of video and audio information in a smaller file size"},
    {name:"m4p",type:"video",tooltip:"The M4P stands for MPEG 4 Protected (audio)."},
    {name:"m4v",type:"video",tooltip:"The M4V file format is a video container format developed by Apple."},
    {name:"avi",type:"video",tooltip:"An AVI file is a video file saved in the Audio Video Interleave (AVI) multimedia container format."},
    {name:"wmv",type:"video",tooltip:"Windows Media Video (WMV) is a series of video codecs and their corresponding video coding formats developed by Microsoft."},
    {name:"mov",type:"video",tooltip:"It’s an MPEG 4 video container file that is primarily used with Apple’s QuickTime program."},
    {name:"qt",type:"video",tooltip:"A QT file is a movie file saved in the QuickTime File Format (QTFF)"},
    {name:"flv",type:"video",tooltip:"FLV (Flash Video) is a container file format with the . flv extension."},
    {name:"swf",type:"video",tooltip:"An SWF file is an animation that may contain text, vector and raster graphics, and interactive content written in ActionScript."},
    {name:"avchd",type:"video",tooltip:"AVCHD is a recording format developed for recording high-definition video to media such as recordable DVD media, hard disk drives, and memory cards."},

    {name:"pcm",type:"audio",tooltip:"Pulse-code modulation (PCM) is a method used to digitally represent sampled analog signals. It is the standard form of digital audio in computers, compact discs, digital telephony and other digital audio applications"},
    {name:"aiff",type:"audio",tooltip:"An audio coding format developed by Apple Inc. for lossless data compression of digital music."},
    {name:"mp3",type:"audio",tooltip:"MPEG Layer III Audio. It is the most common sound file format used today."},
    {name:"acc",type:"audio",tooltip:"The Advanced Audio Coding format is based on the MPEG-2 and MPEG-4 standards. AAC files are usually ADTS or ADIF containers."},
    {name:"wma",type:"audio",tooltip:"Windows Media Audio (WMA) is a series of audio codecs and their corresponding audio coding formats developed by Microsoft. It is a proprietary technology that forms part of the Windows Media framework."},
    {name:"flac",type:"audio",tooltip:"FLAC (Free Lossless Audio Codec) is an audio coding format for lossless compression of digital audio, developed by the Xiph.Org Foundation"},
    {name:"alac",type:"audio",tooltip:"An audio coding format developed by Apple Inc. for lossless data compression of digital music."},
    {name:"wav",type:"audio",tooltip:"Standard audio file container format used mainly in Windows PCs. Commonly used for storing uncompressed (PCM), CD-quality sound files, which means that they can be large in size—around 10 MB per minute. Wave files can also contain data encoded with a variety of (lossy) codecs to reduce the file size (for example the GSM or MP3 formats). Wav files use a RIFF structure."},
    {name:"aa3",type:"audio",tooltip:"Audio format used by Sony electronic devices, such as the PlayStation Portable (PSP) and Sony digital audio players; ATRAC stands for 'Adaptive Transform Acoustic Coding' and refers to multiple compression algorithms developed by Sony"},
    {name:"oma",type:"audio",tooltip:"Audio format used by Sony electronic devices, such as the PlayStation Portable (PSP) and Sony digital audio players; ATRAC stands for 'Adaptive Transform Acoustic Coding' and refers to multiple compression algorithms developed by Sony"},
    {name:"shn",type:"audio",tooltip:"Shorten (SHN) is a file format used for compressing audio data. It is a form of data compression of files and is used to losslessly compress CD-quality audio files (44.1 kHz 16-bit stereo PCM)"},
    {name:"aa",type:"audio",tooltip:"A low-bitrate audiobook container format with DRM, containing audio encoded as either MP3 or the ACELP speech codec."},
    {name:"aax",type:"audio",tooltip:"An Audiobook format, which is a variable-bitrate (allowing high quality) M4B file encrypted with DRM. MPB contains AAC or ALAC encoded audio in an MPEG-4 container."},
    {name:"au",type:"audio",tooltip:"The standard audio file format used by Sun, Unix and Java. The audio in au files can be PCM or compressed with the μ-law, a-law or G729 codecs."},
    {name:"m4a",type:"audio",tooltip:"An audio-only MPEG-4 file, used by Apple for unprotected music downloaded from their iTunes Music Store. Audio within the m4a file is typically encoded with AAC, although lossless ALAC may also be used."},

    {name:"fb2",type:"document",tooltip:"FictionBook 2.0 eBook files that contains the structure of the eBook."},
    {name:"docx",type:"document",tooltip:"DOCX is a well-known format for Microsoft Word documents."},
    {name:"doc",type:"document",tooltip:"DOC file extension refers to a word processing document format."},
    {name:"odt",type:"document",tooltip:"ODT files are type of documents created with word processing applications that are based on OpenDocument Text File format."},
    {name:"sxw",type:"document",tooltip:"Word processing document originally created by StarOffice Writer"},
    {name:"pdf",type:"document",tooltip:"PDF is an abbreviation that stands for Portable Document Format."},
    {name:"rtf",type:"document",tooltip:"RTF stands for Rich Text Format and is a universal document file format and is easily read by many of the word-processing packages."},
    {name:"ps",type:"document",tooltip:"PS stands for PostScript, a type of vector graphic file."},
    {name:"asc",type:"document",tooltip:"Asc files is for encryption and securecommunication."},
    {name:"epub",type:"document",tooltip:"EPUB is an e-book file format"},
    {name:"gdoc",type:"document",tooltip:"A GDOC file is a shortcut used to open a Google Docs document stored within Google Drive."},
    {name:"mobi",type:"document",tooltip:"A MOBI file is specifically designed for mobile or eReader devices"},
    {name:"tex",type:"document",tooltip:"Digital text which has styling information beyond the minimum of semantic elements"},
    {name:"txt",type:"document",tooltip:"Standard text document that contains plain text."},
    {name:"xml",type:"document",tooltip:"Extensible Markup Language (XML) is a simple text-based format for representing structured information"},
    {name:"html",type:"document",tooltip:"HTML (Hyper Text Markup Language) is the extension for web pages created for display in browsers"},

    {name:"gsheet",type:"document",tooltip:"JSON file that contains a spreadsheet's URL and document ID, as well as the name of the Gmail account used to create the spreadsheet"},
    {name:"xls",type:"document",tooltip:"The XLS file extension is used for files saved as Microsoft Excel worksheets"},
    {name:"xlsx",type:"document",tooltip:"XLSX is well-known format for Microsoft Excel documents that was introduced by Microsoft with the release of Microsoft Office 2007"},

    {name:"ttf",type:"other",tooltip:"Font files based on the TrueType specifications font technology"},
    {name:"otf",type:"other",tooltip:"OTF font format is more scalable and extends the existing features of TTF formats for digital typography."},
    {name:"woff",type:"other",tooltip:"WOFF (the Web Open Font Format) is a web font format developed by Mozilla"},

    {name:"bmp",type:"graphic",tooltip:"A BMP file contains uncompressed data, making it ideal for storing and displaying high-quality digital images"},
    {name:"gif",type:"graphic",tooltip:"GIF stands for Graphics Interchange Format"},
    {name:"ico",type:"graphic",tooltip:"ICO file format is an image file format for computer icons in Microsoft Windows"},
    {name:"jng",type:"graphic",tooltip:"JPEG Network Graphics is a JPEG-based graphics file format which is closely related to PNG"},
    {name:"jpeg",type:"graphic",tooltip:"JPEG stands for “Joint Photographic Experts Group”. It's a standard image format for containing lossy and compressed image data."},
    {name:"png",type:"graphic",tooltip:"A PNG (Portable Network Graphics) file is a raster image file format that uses lossless compression."},
    {name:"psd",type:"graphic",tooltip:"PSD files are the native file format of Adobe Photoshop"},
    {name:"pdd",type:"graphic",tooltip:"Adobe PhotoDeluxe image file that was created with PhotoDeluxe."},
    {name:"tiff",type:"graphic",tooltip:"A TIFF, which stands for Tag Image File Format, is a computer file used to store raster graphics and image information."},
    {name:"xcf",type:"graphic",tooltip:"XCF, short for eXperimental Computing Facility, is the native image format of the GIMP image-editing program."},
    {name:"ai",type:"graphic",tooltip:"AI, which stands for Adobe Illustrator, is the proprietary Adobe file type for vector images."},
    {name:"cdr",type:"graphic",tooltip:"A CDR file is a vector graphics file that stores a digital image encoded and compressed to be opened and manipulated by a vector editing software program"},
    {name:"eps",type:"graphic",tooltip:"EPS is a vector file format often required for professional and high-quality image printing."},
    {name:"svg",type:"graphic",tooltip:"Scalable Vector Graphics (SVG) is a web-friendly vector file format."},
    {name:"wmf",type:"graphic",tooltip:"WMF is short for Windows Metafile."},

    {name:"ac",type:"graphic",tooltip:"AC file contains information about the appearance of a a 3D model in AC3D format."},
    {name:"blend",type:"graphic",tooltip:"BLEND file is a 3D image or animation project created with Blender, an open-source 3D modeling program"},
    {name:"max",type:"graphic",tooltip:"A MAX file is a three-dimensional scene created by Autodesk 3ds Max, a 3D modeling, animation, and rendering application"},
    {name:"lwo",type:"graphic",tooltip:"Three-dimensional object created by LightWave 3D, a program used for 3D modeling, animation, and rendering"},
    {name:"lws",type:"graphic",tooltip:"Three-dimensional scene created with LightWave 3D, a 3D modeling, animation, and rendering program"},
    {name:"pov",type:"graphic",tooltip:"Graphic format used by Prolab ImageFolio"},
    {name:"u3d",type:"graphic",tooltip:"U3D (Universal 3D) is a compressed file format and data structure for 3D computer graphics"},
    {name:"vue",type:"graphic",tooltip:"A VUE file contains a sequence of frames to render."},

    {name:"apk",type:"executable",tooltip:"He Android Package with the file extension apk is the file format used by the Android operating system, and a number of other Android-based operating systems"},
    {name:"app",type:"executable",tooltip:"Executable application program files run on the Mac OS X operating system."},
    {name:"bac",type:"executable",tooltip:"AC file contains the backup data of more than one file."},
    {name:"dll",type:"executable",tooltip:"Binary file type and supported by all versions of Microsoft Windows."},
    {name:"exe",type:"executable",tooltip:"Common filename extension denoting an executable file (the main execution point of a computer program) for Microsoft Windows."},
    {name:"jar",type:"executable",tooltip:"JAR (Java archive) file is a package file format typically used to aggregate many Java class files and associated metadata and resources"},
    {name:"war",type:"executable",tooltip:"WAR file is a file used to distribute a collection of JAR-files, JavaServer Pages, Java Servlets, Java classes"},


    {name:"css",type:"other",tooltip:"Cascading Style Sheets (CSS) is used to format the layout of a webpage."},
    {name:"xslt",type:"other",tooltip:"XSLT (Extensible Stylesheet Language Transformations) is a language originally designed for transforming XML documents into other XML documents"},
    {name:"xsl",type:"other",tooltip:"XLS file extension is used for files saved as Microsoft Excel worksheets."},
    {name:"ost",type:"other",tooltip:"OST file (.ost) is an offline folder file in Microsoft Outlook."},
    {name:"pst",type:"other",tooltip:"Personal Storage Table (. pst) is an open proprietary file format used to store copies of messages, calendar events, and other items within Microsoft software such as Microsoft Exchange Client, Windows Messaging, and Microsoft Outlook."},
    {name:"gslide",type:"other",tooltip:"GSLIDES file is a JSON file that contains a presentation's URL and document ID, as well as the name of the Gmail account used to create the presentation"},
    {name:"ppt",type:"other",tooltip:"PPT extension represents PowerPoint file that consists of a collection of slides for displaying as SlideShow"},
    {name:"pptx",type:"other",tooltip:"PPTX file extension is a Microsoft PowerPoint presentation that stores slide shows"},
    {name:"enl",type:"other",tooltip:"ENL is the default format for saving files using EndNote"},
    {name:"vdi",type:"other",tooltip:"A file with . vdi extension is a virtual disk image; specific to the Oracle's open source desktop virtualization program called VirtualBox."},
    {name:"sav",type:"other",tooltip:"SAV file extension is more commonly known as a generic extension that is used to save files and data"},
    {name:"csv",type:"other",tooltip:"CSV (comma-separated values) file is a text file that has a specific format which allows data to be saved in a table structured format."},
    {name:"json",type:"other",tooltip:"avaScript Object Notation (JSON) is a standard text-based format for representing structured data based on JavaScript object syntax"},
    {name:"yaml",type:"other",tooltip:"YAML is a digestible data serialization language often used to create configuration files with any programming language"},
    {name:"dat",type:"other",tooltip:"DAT file is a data file that contains specific information about the program used to create it."},
    {name:"cnf",type:"other",tooltip:"CNF file format is an ASCII file format"},
    {name:"cfg",type:"other",tooltip:"A file with a .cfg extension is a type of “settings” file"},
    {name:"conf",type:"other",tooltip:"CONF file is a configuration or 'config' file used on Unix and Linux based systems."},
    {name:"log",type:"other",tooltip:"A log format is a structured format that allows logs to be machine-readable and easily parsed"},
    {name:"7z",type:"other",tooltip:"7z files are simply compressed archive files"},
    {name:"zip",type:"other",tooltip:"ZIP is an archive file format that supports lossless data compression"},
    {name:"tar",type:"other",tooltip:"A tar (tape archive) file format is an archive created by tar, a UNIX-based utility used to package files together for backup or distribution purposes"},
    {name:"deb",type:"other",tooltip:"deb is the format, as well as extension of the software package format for the Debian Linux distribution and its derivatives"},
    {name:"gz",type:"other",tooltip:"GZ file is a compressed archive that is created using the standard gzip (GNU zip) compression algorithm"},
]