import { Clear } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../store/slices/UI2";
import IconButton from "../IconButton";
import UIError from "../Utilities/UIError";

const UIText = {
    'es-CL':{
        tooltip:'Despejar'
    },
    'en-US':{
        tooltip:'Clear'
    }
}

const ClearInsert = (props)=>{
  const { id,disabled, position = "end",fontSize = '1rem' } = props;
  const {dispatch, settings } = useUIBoilerplate();
  const locale = settings["locale"];

  if (UIError(id, locale, UIText)) {
    return;
  }

    
  const handleClick = ()=>{
    dispatch(UISliceActions.clear(id))
  }

  return (
    <InputAdornment {...{ position }}>
        <IconButton 
            {...{
                icon:<Clear sx={{fontSize}}/>,
                disabled,
                UIText,
                onClick:handleClick,
            }}
        />
    </InputAdornment>
  );
}

export default ClearInsert