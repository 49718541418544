import { Grid, Typography } from "@mui/material";

const UIText = {
  "es-CL": { label: "Seguridad" },
  "en-US": { label: "Security" },
};

const Security = (props) => {
  const { locale } = props;
  const { label } = UIText[locale];

  return (
    <Grid container item xs={12}>
      <Typography fontWeight="bold">{label}</Typography>
    </Grid>
  );
};

export default Security;
