import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import addRecovery from "../../../../../store/actions2/Recoveries/addRecovery";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Iniciar nueva solicitud" },
  "en-US": { label: "Start new request"},
};

const Continue = ({ newRecovery }) => {
  const {dispatch,navigate} = useUIBoilerplate();

  const onClick = () => {
    dispatch(recoveries.reset());
    dispatch(addRecovery(newRecovery));
    dispatch(UISliceActions.closeModal());
    dispatch(recoveries.dontAskAgain());
    navigate("/dashboard/recovery/step2");
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Continue;
