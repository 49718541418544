import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";

export const UIText = {
  "es-CL": {
    success: "Se envió un codigo de verificación al siguiente correo: ",
    error: "Error al enviar el correo con el codigo de verificación: ",
  },
  "en-US": {
    success: "A verification code was sent to the following email: ",
    error: "Error sending verification code to the email: ",
  },
};

export const sendCodeId = "sendCode";

export const sendVerificationCode = (data) => {
  const { W365server, cloner_key, mail, locale } = data;
  const id = sendCodeId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    const bodyData = JSON.stringify({ mail });
    const { success, error } = UIText[locale];
    try {
      const url = `${W365server}mailapi/mail`;
      await axios.post(url, bodyData, { headers: { cloner_key } });
      // console.log("mailing", mailing);
      const message = success + mail;
      enqueueSnackbar(message, { variant: "success" });
      // dispatch(UI.setValue({ id: "o365codeshow", value: true }));
      dispatch(worker.finish({ id }));
    } catch (e) {
      const message = `${error} (${mail}): ${e.message}`;
      enqueueSnackbar(message, { variant: "error" });
      dispatch(worker.finish({ id, error: message }));
    }
  };
};
