import { Grid } from "@mui/material";
import Back from "./Components/Back";
import Save from "./Components/Save";


const Bottom = () => {
  return (
    <Grid container item xs={12} padding={2} justifyContent="end" gap={2}>
      <Back />
      <Save />
    </Grid>
  );
};

export default Bottom;
