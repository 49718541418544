import { Fragment, useEffect, useMemo } from "react";
import useTrees from "../../../../../hooks2/useNewTrees";
import { useWorker } from "../../../../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../../../../store/slices/workers";
import { Collapse, Grid, Typography } from "@mui/material";
import { generateHexString } from "../../../../../utility/generateString";
import SelectButton from "./Select";
import ExpandButton from "./Expand";
import { useDispatch } from "react-redux";
import Loading from "./Loading";
import { TypeIcon } from "./utils";

const SelectionNode = (props) => {
  const {
    backup,
    idx,
    isDir,
    level,
    loaded,
    name,
    status,
    expanded,
    root,
    repo,
    path,
    childrenIdx: children = [],
  } = props;
  const rnd = useMemo(() => generateHexString(20), []);
  const id = level ? rnd : 'root'+root;
  const dispatch = useDispatch();
  const { working } = useWorker(id);
  const trees = useTrees();
  const tree = trees[root]??[]
  const fullPath = level > 1 ? path + "/" + name : path + name;

  useEffect(() => {
    return () => {
      dispatch(workers.clear(id));
    };
  }, [dispatch, id]);

  // changed
  const typeIcon = TypeIcon(
    idx,
    isDir,
    isDir && status === 1,
    working,
    null /*'isBad?'*/
  );

  const directories = tree
    .filter((x) => x.isDir && x.parentIdx === idx)
    .map((data) => (
      <SelectionNode
        {...data}
        {...{ root, repo, path: fullPath, level: level + 1, key: data.idx }}
      />
    ));
  const files = tree
    .filter((x) => !x.isDir && x.parentIdx === idx)
    .map((data) => (
      <SelectionNode
        {...data}
        {...{ root, repo, path: fullPath, level: level + 1, key: data.idx }}
      />
    ));

  return (
    <Fragment>
      <Grid
        item
        container
        {...{
          xs: 12,
          wrap: "nowrap",
          paddingLeft: level * 2.7,
          sx: { backgroundColor: `rgba(39, 181, 245, ${level * 0.05})` },
          alignItems: "center",
        }}
      >
        {working ? (
          <Loading />
        ) : (
          <SelectButton {...{ treeId: root, idx, working, backup }} />
        )}
        <ExpandButton
          {...{
            id,
            fullPath,
            treeId: root,
            level,
            idx,
            loaded,
            expanded,
            isDir,
            hasChildren: !!children.length,
            working,
            status,
          }}
        />
        <Grid container item xs={true} alignItems="center">
          {typeIcon}
          <Typography noWrap fontSize={14} paddingLeft={1} color={working?'grey':undefined}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={expanded} sx={{width:'100%'}} unmountOnExit>
        {directories}
        {files}
      </Collapse>
    </Fragment>
  );
};

export default SelectionNode;
