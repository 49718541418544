import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import useURLQuery from "../../hooks2/useURLQuery";
import { UISliceActions } from "../../store/slices/UI2";
import GenericConfirmation from "./GenericConfirmation/GenericConfirmation";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { deleteUsersId } from "../../store/actions2/Users/deleteUsers";

const DeleteUsers = (props) => {
  const { dispatch, navigate, settings, UI } = useUIBoilerplate();
  const { group } = useURLQuery();
  const { done, error } = useWorker(deleteUsersId);
  const { payload ={} } = UI.modal??{}
  const { users} = payload
  const locale = settings.locale;

  const UIText = {
    "es-CL": {
      header:
        users.length > 1
          ? `¿Esta seguro que desea eliminar estos ${users.length} usuarios?`
          : `¿Esta seguro que desea eliminar el usuario "${users[0]}"?`,
      message: `Importante: Ten en cuenta que esta operación es definitiva e irreversible. Al confirmar, ${
        users.length > 1
          ? `los usuarios seran eliminados`
          : `el usuario será eliminado`
      } junto con toda su información. La información eliminada podrá ser recuperada dentro de un plazo de 90 días`,
    },
    "en-US": {
      header:
        users.length > 1
          ? `Are you sure you want delete these ${users.length} users?`
          : `Are you sure you want delete the user "${users[0]}"?`,
      message: `Important: Beware that this operation is definitive and irreversible. Once confirmed, the user${
        users.length > 1 ? "s" : ""
      } will be deleted, along with all it's data. The deleted data can be recovered before 90 days have passed`,
    },
  };
  const { header, message } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UISliceActions.closeModal());
      dispatch(UISliceActions.clear("selected"));
      dispatch(workers.clear(`groupData${group}`));
    }
  }, [dispatch, navigate, done, error, group]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(deleteUsersId));
    };
  }, [dispatch]);

  const confirmation = () => {
    dispatch(
      UISliceActions.openModal2({
        type: "delete-users-confirmation",
        payload: { users },
      })
    );
  };

  return (
    <GenericConfirmation
      alternativeText
      {...{
        header,
        message,
        severity: "warning",
        confirmation,
        workerId: deleteUsersId,
      }}
    />
  );
};

export default DeleteUsers;
