const UIText = {
  "es-CL": {
    error: "El nombre del grupo no puede estar vacío",
  },
  "en-US": {
    error: "The groupname cannot be empty",
  },
};

export const notEmptyGroup = (value,locale) => {
    const {error} = UIText[locale]

  if (!value) {
    return error
  }
  return ''
};