import { useDispatch } from "react-redux";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UIactions.closeModal());
  };

  return <Button {...{ UIText, onClick, variant:'outlined' }} />;
};

export default Cancel;
