/**
 * Represents the DevicesExclusions component.
 * @component
 */
import { Card, Grid } from "@mui/material";
import Top from "./Components/Top";
import DevicesList, {
  exclusionDevicesData,
} from "./Components/DevicesList/DevicesList";
import Filters from "./Components/Filters/Filters";
import Divider from "../UI2/Divider";
import Audio from "./Components/Category/Audio";
import Video from "./Components/Category/Video";
import Document from "./Components/Category/Document";
import Image from "./Components/Category/Images";
import Executables from "./Components/Category/Executables";
import Other from "./Components/Category/Other";
import CustomExtensions from "./Components/CustomExtensions/CustomExtensions";
import Problematic, {
  problematicUI,
} from "./Components/Problematic/Problematic";
import Bottom from "./Components/Bottom/Bottom";
import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import getDeviceConfig, { configDataId } from "../../store/actions2/Devices/getConfig";
import { useSelector } from "react-redux";
import { extensions } from "./Components/Category/extensions";
import { options as paths } from "./Components/Problematic/options";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { selectedCommonUI } from "./Components/Category/Category";
import { customExtensionsUI } from "./Components/CustomExtensions/Top/NewExtension";
import { currentExclusionDevicesUI } from "./Components/DevicesList/Headers2";
import { warehouseSliceActions } from "../../store/slices/warehouse";
import { workersSliceActions } from "../../store/slices/workers";
import { updateExclusionsUI } from "../../store/actions2/Devices/updateExclusions";

export const initExclusionsUI = "initExclusions";
export const singleExclusionUI = "singleExclusion";

const DevicesExclusions = () => {
  const { auth, dispatch } = useUIBoilerplate();
  const devicesObj = useSelector((state) => state.warehouse.storage[exclusionDevicesData]);
  const allDevices = useMemo(() => {
    let devices = []
    for (const key in devicesObj) {
      devices.push(devicesObj[key])
    }
    return devices
  }, [devicesObj]);
  const currentConfig = useSelector(
    (state) => state.warehouse.storage[configDataId]
  ); // watchout this is static

  useEffect(() => {
    const controller = new AbortController();
    if (allDevices.length === 1) {
      dispatch(
        getDeviceConfig({
          ...auth,
          controller,
          payload: {
            user: allDevices[0].user,
            machine: allDevices[0].mid,
            token: auth.token,
          },
        })
      );
    }
  }, [dispatch, allDevices, auth]);

  useEffect(() => {
    if (currentConfig) {
      const exclusions = currentConfig.exclusions[0]
        .split("\n")
        .map((x) => x.slice(2));

      dispatch(UIactions.clear(selectedCommonUI));
      dispatch(UIactions.clear(customExtensionsUI));
      dispatch(UIactions.clear(problematicUI));

      for (const exc of exclusions) {
        const found = extensions.find(
          (ext) => ext.name.toLowerCase() === exc.toLowerCase()
        );
        if (found) {
          dispatch(UIactions.add({ id: selectedCommonUI, key: exc }));
          continue;
        }
        const found2 = paths.find((path) => path.value === exc);
        if (found2) {
          dispatch(UIactions.add({ id: problematicUI, key: exc }));
          continue;
        }
        dispatch(UIactions.add({ id: customExtensionsUI, key: exc }));
      }
    }
  }, [dispatch, currentConfig]);

  useEffect(() => {
    return () => {
      dispatch(warehouseSliceActions.unload(configDataId))
      dispatch(UIactions.clear(selectedCommonUI));
      dispatch(UIactions.clear(currentExclusionDevicesUI));
      dispatch(UIactions.clear(customExtensionsUI));
      dispatch(UIactions.clear(problematicUI));
      dispatch(workersSliceActions.clear(updateExclusionsUI))
    };
  }, [dispatch]);

  return (
    <Grid
      container
      item
      {...{
        xs: 12,
        justifyContent: "start",
        sx: {
          overflow: "scroll",
          height: "92vh",
          padding: "2vh",
        },
      }}
    >
      <Grid item lg={10} xs={12} alignItems="center" rowGap={2}>
        <Card>
          <Grid container {...{ sx: { width: "100%" } }}>
            <Top />
            <Divider />
            <DevicesList />
            <Grid container item xs={12} paddingLeft={2}>
              <Filters />
              <Audio />
              <Video />
              <Document />
              <Image />
              <Executables />
              <Other />
              <CustomExtensions />
              <Problematic />
              <Bottom />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DevicesExclusions;
