import { Edit } from "@mui/icons-material";
import ActionButton from "../../../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Editar" },
  "en-US": { label: "Edit" },
};

const EditBtn = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI.selected ?? [];
  // const devices = warehouse[devicesData] ?? {};

  const onClick = () => {
    dispatch(
      UISliceActions.openModal({
        type: "edit-recoveries",
        payload: { devices: selected },
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Edit />,
      }}
    />
  );
};

export default EditBtn;
