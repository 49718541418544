import useTrees from "../../../hooks2/useNewTrees";
import useURLQuery from "../../../hooks2/useURLQuery";
import BasicLayout from "../../UI2/BasicLayout/BasicLayout";
import Actions from "./Actions/Actions";
import headersData from "./Headers";
import MailRow from "./MailRow/MailRow";

const Core = (props) => {
  const { mails, allMails, done, working, error, workerId,workerId2 } = props;
  const { folderId, idx } = useURLQuery();
  const trees = useTrees() ?? {};
  const tree = trees[`mails-${folderId}`] ?? [];
  const node = tree[idx] ?? {};

  const header = node.displayName;
  const plusActions = <Actions />;

  const elements = mails.map((data, idx) => <MailRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      workerId2,
      error,
      UIText: {
        "es-CL": { message: "Error al cargar los correos: " + error },
        "en-US": { message: "Error loading emails: " + error },
      },
    },
    empty: {
      isEmpty: !mails.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay correos disponibles" },
        "en-US": { message: "There are no emails available" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{ plusActions, header, headersData, fullArray: allMails, coreData }}
    />
  );
};

export default Core;
