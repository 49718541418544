const UIArrayError = (id,locale,options)=>{
    if (!id){
        console.error('UI component lacks id!')
        return true
    }

    if (!options.length){
        console.error('UI component has no options (at least 1 is required)')
        return true
    }

    for (const opt of options){
        if (!opt.UIText[locale]){
        console.error('UI component has no locale matching selected one')
        return true
        }
    }

    return false
}

export default UIArrayError