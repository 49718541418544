import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import { currentGroupnameId } from "../../../store/actions2/GroupTraveler/loadGroupsTree";
import BasicLayout from "../../UI2/BasicLayout/BasicLayout";
import Actions from "./Actions/Actions";
import DeviceRow from "./DeviceRow/DeviceRow";
import headersData from "./Headers";

const Core = (props) => {
  const { devices, allDevices, done, working, error, workerId } = props;
  const { settings, UI } = useUIBoilerplate();
  const groupname = UI[currentGroupnameId];

  const UIText = {
    "es-CL": `Dispositivos del grupo ${groupname}`,
    "en-US": `Devices in group ${groupname}`,
  };
  const header = UIText[settings.locale];
  const plusActions = <Actions />;

  const elements = devices.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": {
          message: "Error al cargar los dispositivos: " + error,
        },
        "en-US": { message: "Error loading the devices: " + error },
      },
    },
    empty: {
      isEmpty: !devices.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay items dispositivos disponibles" },
        "en-US": { message: "There are no available devices" },
      },
    },
    done,
    elements,
  };

  return (
    <BasicLayout
      {...{ plusActions, header, headersData, fullArray: allDevices, coreData }}
    />
  );
};

export default Core;
