import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../../../../hooks2/useUIBoilerplate";
import TextField from "../../../../../../UI2/TextField";
import SlimAlert from "../../../../../../UI2/SlimAlert";
import { aditionalOptionUI, recoveryDateUI } from "./AditionalOptions";

const UITextDate = {
  "es-CL": { label: "Detalles adicionales" },
  "en-US": { label: "Additional details" },
};

const UIText = {
  "es-CL": {
    emptyDate: "Fecha vacía",
    badDate: "Fecha inválida. Debe ser anterior a la fecha actual",
  },
  "en-US": {
    emptyDate: "Empty date",
    badDate: "Invalid date. Must be before current date",
  },
};

export const aditionalInfoUI = "aditionalInfo";

const AditionalDetails = ({ id,readOnly }) => {
  const { UI, settings } = useUIBoilerplate();
  const { emptyDate, badDate } = UIText[settings.locale];
  const selected = UI[`${aditionalOptionUI}-${id}`];
  const { value: selectedDate } = UI[`${recoveryDateUI}-${id}`] ?? {};

  let dateError = "";
  if (selected === "dated") {
    if (!selectedDate) {
      dateError = emptyDate;
    } else if (new Date(selectedDate) > Date.now()) {
      dateError = badDate;
    }
  }

  return (
    <Grid container item {...{ alignItems: "center", xs: 12, rowGap: 2 }}>
      <SlimAlert {...{ errors: [dateError] }} />
      <TextField
        multiline
        {...{
          id: `${aditionalInfoUI}-${id}`,
          disabled:readOnly,
          UIText: UITextDate,
          minRows: 2,
          maxRows: 6,
          xs: 12,
        }}
      />
    </Grid>
  );
};

export default AditionalDetails;
