import { Card, Grid, Step, StepLabel, Stepper } from "@mui/material";
import useRecoveries from "../../../../hooks2/useRecoveries";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import FilterPlusActions from "../../../UI2/BasicLayout/Components/FilterPlusActions/FilterPlusActions";
import BasicFooter from "../../../UI2/BasicLayout/Components/BasicFooter";
import BasicHeader from "../../../UI2/BasicLayout/Components/Header";
import DeviceRow from "./Components/DeviceRow/DeviceRow";
import Actions from "./Components/Actions/Actions";
import BasicHeaders from "../../../UI2/BasicLayout/Components/BasicHeaders";
import headersData from "./Components/Headers";
import Core from "../../../UI2/BasicLayout/Components/Core/Core";
import Reset from "./Components/Reset";
import Next from "./Components/Next";
import { RecoverySteps } from "../Steps";
import useVH from "../../../../hooks2/useVH";

const RecoveryStep1 = (props) => {
  const { settings } = useUIBoilerplate();
  const vh = useVH(91);
  const vh2 = useVH(12);
  const vh3 = useVH(88);
  const vh4 = useVH(6);
  const working = false;
  const done = true;
  const workerId = "placeholder";
  const error = "";
  const requestData = useRecoveries() ?? {};
  const { recoveries } = requestData;

  const devices = recoveries.map((r) => {
    let full_size = 0;
    for (const repo of r.repositories) {
      full_size += repo.size;
    }

    return {
      id: r.id,
      user: r.user,
      mid: r.mid,
      device: r.device,
      full_size,
      lastBkp: r.lastBkp,
      repositories: r.repositories,
      mode: r.mode,
    };
  });

  const fullArray = recoveries.map((r) => r.id);
  const isEmpty = devices.length === 0;

  const elements = devices.map((data, idx) => (
    <DeviceRow key={idx} {...data} />
  ));
  const plusActions = <Actions />;
  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: { "es-CL": { message: "" }, "en-US": { message: "" } },
    },
    empty: {
      isEmpty,
      UIText: {
        "es-CL": { message: "No se ha agregado ninguna recuperación" },
        "en-US": { message: "No recovery has been added" },
      },
    },
    done,
    elements,
  };

  const steps = RecoverySteps[settings.locale];
  const header = steps[0];

  return (
    <Grid
      container
      {...{
        sx: {
          padding: "2vh",
          paddingTop: 0,
          paddingBottom: 0,
          height: vh,
          overflow: "hidden",
          // overflow: "scroll",
        },
        alignContent: "start",
      }}
    >
      <Grid
        container
        item
        {...{
          alignItems: "center",
          sx: { height: vh2 },
        }}
      >
        <Stepper activeStep={0} alternativeLabel sx={{ width: "100%" }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid container item>
        <Card sx={{ width: "100%" }}>
          <Grid
            container
            {...{
              sx: {
                maxHeight: vh3,
                width: "100%",
              },
            }}
          >
            <BasicHeader>{header}</BasicHeader>
            <FilterPlusActions>{plusActions}</FilterPlusActions>
            {headersData ? (
              <BasicHeaders {...headersData} {...{ fullArray }} />
            ) : null}
            <Core hasHeaders={!!headersData} {...coreData} contentSize={52} />
            <BasicFooter />
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        item
        {...{
          justifyContent: "space-between",
          alignItems: "center",
          sx: { height: vh4 },
        }}
      >
        <Reset />
        <Next />
      </Grid>
    </Grid>
  );
};

export default RecoveryStep1;
