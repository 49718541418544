import {
  default as CustomDevicePicker,
  devicePickerId,
} from "../../../../../UI2/Specialized/DevicePicker";
import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../../../hooks2/useURLQuery";
import { userPickerId } from "../../../../../UI2/Specialized/UserPicker";
import { devicesDataId } from "../../../../../../store/actions2/Devices/getAllDevices";
import { UISliceActions as UIactions } from "../../../../../../store/slices/UI2";
import { warehouseSliceActions as warehouseActions } from "../../../../../../store/slices/warehouse";
import {
  getRepoId,
  repoDataId,
} from "../../../../../../store/actions2/Devices/getRepositories";
import { workersSliceActions as workers } from "../../../../../../store/slices/workers";
import { newTreesSliceActions as newTrees } from "../../../../../../store/slices/trees2";

const DevicePicker = () => {
  const { dispatch, navigate, UI, warehouse } = useUIBoilerplate();
  const { id: user } = UI[userPickerId] ?? {};
  const { mid } = UI[devicePickerId] ?? {};
  const { user: urlUser, mid: urlMid } = useURLQuery();
  const devices = useMemo(() => {
    return warehouse[devicesDataId] ?? [];
  }, [warehouse]);

  const midClash = !!urlMid && !!mid && urlMid !== mid;
  const userSame = !!urlUser && !!user && urlUser === user;

  useEffect(() => {
    if (midClash && userSame) {
      const dvc = devices.find((x) => x.mid === urlMid);
      const value = { mid: dvc.mid, label: dvc.name };
      dispatch(UIactions.set({ id: devicePickerId, value }));
      dispatch(warehouse.unload(repoDataId));
      dispatch(workers.clear(getRepoId));
      navigate(`?user=${encodeURIComponent(urlUser)}&mid=${value.mid}`);
    }
  }, [
    dispatch,
    navigate,
    urlMid,
    urlUser,
    midClash,
    userSame,
    devices,
    warehouse,
  ]);

  const onLoaded = (devices) => {
    if (urlMid) {
      // if there is a URL machine set
      const option = devices.find((x) => x.mid === urlMid);
      const value = option ?? devices[0];
      dispatch(UIactions.setValue({ id: devicePickerId, value }));

      if (!option) {
        const url = `?user=${encodeURIComponent(
          urlUser
        )}&mid=${encodeURIComponent(value.mid)}`;
        navigate(url, { replace: true });
      }
    } else if (!urlMid) {
      // Set first entry if there is no URL machine
      const value = devices[0];
      dispatch(UIactions.setValue({ id: devicePickerId, value }));
      const url = `?user=${encodeURIComponent(
        urlUser
      )}&mid=${encodeURIComponent(value.mid)}`;
      navigate(url, { replace: true });
    }
  };

  const onChange = (value) => {
    dispatch(warehouseActions.unload(repoDataId));
    dispatch(newTrees.clearForest());
    dispatch(workers.clear(getRepoId));
    navigate(`?user=${encodeURIComponent(user)}&mid=${value.mid}`);
  };

  return (
    <CustomDevicePicker autoload {...{ onChange, onLoaded, user, xs: 4 }} />
  );
};

export default DevicePicker;
