import { Typography } from "@mui/material";
import { timeDiff } from "../../../../../utility/humanizers";

const UIText = {
  "es-CL": { text: "hace " },
  "en-US": { text: "since " },
};

const LastChanges = ({ fontSize, mid, lastChanges, settings }) => {
  const { text } = UIText[settings.locale];
  const now = Date.now();

  return (
    <Typography {...{ fontSize, sx: { cursor: "default" } }}>
      {mid && lastChanges !== -62135596800000
        ? text + timeDiff(now - lastChanges, settings.locale)
        : "--"}
    </Typography>
  );
};

export default LastChanges;
