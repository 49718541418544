import { Tooltip, Typography } from "@mui/material";

const Device = ({ fontSize, device }) => {
  return (
    <Tooltip title={device}>
      <Typography {...{ fontSize, sx: { cursor: "default" } }}>
        {device}
      </Typography>
    </Tooltip>
  );
};

export default Device;
