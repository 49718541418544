import TextField from "../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Hasta" },
  "en-US": { label: "Until" },
};

export const filterToUI = "filterTo";

const FilterTo = () => {
  return (
    <TextField
      fullWidth
      {...{
        id: filterToUI,
        UIText,
        type: "datetime-local",
        inputSx: { fontSize: 13 },
      }}
    />
  );
};

export default FilterTo;
