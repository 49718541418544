import { Grid, Typography } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import MultiSelect from "../../../UI2/MultiSelect";
import { options } from "./options";

const UIText = {
  "es-CL": { header: "Rutas problematicas" },
  "en-US": { header: "Troublesome paths" },
};

const UIText2 = {
  "es-CL": { label: "Rutas" },
  "en-US": { label: "Paths" },
};

export const problematicUI = 'problematic'

const Problematic = () => {
  const { settings } = useUIBoilerplate();
  const { header } = UIText[settings.locale];

  return (
    <Grid container item {...{ padding: 2, xs: 12, rowGap: 1 }}>
      <Typography fontSize='1.1rem'>{header}</Typography>
      <Grid container item gap={1} paddingLeft={2} paddingRight={2}>
        <MultiSelect {...{id:problematicUI, UIText: UIText2, options, xs:6 }} />
      </Grid>
    </Grid>
  );
};

export default Problematic;
