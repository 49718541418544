import Category from "./Category";

const UIText = {
  "es-CL": { header: "Otros" },
  "en-US": { header: "Other" },
};

const Other = () => {
  return <Category {...{ category: "other", UIText }} />;
};

export default Other;
