import { Dialog, Grid } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { usernameUI } from "./Components/Username";
import { useEffect } from "react";
import { workersSliceActions as workers } from "../../store/slices/workers";
import User, { userUI } from "./Components/User";
import Username from "./Components/Username";
import { pass1UI } from "./Components/Password/Password1";
import { pass2UI } from "./Components/Password/Password2";
import { permissionsUI } from "./Components/Permissions/PermissionsSelect";
import { postUserId } from "../../store/actions2/UserForm/postUser";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import { changeGroupUI } from "./Components/Group/ChangeGroup";
import { changePasswordUI } from "./Components/Password/ChangePassword";
import { changePermissionsUI } from "./Components/Permissions/ChangePermissions";
import loadGroupsTree, {
  selectedGroupId2,
  selectedGroupIdx2,
} from "../../store/actions2/GroupTraveler/loadGroupsTree";
import Buttons from "./Components/Buttons/Buttons";
import Group from "./Components/Group/Group";
import Password from "./Components/Password/Password";
import Permissions from "./Components/Permissions/Permissions";

const UIText = {
  "es-CL": {
    header1: "Crear nuevo usuario",
    header2: "Editar usuario",
    header3: "Editar usuarios",
  },
  "en-US": {
    header1: "Create new user",
    header2: "Edit user",
    header3: "Edit users",
  },
};

const UserForm = (props) => {
  const { isNew } = props;
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { group, users = [] } = payload;

  useEffect(() => {
    dispatch(
      loadGroupsTree({
        ...auth,
        startGroup: parseInt(group),
        mode: "modal",
      })
    );
  }, [dispatch, auth, group]);

  useEffect(() => {
    if (users.length === 1) {
      const userData = users[0];
      if (userData.login) {
        dispatch(UIactions.setField({ id: userUI, value: userData.login }));
        dispatch(UIactions.setField({ id: usernameUI, value: userData.name }));
        let permissions = [];
        if (userData.group === userData.level) {
          permissions.push("admin");
        }
        if (userData.has_access) {
          permissions.push("access");
        }
        if (userData.receives_mail) {
          permissions.push("mails");
        }
        dispatch(UIactions.setValue({ id: permissionsUI, value: permissions }));
      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(postUserId));
      dispatch(UIactions.clear(userUI));
      dispatch(UIactions.clear(usernameUI));
      dispatch(UIactions.clear(pass1UI));
      dispatch(UIactions.clear(pass2UI));
      dispatch(UIactions.clear(permissionsUI));
      dispatch(UIactions.clear(changeGroupUI));
      dispatch(UIactions.clear(changePasswordUI));
      dispatch(UIactions.clear(changePermissionsUI));
      dispatch(UIactions.clear(selectedGroupId2));
      dispatch(UIactions.clear(selectedGroupIdx2));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  let header = "";
    const { header1,header2,header3 } = UIText[locale];
  if (isNew) {
    header = header1;
  } else if (!isNew && users.length > 1) {
    header = `${header3} ( ${users.length} )`;
  } else {
    header = header2;
  }

  return (
    <Dialog {...{ open: true, onClose }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container item {...{ rowGap: 2, sx: { width: "100%" } }}>
          <Group {...{ isNew, users, group }} />
          {users.length > 1 ? null : <User {...props} />}
          {users.length > 1 ? null : <Username {...props} />}
          <Password {...{ isNew, users }} />
          <Permissions {...{ isNew, users }} />
        </Grid>
      </BasicDialogContent>
      <Buttons {...{ isNew, users }} />
    </Dialog>
  );
};

export default UserForm;
