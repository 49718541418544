import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { w365shareUI } from "../Share";
import BasicDialogTitle from "../../../../UI2/BasicDialog/BasicDialogTitle";
import Cancel from "./Cancel";
import BasicDialogActions from "../../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import Confirm from "./Confirm";
import { useWorker } from "../../../../../hooks2/useWorker";
import { updateOrgId } from "../../../../../store/actions2/Windows365/changeW365Sharing";
import { workersSliceActions as workers } from "../../../../../store/slices/workers";
import { useEffect } from "react";

const W365SharingToggle = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const { done, error } = useWorker(updateOrgId);
  const active = UI[w365shareUI];
  const locale = settings.locale;
  const { payload: p } = UI.modal2;
  const { payload, groupname } = p;

  const UITextActivate = {
    "es-CL": {
      header: `Compartir credenciales del dominio '@${payload.domain}' de Windows365 con los subgrupos de '${groupname}'?`,
    },
    "en-US": {
      header: `Share Windows365 credentials from the domain '@${payload.domain}' with subgroups of '${groupname}'?`,
    },
  };
  const UITextDeactivate = {
    "es-CL": {
      header: `Dejar de compartir credenciales del dominio '@${payload.domain}' de Windows365 con los subgrupos de '${groupname}'?`,
    },
    "en-US": {
      header: `Stop sharing Windows365 credentials from the domain '@${payload.domain}' with subgroups of '${groupname}'?`,
    },
  };
  const UIText = active ? UITextDeactivate : UITextActivate;
  const { header } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal2());
      dispatch(workers.clear(updateOrgId));
    }
  }, [dispatch, done, error]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal2());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogActions>
        <Cancel />
        <Confirm {...{ payload, locale }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default W365SharingToggle;
