export const createNewData = () => {
  return {
    user: "",
    group: 0,
    bandwidth: 0,
    connected: false,
    croppedVersion: "",
    lastBkp: 0,
    lastChanges: 0,
    lastConnected: 0,
    mid: "",
    name: "",
    space_active: 0,
    status: 0,
    version: "",
    lastConnectedAdjusted: 0,
  };
};

const alertRange = 1000 * 60 * 60 * 24 * 4; // 1000 ms * 60 s * 60 min * 24 hrs * 4 days
export const createMachineData = (user, group, machine) => {
  let BaseData = createNewData();
  BaseData = {
    ...machine,
    user,
    group,
    connected: !!machine.bandwidth,
    croppedVersion: machine.version ? machine.version.split("#")[0] : "",
  };

  const now = Date.now();
  const parsedLastBkp = Date.parse(machine.last_backup);
  const parsedLastChanges = Date.parse(machine.last_changes);
  const parsedLastConnect = Date.parse(machine.last_connected);

  let status = 1; // backup OK
  if (!machine.mid) {
    status = 0; // no devices
  } else if (parsedLastBkp === -62135596800000) {
    status = 5; // never bkp
  } else if (now - parsedLastConnect > alertRange && !machine.bandwidth) {
    status = 4; // not connected since a while
  } else if (now - parsedLastBkp > alertRange) {
    status = 3; // not bkp since a while
  } else if (now - parsedLastChanges > alertRange) {
    status = 2; // not sent since a while
  }
  BaseData.status = status;
  BaseData.lastBkp = parsedLastBkp;
  BaseData.lastChanges = parsedLastChanges;
  BaseData.lastConnected = parsedLastConnect;
  BaseData.lastConnectedAdjusted = machine.bandwidth
    ? parsedLastConnect - now
    : now - parsedLastConnect;
  return BaseData;
};

export const parseGroupUsers = (groupsData) => {
  let newData = []
  for (const user of groupsData.users) {
    newData.push(user)
  }
  for (const groupData of groupsData.groups) {
    newData = [...newData,...parseGroupUsers(groupData)]
  }
  return newData
};

export const parseGroupsRecursive = (groupsData) => {
  let newData = []
  for (const user of groupsData.users) {
    if (!user.machines_info.length) {
      let reportItemData = createNewData();
      reportItemData.user = user.login;
      reportItemData.group = user.group;
      newData.push(reportItemData);
      continue;
    }
    for (const machine of user.machines_info) {
      const reportItemData = createMachineData(user.login, user.group, machine);
      newData.push(reportItemData);
    }
  }
  for (const groupData of groupsData.groups) {
    newData = [...newData,...parseGroupsRecursive(groupData)]
  }
  return newData
};

export const extractUsers = (groupsData) =>{
  let newData = []
  for (const user of groupsData.users){
    newData.push(user)
  }
  for (const groupData of groupsData.groups) {
    newData = [...newData,...extractUsers(groupData)]
  }
  return newData
}