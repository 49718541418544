export const requirements = [
    {
        value:'consulta',
        'es-CL':{label:'Consulta'},
        'en-US':{label:'Question'}
    },
    {
        value:'sugerencia',
        'es-CL':{label:'Sugerencia'},
        'en-US':{label:'Suggestion'}
    },
    {
        value:'problema',
        'es-CL':{label:'Problema'},
        'en-US':{label:'Problem'}
    },
    {
        value:'reclamo',
        'es-CL':{label:'Reclamo'},
        'en-US':{label:'Complaint'}
    },
]

export const about = [
    {
        value:'web',
        'es-CL':{label:'Pagina web'},
        'en-US':{label:'Webpage'}
    },
    {
        value:'instalation',
        'es-CL':{label:'Instalación'},
        'en-US':{label:'Instalation'}
    },
    {
        value:'alldevices',
        'es-CL':{label:'Todos los dispositivos'},
        'en-US':{label:'All devices'}
    },
    {
        value:'onedevice',
        'es-CL':{label:'Dispositivo particular'},
        'en-US':{label:'Specific device'}
    },
    {
        value:'thecompany',
        'es-CL':{label:'La compañía, Cloner Spa.'},
        'en-US':{label:'The company, Cloner Spa.'}
    },
]