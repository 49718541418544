import FilterPlusActions from "./Components/FilterPlusActions/FilterPlusActions";
import Core from "./Components/Core/Core";
import BasicContainer from "./BasicContainer";
import BasicHeader from "./Components/Header";
import BasicFooter from "./Components/BasicFooter";
import BasicHeaders from "./Components/BasicHeaders";

const BasicLayout = (props) => {
  const { header, plusActions, headersData, coreData, fullArray } = props;
  return (
    <BasicContainer>
      <BasicHeader>{header}</BasicHeader>
      <FilterPlusActions>{plusActions}</FilterPlusActions>
      {headersData ? <BasicHeaders {...headersData} {...{fullArray}} /> : null}
      <Core hasHeaders={!!headersData} {...coreData} />
      <BasicFooter />
    </BasicContainer>
  );
};

export default BasicLayout;
