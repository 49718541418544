import { HelpOutline } from "@mui/icons-material"
import useUIBoilerplate from "../../hooks2/useUIBoilerplate"
import AutoHelp from "./Wrappers/AutoHelp"

const HelpTooltip = ({UIText, size='1.2rem'})=>{
    const {settings} = useUIBoilerplate()
    const {tooltip:fixedTooltip} = UIText[settings.locale]

    return (
        <AutoHelp {...{fixedTooltip}}>
            <HelpOutline color="primary" sx={{fontSize:size}}/>
        </AutoHelp>
    )
}

export default HelpTooltip