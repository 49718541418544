import { newTreesSliceActions } from "../../../../../store/slices/trees2";
import ExpandedIcon from "../../../../../utility/expandedIcon";
import IconButton from "../../../../UI2/IconButton";
import loadRepositoryNode from "../../../../../store/actions2/RemoteSelection/loadRepositoryNode";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { tunnelTokenId } from "../../../../../store/actions2/RemoteSelection/getMachineStatus";

const UIText = {
  universal: {},
};

const ExpandButton = (props) => {
  const { auth, dispatch, UI } = useUIBoilerplate();
  const {
    id,
    fullPath,
    treeId,
    idx,
    loaded,
    expanded,
    isDir,
    hasChildren,
    level,
    working,
    status,
  } = props;
  const tunnelToken = UI[tunnelTokenId];

  const expandIcon = ExpandedIcon(
    loaded,
    expanded,
    isDir,
    hasChildren, /*!!children.length*/
    status,
  );

  const handleExpand = () => {
    // console.log("handling expand!");
    if (loaded) {
      dispatch(
        newTreesSliceActions.toggleExpand({
          id: treeId,
          idx,
        })
      );
    } else {
      dispatch(
        loadRepositoryNode({
          id: level ? id : 'root'+treeId,
          root: treeId,
          idx,
          server: auth.server,
          tunnelToken,
          path: fullPath,
        //   repoId: repo,
        })
      );
    }
  };


  return (
    <IconButton
      {...{
        UIText,
        disabled: working || (loaded && !hasChildren) || !isDir || !!status,
        onClick: handleExpand,
        icon: expandIcon,
        sx: { padding: 0.3 },
        size: "small",
        xs: "auto",
      }}
    />
  );
};

export default ExpandButton;
