import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../UI2/Button";
import TextField from "../UI2/TextField";
import SlimAlert from "../UI2/SlimAlert";
import updateSharedLink, {
  updateSharedLinkId,
} from "../../store/actions2/Shared/updateSharedLink";
import {
  getSharedFilesId,
  sharedFilesData,
} from "../../store/actions2/Shared/getSharedFiles";
import notEmptyPassword from "../../utility/validations/notEmptyPassword";

const UIText = {
  "es-CL": {
    header: "Proteger enlace de descarga",
    instructions:
      "La contraseña será requerida por cualquiera que intente descargar el archivo mediante el enlace de descarga",
  },
  "en-US": {
    header: "Protect download link",
    instructions:
      "The password will be required of anyone that attempts to download the file through the download link",
  },
};
const UIText1 = {
  "es-CL": { label: "Contraseña de descarga", helper: "" },
  "en-US": { label: "Download password", helper: "" },
};
const UIText2 = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};
const UIText3 = {
  "es-CL": { label: "Proteger" },
  "en-US": { label: "Protect" },
};

export const passwordFieldId = "passwordField";

const ProtectSharedLink = (props) => {
  const { auth, dispatch, navigate, settings, UI, warehouse } =
    useUIBoilerplate();
  const { done, working, error } = useWorker(updateSharedLinkId);
  const locale = settings.locale;
  const { helper } = UIText[locale];
  const { payload } = UI.modal;
  const { id: Id } = payload;
  const sharedFiles = warehouse[sharedFilesData];

  const { header, instructions } = UIText[locale];
  const passField = UI[passwordFieldId] ?? {};
  const { value: password, error: error2 } = passField;

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear("selected"));
      dispatch(workers.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(passwordFieldId));
      dispatch(workers.clear(updateSharedLinkId));
    };
  }, [dispatch]);

  const close = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  const validation = () => {
    dispatch(
      UIactions.validateField({
        id: passwordFieldId,
        force: true,
        fn: notEmptyPassword,
        locale,
      })
    );
  };

  const submit = () => {
    const { id, file, repo_id } = sharedFiles.find((x) => x.id === Id);
    dispatch(
      updateSharedLink({
        ...auth,
        locale,
        payload: {
          id,
          file,
          repo_id,
          password,
          password_set: true,
          public: true,
        },
      })
    );
  };

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose: close,
        maxWidth: "xs",
      }}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid container rowGap={2} paddingLeft={2} paddingRight={2}>
          <SlimAlert
            notCollapse
            {...{ errors: [instructions], severity: "info", xs: 12 }}
          />
          <TextField
            autoFocus
            tagError
            {...{
              id: passwordFieldId,
              validation: notEmptyPassword,
              type: "password",
              UIText: UIText1,
              helperText: helper,
              submit,
              onKeyDown: validation,
              size: "medium",
              xs: 12,
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
        <Button
          {...{
            UIText: UIText2,
            onClick: close,
            variant: "text",
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
        <Button
          {...{
            disabled: !!error2,
            UIText: UIText3,
            onClick: submit,
            onMouseDown: validation,
            working,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ProtectSharedLink;
