const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
export function generateString(length) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

const charactersPlus =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$.*&^[]{},:;()%-_=+";
export function generateStringPlus(length) {
  let result = "";
  const charactersLength = charactersPlus.length;
  for (let i = 0; i < length; i++) {
    result += charactersPlus.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const generatePassword = (length) => {
  while (true) {
    const newPass = generateString(length);
    const ok =
      newPass.match(/[A-Z]/) &&
      newPass.match(/[a-z]/) &&
      newPass.match(/[0-9]/);
    if (ok) {
        return newPass
    }
  }
};
export const generatePasswordPlus = (length) => {
  while (true) {
    const newPass = generateStringPlus(length);
    const ok =
      newPass.match(/[A-Z]/) &&
      newPass.match(/[a-z]/) &&
      newPass.match(/[0-9]/) 
    if (ok) {
        return newPass
    }
  }
};

const hexCharacters = "abcdef0123456789";
export function generateHexString(length) {
  let result = "";
  const charactersLength = hexCharacters.length;
  for (let i = 0; i < length; i++) {
    result += hexCharacters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }

  return result;
}
