import { Grid } from "@mui/material";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { warehouseSliceActions as warehouse } from "../../../../store/slices/warehouse";
import { usersData } from "../../../../store/actions2/Users/getUsersRecursive";
import Switch from "../../../UI2/Switch2";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": { label: "Incluir subgrupos", fixedTooltip: "Incluir usuarios de subgrupos"},
  "en-US": { label: "Include subgroups", fixedTooltip: "Include users from subgroups" },
};

export const recursiveRequestId = "recursiveRequest";

const RecursiveSwitch = (props) => {
  const { working: disabled } = props;
  const dispatch = useDispatch()
  const { group } = useURLQuery();


  const onChange = () => {
    dispatch(workers.clear(`groupData${group}`));
    dispatch(warehouse.unload(usersData));
  };

  return (
    <Grid container item xs={true} alignItems="center" justifySelf="start">
      <Switch
        labelRight
        {...{
          id: recursiveRequestId,
          UIText,
          disabled,
          onChange,
          size: "small",
          xs: "auto",
        }}
      />
    </Grid>
  );
};

export default RecursiveSwitch;
