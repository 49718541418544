import { Grid } from "@mui/material";
import TextField from "../../UI2/TextField";
import notEmptyPassword from "../../../utility/validations/notEmptyPassword";

const UIText1 = {
  "es-CL": { label: "Nueva contraseña", placeholder: "**********" },
  "en-US": { label: "New password", placeholder: "**********" },
};
const UIText2 = {
  "es-CL": { label: "Repetir contraseña", placeholder: "**********" },
  "en-US": { label: "Repeat password", placeholder: "**********" },
};

export const pass1UI = "password1";
export const pass2UI = "password2";

const Password = () => {
  return (
    <Grid container item xs={12} columnSpacing={2} rowGap={2}>
      <TextField
        tagError
        {...{
          id: pass1UI,
          UIText: UIText1,
          twinId: pass2UI,
          twinValidation: notEmptyPassword,
          sm: 6,
          xs: 12,
        }}
      />
      <TextField
        tagError
        {...{
          id: pass2UI,
          UIText: UIText2,
          twinId: pass1UI,
          twinValidation: notEmptyPassword,
          sm: 6,
          xs: 12,
        }}
      />
    </Grid>
  );
};

export default Password;
