import { useLocation } from "react-router-dom";

// useURLQuery
const useURLQuery = () =>{
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let obj = {}

  params.forEach((value,key)=> {obj[key]=value})

  return obj
}
export default useURLQuery