import { useDispatch } from "react-redux";
import Button from "../../UI2/Button";
import { UISliceActions as UIactions } from "../../../store/slices/UI2";
import { tfaSwitch } from "../../Settings/Components/TFA";

const UIText = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};

const Cancel = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(UIactions.closeModal());
    dispatch(UIactions.clear(tfaSwitch))
  };

  return <Button {...{ UIText, onClick, variant: "text" }} />;
};

export default Cancel;
