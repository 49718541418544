import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import addRecoveries from "../../../../../store/actions2/Recoveries/addRecoveries";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";

const UIText = {
  "es-CL": { label: "Iniciar nueva solicitud" },
  "en-US": { label: "Start new request" },
};

const Continue = ({ devices = [] }) => {
  const { auth, dispatch, settings } = useUIBoilerplate();
  const locale = settings.locale;

  const onClick = () => {
    dispatch(recoveries.reset());
    dispatch(
      addRecoveries({
        ...auth,
        locale,
        devices,
      })
    );
    dispatch(UISliceActions.closeModal());
  };

  return <Button {...{ UIText, onClick }} />;
};

export default Continue;
