import useUIBoilerplate from "../../../../../../../../hooks2/useUIBoilerplate";
import { Grid, Typography } from "@mui/material";
import RadioSelect from "../../../../../../../UI2/RadioSelect";
import AddressInfo from "../AddressInfo/AddressInfo";
import DeliveryWindow from "../DeliveryWindow";
import CollapseGrid from "../../../../../../../UI2/CollapseGrid";
import { defaultRadioSelect } from "../../../../../../../UI2/genericIDs";

const UIText = {
  "es-CL": {
    subtitle: "Modalidad de recuperación",
    label1: "Vínculo de descarga",
    label2: "Disco duro",
  },
  "en-US": {
    subtitle: "Recovery mode",
    label1: "Download link",
    label2: "Hard drive",
  },
};

const DeliveryMode = () => {
  const { settings,UI } = useUIBoilerplate();
  const mode = UI[defaultRadioSelect] ?? "link";
  const locale = settings.locale;
  const { subtitle, label1, label2 } = UIText[locale];

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} paddingBottom={2}>
        <Typography fontWeight="bold">{subtitle}</Typography>
      </Grid>
      <RadioSelect
        {...{
          xs: 6,
          label: label1,
          value: "link",
        }}
      />
      <RadioSelect
        {...{
          xs: 6,
          label: label2,
          value: "hdd",
        }}
      />
      <CollapseGrid open={mode === "link" ? false : true}>
        <AddressInfo />
        <DeliveryWindow />
      </CollapseGrid>
    </Grid>
  );
};

export default DeliveryMode;
