import { Typography } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const DateTime = ({ fontSize, receivedDateTime }) => {
  const { settings } = useUIBoilerplate();

  const localDate = new Date(receivedDateTime).toLocaleString(settings.locale);

  return <Typography {...{ fontSize }}>{localDate}</Typography>;
};

export default DateTime;
