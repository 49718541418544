import { Dialog } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import SlimAlert from "../../../UI2/SlimAlert";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import Button from "../../../UI2/Button";
import TimeoutButton from "../../../UI2/TimeoutButton";

const UIText = {
    'es-CL': { label: 'Cerrar' },
    'en-US': { label: 'Close' },
}
const UIText2 = {
    'es-CL': { label: 'Ir a version antigua' },
    'en-US': { label: 'Go to old version' },
}

const UIText3 = {
    'es-CL': { header: 'Esta seguro que quiere volver al sitio web antiguo?', message: 'La pagina antigua funcionará hasta la fecha XX-XX-XX, luego de la cual dejará de estar disponible. Lo alentamos a utilizar la version nueva, que ha sido rediseñada para una mejor usabilidad y ademas es un requisito si se desea respaldar Microsoft 365' },
    'en-US': { header: 'Are you sure you want to go back to the old website?', message: 'The old website will be available until XX-XX-XX, after which it will be retired. We encourage you to use the new version, which has been redesigned to grant a better user experience and is also a pre-requisite in order to backup Microsoft 365' },
}

const LegacyDialog = () => {
  const { dispatch,UI,settings } = useUIBoilerplate();
  const modal = UI.modal ?? { type: "" };
  const { open } = modal;
  const locale = settings.locale ?? "es-CL";
  const {header, message} = UIText3[locale]

  const onCancel = () => {
    dispatch(UIactions.closeModal());
  }

  const onConfirm= () => {
    dispatch(UIactions.closeModal());
    window.location.href = "https://login.cloner.cl";
  };


  if (!open) return null;

  return (
    <Dialog
      {...{
        open: true,
        onClose: onCancel,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <SlimAlert {...{ errors: [message], severity:"warning" }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Button
          {...{
            UIText,
            onClick: onCancel,
            variant: "text",
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
        <TimeoutButton
          {...{
            UIText: UIText2,
            onClick: onConfirm,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </BasicDialogActions>
    </Dialog>
  );
};

export default LegacyDialog;
