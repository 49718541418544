import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { UISliceActions as UI, UISliceActions } from "../../slices/UI2";
import { workersSliceActions as worker } from "../../slices/workers";
import { fixChildrenIdx, parseGroupsListRecursive } from "./utils";
import { newTreesSliceActions as treeActions } from "../../slices/trees2";
import { reloadUI } from "../../../components/GroupsTraveler/GroupsTraveler";

export const getGroupsListId = "groupsList";
export const groupsTreeId = "groups";
export const selectedGroupIdx = "groupIdx";
export const selectedGroupId = "groupId";
export const selectedGroupIdx2 = "groupIdx2";
export const selectedGroupId2 = "groupId2";
export const currentGroupnameId = "currentGroupname";
export const currentGroupId = "currentGroup";

const loadGroupsTree = (data) => {
  console.log("Getting groups list!");
  const { server, group, cloner_key, startGroup, mode } = data;
  const id = getGroupsListId;

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      const response = await axios.get(server + "api/groups_list", {
        params: { group },
        headers: { cloner_key },
      });
      const groupsListData = [{ ...response.data }];

      const tree = parseGroupsListRecursive(groupsListData[0], null, null, 0);
      const fixedTree = fixChildrenIdx(tree);
      dispatch(treeActions.loadTree({ id: groupsTreeId, tree: fixedTree }));

      const endGroup = fixedTree.find(
        (node) => node.id === parseInt(startGroup)
      );

      if (mode === "topbar") {
        dispatch(UI.setValue({ id: selectedGroupIdx, value: endGroup.idx }));
        dispatch(UI.setValue({ id: selectedGroupId, value: endGroup.id }));
        dispatch(UI.setValue({ id: currentGroupnameId, value: endGroup.name }));
        dispatch(UI.setValue({ id: currentGroupId, value: endGroup.id }));
      } else if (mode === "modal") {
        console.log("going for modal", endGroup);
        dispatch(UI.setValue({ id: selectedGroupIdx2, value: endGroup.idx }));
        dispatch(UI.setValue({ id: selectedGroupId2, value: endGroup.id }));
      }
      dispatch(UISliceActions.clear(reloadUI))
      dispatch(worker.finish({ id }));
    } catch (e) {
      console.error(e);
      dispatch(worker.finish({ id, error: e.message }));
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };
};

export default loadGroupsTree;
