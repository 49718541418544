import notEmptyField from "../../../../../../../../utility/validations/notEmpty";
import TextField from "../../../../../../../UI2/TextField";

const UIText = {
  "es-CL": { label: "Nombre de contacto", placeholder:'Juan Perez'},
  "en-US": { label: "Contact name", placeholder:'John Doe' },
};

export const contactNameUI = "contactName";

const ContactName = () => {
  return (
    <TextField
      required
      {...{
        id: contactNameUI,
        validation: notEmptyField,
        UIText,
        xl: 4,
        lg: 4,
        md: 4,
        sm: 12,
        xs:12,
      }}
    />
  );
};

export default ContactName;
