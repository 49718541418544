import { default as CustomDevicePicker } from "../../../UI2/Specialized/DevicePicker";
import { UISliceActions } from "../../../../store/slices/UI2";
import { tunnelTokenId } from "../../../../store/actions2/RemoteSelection/getMachineStatus";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { selectionBlockUI } from "../../RemoteSelection3";
import { passFieldId } from "../Blocked/PassField";
import { tunnelsDataId } from "../../../../store/actions2/Users/getTunnelData";

const DevicePicker = ({user}) => {
  const {dispatch,warehouse} = useUIBoilerplate()
  const tunnels = warehouse[tunnelsDataId]??{}
  const whitelist = tunnels[user]??undefined

  const onChange = () => {
    dispatch(UISliceActions.clear(tunnelTokenId));
    dispatch(UISliceActions.clear(selectionBlockUI))
    dispatch(UISliceActions.clear(passFieldId))
  };

  return <CustomDevicePicker autoload useWhitelist {...{ user, whitelist, onChange, xs: 12 }} />;
};

export default DevicePicker;
