import { Grid } from "@mui/material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import ChangeGroup, { changeGroupUI } from "./ChangeGroup";
import GroupButton from "./GroupButton";
import CollapseGrid from "../../../UI2/CollapseGrid";

const Group = ({ isNew, users, group }) => {
  const { UI, settings } = useUIBoilerplate();
  const changeGroup = UI[changeGroupUI];

  return (
    <Grid container item {...{ xs: 12 }}>
      {users.length > 1 ? (
        <ChangeGroup {...{ locale: settings.locale }} />
      ) : null}
      <CollapseGrid
        open={changeGroup || users.length === 1 || isNew}
        outerProps={{ xs: 12 }}
      >
        <GroupButton
          init
          disabled={isNew}
          startGroup={users.length === 1 ? users[0].group : group}
        />
      </CollapseGrid>
    </Grid>
  );
};

export default Group;
