import {
    AddBoxOutlined,
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined as Indeterminate,
  SquareOutlined,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const BoxStateIcon = (backupMode, working, fontSize = 17) => {
  if (!working) {
    switch (backupMode) {
      case 0:
        return <SquareOutlined sx={{ fontSize }} />;
      case 1:
        return <Indeterminate {...{ color: "warning", sx: { fontSize } }} />;
      case 2:
        return <AddBoxOutlined {...{ color: "success", sx: { fontSize } }} />;
      case 3:
        return <CheckBoxOutlined {...{ color: "info", sx: { fontSize } }} />;
      default:
        break;
    }
  } else {
    return <CircularProgress size={17} />;
  }
};

export default BoxStateIcon;
