import Category from "./Category";

const UIText = {
  "es-CL": { header: "Audio" },
  "en-US": { header: "Audio" },
};

const Audio = () => {
  return <Category {...{ category: "audio", UIText }} />;
};

export default Audio;
