import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import Button from "../UI2/Button";
import SlimAlert from "../UI2/SlimAlert";
import updateSharedLink, {
  updateSharedLinkId,
} from "../../store/actions2/Shared/updateSharedLink";
import {
  getSharedFilesId,
  sharedFilesData,
} from "../../store/actions2/Shared/getSharedFiles";

const UIText = {
  "es-CL": {
    header: "¿Esta seguro que desea remover proteccion del enlace de descarga?",
    instructions:
      "Se removerá la proteccion por contraseña del enlace de descarga permitiendo que cualquiera con acceso al enlace pueda descargar el archivo libremente",
  },
  "en-US": {
    header:
      "Are you sure you want to remove the protection from this download link?",
    instructions:
      "The download link's password protection will be removed, allowing anyone with access to the link to freely download the file",
  },
};
const UIText2 = {
  "es-CL": { label: "Cancelar" },
  "en-US": { label: "Cancel" },
};
const UIText3 = {
  "es-CL": { label: "Confirmar" },
  "en-US": { label: "Confirm" },
};

const DeprotectSharedLink = (props) => {
  const { auth, dispatch, navigate, settings, UI, warehouse } =
    useUIBoilerplate();
  const { done, working, error } = useWorker(updateSharedLinkId);
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { id: Id } = payload;
  const sharedFiles = warehouse[sharedFilesData];

  const { header, instructions } = UIText[locale];

  useEffect(() => {
    if (done && !error) {
      dispatch(UIactions.closeModal());
      dispatch(UIactions.clear("selected"));
      dispatch(workers.clear(getSharedFilesId));
    }
  }, [dispatch, navigate, done, error]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(updateSharedLinkId));
    };
  }, [dispatch]);

  const close = () => {
    dispatch(UIactions.closeModal());
  };

  const submit = () => {
    const { id, file, repo_id } = sharedFiles.find((x) => x.id === Id);
    dispatch(
      updateSharedLink({
        ...auth,
        locale,
        payload: {
          id,
          file,
          repo_id,
          password: "",
          password_set: false,
          public: true,
        },
      })
    );
  };

  return (
    <Dialog
      fullWidth
      {...{
        open: true,
        onClose: close,
        maxWidth: "sm",
      }}
    >
      <DialogTitle>{header}</DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid container rowGap={2} paddingLeft={2} paddingRight={2}>
          <SlimAlert
            notCollapse
            {...{ errors: [instructions], severity: "warning", xs: 12 }}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: 3, paddingRight: 3 }}>
        <Button
          {...{
            UIText: UIText2,
            onClick: close,
            variant: "text",
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
        <Button
          {...{
            UIText: UIText3,
            onClick: submit,
            working,
            labelSx: { fontSize: 13, fontWeight: "bold" },
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeprotectSharedLink;
