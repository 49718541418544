import { Grid, Tooltip, Typography } from "@mui/material";

const Repositories = ({ fontSize, repositories }) => {
  let allRepoNames = repositories.map((repo, idx) => {
    return repo.name;
  }).join(", ");

  let repoText = repositories.map((repo, idx) => {
    return (
      <Typography
        key={idx}
        {...{
          key: idx,
          fontSize,
          sx: {
            cursor: "default",
            textDecoration: repo.recover ? "none" : "line-through",
            color:repo.recover ? "black" : "grey",
          },
        }}
      >
        {idx ? ", " : ""}
        {repo.name}
      </Typography>
    );
  });



  return (
    <Tooltip title={allRepoNames}>
      <Grid container item xs={12} alignItems="center">
        {repoText}
      </Grid>
    </Tooltip>
  );
};

export default Repositories;
