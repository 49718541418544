import { customExtensionsUI } from "./NewExtension";
import Button from "../../../../UI2/Button";
import { useDispatch } from "react-redux";
import { UISliceActions } from "../../../../../store/slices/UI2";

const UIText = {
  "es-CL": { label: "Eliminar extensiones" },
  "en-US": { label: "Delete extensions" },
};

const Clear = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UISliceActions.clear(customExtensionsUI));
  };

  return (
    <Button
      {...{
        UIText,
        onClick,
        variant: "text",
        xs: "auto",
        size: "medium",
        labelSx: { fontSize: 14 },
      }}
    />
  );
};

export default Clear;
