import { Tooltip, Typography } from "@mui/material";

const Username = ({ fontSize, user }) => {
  return (
    <Tooltip title={user}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {user}
      </Typography>
    </Tooltip>
  );
};

export default Username;
