import { Dialog, Grid } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { useEffect } from "react";
import { workersSliceActions as workers } from "../../store/slices/workers";
import User, { userUI } from "./Components/User";
import { postUserId } from "../../store/actions2/UserForm/postUser";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import loadGroupsTree, {
  selectedGroupId2,
  selectedGroupIdx2,
} from "../../store/actions2/GroupTraveler/loadGroupsTree";
import Buttons from "./Components/Buttons/Buttons";
import W365 from "./Components/W365/W365";
import GoogleCloud from "./Components/Google/GoogleCloud";
import CollapseGrid from "../UI2/CollapseGrid";
import TextField from "../UI2/SimpleTextField";
import Select from "../UI2/Select";
import Switch from "../UI2/Switch2";

const UIText = {
  "es-CL": {
    header: "Integraciones Cloud",
  },
  "en-US": {
    header: "Cloud Integrations",
  },
};

const UserCloudIntegrations = (props) => {
  const { isNew } = props;
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const locale = settings.locale;
  const { payload } = UI.modal;
  const { group, users = [] } = payload;

  useEffect(() => {
    dispatch(
      loadGroupsTree({
        ...auth,
        startGroup: parseInt(group),
        mode: "modal",
      })
    );
  }, [dispatch, auth, group]);

  useEffect(() => {
    if (users.length === 1) {
      const userData = users[0];
      if (userData.login) {
        dispatch(UIactions.setField({ id: userUI, value: userData.login }));
        // dispatch(UIactions.setField({ id: usernameUI, value: userData.name }));
        // let permissions = [];
        // if (userData.group === userData.level) {
        //   permissions.push("admin");
        // }
        // if (userData.has_access) {
        //   permissions.push("access");
        // }
        // if (userData.receives_mail) {
        //   permissions.push("mails");
        // }
        // dispatch(UIactions.setValue({ id: permissionsUI, value: permissions }));
      }
    }
  }, [dispatch, users]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(postUserId));
      dispatch(UIactions.clear(userUI));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  let header = UIText[locale].header;

  return (
    <Dialog {...{ open: true, onClose }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        {/* <Grid container item {...{ rowGap: 2, paddingTop:2, sx: { width: "100%" } }}> */}
        <User {...props} />
        <W365 />
        <CollapseGrid
          {...{
            open: true,
            innerProps: { sx: { paddingBottom: 2, rowGap: 2 } },
          }}
        >
          <Grid container item xs={12} columnSpacing={2}>
            <TextField
              {...{
                id: "w365mail",
                UIText: {
                  "es-CL": {
                    label: "Correo Windows 365",
                  },
                  "en-US": {
                    label: "Windows 365 Mail",
                  },
                },
                xs: true,
              }}
            />
            <Grid container item xs="auto" sx={{alignItems:"center"}}>@dominio.dominio.cl</Grid>
          </Grid>
          <Switch
            {...{
              id: "w365mailbackup",
              UIText: {
                "es-CL": {
                  label: "Respaldar correos",
                },
                "en-US": {
                  label: "Backup emails",
                },
              },
              xs: 6,
            }}
          />
          <Switch
            {...{
              id: "w365filesbackup",
              UIText: {
                "es-CL": {
                  label: "Respaldar archivos",
                },
                "en-US": {
                  label: "Backup files",
                },
              },
              xs: 6,
            }}
          />
        </CollapseGrid>
        <GoogleCloud />
        {/* </Grid> */}
      </BasicDialogContent>
      <Buttons {...{ isNew, users }} />
    </Dialog>
  );
};

export default UserCloudIntegrations;
