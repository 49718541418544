import { TextField } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";


const DownloadLink = ({ id, fontSize }) => {
  const { auth } = useUIBoilerplate();

  let baseURL = "";
  if (typeof window !== "undefined") {
    baseURL = window.location.origin;
  }
  const downloadLink =
    baseURL + "/shared?id=" + id + "&user=" + encodeURIComponent(auth.user);

  return (
    <TextField
      fullWidth
      disabled
      {...{
        value: downloadLink,
        size: "small",
        inputProps: {
          sx: { fontSize },
        },
      }}
    />
  );
};

export default DownloadLink;
