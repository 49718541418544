export const setSelectionRecursive = (nodes, idx, select) => {
  const node = nodes[idx];
  if (select) {
    node.backupMode = 3;
  } else {
    node.backupMode = 0;
  }
  if (node.children.length > 0) {
    for (const childIdx of node.children) {
      setSelectionRecursive(nodes, childIdx, select);
    }
  }
};

export const calculateSelectionModes = (nodes, idx) => {
  // console.log('calculating selection nodes for idx',0)
  const node = nodes[idx];

  if (!node.children.length) {
    return node.backupMode;
  }

  let empty = true;
  let full = true;
  for (const childIdx of node.children) {
    const mode = calculateSelectionModes(nodes, childIdx);
    if (mode > 0) {
      empty = false;
    }
    if (mode < 3) {
      full = false;
    }
  }

  switch (node.backupMode) {
    case 0:
      if (!empty) {
        node.backupMode = 1;
      }
      break;
    case 1:
      if (empty) {
        node.backupMode = 0;
      }
      break;
    case 2:
      if (full) {
        node.backupMode = 3;
      }
      break;
    case 3:
      if (!full) {
        node.backupMode = 2;
      }
      break;
    default:
      break;
  }

  return node.backupMode;
};

export const generateGreylist = (nodes, idxList) => {
  let refNode = nodes[idxList[0]];
  let newNode = {
    name: refNode.name,
    scanfolder: refNode.backupMode > 1 ? true : false,
    next: null,
    subtree: null,
  };
  if (idxList.length > 1) {
    newNode.next = generateGreylist(nodes, idxList.slice(1));
  }
  if (
    refNode.children.length > 0 &&
    (refNode.backupMode === 1 || refNode.backupMode === 2)
  ) {
    newNode.subtree = generateGreylist(nodes, refNode.children);
  }
  return newNode;
};

export const generateGreylist2 = (nodes, idxList) => {
  let refNode = nodes[idxList[0]];
  let newNode = {
    name: refNode.name,
    scanfolder: refNode.backup > 1 ? true : false,
    next: null,
    subtree: null,
  };
  if (idxList.length > 1) {
    newNode.next = generateGreylist2(nodes, idxList.slice(1));
  }
  if (
    refNode.childrenIdx.length > 0 &&
    (refNode.backup === 1 || refNode.backup === 2)
  ) {
    newNode.subtree = generateGreylist2(nodes, refNode.childrenIdx);
  }
  return newNode;
};
