import { Grid } from "@mui/material";
import { debounce } from "lodash";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import resetPassword, {
  passRecoveryId,
} from "../../../../store/actions2/Login/resetPassword";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import { notEmptyUser } from "../../../../utility/stringValidations-deprecated";
import Button from "../../../UI2/Button";
import { useWorker } from "../../../../hooks2/useWorker";

const UIText = {
  "es-CL": {
    label: "Resetear Contraseña",
  },
  "en-US": {
    label: "Reset password",
  },
};

const ResetBtn = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { user = { value: "" } } = UI;
  const { done, working } = useWorker(passRecoveryId);

  const debouncedReset = debounce(() => {
    if (!user.error) {
      dispatch(resetPassword({ login: user.value, locale: settings.locale }));
    }
  }, 200);
  const handleClick = () => {
    debouncedReset();
  };

  const debouncedValidation = debounce(() => {
    const payload = { id: "user", fn: notEmptyUser, force: true };
    dispatch(UIactions.validateField(payload));
  }, 200);
  const handleMouseDown = () => {
    debouncedValidation();
  };

  const disabled = working || !!user.error;

  if (!done) {
    return (
      <Grid container item xs={12} justifyContent="center">
        <Button
          fullWidth
          {...{
            UIText,
            working,
            onMouseDown: handleMouseDown,
            onClick: handleClick,
            disabled,
            lg: 3,
            md: 4,
            sm: 6,
            xs: 8,
          }}
        />
      </Grid>
    );
  }
};

export default ResetBtn;
