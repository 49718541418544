import { Grid } from "@mui/material";
import clonerWhite from "../../Resources/CLONER.png";

const Logo = (props) => {
  return (
    <Grid container item xs={12} justifyContent="center">
      <img src={`${clonerWhite}`} alt="cloner-logo" height={100} />
    </Grid>
  );
};

export default Logo;
