import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import useURLQuery from "../../hooks2/useURLQuery";
import { useWorker } from "../../hooks2/useWorker";
import { getDevicesId } from "../../store/actions2/Devices/getDevices2";
import getRepositories, {
  getRepoId,
  repoDataId,
} from "../../store/actions2/Devices/getRepositories";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { devicePickerId } from "../UI2/Specialized/DevicePicker";
import { userPickerId } from "../UI2/Specialized/UserPicker";
import Core from "./Components/Core";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import { newTreesSliceActions as newTrees } from "../../store/slices/trees2";
import { filesDataId, getFiles } from "../../store/actions2/Devices/getFiles";
import { sortObjByKey } from "../../utility/sortObjByKey";
import { lowerMatch } from "../../utility/filter";
import CalculatePagination from "../UI2/Utilities/CalculatePagination";
import { showDeletedUI } from "./Components/Actions/Deleted";
import { defaultPagination } from "../UI2/genericIDs";

const Files = () => {
  const { auth, dispatch, navigate, UI, settings, warehouse } =
    useUIBoilerplate();
  const { user: urlUser, file, repo } = useURLQuery();
  const { done: dDone } = useWorker(getDevicesId);
  const { done: rDone } = useWorker(getRepoId);
  const { user: authUser } = auth;
  const userPicker = UI[userPickerId] ?? {};
  const devicePicker = UI[devicePickerId] ?? {};
  const files = warehouse[filesDataId];
  const repositories = warehouse[repoDataId];
  const user = userPicker.id ? userPicker.id : auth.user;
  const mid = devicePicker.mid;
  const locale = settings.locale;
  const sort = UI.sort ?? {};
  const filter = UI.filter ?? "";
  const pagination = UI.pagination ?? { page: 0, count: 0, rowsPerPage: 0 };
  const deleted = !!UI[showDeletedUI];

  useEffect(() => {
    if (!urlUser) {
      navigate("/dashboard/files?user=" + encodeURIComponent(authUser), {
        replace: true,
      });
    }
  }, [navigate, urlUser, authUser]);

  // NO repositories? get repositories if there is a user and a machine!
  useEffect(() => {
    const controller = new AbortController();
    if (!rDone && dDone && user && mid) {
      dispatch(getRepositories({ controller, ...auth, user, mid }));
    }
  }, [dispatch, auth, rDone, dDone, user, mid]);

  useEffect(() => {
    const controller = new AbortController();
    if (file && repo) {
      // dispatch(workers.clear(getFiles));
      // dispatch(UIactions.clear(filesSortId));
      dispatch(
        getFiles({
          controller,
          ...auth,
          file,
          repo,
          locale,
        })
      );
    }
    return () => {
      controller.abort();
    };
  }, [dispatch, auth, file, repo, locale]);

  const fileItems = useMemo(() => {
    if (!files) {
      return [];
    }
    return [...files]
      .filter(
        (f) =>
          (!!deleted || !f.deleted) &&
          lowerMatch(f.name, filter) &&
          f.id !== "" &&
          f.name !== "[Subir]" &&
          f.name.toLowerCase().match("")
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending))
      .map((data) => {
        return { ...data, repo };
      });
  }, [files, repo, deleted, filter, sort.value, sort.descending]);

  const repoItems = useMemo(() => {
    if (!repositories) {
      return [];
    }
    return [...repositories]
      .sort((a, b) => sortObjByKey(a, b, "name"))
      .map((data) => {
        return {
          id: data.root,
          repo: data.id,
          name: data.name,
          num_versions: 0,
          created: data.last_changes,
          size: data.active_space,
          deleted: data.deleted,
          type: 99,
        };
      });
  }, [repositories]);

  let filteredFiles = file ? fileItems : repoItems;

  useEffect(() => {
    // console.log('filteredFilesLength',filteredFiles.length)
    if (filteredFiles.length > 0) {
      dispatch(UIactions.setPagination({ count: filteredFiles.length }));
    }
  }, [dispatch, filteredFiles.length]);

  // Calculating effective pagination
  const { from, to } = CalculatePagination(pagination);
  const paginatedFiles = useMemo(() => {
    let directories = [];
    let Files = [];
    // console.log("files?", files);
    var sortedFiles = [...filteredFiles]
    console.log("sortedFiles", sortedFiles)
    sortedFiles.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );

    for (const item of sortedFiles) {
      if (item.type === 0) {
        directories.push(item);
      } else {
        Files.push(item);
      }
    }
    var allFiles = [...directories, ...Files];

    return allFiles.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredFiles]);

  useEffect(() => {
    return () => {
      dispatch(newTrees.removeTreeGroup("repo-"));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(warehouseActions.clear(repoDataId));
      dispatch(workers.clear(getRepoId));
      dispatch(workers.clearGroup("node-"));
      dispatch(workers.clearGroup("file-"));
    };
  }, [dispatch]);

  return <Core {...{ files: paginatedFiles, allFiles: filteredFiles }} />;
};

export default Files;
