import { useDispatch } from "react-redux";
import BoxStateIcon from "../../../../../utility/boxStateIcon";
import IconButton from "../../../../UI2/IconButton";
import { newTreesSliceActions } from "../../../../../store/slices/trees2";

const UIText = {
  universal: {},
};

const SelectButton = (props) => {
  const { treeId:id, idx, backup, working } = props;
  const dispatch = useDispatch();

  const checkIcon = BoxStateIcon(backup, working);

  const handleSelect = () => {
    dispatch(newTreesSliceActions.RSnodeSelect({ id, idx }));
  };

  return (
    <IconButton
      {...{
        UIText,
        disabled: working,
        onClick: handleSelect,
        icon: checkIcon,
        sx: { padding: 0.3 },
        size: "small",
        xs: "auto",
      }}
    />
  );
};

export default SelectButton;
