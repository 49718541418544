import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";

// const UIText = {
//   'es-CL':{
//     label:''
//   }
// }

const SortHeader = (props) => {
  const { id = "sort", Key: key, xs, UIText = {}, size = 1, alignCenter,disabled } = props;
  const { dispatch, UI, settings } = useUIBoilerplate();
  const sort = UI[id] ?? { value: "" };

  const { label } = UIText[settings.locale];

  let icon;
  if (key === sort.value) {
    if (sort.descending) {
      icon = <ArrowDropUp sx={{ fontSize: `${size * 1.5}rem` }} />;
    } else {
      icon = <ArrowDropDown sx={{ fontSize: `${size * 1.5}rem` }} />;
    }
  }

  const handleToggle = () => {
    dispatch(UISliceActions.toggleSort({ id, key }));
  };

  return (
    <Grid
      container
      item
      {...{ alignItems: "center", wrap: "nowrap", onClick: !disabled?handleToggle:undefined, xs }}
    >
      {alignCenter?<Grid item xs={true}/>:null}
      <Grid container item xs="auto" alignItems="center">
        <Typography
          {...{
            fontSize: `${size}rem`,
            sx: { cursor: "default"},
            fontWeight:'bold',
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid container item xs={true} alignItems="center">
        {icon}
      </Grid>
    </Grid>
  );
};

export default SortHeader;
