import { CloudSync } from "@mui/icons-material";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import ActionButton from "../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import restoreMails from "../../../../store/actions2/Mails/restoreMails";
import { useWorker } from "../../../../hooks2/useWorker";

const UIText = {
  "es-CL": { label: "Restaurar correos" },
  "en-US": { label: "Restore emails" },
};

const Restore = () => {
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { working } = useWorker("restore-mails");
  const selected = UI.selected ?? [];

  const onClick = () => {
    dispatch(
      restoreMails({
        ...auth,
        workerId: "restore-mails",
        mailIDs: [...selected],
        locale: settings.locale,
      })
    );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        working,
        icon: <CloudSync />,
      }}
    />
  );
};

export default Restore;
