const UIText = {
  "es-CL": {
    error: "El nombre no puede estar vacío",
  },
  "en-US": {
    error: "The username cannot be empty",
  },
};

export const notEmptyName = (value,locale) => {
    const {error} = UIText[locale]

  if (!value) {
    return error
  }
  return ''
};