import bcrypt from "bcryptjs-react";
import { UISliceActions } from "../../slices/UI2";
import { selectionBlockUI } from "../../../components/RemoteSelection3/RemoteSelection3";
import { enqueueSnackbar } from "notistack";

const UIText = {
  "es-CL": { error: "La contraseña de selección es incorrecta" },
  "en-US": { error: "The selection password is wrong" },
};

export const validateSelectionPassword = (data) => {
  console.log("Checking for password equality!");
  const { password, cpassword, locale } = data;
  console.log('check:',data)
  const { error } = UIText[locale];
  return async (dispatch) => {
    const isEqual = await bcrypt.compare(password, cpassword);
    console.log('check?',isEqual)
    if (isEqual) {
      dispatch(UISliceActions.setValue({ id: selectionBlockUI, value: false }));
    } else {
      enqueueSnackbar(error,{variant:'error'});
    }
  };
};
