import { Grid } from "@mui/material";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { devicesLoadedId } from "../../Devices";
import Switch from "../../../UI2/Switch2";
import { useDispatch } from "react-redux";

const UIText = {
  "es-CL": {
    label: "Incluir subgrupos",
    fixedTooltip: "Incluir dispositivos de subgrupos",
  },
  "en-US": {
    label: "Include subgroups",
    fixedTooltip: "Include devices from subgroups",
  },
};

export const recursiveRequestId = "recursiveRequest";

const RecursiveSwitch = (props) => {
  const { working: disabled } = props;
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(workers.clear(devicesLoadedId));
  };

  return (
    <Grid container item xs={true} alignItems="center" justifySelf="start">
      <Switch
        labelRight
        {...{
          id: recursiveRequestId,
          UIText,
          disabled,
          onChange,
          size: "small",
          xs: "auto",
        }}
      />
    </Grid>
  );
};

export default RecursiveSwitch;
