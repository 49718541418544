import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "./slices/authentication";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import recoveriesSlice from "./slices/recoveries";
import workersSlice from "./slices/workers";
import warehouseSlice from "./slices/warehouse";
import settingsSlice from "./slices/settings";
import UISlice from "./slices/UI2";
import newTreesSlice from "./slices/trees2";

const authPersistConfig = {
  key: "auth",
  storage: localStorage.getItem("remember-session") ? storage : storageSession,
  stateReconciler: hardSet,
};

export const sessionPersistConfig = {
  key: "root",
  storage: storageSession,
  stateReconciler: hardSet,
};

const persistedAuthReducer = persistReducer(
  authPersistConfig,
  authenticationSlice
);

const combinedReducers = combineReducers({
  workers: workersSlice,
  recoveries: recoveriesSlice,
  warehouse: warehouseSlice,
  settings: settingsSlice,
  UI: UISlice,
  newTrees: newTreesSlice,
  authentication: persistedAuthReducer,
});

const rootPersistedReducer = persistReducer(
  sessionPersistConfig,
  combinedReducers
);

const store = configureStore({
  reducer: rootPersistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
export const persistor = persistStore(store);
