import { Grid } from "@mui/material";
import DeleteLink from "./Delete";

const Actions = () => {
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <DeleteLink />
    </Grid>
  );
};

export default Actions;
