import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import { defaultSort } from "./genericIDs";

// const UIText = {
//   'es-CL':{
//     label:''
//   }
// }

const SortHeader = (props) => {
  const {
    id = defaultSort,
    filterKey: key,
    xl,
    lg,
    md,
    sm,
    xs,
    UIText = {},
    fontSize = 14,
    justify = "start",
    disabled,
  } = props;
  const { dispatch, UI, settings } = useUIBoilerplate();
  const sort = UI[id] ?? { value: "" };

  const { label } = UIText[settings.locale] ?? UIText.universal;

  let icon;
  // if (key === sort.value) {
  if (sort.descending) {
    icon = <ArrowDropUp sx={{ fontSize: fontSize * 1.5 }} />;
  } else {
    icon = <ArrowDropDown sx={{ fontSize: fontSize * 1.5 }} />;
  }
  // }

  const handleToggle = () => {
    dispatch(UISliceActions.toggleSort({ id, key }));
  };

  return (
    <Grid
      container
      item
      {...{
        alignItems: "center",
        wrap: "nowrap",
        onClick: !disabled ? handleToggle : undefined,
        xl,
        lg,
        md,
        sm,
        xs,
        sx: { cursor: key ? "pointer" : undefined },
      }}
    >
      {justify === "start" ? null : (
        <Grid item xs={justify === "center" || justify === "end"} />
      )}
      <Grid container item xs="auto" alignItems="center">
        <Typography
          {...{
            fontSize,
            sx: { cursor: key?"pointer":"default" },
            fontWeight: "bold",
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={justify !== "end" ? true : "auto"}
        alignItems="center"
        sx={{ visibility: key === sort.value ? undefined : "hidden" }}
      >
        {disabled ? null : icon}
      </Grid>
    </Grid>
  );
};

export default SortHeader;
