import { Grid } from "@mui/material";
import Locale from "../Actions/Locale";
import HelpMode from "../Actions/HelpMode";
import UserOptions from "../Actions/UserOptions";

const Blank = ({height}) => {
  const barHeight = (height * 8) / 100;

  return (
    <Grid
      container
      item
      {...{
        bgcolor: "white",
        height: barHeight,
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 3,
        paddingRight: 3,
        xs: 12,
        borderBottom: "1px lightgrey solid",
      }}
    >
      <Grid container item {...{ columnGap: 2, xs: "auto" }}>
      </Grid>
      <Grid container item {...{ alignItems: "center", xs: "auto" }}>
        <Locale />
        <HelpMode />
        <UserOptions />
      </Grid>
    </Grid>
  );
};

export default Blank;
