import { Grid, Tooltip, Typography } from "@mui/material";
import BasicRow from "../../../../UI2/BasicLayout/Components/BasicRow";
import { styled } from "@mui/material/styles";
import {
  AdminPanelSettings,
  Delete,
  Email,
  Error,
  Key,
} from "@mui/icons-material";
import IconButton from "../../../../UI2/IconButton";
import { UISliceActions } from "../../../../../store/slices/UI2";
// import { newUsersUI } from "../../TopBar/UploadSheet";
import notEmptyUser from "../../../../../utility/validations/notEmptyUser";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { notEmptyName } from "../../../../../utility/validations/notEmptyName";
import notEmptyPassword from "../../../../../utility/validations/notEmptyPassword";
import { tooltipClasses } from "@mui/material/Tooltip";
import AutoHelp from "../../../../UI2/Wrappers/AutoHelp";
import { useEffect } from "react";
import Username from "./Username";
import { arrayId } from "../UsersList";
import W365mail from "./W365mail";
import BackupMail, { bkpMailArrayId } from "./BackupMail";
import BackupOnedrive, { bkpOneArrayId } from "./BackupOnedrive";
import Domain from "./Domain";

const UIText = {
  "es-CL": { fixedTooltip: "Remover usuario" },
  "en-US": { fixedTooltip: "Remove user" },
};

const UITextAdmin = {
  "es-CL": { fixedTooltip: "Administrador" },
  "en-US": { fixedTooltip: "Administrator" },
};
const UITextMails = {
  "es-CL": { fixedTooltip: "Recibe correos" },
  "en-US": { fixedTooltip: "Receives mails" },
};
const UITextAccess = {
  "es-CL": { fixedTooltip: "Accede a la plataforma" },
  "en-US": { fixedTooltip: "Has access to the platform" },
};
const UITextDuplicate = {
  "es-CL": "El usuario debe ser unico y se encuentra duplicado",
  "en-US": "The user must be unique and has a duplicate",
};

export const rowErrorsUI = "rowErrors";
const fontSize = 13;

const UserRow = (props) => {
  const { login, group } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  let w365mailfield = UI["365mail-"+login] ?? {};
  let w365mail = w365mailfield.value;
  let bkpMailArray = UI[bkpMailArrayId] ?? [];
  let bkpMailEnabled = !!bkpMailArray.find((x) => x === login);
  let bkpOneArray = UI[bkpOneArrayId] ?? [];
  let bkpOneEnabled = !!bkpOneArray.find((x) => x === login);
  // const users = UI[newUsersUI] ?? [];
  const locale = settings.locale;

  // console.log("Bkp Mail enabled", login, bkpMailEnabled);

  // const onClick = () => {
  //   dispatch(UISliceActions.removeObj({ id: newUsersUI, key: id }));
  //   dispatch(UISliceActions.remove({ id: rowErrorsUI, key: id }));
  // };

  let errors = [];

  

  const nameError = notEmptyName(w365mail, locale);
  // console.log("Name Error:", w365mail, nameError);
  if ((nameError && ( bkpOneEnabled || bkpMailEnabled)) || !!w365mailfield.error) {
    errors.push(nameError);
  }

  const error = !!errors.length;

  useEffect(() => {
    if (error) {
      dispatch(UISliceActions.add({ id: rowErrorsUI, key: login }));
    } else {
      dispatch(UISliceActions.remove({ id: rowErrorsUI, key: login }));
    }
  }, [dispatch, error, login]);

  const selectedColor = error ? "error" : "primary";
  const errorTTP = errors.map((x, idx) => <li key={idx}>{x}</li>);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: "none" } });

  const rowData = {
    id: login,
    arrayId,
    error,
    elements: [
      { content: <Username {...{ login, fontSize }} />, xs: 2.4 },
      { content: <W365mail {...{ login, fontSize }} />, xs: 2.4 },
      { content: <Domain {...{ group, fontSize }} />, xs: 2.4 },
      { content: <BackupMail {...{ login, fontSize }} />, xs: 2.4 },
      { content: <BackupOnedrive {...{ login, fontSize }} />, xs: true },
    ],
  };
  return <BasicRow {...rowData} />;
};

export default UserRow;
