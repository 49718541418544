import { AccountTree } from "@mui/icons-material";
import ToggleButton from "../../../UI2/ToggleButton";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import useURLQuery from "../../../../hooks2/useURLQuery";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import { currentGroupnameId } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";

const UIText = {
  "es-CL": {
    label: "Compartir",
    fixedTooltip: "Compartir credenciales a subgrupos",
  },
  "en-US": { label: "Share", fixedTooltip: "Share credentials with subgroups" },
};

export const w365shareUI = "windows365share";

const Share = ({ domain, own,working }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { group } = useURLQuery();
  const groupname = UI[currentGroupnameId];


  const onClick = () => {
    dispatch(
      UIactions.openModal2({
        type: "w365-share",
        payload: {
          groupname,
          payload: {
            domain,
            id: own.id,
            group_id: group,
            prevent_propagation: !own.prevent_propagation,
            group_locked: own.group_locked,
          },
        },
      })
    );
  };

  return (
    <ToggleButton
      {...{
        disabled:working,
        id: w365shareUI,
        UIText,
        onClick,
        startIcon: <AccountTree sx={{ marginRight: 1, fontSize: 20 }} />,
        color: "primary",
        variant: "outlined",
      }}
    />
  );
};

export default Share;
