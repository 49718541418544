import { useSelector } from "react-redux";

export const useWorkerGroup = (word) => {
  const workers = useSelector((state) => state.workers.workers);

  let done = false;
  let working = false;
  let errors = [];

  let first = true;
  for (const key in workers) {
    if (!!key.match("root")) {
      const { done: d, working: w, error: e } = workers[key];
      if (first) {
        done = d;
        first = false;
      } else {
        done = done && d;
      }
      working = working || w;
      if (e) {
        errors.push(e);
      }
    }
  }
  return { done, working, errors };
};
