import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import ConfigureDevicesConfirmation from "../ConfirmationModals/ConfigureDevicesConfirmation";
import CreateUsersConfirmation from "../ConfirmationModals/CreateUsersConfirmation";
import DeleteDevicesConfirmation from "../ConfirmationModals/DeleteDevicesConfirmation";
import DeleteGroupConfirmation from "../ConfirmationModals/DeleteGroupConfirmation";
import DeleteSharedLinksConfirmation from "../ConfirmationModals/DeleteSharedLinkConfirmation";
import DeleteUsersConfirmation from "../ConfirmationModals/DeleteUsersConfirmation";
import FileDataPop from "../FilesDataPop/FilesDataPop";
import GroupsTravelerModal from "../GroupsTravelerModal/GroupsTravelerModal";
import ClearW365Org from "../OrgCloudBackup/Components/Windows365/ClearW365Org/ClearW365Org";
import W365GroupLockToggle from "../OrgCloudBackup/Components/Windows365/ToggleGroupLock/ToggleGroupLock";
import W365SharingToggle from "../OrgCloudBackup/Components/Windows365/ToggleSharing/ToggleSharing";
import Windows365OrgEnable from "../Windows365OrgEnable/Windows365OrgEnable";

const SecondaryModalsOutlet = () => {
  const { UI } = useUIBoilerplate();
  const modal = UI.modal2 ?? { type: "" };
  const { type: mType, open } = modal;

  if (open) {
    switch (mType) {
      case "w365-org-delete":
        return <ClearW365Org />;
      case "groups-traveler":
        return <GroupsTravelerModal />
      case "w365-fetch":
        return <W365GroupLockToggle />;
      case "w365-share":
        return <W365SharingToggle />;
      case "file-details":
        return <FileDataPop {...{ modal: "2" }} />;
      case "get-w365code":
        return <Windows365OrgEnable />;
      case "delete-shared-links-confirmation":
        return <DeleteSharedLinksConfirmation />;
      case "delete-devices-confirmation":
        return <DeleteDevicesConfirmation />;
      case "delete-users-confirmation":
        return <DeleteUsersConfirmation />;
      case "delete-group-confirmation":
        return <DeleteGroupConfirmation />;
      case "confirm-devices-edit":
        return <ConfigureDevicesConfirmation />;
      case "create-users-confirmation":
        return <CreateUsersConfirmation />;
      case "post-users-confirmation":
        return <CreateUsersConfirmation />;
      default:
        console.error("modal type does not match with any known modal");
        return;
    }
  }
  return;
};
export default SecondaryModalsOutlet;
