import { Grid } from "@mui/material";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { getGroupsListId, selectedGroupIdx } from "../../../../store/actions2/GroupTraveler/loadGroupsTree";
import { useDispatch } from "react-redux";
import { UISliceActions as UIactions} from "../../../../store/slices/UI2";
import Switch from "../../../UI2/Switch2";

const UIText = {
  "es-CL": { label: "Cambiar grupo" },
  "en-US": { label: "Change group" },
};

export const changeGroupUI = "changeGroup";

const ChangeGroup = () => {
  const dispatch = useDispatch();

  const onChange = () => {
    dispatch(UIactions.clear(selectedGroupIdx))
    dispatch(workers.clear(getGroupsListId));
  };

  return (
    <Grid container item xs={12} alignItems="center" columnGap={2}>
      <Switch
        labelRight
        {...{
          id: changeGroupUI,
          UIText,
          onChange,
          labelProps: {
            fontSize: "1.1rem",
            fontWeight: "bold",
          },
          xs: "auto",
        }}
      />
    </Grid>
  );
};

export default ChangeGroup;
