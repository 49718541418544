import {  Dialog, Grid } from "@mui/material";
import BasicDialogTitle from "../../../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../../../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../../../UI2/BasicDialog/BasicDialogActions";
import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import Divider from "../../../UI2/Divider";
import Details from "./Components/Details";
import Options, {
  recoveryDateUI,
  recoveryOptionUI,
} from "./Components/Options";
import Close from "./Components/Close";
import User from "./Components/User";
import DeviceName from "./Components/DeviceName";
import Save from "./Components/Save";
import { useEffect } from "react";

const UIText = {
  "es-CL": "Agregar dispositivo a la solicitud",
  "en-US": "Add devices to request",
};

const EditDevice = () => {
  const { dispatch, settings, UI } = useUIBoilerplate();
  const header = UIText[settings.locale];
  const { payload } = UI.modal ?? {};
  const { user, device } = payload ?? {};

  console.log("payload", payload);

  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(recoveryOptionUI));
      dispatch(UIactions.clear(recoveryDateUI));
    };
  }, [dispatch]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog fullWidth {...{ open: true, onClose, maxWidth: "sm" }}>
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid
          container
          item
          {...{ rowGap: 2, paddingTop: 2, sx: { width: "100%" } }}
        >
          <User {...{ user }} />
          <DeviceName {...{ device }} />
          <Details {...payload} />
          <Divider {...{ xs: 12 }} />
          <Options {...payload} />
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Close />
        <Save {...payload} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default EditDevice;
