import axios from "axios";
import { enqueueSnackbar } from "notistack";
import {
  workersSliceActions as worker,
  workersSliceActions,
} from "../../slices/workers";
import { getW365OrgId, w365orgData } from "./getOrganization";
import { UISliceActions } from "../../slices/UI2";
import { w365switchUI } from "../../../components/OrgCloudBackup/Components/Windows365/Windows365";
import { warehouseSliceActions } from "../../slices/warehouse";

export const UIText = {
  "es-CL": {
    success: "Organizacion de office 365 eliminada exitosamente",
    error: "Error al intentar eliminar organizacion de Office 365: ",
  },
  "en-US": {
    success: "Office 365 organization deleted successfully",
    error: "Error deleting Office 365 organization: ",
  },
};
export const deleteOrgId = "deleteOrganization";

export const deleteOrganization = (data) => {
  const id = deleteOrgId;
  const { W365server, cloner_key, orgId, locale } = data;
  const { success, error } = UIText[locale];

  return async (dispatch) => {
    dispatch(worker.start(id));
    try {
      const url = `${W365server}mailapi/organization/${orgId}`;
      await axios.delete(url, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(workersSliceActions.clear(getW365OrgId));
      dispatch(UISliceActions.clear(w365switchUI))
      dispatch(warehouseSliceActions.unload(w365orgData))
    } catch (e) {
      const messsage = `${error} ${e.message}`;
      enqueueSnackbar(messsage, { variant: "error" });
      dispatch(worker.finish({ id, error: e.message }));
    }
  };
};
