
const headersData = {
  headers: [
    {
      UIText: {
        "es-CL": { label: "Usuario" },
        "en-US": { label: "User" },
      },
      filterKey: "user",
      xs: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Dispositivo" },
        "en-US": { label: "Device" },
      },
      filterKey: "device",
      xs: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Repositorios" },
        "en-US": { label: "Repository" },
      },
      filterKey: "full_size",
      xs: 3,
    },
    {
      UIText: {
        "es-CL": { label: "Tamaño" },
        "en-US": { label: "Size" },
      },
      filterKey: "full_size",
      xs: 1.5,
    },
    {
      UIText: {
        "es-CL": { label: "Mas" },
        "en-US": { label: "More" },
      },
      filterKey: "",
      xs: true,
      justify: "end",
    },
  ],
};

export default headersData;
