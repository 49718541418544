import { Collapse, Grid } from "@mui/material";
import { Fragment, useEffect } from "react";
import Expand from "./Expand";
import Name from "./Name";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { useWorker } from "../../../../hooks2/useWorker";
import useTrees from "../../../../hooks2/useNewTrees";
import { workersSliceActions as workers } from "../../../../store/slices/workers";
import { sortObjByKey } from "../../../../utility/sortObjByKey";

const Node = (props) => {
  const { id, repository, level, expanded, childrenIdx } = props;
  const { auth, dispatch, UI, settings } = useUIBoilerplate();
  const { filesViewToggles = [] } = UI;
  const { working } = useWorker("node-" + id);
  const nodes = useTrees()['repo-'+repository].nodes;
  const deleted = filesViewToggles.find((x) => x === "deleted");
  const locale = settings.locale;

  useEffect(() => {
    return () => {
      dispatch(workers.clear(id));
    };
  }, [dispatch, id]);

  let protoItems = [];
  for (const idx of childrenIdx) {
    protoItems.push(nodes[idx]);
  }

  const itemsData = protoItems
    .filter(
      (x) => (!!deleted || !x.deleted) && x.id !== "" && x.name !== "[Subir]"
    )
    .sort((a, b) => sortObjByKey(a, b, "name"));

  const items = itemsData.map((data) => (
    <Node key={data.id} {...data} level={level + 1} repository={repository} />
  ));

  return (
    <Fragment>
      <Grid
        container
        item
        {...{
          xs: 12,
          sx: { fontSize: 13 },
          wrap: "nowrap",
          alignItems: "center",
          paddingLeft: level * 1,
        }}
      >
        <Expand {...props} {...{ noChildren: !items.length, auth, locale }} />
        <Name {...props} {...{ working }} />
      </Grid>
      <Collapse in={expanded} sx={{width:'100%'}} unmountOnExit>
      {items}
      </Collapse>
    </Fragment>
  );
};

export default Node;
