import { TextField } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../store/slices/UI2";
import timeToMinutes from "../../utility/timeToMinutes";
import minutesToTime from "../../utility/minutesToTime";
import AutoGrid from "./Wrappers/AutoGrid";

const TimePicker = ({
  id = "",
  min = "",
  max = "",
  onChange = () => {},
  size = "small",
  UIText = {},
  xl,
  lg,
  md,
  sm,
  xs,
  forceError,
}) => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const { value: timeInMinutes, error } = UI[id] ?? {};
  const { label } = UIText[settings.locale] ?? {};

  const UITextErrors = {
    "es-CL": {
      min: `El valor elegido (${minutesToTime(timeInMinutes)}) es menor al mínimo permitido (${min})`,
      max: `El valor elegido (${minutesToTime(timeInMinutes)}) es mayor al máximo permitido (${max})`,
    },
    "en-US": {
        min: `The chosen value (${minutesToTime(timeInMinutes)}) is less than the minimum allowed (${min})`,
        max: `The chosen value (${minutesToTime(timeInMinutes)}) is greater than the maximum allowed (${max})`,
    },
  };
  const { min: minErr, max: maxErr } = UITextErrors[settings.locale] ?? {};

  const handleChange = (e) => {
    const value = timeToMinutes(e.target.value);
    dispatch(UISliceActions.setField({ id, value }));
    const minMins = timeToMinutes(min);
    const maxMins = timeToMinutes(max);
    if (min && value < minMins) {
      dispatch(UISliceActions.setFieldError({ id, value: minErr }));
    } else if (max && value > maxMins) {
      dispatch(UISliceActions.setFieldError({ id, value: maxErr }));
    }
    onChange(e);
  };

  const value = minutesToTime(timeInMinutes);
  const tfError = forceError ?? !!error;

  return (
    <AutoGrid {...{ xl, lg, md, sm, xs }}>
      <TextField
        {...{
          label,
          fullWidth: xl || lg || md || sm || xs ? true : false,
          onChange: handleChange,
          type: "time",
          value,
          size,
          InputLabelProps: { shrink: true },
          inputProps: { style: { textAlign: "center" } },
          error: tfError,
        }}
      />
    </AutoGrid>
  );
};

export default TimePicker;
