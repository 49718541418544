import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { workersSliceActions as worker } from "../../slices/workers";
import { getW365OrgId } from "./getOrganization";

export const updateOrgId = "updateOrganization";

export const UIText1 = {
  "es-CL": {
    success: "La organización de Windows365 ahora está siendo obtenida del grupo padre",
    error: "No se pudo habilitar la obtención de la organizacion de Windows365: ",
  },
  "en-US": {
    success: "The Windows365 organization is being fetched from the parent group",
    error: "Windows365 organization couldn't be fetched from parent group: ",
  },
};
export const UIText2 = {
  "es-CL": {
    success: "Se dejó de obtener la organización de Windows365 del grupo padre",
    error: "No se pudo dejar de obtener la organizacion de Windows365 del grupo padre: ",
  },
  "en-US": {
    success: "Windows365 organization fetching was stopped",
    error: "Windows365 organization fetching couln't be stopped: ",
  },
};

const changeW365GroupLock = (data) => {
  const id = updateOrgId;
  return async (dispatch) => {
    const { W365server, cloner_key, locale, payload } = data;
    // let UIText
    // if (mode === "propagate") {
    const UIText = payload.group_lock ? UIText2 : UIText1;
    // } else if (mode ==='lock'){
    //   UIText = payload.prevent_propagation ? UIText4 : UIText3;
    // }

    const { success, error } = UIText[locale];
    const bodyData = JSON.stringify(payload);
    dispatch(worker.start(id));
    try {
      const url = W365server + "mailapi/organization";
      console.log("URL", url);
      console.log("bodyData", bodyData);
      await axios.put(url, bodyData, { headers: { cloner_key } });

      enqueueSnackbar(success, { variant: "success" });
      dispatch(worker.finish({ id }));
      dispatch(worker.clear(getW365OrgId));
    } catch (e) {
      enqueueSnackbar(error + e.message, { variant: "error" });
      dispatch(worker.finish({ id, error }));
    }
  };
};

export default changeW365GroupLock;
