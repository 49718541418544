import axios from "axios";
import { workersSliceActions as workers } from "../../slices/workers";
import { parseMailFolders } from "./utils";
import { newTreesSliceActions as trees } from "../../slices/trees2";

export const getMailCategoriesId = "mailCategories";

const getMailCategories = (data) => {
  const id = getMailCategoriesId;
  const { W365server, cloner_key } = data;

  return async (dispatch) => {
    dispatch(workers.start(id));
    try {
      const url = W365server + "mailapi/folder";
      const response = await axios.get(url, { headers: { cloner_key } });

      for (const cat of response.data) {
        let nodes = [];
        parseMailFolders(cat, nodes, null);
        dispatch(trees.loadTree({ id: `mails-${nodes[0].id}`, tree: nodes }));
      }

      // if (folders.data) {
      //   dispatch(warehouseSliceActions.load({ id, data: folders.data }));
      // } else {
      //   dispatch(warehouseSliceActions.unload(id));
      // }
      dispatch(workers.finish({ id }));
    } catch (e) {
      console.error(e)
      dispatch(workers.finish({ id, error:e.message }));
    }
  };
};

export default getMailCategories;

// "AAMkADVhODk0NmQ0LTMxYzUtNGIzYy05Mzg0LWUwMTllMjQ0M2U1MQAuAAAAAAB5Xw8WrtozSb6xzGgc8TjGAQDB2T0ql-wnToeItTnOfd_FAAABiNgUAAA="
// "AQMkADVhODk0NmQ0LTMxYzUtNGIzYy05Mzg0LWUwMTllMjQ0ADNlNTEALgAAA3lfDxau2jNJvrHMaBzxOMYBAMHZPSqX-CdOh4i1Oc5934UAAAIBDwAAAA=="
