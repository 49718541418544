import xlsx from "json-as-xlsx";
import {
  humanBytes,
} from "../../../utility/humanizers";

const UIText = {
  "es-CL": {
    filename: "Data de archivos",
    sheet1: "Archivos",
    nameCol: "Nombre",
    sizeCol: "Tamaño",
    repoIdCol: "repoId",
    metafileIdCol: "metafileId",
  },
  "en-US": {
    filename: "Files data",
    sheet1: "Files",
    nameCol: "Name",
    sizeCol: "Size",
    repoIdCol: "repoId",
    metafileIdCol: "metafileId",
  },
};

const exportFiles = (data) => {
  const { allFiles, locale } = data;
  return async (dispatch) => {

    const text = UIText[locale];

    const columns = [
      { label: text.sizeCol, value: (row) => humanBytes(row.size) }, // Custom format
      { label: text.nameCol, value: "name" }, // Top level data
      { label: text.repoIdCol, value: "repo" }, // Top level data
      { label: text.metafileIdCol, value: "id" }, // Top level data
    ];

    let data = [
      {
        sheet: text.sheet1,
        columns,
        content: allFiles,
      },
    ];

    let settings = {
      fileName: text.filename, // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      // writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      // writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      // RTL: true, // Display the columns from right-to-left (the default value is false)
    };

    xlsx(data, settings); // Will download the excel file
  };
};

export default exportFiles;
