import { Collapse, Grid } from "@mui/material";
import TextField from "../../UI2/TextField";
import Button from "../../UI2/Button";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import VerificationCode from "./VerificationCode";
import {
  sendCodeId,
  sendVerificationCode,
} from "../../../store/actions2/Windows365/sendVerificationCode";
import { useWorker } from "../../../hooks2/useWorker";

const UIText1 = {
  "es-CL": {
    label: "ID de aplicación",
    placeholder: "01234567-89ab-cdef-0123-456789abcdef",
  },
  "en-US": {
    label: "App Id",
    placeholder: "01234567-89ab-cdef-0123-456789abcdef",
  },
};
const UIText2 = {
  "es-CL": {
    label: "ID de inquilino",
    placeholder: "01234567-89ab-cdef-0123-456789abcdef",
  },
  "en-US": {
    label: "inquilino Id",
    placeholder: "01234567-89ab-cdef-0123-456789abcdef",
  },
};
const UIText3 = {
  "es-CL": {
    label: "Llave secreta",
    placeholder: "6MuoipfXjcugfGdupUVkdWJDm64qkNLvobh3h",
  },
  "en-US": {
    label: "Secret key",
    placeholder: "6MuoipfXjcugfGdupUVkdWJDm64qkNLvobh3h",
  },
};
const UIText4 = {
  "es-CL": {
    label: "Correo Microsoft",
    placeholder: "usuario@cloner.onmicrosoft.com",
  },
  "en-US": {
    label: "Microsoft email",
    placeholder: "user@cloner.onmicrosoft.com",
  },
};
const UIText5 = {
  "es-CL": { label: "Enviar codigo de verificación" },
  "en-US": { label: "Send verification code" },
};

export const appIdUI = "appId";
export const clientIdUI = "clientId";
export const secretKeyUI = "secretKey";
export const emailUI = "orgEmail";
export const validationCodeUI = "validationCode";

const Form = () => {
  const { auth, dispatch, settings, UI } = useUIBoilerplate();
  const { working } = useWorker(sendCodeId);
  const { value: mail } = UI[emailUI] ?? {};

  const onClick = () => {
    dispatch(
      sendVerificationCode({
        ...auth,
        mail,
        locale: settings.locale,
      })
    );
  };

  return (
    <Collapse in={true}>
      <Grid
        container
        item
        {...{ rowGap: 2, columnSpacing: 2, sx: { paddingTop: 0.5 } }}
      >
        <TextField {...{ id: appIdUI, UIText: UIText1, xs: 12 }} />
        <TextField {...{ id: clientIdUI, UIText: UIText2, xs: 12 }} />
        <TextField {...{ id: secretKeyUI, UIText: UIText3, xs: 12 }} />
        <TextField {...{ id: emailUI, UIText: UIText4, xs: 6 }} />
        <Button
          {...{
            disabled: !mail,
            UIText: UIText5,
            onClick,
            working,
            xs: 6,
            variant: "outlined",
          }}
        />
        <VerificationCode />
      </Grid>
    </Collapse>
  );
};

export default Form;
