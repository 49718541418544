import { useEffect } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import getDeviceConfig, {
  configDataId,
  getConfigId,
} from "../../store/actions2/Devices/getConfig";
import { Dialog } from "@mui/material";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import Form from "./Components/Form/Form";
import Cancel from "./Components/Cancel";
import { useWorker } from "../../hooks2/useWorker";
import { workersSliceActions as workers } from "../../store/slices/workers";
import { deviceNameUI } from "./Components/Form/Fields/NewName";
import { intervalUI } from "./Components/Form/Fields/Interval";
import { suspendedUI } from "./Components/Form/Fields/Suspended";
import { stealthModeUI } from "./Components/Form/Fields/StealthMode";
import {
  bandwidthLimitUI,
  throttleActiveUI,
} from "./Components/Form/Fields/Bandwidth";
import {
  multiUploadActiveUI,
  multiUploadUI,
} from "./Components/Form/Fields/Multiupload";
import { cpasswordActiveUI } from "./Components/Form/Fields/SelectionBlock";
import { postDeviceId } from "../../store/actions2/Devices/postDeviceInfo";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Save from "./Components/Save";

const UIText = {
  "es-CL": {
    preheader: "Configurar dispositivo ",
    multiheader: "Configurar dispositivos ",
  },
  "en-US": {
    preheader: "Configure device ",
    multiheader: "Configure devices ",
  },
};

const DeviceEdit = () => {
  const { auth, dispatch, settings, UI, warehouse } = useUIBoilerplate();
  const { done } = useWorker(getConfigId);
  const { done: postDone, error: postError } = useWorker(postDeviceId);
  const { payload } = UI.modal;
  const { devices = [] } = payload
  const deviceName = devices.length>0?devices[0].name:''
  const locale = settings.locale;
  const config = warehouse[configDataId] ?? {};

  const { preheader, multiheader } = UIText[locale] ?? "";
  const header =
    devices.length > 1
      ? multiheader + `(${devices.length})`
      : preheader + deviceName;

  useEffect(() => {
    if (devices.length === 1) {
      const controller = new AbortController();
      dispatch(
        getDeviceConfig({
          ...auth,
          controller,
          payload: {
            user: devices[0].user,
            machine: devices[0].mid,
            token: auth.token,
          },
        })
      );
      return ()=>{
        controller.abort()
      }
    }
  }, [dispatch, devices, auth]);

  useEffect(() => {
    const config = warehouse[configDataId] ?? {};
    if (done) {
      dispatch(UIactions.setField({ id: deviceNameUI, value: deviceName }));
      dispatch(
        UIactions.setValue({ id: intervalUI, value: config.scan_interval })
      );
      dispatch(
        UIactions.setValue({ id: suspendedUI, value: !!config.service_status })
      );
      dispatch(
        UIactions.setValue({ id: stealthModeUI, value: config.stealth_mode })
      );
      dispatch(
        UIactions.setValue({ id: throttleActiveUI, value: config.throttling })
      );
      dispatch(
        UIactions.setValue({
          id: bandwidthLimitUI,
          value: config.network_packet_size,
        })
      );
      dispatch(
        UIactions.setValue({
          id: multiUploadActiveUI,
          value: config.concurrent_uploads > 1,
        })
      );
      dispatch(
        UIactions.setValue({
          id: multiUploadUI,
          value: config.concurrent_uploads > 1 ? config.concurrent_uploads : 1,
        })
      );
      dispatch(
        UIactions.setValue({ id: cpasswordActiveUI, value: !!config.cpassword })
      );
    }
  }, [dispatch, done, warehouse, deviceName]);

  useEffect(() => {
    return () => {
      dispatch(workers.clear(getConfigId));
      dispatch(workers.clear(postDeviceId));
      dispatch(UIactions.clear(deviceNameUI));
      dispatch(UIactions.clear(intervalUI));
      dispatch(UIactions.clear(suspendedUI));
      dispatch(UIactions.clear(stealthModeUI));
      dispatch(UIactions.clear(throttleActiveUI));
      dispatch(UIactions.clear(bandwidthLimitUI));
      dispatch(UIactions.clear(multiUploadActiveUI));
      dispatch(UIactions.clear(multiUploadUI));
      dispatch(UIactions.clear(cpasswordActiveUI));
    };
  }, [dispatch]);

  useEffect(() => {
    if (postDone && !postError) {
      dispatch(UIactions.closeModal());
    }
  }, [dispatch, postDone, postError]);

  const onClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    }
  };

  return (
    <Dialog
      {...{
        open: true,
        onClose,
        fullWidth: true,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Form {...{ locale, multi: devices.length > 1 }} />
      </BasicDialogContent>
      <BasicDialogActions>
        <Cancel {...{ locale }} />
        <Save {...{ config, devices }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default DeviceEdit;
