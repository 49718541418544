import { ExitToApp } from "@mui/icons-material";
import Button from "../../../../UI2/Button";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

const UIText = {
  "es-CL": { label: "Regresar" },
  "en-US": { label: "Return" },
};

const Return = () => {
  const { auth } = useUIBoilerplate();

  return (
    <Button
      {...{
        UIText,
        navTo: `/dashboard/reports?group=${auth.group}`,
        startIcon: <ExitToApp />,
      }}
    />
  );
};

export default Return;
