import { Grid } from "@mui/material";
import TextField from "../../UI2/TextField";
import SlimAlert from "../../UI2/SlimAlert";
import { notEmptyName } from "../../../utility/validations/notEmptyName";
import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";

const UIText1 = {
  "es-CL": { label: "Usuario" },
  "en-US": { label: "User" },
};

const UIText2 = {
  "es-CL": { label: "Nombre" },
  "en-US": { label: "Name" },
};

export const userUI = "userlogin";
export const usernameUI = "username";

const User = () => {
  const { UI } = useUIBoilerplate();
  const { error } = UI[usernameUI] ?? {};

  return (
    <Grid container item xs={12} columnSpacing={2} rowGap={2}>
      <TextField disabled {...{ id: userUI, UIText: UIText1, sm: 6, xs: 12 }} />
      <TextField
        {...{
          id: usernameUI,
          UIText: UIText2,
          validation: notEmptyName,
          sm: 6,
          xs: 12,
        }}
      />
      <SlimAlert
        {...{
          messages: [error],
          xs: 12,
          sx: { paddingLeft: 2},
        }}
      />
    </Grid>
  );
};

export default User;
