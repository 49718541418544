import { UISliceActions as UIactions } from "../../../../store/slices/UI2";
import Button from "../../../UI2/Button";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { contactEmailUI, detailsUI, requirementsUI, supportFocusUI } from "../Content/Content";

const UIText = {
  "es-CL": { label: "Borrar" },
  "en-US": { label: "Clear" },
};

const Clear = () => {
  const {auth,dispatch} = useUIBoilerplate();

  const onClick = () => {
    dispatch(UIactions.setField({ id: contactEmailUI, value: auth.user }));
    dispatch(UIactions.clear(requirementsUI));
    dispatch(UIactions.clear(supportFocusUI));
    dispatch(UIactions.clear(detailsUI));
  };

  return <Button {...{ UIText, onClick, variant: "text" }} />;
};

export default Clear;
