/**
 * This component renders a set of actions for a device row in a table.
 * It includes buttons for recovering, excluding, configuring, viewing details, and deleting a device.
 * The component receives the following props:
 * @param {string} mid - The device ID.
 * @param {string} user - The user associated with the device.
 * @param {string} name - The name of the device.
 * @param {string} last_bkp - The date of the last backup for the device.
 * @param {number} space_active - The amount of active space on the device.
 * @returns {JSX.Element} - A MoreActions component with the set of device actions.
 */
import MoreActions from "../../../../UI2/BasicLayout/Components/MoreActions/MoreActions";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import {
  Block,
  Delete,
  Description,
  List,
  // LocalShipping,
  Settings,
} from "@mui/icons-material";
import { warehouseSliceActions as warehouse } from "../../../../../store/slices/warehouse";
import { devicesDataId } from "../../../../../store/actions2/Devices/getAllDevices";
import {
  currentExclusionDevicesUI,
  exclusionDevicesUI,
} from "../../../../Exclusions/Components/DevicesList/Headers2";
// import useRecoveries from "../../../../../hooks2/useRecoveries";
// import addRecovery from "../../../../../store/actions2/Recoveries/addRecovery";
// import { recoveriesSliceActions as recoveries } from "../../../../../store/slices/recoveries";
import { exclusionDevicesData } from "../../../../Exclusions/Components/DevicesList/DevicesList";

const Actions = (props) => {
  const { mid, user /*, name, last_bkp, space_active*/ } = props;
  const {
    auth,
    dispatch,
    // navigate,
    warehouse: Warehouse,
    // settings,
  } = useUIBoilerplate();
  // const locale = settings.locale;
  // const requestData = useRecoveries();
  // const { initialized } = requestData;
  const devices = Warehouse[devicesDataId];

  let data = {
    id: mid,
    buttonsData: [
      // {
      //   icon: <LocalShipping sx={{ color: "grey" }} />,
      //   UIText: {
      //     "es-CL": { label: "Recuperar" },
      //     "en-US": { label: "Recovery" },
      //   },
      //   onClick: () => {
      //     const newRecovery = {
      //       ...auth,
      //       user,
      //       mid,
      //       device: name,
      //       fullSize: space_active,
      //       lastBkp: last_bkp,
      //       locale,
      //     };
      //     if (!initialized) {
      //       dispatch(addRecovery(newRecovery));
      //       dispatch(recoveries.dontAskAgain());
      //       navigate("/dashboard/recovery/step1");
      //     } else {
      //       dispatch(
      //         UIactions.openModal({
      //           type: "new-device-new-request",
      //           payload: { newRecovery },
      //         })
      //       );
      //     }
      //   },
      // },
      {
        icon: <Block sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Exclusiones" },
          "en-US": { label: "Exclusions" },
        },
        navTo: "/dashboard/devices/exclusions",
        onClick: () => {
          let newObj = {};
          newObj[mid] = devices[mid];
          dispatch(
            UIactions.setValue({ id: exclusionDevicesUI, value: [mid] })
          );
          dispatch(
            UIactions.setValue({
              id: currentExclusionDevicesUI,
              value: [mid],
            })
          );
          dispatch(
            warehouse.load({ id: exclusionDevicesData, data: newObj })
          );
          // dispatch(
          //   warehouse.load({ id: "singleExclusionUI", data: devices[mid] })
          // );
        },
      },
      {
        icon: <Settings sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Configurar" },
          "en-US": { label: "Configure" },
        },
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-edit",
              payload: { devices: [props] },
            })
          );
        },
      },
      {
        icon: <List sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Ver detalles" },
          "en-US": { label: "View details" },
        },
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-details",
              payload: { user, mid },
            })
          );
        },
      },
      {
        icon: <Delete sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Eliminar" },
          "en-US": { label: "Delete" },
        },
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "delete-devices",
              payload: { devices: [props] },
            })
          );
        },
      },
    ],
  };

  if (auth.group === 1) {
    data.buttonsData = [
      {
        icon: <Description sx={{ color: "grey" }} />,
        UIText: {
          "es-CL": { label: "Data" },
          "en-US": { label: "Data" },
        },
        onClick: () => {
          dispatch(
            UIactions.openModal({
              type: "device-details-support",
              payload: { data: props },
            })
          );
        },
      },
      {
        divider: true,
      },
      ...data.buttonsData,
    ];
  }

  return <MoreActions {...data} />;
};

export default Actions;
