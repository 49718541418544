import { Typography } from "@mui/material";
import { humanBytes } from "../../../../../utility/humanizers";

const Size = ({ fontSize, size }) => {
  return (
      <Typography {...{ fontSize, sx: { cursor: "default" } }}>
        {humanBytes(size)}
      </Typography>
  );
};

export default Size;
