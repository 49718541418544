export const selectedUsersUI = "selectedUsers";

const headersData = {
    headers:[
        {
            UIText:{
                'es-CL':{label:'Admin'},
                'en-US':{label:'Admin'}},
            justify:'center',
            xs:0.7,
        },
        {
            UIText:{
                'es-CL':{label:'Usuario'},
                'en-US':{label:'User'}},
            filterKey:'login',
            xs:3.5,
        },
        {
            UIText:{
                'es-CL':{label:'Nombre'},
                'en-US':{label:'Name'}},
            filterKey:'name',
            xs:3.2,
        },
        {
            UIText:{
                'es-CL':{label:'Dispositivos'},
                'en-US':{label:'Devices'}},
            filterKey:'machines',
            xs:1.5,
            justify:'center',
        },
        {
            UIText:{
                'es-CL':{label:'Conectados'},
                'en-US':{label:'Connected'}},
            filterKey:'connected',
            xs:1.5,
            justify:'center',
        },
        {
            UIText:{
                'es-CL':{label:'Mas'},
                'en-US':{label:'More'}},
            xs:true,
            justify:'end',
        },
    ]

}


export default headersData