import axios from "axios";
import { warehouseSliceActions as warehouse } from "../../slices/warehouse";
import { workersSliceActions as workers } from "../../slices/workers";
import { devicesLoadedId } from "../../../components/Devices/Devices";
import { createMachineData } from "./utils";
import { devicesDataId } from "./getAllDevices";

const getRootDevices = (data) => {
  console.log("getting root devices!");
  return async (dispatch) => {
    const { cloner_key, server, group, includeEmpty, controller } = data;
    const id = devicesLoadedId;

    dispatch(workers.start(id));
    const getDevicesController = new AbortController();
    try {
      const url = server + "api/groups";
      const response = await axios.get(url, {
        signal: controller.signal,
        params: { group },
        headers: { cloner_key },
      });
      const data = response.data;

      for (const user of data.users) {
        if (user.machines) {
          const url = server + "api/machines";
          const response = await axios.get(url, {
            signal: controller.signal,
            headers: { cloner_key },
            params: { user: user.login },
          });
          for (const machine of response.data.machines_info) {
            const newDevice = createMachineData(user.login, 0, machine);
            dispatch(
              warehouse.add({
                id: devicesDataId,
                key: newDevice.mid,
                data: newDevice,
              })
            );
          }
        } else if (includeEmpty) {
          dispatch(
            warehouse.add({
              id: devicesDataId,
              key: user.login,
              data: {
                bandwidth: 0,
                connected: false,
                croppedVersion: "",
                // group: 2,
                lastBkp: 0,
                lastChanges: 0,
                lastConnected: 0,
                lastConnectedAdjusted: 0,
                mid: "",
                name: "",
                space_active: 0,
                status: 0,
                user: user.login,
                version: "",
              },
            })
          );
        }
      }

      dispatch(workers.finish({ id }));
    } catch (e) {
      getDevicesController.abort();
      if (e.code === "ERR_CANCELED") {
        return;
      }
      console.error(e);
      dispatch(workers.finish({ id, error: e.message }));
      getDevicesController.abort();
    }
  };
};

export default getRootDevices;
