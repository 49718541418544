import { useEffect, useMemo } from "react";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { useWorker } from "../../hooks2/useWorker";
import getAllDevices from "../../store/actions2/Devices/getAllDevices";
import getRootDevices from "../../store/actions2/Devices/getRootDevices";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import { warehouseSliceActions as warehouseActions } from "../../store/slices/warehouse";
import { workersSliceActions as workers } from "../../store/slices/workers";
import Core from "./Components/Core";
import { recursiveRequestId } from "./Components/Actions/Recursive";
import { sortObjByKey } from "../../utility/sortObjByKey";
import { containsEscaped } from "../../utility/filter";
import CalculatePagination from "../UI2/Utilities/CalculatePagination";
import {
  defaultFilter,
  defaultPagination,
  defaultPreFilter,
  defaultSelected,
  defaultSort,
} from "../UI2/genericIDs";
import { addRecoveriesUI } from "../../store/actions2/Recoveries/addRecoveries";
import useURLQuery from "../../hooks2/useURLQuery";

export const devicesLoadedId = "devicesLoaded";
export const devicesData = "devices";
export const currentDevicesId = "currentDevices";

const Devices = () => {
  const { auth, dispatch, navigate, UI, warehouse } = useUIBoilerplate();
  const { group } = useURLQuery();
  const workerId = devicesLoadedId;
  const { done, working, error } = useWorker(workerId);
  const prefilter = UI[defaultPreFilter];
  const recursive = UI[recursiveRequestId];
  const filter = UI.filter ?? "";
  const sort = UI.sort ?? { value: "user" };
  const pagination = UI.pagination ?? {};

  // If user is not an admin move him to his files
  useEffect(() => {
    if (auth.admin !== auth.group) {
      const url = "/dashboard/files?user=" + encodeURIComponent(auth.user);
      navigate(url, { replace: true });
    } else if (!group && auth.admin) {
      navigate("/dashboard/devices?group=" + auth.group, { replace: true });
    }
  }, [navigate, auth, group]);

  useEffect(() => {
    if (prefilter) {
      dispatch(UIactions.setValue({ id: defaultFilter, value: prefilter }));
      dispatch(UIactions.clear(defaultPreFilter));
    }
  }, [dispatch, prefilter]);

  // Getting devices data
  useEffect(() => {
    if (!done && !prefilter) {
      const controller = new AbortController();
      dispatch(warehouseActions.unload(devicesData));
      if (!recursive) {
        dispatch(getRootDevices({ ...auth, group, controller }));
      } else {
        dispatch(getAllDevices({ ...auth, group, controller }));
      }
      return () => {
        controller.abort();
      };
    }
  }, [dispatch, auth, group, recursive, done, prefilter]);

  // Raw devices data
  const devices = useMemo(() => {
    const rawData = warehouse[devicesData];
    let devices = [];
    for (const key in rawData) {
      devices.push(rawData[key]);
    }
    return devices;
  }, [warehouse]);

  // filtering devices
  const filteredDevices = useMemo(() => {
    return devices
      .filter(
        (dev) =>
          containsEscaped(dev.user, filter) || containsEscaped(dev.name, filter)
      )
      .sort((a, b) => sortObjByKey(a, b, sort.value, sort.descending));
  }, [devices, filter, sort.value, sort.descending]);

  // Array to select all
  const allDevices = useMemo(() => {
    return filteredDevices.map((x) => x.mid);
  }, [filteredDevices]);

  // Initializing pagination
  useEffect(() => {
    dispatch(UIactions.setPagination({ count: filteredDevices.length }));
  }, [dispatch, filteredDevices.length]);

  // Trimming devices to only current page ones
  const { from, to } = CalculatePagination(pagination);
  const paginatedDevices = useMemo(() => {
    return filteredDevices.filter((x, idx) => from <= idx + 1 && idx < to);
  }, [from, to, filteredDevices]);

  // Garbage collection
  useEffect(() => {
    return () => {
      dispatch(UIactions.clear(currentDevicesId));
      dispatch(UIactions.clear(defaultFilter));
      dispatch(UIactions.clear(defaultSelected));
      dispatch(UIactions.clear(defaultSort));
      dispatch(UIactions.clear(defaultPagination));
      dispatch(warehouseActions.unload(devicesData));
      dispatch(workers.clear(devicesLoadedId));
      dispatch(workers.clear(addRecoveriesUI));
    };
  }, [dispatch]);

  return (
    <Core
      {...{ devices: paginatedDevices, allDevices, done, working, error }}
    />
  );
};

export default Devices;
