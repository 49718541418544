import { Divider, Grid } from "@mui/material";
import DeleteUser from "./Delete";
import Recover from "./Recover";
import RecursiveSwitch from "./Recursive";
import EditUsers from "./Edit";
import Create from "./Create";
import CloudIntegrations from "./CloudIntegrations";

const Actions = () => {
  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      <RecursiveSwitch />
      <Create />
      <Divider orientation="vertical" variant="middle" flexItem />
      <EditUsers />
      {/* <CloudIntegrations /> */}
      <DeleteUser />
      <Recover />
    </Grid>
  );
};

export default Actions;
