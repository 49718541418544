import { Grid, Typography } from "@mui/material";
import Repository from "./Repository/Repository";

const UIText = {
  "es-CL": {
    header: "Repositorios",
  },
  "en-US": {
    header: "Summary",
  },
};

const Repositories = ({readOnly, recovery, locale }) => {
  const { header } = UIText[locale];
  const { repositories } = recovery;

  const repositoriesList = repositories.map((repo) => (
    <Repository {...{ key: repo.id, ...repo, readOnly }} />
  ));

  return (
    <Grid
      container
      item
      {...{
        paddingTop: 2,
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        xs: 12,
        rowGap: 2,
      }}
    >
      <Grid container item {...{ lg: 12 }}>
        <Typography fontWeight="bold" fontSize={18}>
          {header}
        </Typography>
      </Grid>
      {repositoriesList}
    </Grid>
  );
};

export default Repositories;
