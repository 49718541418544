import { Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const UIText = {
  "es-CL": { label: "Bloqueo de cambio de selección" },
  "en-US": { label: "Selection change block" },
};

const SelectionBlock = (props) => {
  const { cpassword, locale } = props;
  const { label } = UIText[locale];

  return (
    <Fragment>
      <Grid container item xs={6}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid container item xs={6}>
        <Typography>{cpassword ? "Activado" : "Desactivado"}</Typography>
      </Grid>
    </Fragment>
  );
};

export default SelectionBlock;
