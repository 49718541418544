import { Grid } from "@mui/material";
import SlimAlert from "../../../SlimAlert";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";

// const x = {
//     isEmpty: true,
//     UIText: {
//         universal: { message: "????" }
//     }
// };

const Empty = ({ isEmpty, UIText = { universal: {} } }) => {
  const { settings } = useUIBoilerplate();
  const { message } = UIText[settings.locale] ?? UIText.universal;

  if (!isEmpty) {
    return;
  }

  return (
    <Grid
      container
      item
      {...{
        height: "30vh",
        xs: 12,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <SlimAlert
        notCollapse
        {...{ errors: [message], severity: "info", xs: 6 }}
      />
    </Grid>
  );
};

export default Empty;
