const notEmptyQRCode = (value) => {
  if (value.match(/[^0-9]/)) {
    return "El codigo deben ser 6 numeros";
  } else if (value.length !== 6) {
    return "El codigo deben ser 6 numeros";
  } else if (!value) {
    return "El codigo no puede estar vacío";
  }
  return "";
};

export default notEmptyQRCode