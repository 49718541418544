export const parseMailFolders = (category, nodes, parentIdx) => {
  const newIdx = nodes.length;
  let newNode = { ...category, idx: newIdx, childrenIdx: [], parentIdx };
  nodes.push(newNode);

  if (!!category.childFolders) {
    for (const child of category.childFolders) {
      nodes[newIdx].childrenIdx.push(nodes.length);
      parseMailFolders(child, nodes, newNode);
    }
  }
};
