import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import CreateClientsAfterW365Post from "../ConfirmationModals/CreateClientsAfterPost";
import CreateUsersAfterPost from "../ConfirmationModals/CreateUsersAfterPost";
import DeleteDevices from "../ConfirmationModals/DeleteDevices";
import DeleteGroup from "../ConfirmationModals/DeleteGroup";
import DeleteSharedLinks from "../ConfirmationModals/DeleteSharedLink";
import DeleteUsers from "../ConfirmationModals/DeleteUser";
import DeprotectSharedLink from "../ConfirmationModals/DeprotectSharedLink";
import ProtectSharedLink from "../ConfirmationModals/ProtectSharedLink";
import ContactSupport from "../ContactSupport/ContactSupport";
import DeviceConfig from "../DeviceConfig/DeviceConfig";
import DeviceDataPop from "../DeviceDataPop/DeviceDataPop";
import DeviceEdit from "../DeviceEdit/DeviceEdit";
import FastRecovery from "../FastRecovery2/FastRecovery";
import FileVersions from "../FileVersions/FileVersions";
import FileDataPop from "../FilesDataPop/FilesDataPop";
import GroupForm from "../GroupCUForm/GroupForm";
import MailView from "../MailView/MailView";
import OrgCloudBackup from "../OrgCloudBackup/OrgCloudBackup";
import EditDevice from "../RecoveryRequest/Components/EditDevice/EditDevice";
import EditRecoveries from "../RecoveryRequest/Components/EditRecoveries/EditRecoveries";
import NewDevice from "../RecoveryRequest/Components/NewDevice/NewDevice";
import NewDeviceNewRequest from "../RecoveryRequest/Components/NewDeviceNewRequest/NewDeviceNewRequest";
import NewDevicesNewRequest from "../RecoveryRequest/Components/NewDevicesNewRequest/NewDevicesNewRequest";
import NewRepositoryNewRequest from "../RecoveryRequest/Components/NewRepositoryNewRequest/NewRepositoryNewRequest";
import ResetRequest from "../RecoveryRequest/Components/ResetRequest/ResetRequest";
import UsersNewRequest from "../RecoveryRequest/Components/UsersNewRequest/UsersNewRequest";
import RemoteSelection from "../RemoteSelection3/RemoteSelection3";
import SelectionHistory from "../SelectionHistory/SelectionHistory";
import W365UserDelete from "../Settings/Components/RemoveW365User/W365UserDelete";
import TwoFactorAuthentication from "../TwoFactorAuthentication/TwoFactorAuthentication";
import UserForm from "../UserCUForm/UserForm";
import UserCloudIntegrations from "../UserCloudIntegrations/UserCloudIntegrations";
import UsersCloudIntegration from "../UsersCloudIntegration/UsersCloudIntegration";
import UsersUpload from "../UsersUpload/UsersUpload";
import Windows365UserEnable from "../Windows365UserEnable/Windows365UserEnable";

const ModalsOutlet = () => {
  const { UI } = useUIBoilerplate();
  const modal = UI.modal ?? { type: "" };
  const { type: mType } = modal;

  switch (mType) {
    case "new-repository-new-request":
      return <NewRepositoryNewRequest />;
    case "new-users-request":
      return <UsersNewRequest />;
    case "edit-recoveries":
      return <EditRecoveries />;
    case "new-devices-new-request":
      return <NewDevicesNewRequest />;
    case "new-device-new-request":
      return <NewDeviceNewRequest />;
    case "reset-recovery-request":
      return <ResetRequest />;
    case "new-recovery-device":
      return <NewDevice />;
    case "edit-recovery-device":
      return <EditDevice />;
    case "enable-w365user":
      return <Windows365UserEnable />;
    case "delete-w365user":
      return <W365UserDelete />;
    case "enable-tfa":
      return <TwoFactorAuthentication />;
    case "contact-support":
      return <ContactSupport />;
    case "cloud-backup":
      return <OrgCloudBackup />;
    case "view-mail-content":
      return <MailView />;
    case "deprotect-shared-link":
      return <DeprotectSharedLink />;
    case "protect-shared-link":
      return <ProtectSharedLink />;
    case "delete-shared-links":
      return <DeleteSharedLinks />;
    case "file-versions":
      return <FileVersions />;
    case "file-details":
      return <FileDataPop {...{ modal: "1" }} />;
    case "remote-selection":
      return <RemoteSelection />;
    case "selection-history":
      return <SelectionHistory />;
    case "fast-recovery":
      return <FastRecovery />;
    case "delete-group":
      return <DeleteGroup />;
    case "delete-devices":
      return <DeleteDevices />;
    case "device-edit":
      return <DeviceEdit />;
    case "device-details-support":
      return <DeviceDataPop />;
    case "device-details":
      return <DeviceConfig />;
    case "delete-users":
      return <DeleteUsers />;
    case "download-creation-report":
      return <CreateUsersAfterPost />;
    case "download-clients-report":
      return <CreateClientsAfterW365Post />;
    case "upload-users":
      return <UsersUpload />;
    case "create-new-user":
      return <UserForm isNew />;
    case "edit-user":
      return <UserForm />;
    case "edit-user-cloud":
      return <UserCloudIntegrations />;
    case "multi-cloud-integrations":
      return <UsersCloudIntegration />;
    case "create-new-group":
      return <GroupForm isNew />;
    case "edit-group":
      return <GroupForm />;
    case "":
      return;
    default:
      console.error("modal type does not match with any known modal");
      return;
  }
};
export default ModalsOutlet;
