/**
 * A component that displays a device name with a tooltip.
 * @param {Object} props - The props object.
 * @param {string} props.fontSize - The font size of the device name.
 * @param {string} props.name - The name of the device.
 * @returns {JSX.Element} - The Device component.
 */
import { Tooltip, Typography } from "@mui/material";

const Device = ({ fontSize, name }) => {
  return (
    <Tooltip title={name}>
      <Typography noWrap {...{ fontSize, sx: { cursor: "default" } }}>
        {name}
      </Typography>
    </Tooltip>
  );
};

export default Device;
