import { Divider, Grid } from "@mui/material";
import DeleteBtn from "./Delete";
import AddDevice from "./AddDevice";
import EditBtn from "./Edit";
import Export from "./Export";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";

const Actions = () => {
  const { auth } = useUIBoilerplate();
  const {group,admin} = auth

  return (
    <Grid container item xs={12} justifyContent="end" columnGap={2}>
      {group === 1 && admin ===1 ? <Export /> : null}
      <AddDevice />
      <Divider orientation="vertical" variant="middle" flexItem />
      <EditBtn />
      <DeleteBtn />
    </Grid>
  );
};

export default Actions;
