import { CircularProgress } from "@mui/material";
import IconButton from "../../../../UI2/IconButton";

const UIText = { universal: {} };

const Loading = (props) => {
  const {size = 17} = props

  return (
    <IconButton
      {...{
        UIText,
        disabled: true,
        icon: <CircularProgress {...{size}} />,
        sx: { padding: 0.3 },
        size: "small",
        xs: "auto",
      }}
    />
  );
};

export default Loading