import Actions from "./Actions/Actions";
import headersData from "./Headers";
import FilesLayout from "../../UI2/BasicLayout/FilesLayout";
import Header from "./Header/Header";
import { useMemo } from "react";
import FilesRow from "./FilesRow/FilesRow2";
import useTrees from "../../../hooks2/useNewTrees";
import Node from "./TreeNode/Node";
import { useWorker } from "../../../hooks2/useWorker";
import { getDevicesId } from "../../../store/actions2/Devices/getDevices2";
import { getRepoId } from "../../../store/actions2/Devices/getRepositories";
import { getUsersId } from "../../../store/actions2/Users/getAllUsers";
import useURLQuery from "../../../hooks2/useURLQuery";
// import FilesRow from "./FilesRow/FilesRow";

const Core = (props) => {
  const { files, allFiles } = props;
  const trees = useTrees();
  const { file } = useURLQuery();
  let workerId = "";
  if (file) {
    workerId = "file-" + file;
  } else {
    workerId = getRepoId;
  }
  const { done: d1, working: w1, error: e1 } = useWorker(getUsersId);
  const { done: d2, working: w2, error: e2 } = useWorker(getDevicesId);
  const { done: d3, working: w3, error: e3 } = useWorker(getRepoId);
  const { done, working, error } = useWorker(workerId);

  const filesStatus = {
    usersStatus: { done: d1, working: w1, error: e1, worker: getUsersId },
    devicesStatus: { done: d2, working: w2, error: e2, worker: getDevicesId },
    repositoriesStatus: { done: d3, working: w3, error: e3, worker: getRepoId },
  };

  const header = <Header />;
  const plusActions = <Actions {...{allFiles}}/>;

  // console.log("all files",allFiles)
  // console.log("files", files)
  let elements = useMemo(() => {
    let directories = [];
    let Files = [];
    // console.log("files?", files);
    for (const item of files) {
      if (item.type === 0) {
        directories.push(<FilesRow {...item} {...{ key: item.id }} />);
      } else {
        Files.push(<FilesRow {...item} {...{ key: item.id }} />);
      }
    }
    return [...directories, ...Files];
  }, [files]);
  // console.log("elements", elements);

  let treeRoots = useMemo(() => {
    let items = [];
    for (const key in trees) {
      if (key.match("repo-")) {
        items.push(trees[key]);
      }
    }
    const roots = items.map((tree, key) => (
      <Node
        {...tree.nodes[0]}
        {...{ key, level: 0, repository: tree.data.id }}
      />
    ));
    return roots;
  }, [trees]);

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": { message: "Error al obtener archivos" },
        "en-US": { message: "Error fetching files" },
      },
    },
    empty: {
      isEmpty: !files.length,
      UIText: {
        "es-CL": { message: "No hay archivos o directorios disponibles" },
        "en-US": { message: "There are no available files or directories" },
      },
    },
    done,
    elements: elements,
  };

  return (
    <FilesLayout
      {...{
        plusActions,
        header,
        headersData,
        coreData,
        treeRoots,
        filesStatus,
      }}
    />
  );
};

export default Core;
