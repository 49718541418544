import { Help, HelpOutline } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions as UIactions } from "../../../../../store/slices/UI2";
import SingleTwinSelect from "../../../../UI2/Utilities/SingleTwinSelect";

const UIText = {
  "es-CL": {
    tooltip1: "Activar modo de ayuda",
    tooltip2: "Desactivar modo de ayuda",
  },
  "en-US": {
    tooltip1: "Activate help mode",
    tooltip2: "Deactivate help mode",
  },
};

const icon1 = <HelpOutline />;
const icon2 = <Help />;

const HelpMode = () => {
  const { dispatch, UI, settings } = useUIBoilerplate();
  const selected = UI["helpMode"] ?? false;
  const locale = settings.locale

  const { tooltip1, tooltip2 } = UIText[locale];

  const handleClick = () => {
    dispatch(UIactions.toggle("helpMode"));
  };

  let finalIcon = SingleTwinSelect(icon1, icon2, selected);
  let tooltip = SingleTwinSelect(tooltip1, tooltip2, selected);

  return (
    <Grid item>
      <Tooltip title={tooltip}>
        <IconButton {...{ onClick: handleClick, sx: { color: "secondary.light" } }}>
          {finalIcon}
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default HelpMode;
