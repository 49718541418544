import { Grid } from "@mui/material";
import filterObjKeys from "../../../utility/filterObjectKeys";

const AutoGrid = (props) => {
  const { container = true, children, xs, lg, md, sm, xl } = props;
  const filteredProps = filterObjKeys(props, "children");

  if (xs || md || lg || sm || xl) {
    return (
      <Grid item {...filteredProps} {...{ container }}>
        {children}
      </Grid>
    );
  } else {
    return children;
  }
};

export default AutoGrid;
