import { Delete } from "@mui/icons-material";
// import { UISliceActions } from "../../../../store/slices/UI2";
import ActionButton from "../../../../../UI2/BasicLayout/Components/FilterPlusActions/ActionButton";
import useUIBoilerplate from "../../../../../../hooks2/useUIBoilerplate";
import { recoveriesSliceActions } from "../../../../../../store/slices/recoveries";
import { UISliceActions } from "../../../../../../store/slices/UI2";
import { defaultSelected } from "../../../../../UI2/genericIDs";
// import { devicesData } from "../../Devices";

const UIText = {
  "es-CL": { label: "Eliminar" },
  "en-US": { label: "Delete" },
};

const DeleteBtn = () => {
  const { dispatch, UI } = useUIBoilerplate();
  const selected = UI.selected ?? [];
  // const devices = warehouse[devicesData] ?? {};

  const onClick = () => {
    console.log("selected", selected);
    dispatch(recoveriesSliceActions.deleteRecoveries(selected));
    dispatch(recoveriesSliceActions.removeUnusedRepositories())
    dispatch(UISliceActions.clear(defaultSelected))
    // let selectedDevices = [];
    // for (const mid of selected) {
    //   const device = devices[mid];
    //   selectedDevices.push(device);
    // }

    // dispatch(
    //   UISliceActions.openModal({
    //     type: "delete-devices",
    //     payload: { devices: selectedDevices },
    //   })
    // );
  };

  return (
    <ActionButton
      {...{
        disabled: !selected.length,
        onClick,
        UIText,
        icon: <Delete />,
      }}
    />
  );
};

export default DeleteBtn;
