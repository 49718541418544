import useUIBoilerplate from "../../../../../hooks2/useUIBoilerplate";
import { UISliceActions } from "../../../../../store/slices/UI2";
import { recoveriesSliceActions } from "../../../../../store/slices/recoveries";
import Button from "../../../../UI2/Button";
import { recoveryDateUI, recoveryOptionUI } from "./Options";

const UIText = {
  "es-CL": { label: "Guardar" },
  "en-US": { label: "Save" },
};

const Save = ({ recoveries }) => {
  const { dispatch, UI } = useUIBoilerplate();
  const { value: bkpDate } = UI[recoveryDateUI] ?? "";
  const option = UI[recoveryOptionUI] ?? "";

  const handleClick = () => {
    for (const recovery of recoveries) {
      const repositories = recovery.repositories.map((repo) => ({
        ...repo,
        bkpDate,
        option,
        details: "",
        recover: true,
      }));
      const newRecovery = { ...recovery, repositories, mode: "simple" };
      dispatch(recoveriesSliceActions.updateRecovery(newRecovery));
    }
    dispatch(UISliceActions.closeModal());
  };

  return <Button {...{ UIText, onClick: handleClick }} />;
};

export default Save;
