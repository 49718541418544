import Button from "../../UI2/Button";

const UIText = {
  "es-CL": { label: "Volver" },
  "en-US": { label: "Back" },
};

const Back = () => {
  return <Button {...{ UIText, navTo:-1, variant: "text" }} />;
};

export default Back;
