import BasicRow from "../../../../../UI2/BasicLayout/Components/BasicRow";
import Username from "./Components/Username";
import Actions from "./Components/Actions";
import Device from "./Components/Device";
import Size from "./Components/Size";
import Repositories from "./Components/Repositories";

const fontSize = 13;

const DeviceRow = (props) => {

  const data = {
    elements: [
      { content: <Username {...props} {...{ fontSize }} />, xs: 3 },
      { content: <Device {...props} {...{ fontSize }} />, xs: 3 },
      { content: <Repositories {...props} {...{ fontSize }} />, xs: 3 },
      { content: <Size {...props} {...{ fontSize }} />, xs: 1.5 },
      { content: <Actions {...props} />, justify: "end", xs: true },
    ],
  };

  return <BasicRow {...data} />;
};

export default DeviceRow;
