import { Dialog, Grid } from "@mui/material";
import useUIBoilerplate from "../../hooks2/useUIBoilerplate";
import { postedUsersData } from "../../store/actions2/UserForm/postUsers";
import { UISliceActions as UIactions } from "../../store/slices/UI2";
import SlimAlert from "../UI2/SlimAlert";
import BasicDialogContent from "../UI2/BasicDialog/BasicDialogContent";
import BasicDialogTitle from "../UI2/BasicDialog/BasicDialogTitle";
import BasicDialogActions from "../UI2/BasicDialog/BasicDialogActions";
import Button from "../UI2/Button";
import { Download } from "@mui/icons-material";
import { createUsersPDF } from "../../store/actions2/UserForm/createPDF";
import useTrees from "../../hooks2/useNewTrees";
import { groupsTreeId, selectedGroupIdx } from "../../store/actions2/GroupTraveler/loadGroupsTree";
import { useEffect } from "react";
import { warehouseSliceActions } from "../../store/slices/warehouse";
import { postClientsData } from "../../store/actions2/Windows365/postClients";
import { createClientsPDF } from "../../store/actions2/Windows365/createPDF copy";

const UIText = {
  "es-CL": { label: "Cerrar" },
  "en-US": { label: "Close" },
};
const UIText2 = {
  "es-CL": { label: "Descargar PDF" },
  "en-US": { label: "Download PDF" },
};

const UITextMessages = {
  "es-CL": {
    header: "Guardado de clientes finalizado",
    message: `El proceso de guardado de clientes finalizó. Le sugerimos descargar el archivo PDF con el resumen del resultado de la operación.`,
  },
  "en-US": {
    header: "Users creation finished",
    message: `The users creation process ended. We suggest that you download the PDF file summarizing the operation.`,
  },
};

const CreateClientsAfterW365Post = (props) => {
  const { dispatch, settings, UI, warehouse } = useUIBoilerplate();
  // const trees = useTrees() ?? {};
  // const groups = trees[groupsTreeId] ?? [];
  // const currentGroupIdx = UI[selectedGroupIdx]

  // let groupPath = "";
  // const buildTrace = (idx) => {
  //   const group = groups.find((x) => x.idx === idx);
  //    groupPath = "/" + group.name + groupPath;
  //   if (group.parentIdx !== null) {
  //     buildTrace(group.parentIdx);
  //   }
  // };

  //   const {  } = UI.modal2;
  const postedUsers = warehouse[postClientsData] ?? [];
  const locale = settings.locale;
  // const { devices, updateData: ud } = payload;


  const { header, message } = UITextMessages[locale];

  useEffect(()=>{
    return ()=>{
        dispatch(warehouseSliceActions.unload(postClientsData))
    }
  },[dispatch])

  const onClose = (e, reason) => {
    // if (reason !== "backdropClick") {
      dispatch(UIactions.closeModal());
    // }
  };

  const onClick = () => {
    dispatch(createClientsPDF({ postedUsersData, locale }));
  };


  return (
    <Dialog
      {...{
        open: true,
        onClose,
        maxWidth: "sm",
      }}
    >
      <BasicDialogTitle {...{ header }} />
      <BasicDialogContent>
        <Grid container item {...{ sx: { width: "100%" } }}>
          <SlimAlert
            notCollapse
            {...{ errors: [message], severity: "info", xs: 12 }}
          />
          <Grid
            container
            item
            {...{ padding: 3, xs: 12, justifyContent: "center" }}
          >
            <Button
              {...{
                UIText: UIText2,
                onClick,
                variant: "outlined",
                startIcon: <Download />,
                size: "medium",
              }}
            />
          </Grid>
        </Grid>
      </BasicDialogContent>
      <BasicDialogActions>
        <Button {...{ UIText, onClick:onClose }} />
      </BasicDialogActions>
    </Dialog>
  );
};

export default CreateClientsAfterW365Post;
