import { Grid, Tooltip, Typography } from "@mui/material";
import BasicRow from "../../../UI2/BasicLayout/Components/BasicRow";
import { styled } from "@mui/material/styles";
import {
  AdminPanelSettings,
  Delete,
  Email,
  Error,
  Key,
} from "@mui/icons-material";
import IconButton from "../../../UI2/IconButton";
import { UISliceActions } from "../../../../store/slices/UI2";
import { newUsersUI } from "../TopBar/UploadSheet";
import notEmptyUser from "../../../../utility/validations/notEmptyUser";
import useUIBoilerplate from "../../../../hooks2/useUIBoilerplate";
import { notEmptyName } from "../../../../utility/validations/notEmptyName";
import notEmptyPassword from "../../../../utility/validations/notEmptyPassword";
import { tooltipClasses } from "@mui/material/Tooltip";
import AutoHelp from "../../../UI2/Wrappers/AutoHelp";
import { useEffect } from "react";

const UIText = {
  "es-CL": { fixedTooltip: "Remover usuario" },
  "en-US": { fixedTooltip: "Remove user" },
};

const UITextAdmin = {
  "es-CL": { fixedTooltip: "Administrador" },
  "en-US": { fixedTooltip: "Administrator" },
};
const UITextMails = {
  "es-CL": { fixedTooltip: "Recibe correos" },
  "en-US": { fixedTooltip: "Receives mails" },
};
const UITextAccess = {
  "es-CL": { fixedTooltip: "Accede a la plataforma" },
  "en-US": { fixedTooltip: "Has access to the platform" },
};
const UITextDuplicate = {
  "es-CL": "El usuario debe ser unico y se encuentra duplicado",
  "en-US": "The user must be unique and has a duplicate",
};

export const rowErrorsUI = "rowErrors";

const UserRow = (props) => {
  const { id, mail, name, pass, admin, mails, access } = props;
  const { dispatch, settings, UI } = useUIBoilerplate();
  const users = UI[newUsersUI] ?? [];
  const locale = settings.locale;

  const onClick = () => {
    dispatch(UISliceActions.removeObj({ id: newUsersUI, key: id }));
    dispatch(UISliceActions.remove({ id: rowErrorsUI, key: id }));
  };

  let errors = [];
  const duplicate = users.find((x) => x.mail === mail && x.id !== id);
  if (duplicate) {
    errors.push(UITextDuplicate[locale]);
  }

  const mailError = notEmptyUser(mail, locale);
  if (mailError) {
    errors.push(mailError);
  }
  const nameError = notEmptyName(name, locale);
  if (nameError) {
    errors.push(nameError);
  }
  const passError = notEmptyPassword(pass, locale);
  if (passError) {
    errors.push(passError);
  }

  const error = !!errors.length;

  useEffect(() => {
    if (error) {
      dispatch(UISliceActions.add({ id: rowErrorsUI, key: id }));
    } else {
      dispatch(UISliceActions.remove({ id: rowErrorsUI, key: id }));
    }
  }, [dispatch, error, id]);

  const selectedColor = error ? "error" : "primary";
  const errorTTP = errors.map((x, idx) => <li key={idx}>{x}</li>);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: "none" } });

  const rowData = {
    error,
    elements: [
      {
        content: (
          <Grid container item xs={12} alignItems="center">
            {error ? (
              <Grid container item xs={1} columnSpacing={1}>
                <CustomWidthTooltip
                  placement="bottom-end"
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      <ul style={{ paddingLeft: 15 }}>{errorTTP}</ul>
                    </div>
                  }
                >
                  <Error color="error" />
                </CustomWidthTooltip>
              </Grid>
            ) : null}
            <Grid container item xs={error ? 11 : 12}>
              <Typography noWrap {...{ fontSize: 14 }}>
                {mail}
              </Typography>
            </Grid>
          </Grid>
        ),
        xs: 3,
      },
      {
        content: (
          <Typography noWrap {...{ fontSize: 14 }}>
            {name}
          </Typography>
        ),
        xs: 3,
      },
      {
        content: (
          <Grid container item columnGap={2}>
            <AutoHelp {...{ fixedTooltip: UITextAdmin[locale].fixedTooltip }}>
              <AdminPanelSettings color={admin ? selectedColor : "disabled"} />
            </AutoHelp>
            <AutoHelp {...{ fixedTooltip: UITextMails[locale].fixedTooltip }}>
              <Email color={mails ? selectedColor : "disabled"} />
            </AutoHelp>
            <AutoHelp {...{ fixedTooltip: UITextAccess[locale].fixedTooltip }}>
              <Key color={access ? selectedColor : "disabled"} />
            </AutoHelp>
          </Grid>
        ),
        xs: 3,
      },
      {
        content: (
          <Typography noWrap {...{ fontSize: 14 }}>
            {pass}
          </Typography>
        ),
        xs: true,
      },
      {
        content: (
          <IconButton
            {...{
              onClick,
              UIText,
              icon: <Delete {...{ sx: { fontSize: 18 } }} />,
            }}
          />
        ),
        justify: "end",
        xs: 0.5,
      },
    ],
  };
  return <BasicRow {...rowData} />;
};

export default UserRow;
