import useUIBoilerplate from "../../../hooks2/useUIBoilerplate";
import BasicLayout from "../../UI2/BasicLayout/BasicLayout";
import Actions from "./Actions/Actions";
import headersData from "./Headers2";
import SharedRow from "./SharedRow/SharedRow";

// hasHeaders, empty, working, error, done, elements
const Core = (props) => {
  const {links, allLinks, done,error,working,workerId}  =props
  const { settings } = useUIBoilerplate();

  const UIText = {
    "es-CL": `Archivos compartidos`,
    "en-US": `Shared files`,
  };
  const header = UIText[settings.locale];
  const plusActions = <Actions />;

  const elements = links.map((data, idx) => <SharedRow key={idx} {...data} />);

  const coreData = {
    working,
    error: {
      workerId,
      error,
      UIText: {
        "es-CL": { message: "Error al cargar los archivos compartidos: " + error },
        "en-US": { message: "Error loading the shared files: " + error },
      },
    },
    empty: {
      isEmpty: !links.length && done && !error,
      UIText: {
        "es-CL": { message: "No hay archivos compartidos disponibles" },
        "en-US": { message: "There are no shared files available" },
      },
    },
    done,
    elements,
  };

  return <BasicLayout {...{ plusActions, header, headersData, fullArray:allLinks, coreData }} />  ;
};

export default Core;
